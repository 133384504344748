.card-highlight .feat_property {
  border: 1px solid #db1c1f;
}

.card-highlight .feat_property .details {
  background: #e6c3c6 !important;
}

.card-highlight .feat_property .fp_footer {
  background: #e6c3c6;
}
.feat_property.list.agency .thumb {
  height: 160px;
}
.feat_property.agency .thumb img {
  object-fit: contain;
}
.add-to-favorite-heart-icon {
    display: block;
}