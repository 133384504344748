.default-logo  {
    height: 45px;
    width: 250px;
    @media (max-width: 1125px) and (min-width: $screen-md-max) {
        width: 190px;
    }
}
.color-red {
    color: $red !important;
}
.color-biztositok {
    color: $color-biztositok !important;
}
.color-dark-grey {
    color: $dark-grey !important;
}
.color-dark-green {
    color: $dark-green !important;
}

.ace-responsive-menu {
    li.menu-active {
        .title {
            color: $red !important;
        }
    }
    .active-menu {
        color: $red !important;
    }
}

.login_form {
    .form-group {
        width: 100%;
        margin-bottom: 0;

        .help-block-error {
            color: $red;

            a, a:hover, a:active {
                color: $red;
                text-decoration: underline;
            }
        }
    }
    a {
        @include hover-underline;
    }
}

.additional_details {
    table {
        @include screen-sm-down {
            width: 100%;
        }

        td {
            font-size: 14px;
            color: $dark-grey;
            padding-bottom: 8px;
            vertical-align: baseline;
            span {
                font-weight: bold;
            }
        }
        td.left {
            padding-right: .5rem;
            @include screen-sm-down {
                width: 50%;
            }
        }
    }
}

.login_thumb {
    img {
        border-radius: .3rem;
    }
}

.forgot-password {
    p {
        font-size: 16px;
    }
}


.similar-estate {
    h4 {
        font-weight: bold;
        font-size: 20px;
    }
}

#erkely-radio {
    .radio {
        label {
            margin-bottom: 3px !important;
        }

    }
}

.ad_here_box{
    background-image: url("/images/hirdessen_nalunk.jpg");
    height: 197px;
    background-repeat: no-repeat;
    background-size: cover;
    button{
        position: absolute;
        bottom: 22%;
        left: 22%;
        width: 55%;
    }

}
.ad_here_title{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 17px;
}

a {
    &.hover-underline {
        &:hover, &:focus, &:active {
            text-decoration: underline;
        }
    }
}

.content-padding {
    padding-top: 50px;
    padding-bottom: 50px;
}
.bg-grey {
    background-color: $dark-grey !important;
}
.posr-bg-grey {
    background-color: rgba(15,21,31,0.5) !important;
}

.subscribe{
    p{
        font-size: 18px;
        font-weight: bold;
    }
    .info{
        font-size:16px;
        color: $dark-grey;
    }
    a{
        color: $red;
        &:hover{
            text-decoration: underline;
        }
    }
}
.subscribe__center {
    width:700px;
    margin: 0 auto;
    margin: 0 auto;
    padding: 20px;
}

.bb-solid-grey {
    border-bottom: 1px solid rgba(197, 201, 208, 0.4) !important;
}


.line-solid-grey {
    width: 100%;
    height: 1px;
    background-color: rgba(197, 201, 208, 0.4) !important;
    margin: 50px 0 40px 0;
    &.smaller {
        margin: 10px 0 10px 0;
        color: #7777;
    }
}
.separate-line {
    width: 1px;
    height: 100%;
    background-color: rgba(197, 201, 208, 0.4) !important;
}

.form-control {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    color: #434343;
    font-size: 14px;
    font-family: Arial;
    height: 52px;
    line-height: 1.2;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.fav_list {
    p:last-child {
        margin-bottom: 0;
    }
}

.btn-danger{
    background-color: $red !important;
    border: 1px solid $red;
    &:hover{
        background-color: $red !important;
        border: 1px solid $red;
    }
}

.more-news{
    padding: 25px;
    h3{
        font-size: 20px;
    }
    a{
        font-size: 14px;
        &:hover{
            color: $red;
        }
    }
    ul{
        color:#434343;
        list-style: disc!important;
        padding-left: 20px;
        li{
            display:list-item;
            padding-bottom: 8px;
        }
    }
}

.col-separator {
    margin-top: 12px;
}

.dark-grey {
    color: $dark-grey;
}

.office-advert-item {
    .thumb {
        .img-whp {
            background-color: #fff;
            object-fit: contain;
        }
    }
}

.site-map-item {
    .title {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
        a {
            color: $dark-grey;
            @include hover-red;
        }
    }
    .desc{
        font-size: 12px;
    }
    margin-bottom: 20px;
}
.w-150 {
    width: 150px !important;
}
.w120 {
    width: 120px !important;
}
.w-100 {
    width: 100px !important;
}
.w-90 {
    width: 90px !important;
}

.container-big {
    max-width: 1370px !important;
}
.search-row {
    .border-right {
        border-right: 1px solid $dark-grey;
    }
    .border-left {
        border-left: 1px solid $dark-grey;
    }
}

.saved-plan {
    border: 1px solid $light-grey;
    border-radius: 8px;
    margin-bottom: 30px;

    .left-button {
        padding: 15px 0;
        @include screen-md-up {
            border-right: 1px solid $light-grey;
            border-left: 1px solid $light-grey;
        }
        @include screen-sm-up {
            height: 100%;
        }
    }

    .right-button{
        padding-bottom: 15px;
        @include screen-sm-up {
            padding-left: 12px;
        }

        @include screen-sm-up {
            height: 100%;
        }
    }

    @include screen-xs {
        a {
            display: block;
        }
    }

    .left-text {
        padding: 15px;
    }
}

.saved-search{
    border: 1px solid $light-grey;
    margin-bottom: 30px;
    border-radius: 8px;
    .upper{
        border-bottom: 1px solid $light-grey;
        .left-text{
            padding:15px;
        }

        .left-button{
            @include screen-md-up {
                border-right: 1px solid $light-grey;
                border-left: 1px solid $light-grey;
            }
            @include screen-sm-up {
                height: 100%;
            }
            padding-top: 15px;
        }
        .right-button{
            padding-bottom: 15px;
            @include screen-sm-up {
                padding-left: 12px;
            }
            @include screen-sm-up {
                height: 100%;
            }
        }
        @include screen-xs {
            a {
                display: block;
            }
        }
    }
    .lower{
        font-size: 14px;
        label{
            margin-bottom: 0;
        }
        .checkbox-div{
            display: inline-block;
        }
        .separator{
            margin: 0 15px;
            display: inline-block;
        }
        .custom-control-label{
            padding-left:0;
        }
        .ui_kit_checkbox{
            margin-bottom: 0;
        }
    }
}
.navbered {
    .dropdown-content {
        border: 1px solid #c5c9d0;
    }
}

.favorite-button {
    transition: 0.3s ease-in-out;
    span.flaticon-heart {
        color: #fff !important;
    }
    &.favorite-element {
        opacity: 1 !important;
        background-color: $red !important;
    }
}
.user-menu-dd {
    padding-bottom: 22px !important;
    .title {
        &:before {
            padding-right: 5px;
        }
    }
}

.ace-responsive-menu > li > a > .arrow {
    &:before {
        content: '';
    }
}

.sidebar_feature_listing {
    .break-all {
        word-break: break-all;
        line-height: 1.2;
    }
}

.sl_creator {
    .links {
        a {
            font-size: 14px;
            @include hover-underline;
        }
    }
}
.map-estate-list-opener {
    position: absolute;
    z-index: 10;
    height: 50px;
    width: 50px;
    background-color: rgba(67,67,67, 0.7);
    color: #fff;
    top: 50%;
    cursor: pointer;
    margin-left: 15px;
    &.open {
        right: 0;
        margin-left: 0;
        margin-right: 15px;
        @include screen-xs {
            top: calc(50% - 137px);
        }
    }

}
.map-canvas {
    .infobar {
        @include screen-xs {
            display: none;
        }
    }
}

nav#menu:not(.mm-menu) {
    display: none;
}
.office-seller {
    .media {
        img {
            width: 110px;
            @include screen-xs {
                width: 100%;
            }
        }
    }
}

.toggle-form {
    color: $dark-grey;
    cursor: pointer;
    i {
        transition: 0.15s;
    }
}

.select2-container .select2-search--inline .select2-search__field {
    margin-top: 7px !important;
}

.select2-container--krajee-bs4 .select2-selection--multiple .select2-search--inline .select2-search__field {
    min-width: 100px;
}

.select2-selection__choice__remove {
    margin-top: 2px;
    cursor: pointer;
    transition: .15s;
}


.detailed-search-link{
    color: #db1c1f !important;
    font-weight: bold;
}
.detailed-search-link * {
    color: #db1c1f !important;
}

#elastic-switcher {
    position: fixed;
    top: 0;
    right: 0;
    padding: 5px 10px;
    z-index: 1001;
}

#advertiser-for-fancy {
    border-radius: .3rem;
}

.sidebar_feature_property_slider .feat_property .thumb .thmb_cntnt .fp_price {
    bottom: 50px;
    left: 10px;
}
.feat_property .thumb .thmb_cntnt .fp_price {
    bottom: 15px;
    font-size: 22px;
    font-family: Arial;
    color: #ffffff;
    font-weight: bold;
    left: 10px;
    line-height: 1.2;
    position: absolute;
}

.select2-container--krajee-bs4 .select2-selection{
    border-radius:8px;
}

.calculation-result{
    border-radius: 8px;
    border: 2px solid $red;
}

.white-rounded-border-div{
    border: 1px solid #ebebeb;
    border-radius: 8px;
    background-color: white;
    margin-bottom: 25px;
}

.record-not-found {
    margin-bottom: 35px;
    text-align: center;
    a{
        color: $red;
    }
}

.ad-sidebar-wrap{
    margin-bottom: 30px;
}

.article-outdated-warning-box{
    background-color: #e9ecef;
    padding: 20px;
    border-radius: 8px;
    color: black;
    border-left: 8px solid;
    border-color: #db1c1f;
    font-variant: small-caps;
    font-style: italic;
    font-size: 14px;
    margin-bottom: 30px;
}

.fancybox-content{
    min-width:100px;
}


.estate-endpage-message-box{
    border: 1px solid $red !important;
    .search_option_button {
        text-align: center;
        color: #434343;
        font-size: 14px;
        .btn {
            margin-top: 22px;
        }
    }
    .custom-checkbox {
        label {
            font-size: 14px;
            color: #434343 !important;
        }
    }
    .sl_creator {
        margin-top: 34px;
        &.office-seller {
            .media {
                &.media-responsive {
                    align-items: center !important;
                    flex-direction: column;
                    text-align: center;
                    .media-body {
                        h5 {
                            font-size: 20px;
                            text-transform: uppercase;
                        }
                        .tel, .email {
                            a {
                                font-size: 20px !important;
                                i {
                                    color: $red;
                                    padding-right: 5px;
                                }
                            }
                        }
                        .email {
                            a {
                                font-size: 16px !important;
                            }
                        }
                    }
                }
            }
        }
        h4 {
            font-size: 20px;
            text-align: center;
            font-weight: 700;
        }

        img {
            border-radius: 50%;
            border: 2px solid $red;
            box-shadow: 5px 3px 11px -2px rgba(0,0,0,0.17);
            -webkit-box-shadow: 5px 3px 11px -2px rgba(0,0,0,0.17);
            -moz-box-shadow: 5px 3px 11px -2px rgba(0,0,0,0.17);
            margin-bottom: 14px !important;
            @media (max-width: 575px) {
                align-self: center !important;
                width: 150px;
            }
        }
    }
    &.colored-box {
        background-color: #fff3d9 !important;
        //input, textarea {
        //    background-color: #e8d6ae !important;
        //}
    }
    .contact-box {
        z-index: 1000;
        @media (min-width: 576px) {
            display: none;
        }
        opacity: 1;
        transition: 0.5s ease-in-out;
        &.hidden {
            opacity: 0;
            bottom: -150px;
        }
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 2px solid $red;
        background-color: #fff;
        padding: 10px 0;
        .sasw_list {
            padding-left: 5px;
            .search_option_button {
                margin-bottom: 0 !important;
                button {
                    margin-top: 0 !important;
                }
            }
        }
        .contact-box-callback {
            padding-right: 5px;
        }
    }
}

.our_agents_home6_slider {
    .item {
        img {
            object-fit: cover;
        }
    }
}

#subscribe-popup{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1050;
    width: 100%;
    @media (min-width: 576px) {
        width: 500px;
    }
    height: 150px;
    overflow: hidden;
    outline: 0;
    display:none;

    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0,0,0,.2) !important;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-top-style: solid;
    border-top-width: 1px;
    border-radius: 2px !important;
    outline: 0 !important;
    border-top: solid 2px #db1c1f !important;

    .close{
        margin-bottom: 5px !important;
        float: left !important;
        padding: 0 !important;
        background-color: transparent !important;
        border: 0 !important;
        appearance: none !important;
        font-size: 30px !important;
        color: #000 !important;
        font-weight: 100 !important;
        width: 30px !important;
        height: 30px !important;
        background-color: lightgray !important;
    }

    p {
        line-height: 24px !important;
        margin-bottom: 30px !important;
        font-size: 20px !important;
        font-weight: 700 !important;
        padding-left: 30px !important;
        margin: 0 !important;
        padding-bottom: 15px !important;
    }

    .subscribe-modal-button {
        background-color: #db1c1f !important;
        border-radius: 8px !important;
        color: #fff !important;
        -webkit-transition: all .3s ease !important;
        -o-transition: all .3s ease !important;
        transition: all .3s ease !important;
        padding: 10px 20px !important;
        font-size: 18px !important;
    }
}

.rezsi_attr {
    position: relative;

    &:before {
        content: " ";
        width: 44px;
        height: 30px;
        background: url('/images/new-badge.svg') 0 0 no-repeat transparent;
        display: block;
        position: absolute;
        left: -50px;
    }

}

.introduction-text{
    padding: 30px;
}

.sidebar_listing_list{
    .form-control.is-valid{
        border: 1px solid #ebebeb;
        padding: .375rem .75rem;
        background: none;
        box-shadow: initial;
    }

    .form-control.is-valid:focus, .form-control:valid:focus {
        border: 1px solid #80bdff;
        padding: .375rem .75rem;
        background: none;
        box-shadow: initial;
    }
}

.home_adv_srch_opt{
    .map-selector .field-locations-main .select2-selection::after {
        background: transparent;
    }

    .form-control.is-valid{
        border: 1px solid #ebebeb;
        padding-right: 0rem;
        background: white;
    }

    #searchform-price.is-valid{
        border-right: none !important;
    }

    .form-control.is-valid:focus{
        box-shadow:none;
    }
    .is-invalid{
        border:1px solid #ebebeb; 
        border-right: none !important;
    }

    .input-group-text {
        background-color: transparent;
        border: 1px solid #ebebeb;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        font-weight: 700;
    }
    
}

