/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
/* Small devices (tablets, 768px and up) */
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
/*  (min-width:1280px)+++ */
/*  (min-width:1200px)+++ */
/*  (max-width:1200px)+++ */
/* (min-width:992px) (max-width: 1199.98px) // Large devices (desktops, less than 1200px) */
/* ExTra Class Start*/
/* ExTra Class End*/
/* Margin Padding Change In Responsive Area */
/* END */
/* home responsibe style 2 Start*/
/* home slider end */
/* Medium devices (min-width:768px) (max-width: 991.98px) // (tablets, less than 992px) */
/* ExTra Class Start*/
/* ExTra Class End*/
/* Home responsibe style Start*/
/* home slider end */
/* Small devices (min-width: 576px) (max-width: 767.98px) // (landscape phones, less than 768px) */
/* ExTra Class Start*/
/* ExTra Class End*/
/* Home Content*/
/*.feature_property_slider .feat_property{margin: 30px 20px 30px;}*/
/* Extra small devices (min-width:361px) (max-width: 575.98px) // (portrait phones, less than 576px) */
/* (min-width:361px) (max-width:520px) */
/* ExTra Class */
/* ExTra Class End*/
/* (min-width:480px) (max-width:500px) */
/* (min-width:361px) (max-width:479px) */
/*.sidebar_switch{display: none;}*/
/*.sidebar_content_details{display: block;position: relative !important;padding-top: 0;margin: 0 auto 30px !important;width: auto;}*/
/* ExTra Class Start*/
/* ExTra Class End*/
/* (min-width:321px) (max-width:360px) */
@media only screen and (max-width:1600px) {
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 65%;
      }
    }
  }
  .bg-img3 {
    border-radius: 0px;
  }
  .home-three {
    &:before {
      border-radius: 0px;
    }
  }
}
@media only screen and (max-width:1440px) {
  .dn-1440 {
    display: none;
  }
  .dib-1440 {
    display: inline-block;
  }
  .mouse_scroll {
    right: -120px;
  }
  .our-dashbord.dashbord {
    .container-fluid {
      padding-left: 8%;
      padding-right: 15px;
    }
  }
  .home1-mainslider {
    overflow-x: hidden;
  }
  .feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-next {
      right: -25px;
    }
  }
  .feature_property_home6_slider_shorted.owl-carousel.owl-theme.owl-loaded {
    .owl-next {
      right: -25px;
    }
  }
  .our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-next {
      right: -25px;
    }
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 60%;
      }
    }
  }
  .feature_property_half_clist.style2 {
    li.extrawide {
      width: 95%;
    }
  }
}
@media only screen and (max-width:1366px) {
  .dn-1366 {
    display: none;
  }
  .dib-1366 {
    display: inline-block;
  }
  .mouse_scroll {
    right: -100px;
  }
  .mouse_scroll.home8 {
    right: -80px;
  }
  .m_box.our-dashbord {
    &:before {
      width: 23.5%;
    }
  }
  .sasw_list {
    .max_area {
      float: right;
    }
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 58%;
      }
    }
  }
  .sidebar-menu {
    width: 230px;
    li.header {
      padding-left: 15px;
    }
  }
  .our-dashbord.dashbord {
    .container-fluid {
      padding-left: 3%;
      padding-right: 15px;
    }
  }
  .ff_one {
    .detais {
      margin-top: 0;
    }
  }
  .ht_left_widget.home3 {
    .header_search_widget {
      .mailchimp_form {
        .form-control {
          width: 200px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_three {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 30px 30px 30px 10px;
        }
      }
    }
  }
  .becomea_instructor {
    padding: 65px 15px 55px 100px;
  }
  .becomea_instructor.style2 {
    padding: 65px 100px 55px 15px;
  }
  .ht_left_widget.home5 {
    margin-top: 15px;
  }
  header.header-nav.menu_style_home_five {
    .container-fluid {
      padding: 0 15px;
    }
  }
  .blog_post_home6_date {
    .post_grid {
      padding: 30px 10px 30px 10px;
    }
  }
  .team_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
      left: -30px;
    }
    .owl-next {
      right: -30px;
    }
  }
  .feature_post_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
      left: -10px;
    }
    .owl-next {
      right: -10px;
    }
  }
  .cart_page_form.style3 {
    .table_body {
      th {
        padding: 20px 15px;
        width: 35%;
      }
      td {
        padding: 20px 15px;
      }
    }
  }
}
@media only screen and (max-width:1280px) {
  .mouse_scroll {
    right: -50px;
  }
  .brdr_left_right {
    &::before {
      left: -10px;
    }
  }
  .feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-next {
      right: -25px;
    }
  }
  .feature_property_home6_slider_shorted.owl-carousel.owl-theme.owl-loaded {
    .owl-next {
      right: -25px;
    }
  }
  .our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-next {
      right: -25px;
    }
  }
  .mouse_scroll.home8 {
    right: 0;
  }
  .feature_property_half_clist {
    li.extrawide {
      width: 46%;
    }
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 54%;
      }
    }
  }
  .iu_heading {
    .candidate_revew_search_box {
      input.form-control {
        width: 70%;
      }
    }
  }
  header.header-nav.menu_style_home_one {
    a.navbar_brand {
      margin-top: 21px;
    }
    .ace-responsive-menu {
      >li {
        >a {
          padding: 37px 20px 27px 5px;
        }
      }
    }
  }
  .home1-mainslider {
    .banner-style-one {
      .banner-title {
        font-size: 40px;
      }
    }
  }
  .ht_left_widget {
    ul {
      li.list-inline-item {
        &:first-child {
          margin-right: 10px;
        }
        &:nth-child(2) {
          margin-right: 65px;
        }
      }
    }
  }
  .header_search_widget {
    .mailchimp_form {
      .form-control {
        padding-left: 15px;
        width: 400px;
      }
    }
  }
  .becomea_instructor {
    padding: 65px 15px 55px 50px;
  }
  .becomea_instructor.style2 {
    padding: 65px 50px 55px 15px;
  }
  .blog_post_home2 {
    .details {
      bottom: 80px;
    }
  }
  .blog_post_home2.one {
    .details {
      margin-top: 100px;
    }
  }
  .about_home3 {
    .partners_thumb_list {
      li {
        margin-right: 30px;
      }
    }
  }
  .hvr_img_box {
    padding: 40px 25px;
  }
  .about_box_home6 {
    .thumb {
      &:before {
        height: 355px;
        width: 540px;
      }
    }
  }
  .col-lg-7.col-xl-7.pr0 {
    padding-right: 5px !important;
  }
  .col-lg-5.col-xl-5.pl0 {
    padding-left: 5px !important;
  }
  .footer_menu_widget.home6 {
    ul {
      li {
        padding-right: 5px;
      }
    }
  }
  .logo-widget.home6 {
    span {
      font-size: 18px;
      vertical-align: middle;
    }
  }
  .about_home6 {
    .partners_thumb_list {
      li {
        margin-right: 20px;
      }
    }
  }
  .blog_post_home6_date {
    .post_grid {
      padding: 30px 10px 30px 20px;
    }
  }
  .img_hvr_box.home7 {
    width: auto;
  }
  .logo-widget.home8 {
    margin-left: 100px;
  }
  .main-banner-wrapper.home7 {
    .carousel-btn-block {
      .carousel-btn.left-btn {
        left: 15px;
      }
      .carousel-btn.right-btn {
        right: 15px;
      }
    }
  }
  .blog_tag_widget {
    .tag_list {
      li {
        &:nth-child(3) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .blog_grid_post.style2 {
    .details {
      padding: 20px 45px 25px 40px;
    }
  }
  .courses_single_container {
    .cs_row_five {
      .student_feedback_container {
        .s_feeback_content {
          .sonny_progressbar {
            padding-left: 70px;
          }
        }
        .aii_average_review {
          right: 15px;
        }
      }
    }
    .cs_row_four {
      .about_ins_container {
        .details {
          .review_list {
            float: none;
          }
        }
      }
    }
  }
  .instructor_pricing_widget {
    padding: 30px 15px;
  }
  .blog_grid_post.style2.event_lists {
    border-left: 2px solid rgb(237, 239, 247);
    .details {
      padding: 30px;
    }
  }
}
@media only screen and (min-width:1200px) {
  .dashbord {
    .container {
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 auto;
      max-width: 1395px;
    }
  }
}
@media only screen and (max-width:1200px) {
  .mouse_scroll {
    right: 0px;
  }
  .fn-xl {
    float: none !important;
  }
  .home-one {
    margin-top: -151px;
  }
  header.header-nav.menu_style_home_one {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 37px 10px 27px 5px;
        }
      }
    }
    ul.ace-responsive-menu {
      li.add_listing {
        width: 165px;
      }
    }
  }
  .inbox_chatting_box {
    .chatting_content {
      li.media.reply.first {
        margin: 0;
      }
    }
  }
  .m_box.our-dashbord {
    &:before {
      width: 25%;
    }
  }
  .cnddte_fvrt.our-dashbord {
    &:before {
      width: 25%;
    }
  }
  .ff_one {
    .detais {
      padding: 35px 20px 18px 35px;
      display: inline;
    }
    padding: 15px 10px;
  }
  .feature_property_home6_slider.owl-carousel.owl-theme {
    .owl-stage-outer {
      display: block;
    }
  }
  .feature_property_home6_slider_shorted.owl-carousel.owl-theme {
    .owl-stage-outer {
      display: block;
    }
  }
  .our_agents_home6_slider.owl-carousel.owl-theme {
    .owl-stage-outer {
      display: block;
    }
  }
  header.header-nav.menu_style_home_three {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 30px 25px 30px 10px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_four.home8 {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 18px 15px 18px 15px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_five {
    a.navbar_brand {
      margin-right: 15px;
    }
  }
  .about_box_home7 {
    .thumb {
      &::before {
        height: 350px;
        width: 540px;
      }
    }
  }
  .user_board {
    padding: 0 10px 0 0;
  }
  .my_course_content_list {
    .mc_content_list {
      .sonny_progressbar {
        .bar-container {
          margin-left: -175px;
          margin-right: 115px;
        }
        p.title {
          margin-right: -15px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1290px) {
  .p0-mdd {
    padding: 0;
  }
  .m0-mdd {
    margin: 0 !important;
  }
  .tac-md {
    text-align: center;
  }
  .dn-lg {
    display: none !important;
  }
  .dn-1199 {
    display: none !important;
    display: none;
  }
  .plpr5-md {
    padding-left: 5px;
    padding-right: 5px;
  }
  .fn-1199 {
    float: none !important;
  }
  .fn-lg {
    float: none !important;
  }
  .pr0-md {
    padding-right: 0;
  }
  .plpr0-md {
    padding-left: 0;
    padding-right: 0;
  }
  .pr15-xl {
    padding-right: 15px !important;
  }
  .pl15-xl {
    padding-left: 15px !important;
  }
  .pl0-1199 {
    padding-left: 0;
  }
  .pr0-1199 {
    padding-right: 0;
  }
  .ulockd-pad9100 {
    padding-left: inherit !important;
  }
  .pl130-md {
    padding-left: 130px;
  }
  .ulockd-mrgn12-150 {
    margin-top: auto;
  }
  header.header-nav.menu_style_home_one {
    ul.sign_up_btn {
      margin-top: 20px;
    }
    .mk-search-trigger {
      top: 25px;
    }
    padding: 0 30px;
    a.navbar_brand {
      margin-top: 21px;
    }
    .ace-responsive-menu {
      >li {
        >a {
          padding: 30px 18px 27px 10px;
        }
      }
    }
    ul.ace-responsive-menu {
      li.add_listing {
        width: 100px;
        a {
          top: -25px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_one.style2 ul.ace-responsive-menu li.list_s {
    &:before {
      height: 40px !important;
      top: 21px !important;
    }
  }
  header.header-nav.dashboard {
    padding: 0 5px 0 15px;
  }
  header.header-nav.style_one {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 27px 10px 27px 5px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_three {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 30px 20px 30px 10px;
          padding: 30px 10px 30px 10px;
        }
      }
      li {
        &:nth-child(4) {
          ul.sub-menu {
            li {
              ul.sub-menu {
                margin-left: -520px;
              }
            }
          }
        }
      }
    }
    .container-fluid {
      padding: 0 30px;
    }
    .ht_left_widget.home3.float-left {
      float: left !important;
      margin-top: 20px;
    }
    a.navbar_brand {
      margin-right: 15px;
      margin-top: 15px;
    }
    .ht_left_widget {
      ul {
        li.list-inline-item {
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  header.header-nav.menu_style_home_four {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 30px 20px 30px 10px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_one.style2 {
    ul.ace-responsive-menu {
      li.add_listing {
        a {
          line-height: .7;
        }
      }
    }
  }
  .sign_up_btn {
    .btn {
      width: auto;
    }
  }
  .cnddte_fvrt.our-dashbord {
    &:before {
      width: 35%;
    }
  }
  .home1_adsrchfrm {
    padding: 30px 20px;
  }
  .home1-advnc-search {
    ul {
      li {
        &:first-child {
          .form-control {
            width: 150px;
          }
        }
        &:nth-child(3) {
          .form-group {
            .form-control {
              width: 150px;
            }
          }
        }
        .search_option_button {
          button {
            width: 140px;
          }
        }
      }
    }
    .search_option_two {
      .dropdown.bootstrap-select {
        >.dropdown-toggle {
          width: 150px;
        }
      }
    }
  }
  .small_dropdown2 {
    .dd_btn {
      width: 100px;
    }
  }
  .dropdown-content {
    min-height: 300px;
  }
  .feature_property_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-stage-outer {
      padding: 0;
    }
  }
  .feat_property {
    .details {
      .fp_footer {
        .fp_pdate {
          float: left !important;
          //margin-left: 55px;
        }
        .fp_meta {
          li {
            vertical-align: text-top;
          }
        }
      }
    }
  }
  .feat_property.list {
    .details {
      .fp_footer {
        .fp_pdate {
          float: right !important;
        }
      }
    }
  }
  .footer_mailchimp_form {
    .col-auto {
      input.form-control {
        width: 150px;
      }
    }
  }
  .footer_social_widget {
    li {
      padding-left: 10px;
      padding-right: 0;
    }
  }
  .footer_qlink_widget {
    padding-left: 50px;
  }
  ul.ab_counting {
    li {
      margin-right: 20px !important;
    }
  }
  ul.mc_child_list {
    margin-bottom: 30px;
  }
  ul.mc_parent_list {
    ul.mc_child_list.one {
      li {
        padding: 10px 50px 10px 50px;
      }
    }
  }
  .checkout_form {
    .checkout_coupon {
      form {
        input.coupon_input {
          width: 200px;
        }
        input {
          margin-right: 15px;
        }
      }
    }
  }
  .feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
      left: -15px;
    }
    .owl-next {
      right: -15px;
    }
  }
  .home-one {
    .home-text.home5 {
      position: relative;
    }
  }
  .home-text.home5 {
    .discounts_para {
      margin-bottom: 30px;
    }
    ul {
      li {
        margin-bottom: 10px;
      }
    }
  }
  .home1-advnc-search.home5 {
    padding: 0;
  }
  .ht_left_widget {
    //left: 22%;
    margin-top: -15px;
    position: relative;
    ul {
      li.list-inline-item {
        &:nth-child(2) {
          //margin-left: -250px;
        }
      }
    }
    &.ht-right {
      left: 0 !important;
      margin-left: 0 !important;
      margin-top: 15px;
    }
  }
  .property_city_home6 {
    .details {
      padding: 40px 10px;
    }
  }
  .home1-advnc-search.home7 {
    ul {
      li {
        &:nth-child(2) {
          width: 300px;
        }
      }
    }
  }
  .why_chose_us.home7 {
    padding: 25px 30px;
  }
  .mouse_scroll.home8 {
    right: 0;
  }
  .testimonial_item {
    .details {
      margin: 0 0 0 25px;
    }
  }
  .home1-advnc-search.home10 {
    ul {
      li {
        margin-right: auto !important;
      }
    }
  }
  .for_blog.feat_property.home9 {
    .thumb {
      top: -90px;
    }
    margin-top: 90px;
  }
  .blog_sp_post_meta {
    li {
      &:nth-child(2) {
        margin-right: 10px;
      }
      &:nth-child(4) {
        margin-right: 10px;
      }
      &:nth-child(6) {
        margin-right: 10px;
      }
    }
  }
  .sasw_list {
    .min_area {
      width: 100%;
    }
    .max_area {
      width: 100%;
    }
  }
  .home_two_map.style2 {
    display: block;
  }
  .map-canvas.half_style {
    height: 400px;
    position: relative !important;
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 65%;
      }
    }
  }
  .feature_property_half_clist.style2 {
    li.extrawide {
      width: 48%;
    }
  }
  .sidebar_switch.style2 {
    #main2 {
      .filteropen2 {
        left: auto;
        right: 10px;
      }
    }
  }
  .sidebar-menu {
    width: 250px;
  }
  .ff_one {
    padding: 15px 20px;
  }
  .our-dashbord.dashbord {
    .container-fluid {
      padding-left: 15px;
    }
  }
  .faq_according.style2 {
    .accordion {
      .card {
        .card-header {
          display: inline-table;
        }
      }
    }
  }
  .listing_single_property_slider {
    .owl-controls {
      .owl-nav {
        top: 30%;
      }
    }
  }
  .team_member {
    .overlay {
      .tm_social_icon {
        top: 45%;
      }
    }
  }
  .ui_kit_button {
    .btn {
      margin-bottom: 15px;
    }
  }
  .mbp_pagination {
    display:flex;
    justify-content: space-evenly;
    ul.page_navigation {
      padding-left: 20px;
    }
  }
  .footer_menu {
    padding-left: 0;
  }
  .home_bg3 {
    &:after {
      right: 0;
    }
  }
  .contact_form_grid {
    margin-top: 50px;
  }
  .home5_mt {
    padding: 30px 0;
  }
  .mobile-menu {
    .header_user_notif {
      right: 10%;
      padding-top: 15px;
      li {
        &:first-child {
          vertical-align: text-top;
        }
      }
      .user_notif {
        .dropdown-menu.show {
          top: 0 !important;
          margin-left: -270px;
        }
      }
      li.user_setting {
        .dropdown-menu.show {
          margin-left: -125px;
        }
        .btn.dropdown-toggle {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .header_user_notif {
    li.user_setting {
      .btn {
        span {
          display: none;
        }
      }
    }
    li {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  .stylehome1 {
    .nav_logo_img {
      position: relative;
    }
  }
  .stylehome1.dashbord_mobile_logo {
    .nav_logo_img {
      position: relative;
    }
  }
  .alpha-pag {
    ul.option-set {
      li {
        margin-bottom: 10px;
      }
    }
  }
  .header_top.home3 {
    .container-fluid {
      padding: 0 30px;
    }
    background-color: #051925;
  }
  header.header-nav.menu_style_home_three.navbar-scrolltofixed {
    .ht_left_widget.home3 {
      .header_top_lang_widget {
        margin-top: 0;
      }
    }
  }
  header.header-nav.menu_style_home_five {
    .ace-responsive-menu {
      li {
        &:nth-child(4) {
          ul.sub-menu {
            li {
              ul.sub-menu {
                margin-left: -520px;
              }
            }
          }
        }
      }
    }
  }
  header.header-nav.menu_style_home_six {
    .ace-responsive-menu {
      li {
        &:nth-child(4) {
          ul.sub-menu {
            li {
              ul.sub-menu {
                margin-left: -520px;
              }
            }
          }
        }
      }
    }
  }
  header.header-nav.menu_style_home_seven {
    .ace-responsive-menu {
      li {
        &:nth-child(4) {
          ul.sub-menu {
            li {
              ul.sub-menu {
                margin-left: -520px;
              }
            }
          }
        }
      }
    }
  }
  header.header-nav.menu_style_home_four.home8 {
    .ace-responsive-menu {
      li {
        &:nth-child(4) {
          ul.sub-menu {
            li {
              ul.sub-menu {
                margin-left: -520px;
              }
            }
          }
        }
      }
      >li {
        >a {
          padding: 23px 15px 18px 5px;
        }
      }
    }
    .container-fluid {
      padding: 0 30px;
    }
  }
  .search_overlay.home3 {
    .mk-search-trigger {
      margin-left: 10px;
    }
  }
  .becomea_instructor {
    padding: 65px 15px 55px;
  }
  .becomea_instructor.style2 {
    padding: 65px 15px 55px;
  }
  .header_top.home2 {
    height: auto;
    padding: 15px 0;
  }
  .ht_left_widget.home2 {
    ul {
      li {
        &:first-child {
          float: left;
        }
      }
    }
  }
  .ht_left_widget.home5.float-left {
    float: left !important;
  }
  .ht_right_widget.float-right {
    float: none !important;
    text-align: center;
  }
  .ht_right_widget {
    ul {
      text-align: center !important;
    }
  }
  .home3_about_icon_box {
    padding: 30px;
  }
  .home3_about_icon_box.home8 {
    padding: 30px;
  }
  .about_home3 {
    .partners_thumb_list {
      li {
        margin-right: 15px;
      }
    }
  }
  .cart_btnes.home4 {
    a.btn.cart_btn {
      margin-top: 15px;
    }
  }
  div#countdown {
    .col-xs-6.col-sm-3 {
      &:first-child {
        margin-right: 0;
      }
    }
  }
  section.home4_about {
    overflow: hidden;
  }
  .footer_apps_widget_home1.home6 {
    input.form-control {
      margin: 0 20px 0 0;
    }
  }
  .blog_post_home6 {
    margin-bottom: 30px;
  }
  .logo-widget.home8 {
    margin-left: 50px;
  }
  .blog_post_home2 {
    margin: 0 5px 45px;
  }
  .home7_row {
    margin-top: 60px;
  }
  .funfact_two_details {
    li {
      margin-right: 60px !important;
    }
  }
  .logo-widget.error_paged {
    padding-left: 0;
  }
  .courses_single_container {
    .cs_row_four {
      .about_ins_container {
        .about_ins_info {
          float: none;
          margin-bottom: 15px;
        }
        .details {
          padding-left: 0;
        }
      }
    }
    .cs_row_five {
      .student_feedback_container {
        .aii_average_review {
          display: inline-block;
          left: 0;
          position: relative;
          top: auto;
        }
      }
    }
    .cs_row_three {
      .course_content {
        .details {
          .cc_tab {
            .panel-body {
              .cs_list {
                li {
                  a {
                    span.cs_preiew {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .cs_row_one {
      .courses_big_thumb {
        .thumb {
          .overlay_icon {
            .title {
              top: 70%;
            }
          }
        }
      }
    }
  }
  .instructor_pricing_widget.csv2 {
    margin-top: 0;
  }
  .blog_grid_post.event_lists {
    margin-bottom: 0;
  }
  .participator_team {
    margin-bottom: 30px;
  }
  .main_blog_post_content {
    .row.event_lists {
      margin-bottom: 30px;
    }
  }
  .user_board {
    padding: 0 30px;
    padding-right: 15px;
    padding-left: 0;
  }
  .application_statics {
    margin-bottom: 30px;
  }
  .user_profile {
    .media {
      .media-body {
        h4 {
          padding-left: 30px;
        }
      }
    }
  }
  .dashbord_nav_list {
    li {
      padding-left: 30px;
      margin-right: 30px;
    }
    h4 {
      padding-left: 30px;
    }
  }
  .my_course_content_header {
    display: block;
  }
  .my_course_content_list {
    .mc_content_list {
      display: block;
      .thumb {
        margin-bottom: 20px;
        margin-right: 0;
      }
      .sonny_progressbar {
        .bar-container {
          margin: 0 auto;
        }
        p.title {
          float: right;
          margin-bottom: 0;
          margin-right: 0;
          margin-top: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width:1024px) {
  .dn-md {
    display: none !important;
  }
  .fn-md {
    float: none !important;
  }
  header.header-nav.menu_style_home_one {
    ul.ace-responsive-menu {
      //li.add_listing {
      //  top: 20px;
      //  a {
      //    top: -20px;
      //  }
      //}
    }
    a.navbar_brand {
      margin-top: 10px;
      img {
        width: 200px;
      }
    }
    ul.sign_up_btn {
      margin-top: 15px;
    }
    .mk-search-trigger {
      top: 20px;
    }
    .ace-responsive-menu {
      >li {
        >a {
          padding: 27px 14px 27px 5px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_one.style2 {
    a.navbar_brand {
      margin-top: 14px;
      img {
        width: 200px;
      }
    }
    .ht_left_widget ul li.list-inline-item:nth-child(2) {
      margin-right: 40px;
      margin-left: -15px;
    }
    .ht_left_widget.ht-right {
      margin-top: 13px;
    }
    ul.ace-responsive-menu {
      li.add_listing {
        a {
          line-height: normal;
        }
      }
      li.list_s {
        &:before {
          top: 15px;
        }
      }
    }
  }
  .iu_heading {
    .candidate_revew_search_box {
      input.form-control {
        width: 68%;
      }
    }
  }
  .feat_property.list.favorite_page.style2 {
    .thumb {
      width: 200px;
    }
    .details {
      .tc_content {
        padding-top: 0;
      }
    }
  }
  .user_profile {
    padding: 20px 5px;
  }
  .our-dashbord {
    &:before {
      width: 34% !important;
    }
  }
  .footer_social_widget {
    li {
      margin-right: 0 !important;
    }
  }
  .m_box.our-dashbord {
    &:before {
      width: 33%;
    }
  }
  .sign_up_form.inner_page {
    padding: 0;
  }
  .login_form.inner_page {
    padding: 0;
  }
  header.header-nav.menu_style_home_five {
    .ace-responsive-menu {
      li {
        a {
          padding: 22px 10px 12px 10px;
        }
      }
    }
  }
  .home_slider2 {
    i.left {
      left: 15px;
    }
    span.left.slick-arrow {
      left: 40px;
    }
    i.right {
      right: 15px;
    }
    span.right.slick-arrow {
      right: 40px;
    }
  }
  .home1-mainslider {
    .main-banner-wrapper {
      .carousel-btn-block {
        .carousel-btn.left-btn {
          left: 10px;
        }
        .carousel-btn.right-btn {
          right: 10px;
        }
      }
    }
  }
  .banner-style-one {
    .slide {
      padding: 165px 60px;
    }
  }
  header.header-nav.menu_style_home_three {
    .ace-responsive-menu {
      li {
        &:nth-child(2) {
          ul.sub-menu {
            right: 115px;
            &:before {
              left: auto;
            }
          }
        }
      }
      li.list_four {
        ul.sub-menu {
          right: auto;
        }
      }
      li.list_three {
        ul.sub-menu {
          right: auto;
        }
      }
    }
  }
  header.header-nav.menu_style_home_six {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 23px 10px 18px 5px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_seven {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 18px 15px 18px 15px;
        }
      }
    }
  }
  .home_iconbox_container {
    margin-bottom: 90px;
  }
}
@media only screen and (max-width:992px) {
  .dn-992 {
    display: none !important;
  }
  .db-992 {
    display: block !important;
  }
  .fn-992 {
    float: none !important;
  }
  .pt65-992 {
    padding-top: 65px !important;
  }
  .maxw100flex-992 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .maxw100-992 {
    max-width: 100%;
  }
  header.header-nav.menu_style_home_five {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 25px 15px 18px 5px;
        }
      }
    }
  }
  .stylehome1 {
    &:before {
      position: absolute;
      content: "";
      background-color: #0a0a0a;
      bottom: 0;
      height: 65px;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
  .stylehome1.home3 {
    &:before {
      background-color: transparent !important;
    }
    .mm-navbar {
      background-color: #1c327e !important;
    }
    .mm-panel {
      background-color: #1c327e !important;
    }
  }
  .stylehome1.home4 {
    &:before {
      background-color: transparent !important;
    }
  }
  .stylehome1.home5 {
    &:before {
      background-color: #ffffff !important;
    }
  }
  .stylehome1.home6 {
    &:before {
      background-color: #ffffff !important;
    }
  }
  .mobile-menu {
    .header_user_notif {
      z-index: 99;
    }
  }
  .testimonial_content {
    padding: 60px 40px;
  }
  .home5-slider {
    margin-top: -31px;
  }
  .ulockd_bgi3 {
    background-position: center top !important;
  }
  .main-banner-wrapper {
    .carousel-btn-block {
      top: 5%;
      .carousel-btn.left-btn {
        left: auto;
        right: 80px;
      }
    }
  }
  .home-four {
    margin-top: 1px !important;
  }
  .home-one {
    margin-top: 0;
  }
  .home_content.home5 {
    padding: 230px 0 240px;
  }
  .home_content.home5.style2 {
    padding-top: 200px;
  }
  .our-dashbord.dashbord {
    padding-top: 45px;
  }
  .home_iconbox_container {
    margin-bottom: 120px;
  }
  .header_top.home2 {
    display: none;
  }
  .header_top.home3 {
    display: none;
  }
  .header_top.home4 {
    display: none;
  }
  .header_top.home6 {
    display: none;
  }
  .header_top.home7 {
    display: none;
  }
  .header_top.home8 {
    display: none;
  }
  .home8_wave {
    &::before {
      display: none;
    }
  }
  .home1-mainslider {
    margin-top: -95px;
  }
  #mk-fullscreen-searchform2 {
    margin-top: 250px;
  }
  .header_top.home6.hone {
    z-index: 9;
  }
  .mouse_scroll {
    .icon {
      top: -85px;
    }
    &:before {
      bottom: 40px;
    }
    &:after {
      bottom: 20px;
    }
  }
  .home4_overlay {
    &::before {
      top: -75px;
    }
  }
  .inner_page_breadcrumb {
    margin-top: -95px;
  }
  .checkout_form {
    .checkout_coupon {
      form {
        input {
          margin-right: 15px;
        }
        button.btn2 {
          margin-right: 15px;
        }
      }
    }
  }
  .inbox_user_list {
    padding: 30px 25px 40px;
  }
  .header.stylehome1.home5 {
    a#search-button-listener2 {
      color: #0a0a0a !important;
    }
  }
  .home_adv_srch_opt.home4 {
    .home1_adsrchfrm {
      &:before {
        border-radius: 8px;
      }
      border-radius: 8px;
    }
  }
}
@media only screen and (max-width:991px) {
  .mb35-smd {
    margin-bottom: 35px;
  }
  .mb0-991 {
    margin-bottom: 0 !important;
  }
  .mb30-smd {
    margin-bottom: 30px !important;
  }
  .mb30-991 {
    margin-bottom: 30px !important;
  }
  .mb50-smd {
    margin-bottom: 50px;
  }
  .mb50-991 {
    margin-bottom: 50px;
  }
  .mt30-smd {
    margin-top: 30px;
  }
  .mt30-991 {
    margin-top: 30px;
  }
  .mt50-smd {
    margin-top: 50px;
  }
  .pb30-991 {
    padding-bottom: 30px;
  }
  .tal-smd {
    text-align: left !important;
  }
  .tal-991 {
    text-align: left !important;
  }
  .tac-smd {
    text-align: center !important;
  }
  .fn-smd {
    float: none !important;
  }
  .dn-smd {
    display: none !important;
  }
  .dn-991 {
    display: none !important;
  }
  .db-smd {
    display: block !important;
  }
  .db-991 {
    display: block !important;
  }
  .dv-smd {
    display: inherit !important;
  }
  .prpl0-md {
    padding-left: 0;
    padding-right: 0;
  }
  .prpl5-sm {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .mt10-smd {
    margin-top: 10px !important;
  }
  .pb0-991 {
    padding-bottom: 0 !important;
  }
  .pt0-991 {
    padding-top: 0 !important;
  }
  .home1-advnc-search {
    ul {
      li {
        &:first-child {
          .form-control {
            margin-bottom: 15px;
            width: 190px;
          }
        }
        &:nth-child(3) {
          .form-group {
            .form-control {
              margin-bottom: 15px;
              width: 190px;
            }
          }
        }
        .search_option_button {
          button {
            width: 170px;
          }
        }
      }
    }
    .search_option_two {
      .dropdown.bootstrap-select {
        >.dropdown-toggle {
          margin-bottom: 15px;
          width: 190px;
          margin-bottom: 0 !important;
        }
      }
      .dropdown-menu {
        top: 15px !important;
      }
    }
    ul.apeartment_area_list {
      li {
        margin-right: 10px !important;
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 150px;
          }
        }
      }
    }
  }
  .small_dropdown2 {
    .dd_btn {
      width: 150px;
    }
  }
  .small_dropdown2.home10 {
    .dd_content2 {
      top: 150px;
      z-index: 9;
    }
  }
  .dd_content2 {
    right: auto;
    top: 200px;
    &:before {
      left: 50px;
      right: auto;
    }
  }
  .dropdown-content {
    top: 165px;
    //&:before {
    //  left: -10%;
    //}
  }
  .mega_dropdown_content_closer {
    h5 {
      margin-top: 10px;
      margin-bottom: 35px;
      text-align: center !important;
    }
  }
  .home_content {
    padding: 30px 0 30px !important;
  }
  .home_adv_srch_opt.home6 {
    margin-top: 20px !important;
  }
  .home_content.home4 {
    padding: 350px 0 40px !important;
  }
  .home_content.listing {
    padding: 40px 0 !important;
  }
  .home_content.listing.slider_style {
    padding: 480px 0 0 !important;
  }
  .form_grid {
    margin-bottom: 30px;
  }
  .terms_condition_grid {
    margin-bottom: 30px;
  }
  .faq_according {
    .accordion {
      .card {
        .card-header {
          height: auto;
        }
      }
    }
  }
  .featured_row {
    position: relative;
    display: table;
    padding: 0 0 20px;
  }
  .home3_home_content {
    padding: 20px 0;
    a.popup_video_btn {
      top: 0;
    }
  }
  .home_adv_srch_opt.home4 {
    .home1_adsrchfrm {
      display: table;
    }
  }
  .home4_iconbox {
    li {
      margin-bottom: 10px;
    }
  }
  ul.apeartment_area_list.home5 {
    margin-top: -35px;
  }
  .home-text.home5 {
    .discounts_para {
      margin-bottom: 30px;
    }
  }
  .home1-advnc-search.home5 {
    .form-group.df {
      label {
        right: 35px;
      }
    }
  }
  .home_content.home5 {
    padding: 80px 0 40px !important;
  }
  .home_content.home5.style2 {
    padding-top: 0 !important;
  }
  .property_city_home6 {
    .details {
      padding: 50px 25px 0;
    }
  }
  .home1-advnc-search.home7 {
    ul {
      li {
        &:nth-child(2) {
          width: 200px;
        }
      }
    }
  }
  .our_hotoffer {
    margin-top: 0;
  }
  .our-hot-offer {
    height: auto;
  }
  .testimonial_item {
    .details {
      margin: 0 0 0 10px;
      padding-top: 15px;
    }
  }
  .for_blog.feat_property.home9 {
    margin-top: 120px;
    .thumb {
      top: -115px;
    }
  }
  .sidebar_feature_property_slider {
    .feat_property {
      .thumb {
        height: auto;
      }
    }
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 50%;
      }
    }
  }
  .dropdown-content.half_style {
    height: 490px;
  }
  .shop_single_tab_content.style2 {
    margin-bottom: 30px;
  }
  .sidebar_switch.mobile_style {
    height: 60px;
    position: relative;
  }
  .sidebar_listing_list.style2.mobile_sytle_sidebar {
    max-height: 667px;
    overflow-y: scroll;
  }
  .sidebar_listing_list.style2 {
    max-height: 667px;
    overflow-y: scroll;
  }
  .start_partner {
    margin-bottom: 30px;
  }
  .footer_about_widget {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
  .footer_qlink_widget {
    padding-left: 0px;
    margin-bottom: 30px;
  }
  .footer_mailchimp_form {
    .col-auto {
      input.form-control {
        width: auto;
      }
    }
  }
  .footer_menu_widget {
    margin-bottom: 15px;
    text-align: center !important;
  }
  .copyright-widget {
    text-align: center !important;
  }
  .blog_post.one {
    .thumb {
      .post_title {
        font-size: 48px;
      }
    }
  }
  .icon_box_hiw {
    .icon.middle {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
  .our-dashbord {
    &:before {
      width: 48%;
    }
  }
  .login_form {
    /*margin-bottom: 30px;*/
    button {
      margin-bottom: 40px !important;
    }
    button.btn2 {
      margin-bottom: 0 !important;
    }
  }
  .main_blog_post_content {
    margin-bottom: 30px;
  }
  .footer_contact_widget {
    margin-bottom: 30px;
  }
  .home5_mt {
    margin-top: 0;
  }
  .sign_up_form {
    button {
      margin-bottom: 40px !important;
    }
    button.btn2 {
      margin-bottom: 0 !important;
    }
  }
  .home-content {
    margin: 150px 0 80px;
    margin: 100px 0 80px;
  }
  .home-content.home7 {
    margin: 350px 0 80px;
  }
  .home_icon_box {
    margin-bottom: 30px;
    text-align: center;
  }
  .funfact_one {
    margin-bottom: 30px;
  }
  #our-partners {
    padding-bottom: 30px;
  }
  .our_partner {
    margin: 30px;
    text-align: center;
  }
  .app_grid {
    .apple_btn {
      margin-bottom: 15px;
    }
  }
  .blog_post_home2.home3 {
    margin-bottom: 30px;
  }
  .mk-fullscreen-search-overlay {
    #mk-fullscreen-search-wrapper {
      #mk-fullscreen-searchform {
        #mk-fullscreen-search-input {
          max-width: 500px;
        }
      }
    }
    #mk-fullscreen-search-wrapper2 {
      #mk-fullscreen-searchform2 {
        #mk-fullscreen-search-input2 {
          max-width: 500px;
        }
      }
    }
  }
  .blog_post_home6_media_objects {
    margin-bottom: 30px;
  }
  .home7_row {
    margin-top: 60px;
  }
  .testimonial_item.home2 {
    .details {
      padding: 30px;
    }
  }
  .blog_post_home4.home8 {
    margin-bottom: 30px;
  }
  .logo-widget.home8 {
    margin-left: 180px;
  }
  .footer_one.home8 {
    &::after {
      bottom: 150px;
    }
  }
  .about_program {
    margin-bottom: 30px;
    padding-right: 0;
    text-align: center;
  }
  .home1-mainslider {
    .banner-style-one {
      .banner-title {
        font-size: 30px;
        font-weight: bold;
      }
    }
    .main-banner-wrapper {
      .carousel-btn-block {
        top: 35%;
      }
    }
    .home-content {
      padding-top: 0;
    }
  }
  .home_iconbox_container {
    margin-top: -390px;
  }
  .main-banner-wrapper.home7 {
    .carousel-btn-block {
      .carousel-btn.left-btn {
        left: 0;
      }
      .carousel-btn.right-btn {
        right: 0;
      }
    }
  }
  .logo-widget.error_paged {
    padding-left: 30px;
  }
  .mbp_pagination {
    ul.page_navigation {
      margin-bottom: 30px;
    }
  }
  .shop_order_box {
    .order_list_raw {
      ul {
        li {
          margin-right: 45px;
        }
      }
    }
  }
  .order_sidebar_widget {
    margin-top: 30px;
  }
  .single_product_grid {
    margin-bottom: 30px;
  }
  .courses_single_container {
    .cs_row_one {
      .cs_instructor {
        .cs_instrct_list {
          float: none !important;
          margin-bottom: 20px !important;
        }
        .cs_watch_list {
          float: none !important;
          li {
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
  .cs_row_one.csv2 {
    .cs_instructor {
      .cs_watch_list {
        float: none !important;
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px !important;
      }
    }
  }
  .ff_one {
    padding: 30px;
  }
  .mouse_scroll {
    .icon {
      top: -65px;
    }
    &:before {
      bottom: 20px;
    }
    &:after {
      bottom: 0px;
    }
  }
  .row_home4 {
    margin: 0 !important;
  }
  .home_icon_box.home4 {
    .icon {
      span {
        float: none !important;
      }
      color: #2441e7;
    }
    p {
      color: #6f7074;
    }
  }
  #main2 {
    .filter_open_btn.style2 {
      right: 10px;
      top: 15px;
    }
  }
  .listing_single_row {
    margin-bottom: 42px;
    margin-top: -150px;
  }
}
@media only screen and (max-width:768px) {
  .nav_logo_img.mt10 {
    margin-top: 5px;
  }
  .menu-toggle {
    background-color: transparent !important;
    #menu-btn {
      background-color: #79b530 !important;
      border-color: #79b530 !important;
    }
  }
  .menu_style_home_one.stricky-fixed {
    background-color: transparent;
  }
  header.header-nav.style_one {
    background-color: transparent;
  }
  header.header-nav.menu_style_home_one {
    display: none;
    .ace-responsive-menu {
      background-color: #262626;
      >li {
        background-color: #262626;
      }
      li {
        a {
          padding: 27px 15px 27px 15px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_three.stricky-fixed {
    background-color: #4568dc;
  }
  header.header-nav.menu_style_home_three {
    .ace-responsive-menu {
      li {
        ul.sub-menu {
          background-color: #4568dc;
        }
        a {
          border-bottom: #4568dc;
          &:hover {
            background-color: #4568dc !important;
            border-color: #4568dc !important;
            padding: 18px 15px 18px 15px;
          }
        }
      }
      >li {
        &:hover {
          a {
            .title {
              &:before {
                background-color: transparent;
              }
            }
          }
        }
        >a.active {
          .title {
            &:before {
              background-color: transparent;
            }
          }
        }
        border-bottom: #4568dc;
        >a {
          background-color: #4568dc !important;
          border-color: #4568dc !important;
          padding: 18px 15px 18px 15px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_four.stricky-fixed {
    background-color: #201b71;
  }
  header.header-nav.menu_style_home_four {
    .ace-responsive-menu {
      li {
        a {
          &:hover {
            padding: 25px 15px 18px;
          }
        }
      }
      >li {
        >a {
          padding: 25px 15px 18px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_five {
    .ace-responsive-menu {
      >li {
        >a {
          padding: 25px 15px 18px;
        }
      }
    }
  }
  header.header-nav.menu_style_home_six {
    width: 100% !important;
    .ace-responsive-menu {
      background-color: #ffffff !important;
      li {
        a {
          background-color: #ffffff !important;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .menu-toggle {
      #menu-btn {
        background-color: #13b493 !important;
        border-color: #79b530 !important;
      }
    }
  }
  .dn-smd {
    display: none !important;
  }
  .ui_kit_tooltip {
    .btn {
      margin-bottom: 10px;
    }
  }
  .our-dashbord {
    &:before {
      display: none;
    }
  }
  .sticky-nav-tab {
    padding: 20px 10px 0;
    padding: 20px 10px 10px;
  }
  .testimonial_content {
    padding: 60px 20px;
  }
  .footer_social_widget.home5 {
    li {
      margin-bottom: 5px;
    }
  }
  .cl_skill_checkbox {
    .content.ui_kit_checkbox {
      text-align: left;
    }
  }
  .sidebar_block {
    margin-bottom: 30px;
  }
  button.btn.btn-thm.btns.ml10 {
    margin-left: auto;
  }
  .container-fluid.style2 {
    width: 100%;
  }
  .header_top.home3 {
    display: none;
  }
  .about_home3 {
    margin-bottom: 30px;
    margin-top: 0;
  }
  .home3_about_icon_box.three {
    margin-bottom: 45px;
  }
  .home3_about_icon_box.six {
    margin-bottom: 45px;
  }
  .home3_about_icon_box.one {
    margin-top: 0;
  }
  .home3_about_icon_box.two {
    margin-top: 0;
  }
  .home3_about_icon_box.four {
    margin-top: 0;
  }
  .home3_about_icon_box.five {
    margin-top: 0;
  }
  .home3_about_icon_box.seven {
    margin-top: 0;
  }
  .about2_home3 {
    margin-top: 30px;
    text-align: center;
  }
  .about_thumb_home3 {
    text-align: center !important;
  }
  .search_box_home4 {
    .header_search_widget {
      .mailchimp_form {
        .form-control {
          width: 400px;
        }
      }
    }
  }
  .home_icon_box.home4 {
    margin-bottom: 50px;
    .icon {
      span {
        margin-right: 0;
        margin-top: -20px;
      }
    }
  }
  .divider-two {
    margin-bottom: 50px;
  }
  .main-title.home6 {
    margin-bottom: 30px;
    text-align: center;
  }
  .about_home6 {
    .partners_thumb_list {
      margin-top: 0;
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .footer_social_widget.home6 {
    margin-top: 0;
  }
  .about_box_home6 {
    margin-bottom: 60px;
  }
  .funfact_two_details {
    li {
      margin-bottom: 30px;
      margin-right: 80px !important;
    }
  }
}
@media only screen and (max-width: 767.98px) {
  .home_adv_srch_opt.home6 {
    margin-top: 35px;
  }
  .tal-xsd {
    text-align: left !important;
  }
  .tal-767 {
    text-align: left !important;
  }
  .tac-xsd {
    text-align: center !important;
  }
  .dn-xsd {
    display: none;
  }
  .db-xsd {
    display: block;
  }
  .fn-xsd {
    float: none !important;
  }
  .pb0-767 {
    padding-bottom: 0 !important;
  }
  .pt0-xsd {
    padding-top: 0 !important;
  }
  .mb10-767 {
    margin-bottom: 10px !important;
  }
  .mb20-xsd {
    margin-bottom: 20px;
  }
  .mb35-xsd {
    margin-bottom: 35px !important;
  }
  .mt0-xsd {
    margin-top: 0 !important;
  }
  .mt0-767 {
    margin-top: 0 !important;
  }
  .mt30-xsd {
    margin-top: 30px !important;
  }
  .mt30-767 {
    margin-top: 30px !important;
  }
  .mb0-xsd {
    margin-bottom: 0px !important;
  }
  .mb0-767 {
    margin-bottom: 0px !important;
  }
  .mb30-xsd {
    margin-bottom: 30px !important;
  }
  .mb30-767 {
    margin-bottom: 30px !important;
  }
  .home1-advnc-search {
    ul {
      li {
        &:first-child {
          .form-control {
            width: 100%;
          }
        }
        &:nth-child(3) {
          .form-group {
            .form-control {
              width: 135px;
            }
          }
        }
      }
    }
    .search_option_two {
      .dropdown.bootstrap-select {
        >.dropdown-toggle {
          width: 135px;
        }
      }
    }
    .dropdown.bootstrap-select {
      >.dropdown-toggle {
        margin-bottom: 10px;
      }
    }
    ul.apeartment_area_list {
      li {
        margin-right: 15px;
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 220px;
          }
        }
      }
    }
  }
  .small_dropdown2 {
    .dd_btn {
      width: 140px;
    }
  }
  .dropdown-content {
    top: 0;
    z-index: 1;
  }
  .feature_property_slider.owl-carousel.owl-theme {
    .owl-item {
      max-width: 100%;
    }
  }
  .properti_city {
    .overlay {
      height: 100%;
    }
  }
  .footer_mailchimp_form {
    .col-auto {
      input.form-control {
        width: 170px;
      }
    }
  }
  .mouse_scroll {
    display: none;
  }
  .home_content {
    padding: 150px 0 400px !important;
  }
  .home_adv_srch_opt {
    margin-top: 100px;
  }
  .error_page.footer_apps_widget {
    .mailchimp_form {
      .form-control {
        width: 500px;
      }
    }
  }
  .checkout_form {
    .checkout_coupon {
      form {
        button.btn3 {
          position: relative;
        }
        input {
          margin-bottom: 15px;
        }
        button.btn2 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .home_content.home4 {
    padding: 250px 0 30px !important;
    .home-text {
      h2 {
        font-size: 30px;
      }
    }
  }
  .main-banner-wrapper {
    .carousel-btn-block {
      bottom: 12%;
      top: auto;
    }
  }
  .main-banner-wrapper.home10 {
    .carousel-btn-block {
      bottom: 20%;
      top: auto;
    }
  }
  .home_content.home5 {
    padding: 40px 0;
  }
  .home_content.home6 {
    padding: 30px 0 !important;
  }
  .mega_dropdown_content_closer {
    h5 {
      margin-top: -5px;
    }
  }
  .mega-dropdown.home6 {
    .mega_dropdown_content_closer {
      h5 {
        margin-top: 0;
      }
    }
  }
  .property_city_home6 {
    display: block;
    .details {
      padding: 30px 25px;
    }
  }
  .feat_property {
    .details {
      .fp_footer {
        .fp_meta {
          li {
            vertical-align: middle;
          }
        }
        .fp_pdate {
          //float: right !important;
        }
      }
    }
  }
  .our_hotoffer {
    margin-bottom: 30px;
  }
  .home1-advnc-search.home7 {
    display: table;
    padding: 0 15px;
    position: relative;
    width: 100%;
  }
  .home_content.home7 {
    padding: 250px 0 0 !important;
  }
  .tes-nav {
    img {
      margin: 0 !important;
    }
  }
  .testimonial_item {
    .details {
      padding: 0;
    }
  }
  .bs_carousel {
    .main_title {
      margin-top: 30px !important;
    }
  }
  .feat_property.home8 {
    margin-top: 30px !important;
  }
  .home-nine {
    height: 760px;
  }
  .home1-advnc-search.home9 {
    width: 100%;
    ul {
      li {
        &:first-child {
          .form-control {
            width: 270px;
          }
        }
      }
    }
  }
  .for_blog.feat_property.home9 {
    margin-top: 0;
    .thumb {
      top: 0;
      position: relative;
    }
    .details {
      position: relative;
    }
  }
  .mbp_blockquote {
    display: inline-table;
    .blockquote {
      &:before {
        top: 10px;
      }
    }
  }
  .grid_list_search_result {
    display: inline-table;
  }
  .right_area.style2 {
    text-align: left !important;
    .bootstrap-select {
      >.dropdown-toggle {
        z-index: 0;
      }
    }
  }
  .feat_property.list {
    display: grid;
    .thumb {
      height: auto;
      width: auto;
    }
    .details {
      width: auto;
      .fp_footer {
        padding: 0 20px 20px;
        .fp_pdate {
          margin-top: 8px;
        }
      }
    }
  }
  .home_content.listing {
    padding: 0 !important;
  }
  .home_content.listing.slider_style {
    padding: 380px 0 0 !important;
  }
  .home-listing-slider {
    .main-banner-wrapper {
      .carousel-btn-block {
        bottom: 14%;
      }
    }
  }
  .feature_property_half_clist {
    li.extrawide {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
  .dropdown-content.half_style {
    height: 575px;
    z-index: 9;
  }
  .home1-advnc-search.home2.style2 {
    margin-bottom: 60px;
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        margin-right: 0 !important;
        width: 74%;
      }
      &:nth-child(3) {
        position: relative;
        width: 100%;
      }
    }
    .navbered {
      left: 0;
      position: absolute;
      width: 100%;
      .dropdown-content {
        &:before {
          left: auto;
        }
      }
    }
  }
  .feature_property_half_clist.style2 {
    li.extrawide {
      width: 100%;
    }
  }
  .sidebar_advanced_search_widget {
    .dropdown-menu.show {
      min-width: 100% !important;
    }
  }
  .feat_property.list.agency {
    .thumb {
      height: auto;
      width: auto;
    }
    .details {
      border-left: none;
      padding-left: inherit;
    }
  }
  .feat_property.list.favorite_page {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  .portfolio_item {
    height: auto;
    width: 100%;
    img {
      height: auto;
      width: 100%;
    }
  }
  .lsd_list {
    li {
      margin-bottom: 10px;
    }
  }
  .listing_single_description2 {
    display: block;
  }
  .single_property_social_share.style2 {
    position: relative;
    right: auto;
  }
  .listing_single_property_slider {
    .owl-controls {
      .owl-nav {
        top: 40%;
      }
    }
  }
  .listing_single_row.style2 {
    position: absolute;
    top: -400px;
  }
  #main2 {
    .filter_open_btn {
      top: 25px;
    }
    .filter_open_btn.style3 {
      left: 15px;
      top: -30px;
    }
    .filter_open_btn.style4 {
      right: 10px;
      top: 0;
    }
  }
  .home-five {
    .home-text {
      a.btn {
        display: block;
        margin-top: 35px;
      }
    }
  }
  .bg-img5 {
    background-position: 40% bottom;
  }
  .home_icon_box {
    display: none;
  }
  .home_icon_box.home8 {
    display: block;
  }
  .home_icon_box.home4 {
    display: block;
  }
  .faq_according {
    margin-bottom: 30px;
  }
  .ui_kit_table {
    margin-bottom: 30px;
  }
  .mbp_pagination {
    ul.page_navigation {
      height: 100%;
      padding-left: 15px;
    }
  }
  .our-dashbord {
    &:before {
      display: none;
    }
  }
  .message_container {
    .message_input {
      form {
        button.btn {
          z-index: 9;
        }
        input.form-control {
          z-index: 9;
        }
      }
    }
  }
  .message_input {
    &:before {
      background-color: #ffffff;
      bottom: -32px;
      content: "";
      left: -30px;
      position: absolute;
      right: -30px;
      top: 0;
      z-index: 0;
    }
  }
  .sticky-nav-tab {
    padding: 5px 10px 15px;
  }
  .candidate_revew_select.mt50 {
    margin-top: auto;
  }
  .testimonial_content {
    margin: 70px 50px;
  }
  .t_icon.home3 {
    left: 70px;
  }
  .blog_post {
    margin-bottom: 20px;
  }
  .home5-slider {
    .pogoSlider--dirCenterHorizontal {
      .pogoSlider-dir-btn {
        top: 50%;
      }
    }
    .home_content {
      .slider-text1 {
        font-size: 30px;
      }
    }
  }
  .icon_box_home6 {
    padding: 20px;
  }
  .phone_img {
    display: none;
  }
  .home1-divider2 {
    padding: 100px 0;
  }
  .footer_social_widget.mt15 {
    margin-top: 0;
  }
  .becomea_instructor {
    margin-bottom: 30px;
  }
  .blog_post_home2.home3 {
    margin: 0 auto 30px;
    max-width: 410px;
  }
  .becomea_instructor_home3 {
    padding: 30px;
    a.btn {
      max-width: 150px;
      span {
        padding-left: 5px;
      }
    }
  }
  .brdr_left_right.home6 {
    &:before {
      display: none;
    }
  }
  .footer_menu_widget.home6 {
    text-align: center;
  }
  .banner-style-one {
    .slide.slide-one.home6 {
      .banner-title {
        font-size: 36px;
      }
    }
    .banner-title {
      font-size: 30px;
    }
  }
  .about_box_home7 {
    .thumb {
      &::before {
        display: none;
      }
    }
  }
  .logo-widget.home8 {
    margin-left: 90px;
  }
  .blog_post_home4.home8 {
    text-align: center;
    .bph4_thumb {
      img {
        margin: 0 auto;
      }
    }
  }
  .testimonial_item.home2 {
    .details {
      padding: 50px;
      text-align: center;
    }
  }
  .home1_bgi1 {
    height: 650px;
  }
  .home-text.home8 {
    padding: 250px 0;
  }
  .our-media {
    .container-fluid {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  .home-one {
    .home-text {
      h2 {
        font-size: 30px;
      }
    }
  }
  .home-text.home6 {
    h2 {
      font-size: 30px;
    }
  }
  .home1-mainslider {
    .slide.slide-one {
      height: 660px !important;
    }
    .banner-style-one {
      p {
        margin-top: 10px;
      }
      .banner-btn {
        margin-top: 35px;
      }
    }
  }
  .bg-img6 {
    height: 860px;
  }
  .bg-img1 {
    background-position: 20% center;
  }
  .home-seven {
    margin-top: -95px;
  }
  .maxtext {
    h1 {
      font-size: 30px;
    }
  }
  .home-content {
    margin: 50px 0 80px;
  }
  .logo-widget.error_paged {
    padding-left: 0px;
  }
  .candidate_revew_search_box {
    input.form-control {
      width: auto;
    }
  }
  .shop_order_box {
    .order_list_raw {
      ul {
        li {
          margin-right: 60px;
        }
      }
    }
  }
  ul.mc_parent_list {
    ul.mc_child_list.one {
      li {
        padding: 10px 50px 10px 30px;
      }
    }
    ul.mc_child_list.two {
      li {
        padding: 10px;
      }
    }
    ul.mc_child_list.three {
      li {
        padding: 10px;
      }
    }
    ul.mc_child_list.four {
      li {
        padding: 10px;
      }
    }
  }
  .product_single_content.style2 {
    span.sspd_review {
      float: none !important;
      margin-bottom: 15px;
    }
    .ssp_reply {
      float: none !important;
      margin-bottom: 15px;
    }
  }
  .blog_grid_post {
    .details {
      padding: 15px;
    }
  }
  .courses_list_content {
    .feat_property.list {
      display: block;
      .details {
        .tc_content {
          padding: 15px 0px 20px 15px;
        }
      }
    }
  }
  .courses_single_container {
    .cs_row_five {
      .student_feedback_container {
        .s_feeback_content {
          .sonny_progressbar {
            max-width: 390px;
            padding-left: 60px;
          }
        }
      }
    }
  }
  .cs_row_one.csv3 {
    .cs_watch_list {
      float: none !important;
      margin-top: 10px;
    }
  }
  .inner_page_breadcrumb.csv2 {
    .breadcrumb_content {
      margin-top: 60px;
    }
  }
  .inner_page_breadcrumb.csv3 {
    .breadcrumb_content {
      margin-top: 60px;
    }
  }
  .ff_one {
    padding: 15px 10px;
  }
  .inbox_chatting_box {
    .chatting_content {
      padding: 30px 30px 100px;
    }
  }
}
@media only screen and (max-width:630px) {
  .mobile-menu {
    .header_user_notif {
      right: 12%;
    }
  }
  .banner-style-one {
    .slide {
      padding: 165px 15px;
    }
    .banner-btn {
      display: block;
    }
  }
  .menu_bar_home2 {
    li.list-inline-item {
      &:first-child {
        &:after {
          right: 0;
        }
      }
    }
  }
  ul.view_edit_delete_list {
    display: block !important;
    float: none !important;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 580px) {
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 71%;
      }
    }
  }
  .sticky-nav-tabs-container {
    padding-top: 13px;
  }
}
@media only screen and (max-width: 575.98px) {
  .mt20-xxsd {
    margin-top: 20px;
  }
  .home1-advnc-search {
    ul {
      li {
        &:first-child {
          .form-control {
            width: 135px;
          }
        }
        &:nth-child(3) {
          .form-group {
            .form-control {
              width: 135px;
            }
          }
        }
      }
    }
    .search_option_two {
      .dropdown.bootstrap-select {
        >.dropdown-toggle {
          width: 135px;
        }
      }
    }
    .dropdown.bootstrap-select {
      >.dropdown-toggle {
        margin-bottom: 10px;
      }
    }
  }
  .small_dropdown2 {
    .dd_btn {
      width: 140px;
    }
  }
  .dropdown-content {
    &:before {
      display: none;
    }
  }
  .mega_dropdown_content_closer {
    h5 {
      margin-top: 5px;
    }
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 70%;
      }
    }
  }
  .app_grid {
    .apple_btn {
      margin-bottom: 10px;
    }
    .play_store_btn {
      margin-bottom: 10px;
    }
  }
}
@media only screen and (max-width:520px) {
  .tac-xxsd {
    text-align: center !important;
  }
  .tal-xxsd {
    text-align: left !important;
  }
  .tal-520 {
    text-align: left !important;
  }
  .mb50-xxsd {
    margin-bottom: 50px;
  }
  .mb30-xxsd {
    margin-bottom: 30px;
  }
  .mb30-520 {
    margin-bottom: 30px;
  }
  .mb10-520 {
    margin-bottom: 10px;
  }
  .mb0-xxsd {
    margin-bottom: 0 !important;
  }
  .mb0-520 {
    margin-bottom: 0 !important;
  }
  .mt30-520 {
    margin-top: 30px !important;
  }
  .mt30-xxsd {
    margin-top: 30px !important;
  }
  .col-xxs-6 {
    width: 50% !important;
  }
  .col-xxs-12 {
    width: 100% !important;
  }
  .plpr15-xxsd {
    padding-left: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .rs-smd-pad395 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pr15-xxsd {
    padding-right: 15px;
  }
  .pl15-xxsd {
    padding-left: 15px;
  }
  .prpl0-lg {
    padding-right: 15px;
    padding-left: 15px;
  }
  .pt30-520 {
    padding-top: 30px;
  }
  .pt30-xxsd {
    padding-top: 30px;
  }
  .fn-520 {
    float: none !important;
  }
  .flr-520 {
    float: right !important;
  }
  .db-520 {
    display: block !important;
  }
  .dn-520 {
    display: none !important;
  }
  .home1_adsrchfrm {
    padding: 30px 15px;
  }
  .small_dropdown2 {
    .dd_btn {
      width: 120px;
      width: 100%;
    }
  }
  .home1-advnc-search {
    .search_option_two {
      margin-bottom: 10px;
      margin-right: 0;
      width: 100%;
      .dropdown.bootstrap-select {
        >.dropdown-toggle {
          width: 100%;
        }
        .dropdown-menu.show {
          min-width: auto !important;
          width: 100% !important;
        }
      }
    }
    ul {
      li:not(.select2-selection__choice) {
        margin-right: 0;
        width: 100%;
        &:first-child {
          .form-control {
            width: 100%;
          }
        }
        &:nth-child(3) {
          .form-group {
            .form-control {
              width: 100%;
            }
          }
        }
        .form-control {
          width: 100%;
        }
        .search_option_button {
          button {
            width: 100%;
          }
        }
      }
    }
    ul.apeartment_area_list {
      li {
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 100%;
          }
        }
        margin-right: 20px;
      }
    }
    ul.h1ads_1st_list {
        li:first-child{
            margin-bottom: 25px !important;
        }
      li.custome_fields_520 {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        margin: 10px 0;
        padding: 0 15px;
      }
    }
    .dropdown.bootstrap-select {
      .dropdown-menu.show {
        min-width: auto !important;
        width: 100% !important;
      }
    }
    .bootstrap-select {
      .dropdown-menu {
        li {
          a {
            width: 100%;
          }
        }
      }
    }
  }
  .home1-advnc-search.home9 {
    ul {
      li {
        width: 45%;
      }
    }
  }
  .home_content {
    padding: 60px 0 400px !important;
  }
  .home-one {
    .home-text {
      padding: 0 15px;
    }
  }
  .home_adv_srch_opt {
    margin-top: 120px;
  }
  .dropdown-content {
    bottom: 0;
    left: 0;
    max-height: -webkit-fill-available;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    visibility: visible !important;
  }
  .dropdown-content.home2 {
    top: 0;
  }
  .featured_row {
    margin-top: 0;
  }
  body.mobile_ovyh {
    overflow-y: hidden;
  }
  .dd_content2 {
    top: auto;
    z-index: 9;
  }
  .mega-dropdown {
    .dropbtn {
      display: block;
    }
  }
  .inner_page_breadcrumb {
    .breadcrumb_content {
      margin-top: 50px;
    }
  }
  .contact_form_social_area {
    li {
      margin-bottom: 10px;
    }
  }
  .error_page.footer_apps_widget {
    .mailchimp_form {
      .form-control {
        width: 375px;
      }
    }
    .form-inline {
      display: -webkit-inline-box;
    }
  }
  ul.mc_parent_list {
    li {
      text-align: center;
      width: 100%;
    }
    .membership_header {
      .details {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .checkout_form {
    .checkout_coupon {
      form {
        input.coupon_input {
          width: 100%;
        }
        button {
          margin-top: 15px;
          width: 100%;
        }
        button.btn3 {
          width: 100%;
        }
      }
    }
  }
  .cart_page_form {
    tbody.table_body {
      th {
        .cart_list {
          li {
            display: block;
            padding-right: 0;
          }
        }
      }
    }
    tr.carttable_row {
      th {
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
  ul.total_reivew_view {
    li.write_review {
      position: relative;
      right: auto;
    }
  }
  .main-banner-wrapper {
    .carousel-btn-block {
      .carousel-btn.left-btn {
        display: none;
      }
      .carousel-btn.right-btn {
        display: none;
      }
    }
  }
  .home_content.home4 {
    padding: 120px 0 30px !important;
  }
  .home4_iconbox {
    display: none;
  }
  .banner-style-one {
    .owl-dots {
      bottom: 20px;
      display: block !important;
      text-align: center;
      .owl-dot {
        span {
          margin: 0 4px;
        }
      }
    }
  }
  .home_content.home5 {
    padding: 30px 0 !important;
    .home-text.home5 {
      display: block;
      padding: 0;
    }
  }
  ul.apeartment_area_list.home5 {
    margin-top: 0px;
  }
  .home_content.home6 {
    .home-text {
      display: block;
    }
    padding: 30px 0 !important;
  }
  .home_adv_srch_opt.home6 {
    margin-top: 35px;
  }
  .home1-advnc-search.home7 {
    ul {
      li {
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
    .search_option_two {
      .dropdown.bootstrap-select {
        >.dropdown-toggle {
          width: 100%;
        }
      }
    }
  }
  .home_content.home7 {
    .home-text {
      display: block;
    }
    padding: 200px 0 !important;
  }
  .small_dropdown2.home10 {
    .dd_content2 {
      left: 15px;
      top: 250px;
    }
  }
  .home10-mainslider {
    .home-content {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .sidebar_switch {
    z-index: 1;
  }
  .feat_property.list {
    .details {
      .tc_content {
        padding: 20px;
      }
      .fp_footer {
        padding: 0 20px 20px;
      }
    }
  }
  .home_adv_srch_opt.listing {
    margin-top: 0;
  }
  .h1ads_1st_list.half_style {
    li.custome_fields_half {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
  .feat_property.list.favorite_page {
    display: block;
    .thumb {
      height: auto;
      max-width: 100%;
    }
    .view_edit_delete_list {
      position: relative;
      text-align: center;
    }
    .details {
      .tc_content {
        padding: 20px 0;
      }
    }
  }
  .feat_property.list.favorite_page.style2 {
    .details {
      .tc_content {
        padding: 15px 0 0;
      }
    }
  }
  .my_profile_setting_input {
    .btn1 {
      margin-bottom: 30px;
      width: 100%;
    }
    .btn3 {
      margin-bottom: 30px;
      width: 100%;
    }
    .btn2 {
      width: 100%;
    }
  }
  .listing_single_row {
    margin-bottom: 15px;
  }
  .sidebar_switch.mobile_style {
    position: relative;
    margin-bottom: 75px;
    #main2 {
      .filter_open_btn {
        left: 0;
        margin: 0 auto;
        top: 0;
      }
    }
  }
  .sticky-nav-tab {
    padding: 5px 2px 15px;
  }
  .home5-slider {
    .home_content {
      padding-top: 22%;
      .slider-text1 {
        font-size: 24px;
      }
    }
    .pogoSlider {
      height: 320px;
    }
  }
  .user_profile {
    margin: 15px;
  }
  .testimonial_content {
    margin: 70px 0;
  }
  .blog_post_home2 {
    .bph2_header {
      img {
        width: 100%;
      }
    }
  }
  .mobile-menu {
    .header_user_notif {
      right: 14%;
    }
  }
  .application_statics {
    padding: 15px;
  }
  .divider {
    padding: 80px 0;
  }
  .app_grid {
    text-align: center;
    .apple_btn {
      margin-bottom: 30px;
      display: block;
      width: 100%;
      .icon {
        margin-left: 30%;
      }
    }
    .play_store_btn {
      .icon {
        margin-left: 30%;
      }
      display: block;
      width: 100%;
    }
  }
  .footer_apps_widget_home1 {
    input.form-control {
      margin-bottom: 15px;
      width: 100%;
    }
    button.btn {
      display: block;
      margin-left: auto;
      width: 100%;
    }
  }
  .footer_apps_widget {
    .app_grid {
      .apple_btn {
        .icon {
          margin-left: 10px;
        }
      }
      .play_store_btn {
        .icon {
          margin-left: 10px;
        }
      }
    }
    .mailchimp_form {
      button {
        margin-left: -55px;
        margin-top: 3px;
        width: 45px;
      }
    }
  }
  .logo-widget {
    text-align: center;
  }
  .footer_menu_widget {
    text-align: center;
    ul {
      li {
        padding-right: 15px;
      }
    }
  }
  .footer_social_widget {
    li {
      margin-right: 10px !important;
    }
  }
  .home3_about {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .becomea_instructor_home3.style1 {
    margin-bottom: 30px;
  }
  .logo-widget.home1 {
    display: block;
    img {
      vertical-align: middle;
    }
  }
  .logo-widget.home2 {
    display: block;
    img {
      vertical-align: middle;
    }
  }
  .logo-widget.home3 {
    display: block;
    img {
      vertical-align: middle;
    }
  }
  .home3_bgi6 {
    height: 750px;
  }
  .mk-fullscreen-search-overlay {
    #mk-fullscreen-search-wrapper {
      #mk-fullscreen-searchform {
        #mk-fullscreen-search-input {
          max-width: 350px;
        }
      }
    }
    #mk-fullscreen-search-wrapper2 {
      #mk-fullscreen-searchform2 {
        #mk-fullscreen-search-input2 {
          max-width: 350px;
        }
      }
    }
  }
  div#countdown {
    .col-xs-6.col-sm-3 {
      display: initial;
    }
  }
  .home-five {
    .home-text {
      left: 15px;
      right: 15px;
      h2 {
        font-size: 30px;
      }
    }
  }
  .bg-img5 {
    height: auto;
  }
  .about_box_home6 {
    .thumb {
      &::before {
        display: none;
      }
    }
  }
  .logo-widget.home6 {
    margin-left: 25%;
    margin-top: 10px;
  }
  .footer_apps_widget_home1.home6 {
    input.form-control {
      max-width: 0 0 15px 0;
    }
  }
  .logo-widget.home8 {
    margin-left: 75px;
  }
  .smp_icon_box {
    padding: 0 30px 0;
    text-align: center;
  }
  .smp_icon_box.box1 {
    margin-bottom: 30px;
  }
  .funfact_two_details {
    li {
      .funfact_two {
        text-align: center;
      }
      margin-right: 60px !important;
    }
  }
  .logo-widget.error_paged {
    display: grid;
    left: 0;
    margin: 0 auto;
    padding-left: 0px;
    position: absolute;
    right: 0;
  }
  .team_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
      left: 0px;
      top: 35%;
    }
    .owl-next {
      right: 0px;
      top: 35%;
    }
  }
  .instructor_search_result {
    text-align: center;
  }
  .candidate_revew_search_box {
    input.form-control {
      margin-left: 0;
    }
  }
  .product_single_content {
    span.sspd_review {
      float: none !important;
      margin-bottom: 15px;
    }
    .ssp_reply {
      float: none !important;
      margin-bottom: 15px;
    }
  }
  .membership_container {
    padding: 30px 5px;
  }
  .product_single_content.style2 {
    .mbp_pagination_comments {
      .mbp_first.media {
        .mbp_sub.media {
          display: block;
        }
        display: block;
        img {
          margin-bottom: 15px !important;
        }
      }
      .mbp_second.media {
        display: block;
      }
      .mbp_first {
        img {
          margin-bottom: 15px !important;
        }
      }
      .mbp_second {
        img {
          margin-bottom: 15px !important;
        }
      }
    }
    .mbp_first {
      .mbp_sub {
        img {
          margin-bottom: 15px !important;
        }
      }
    }
  }
  .candidate_revew_select.style2 {
    ul {
      li {
        display: block;
        text-align: center;
      }
    }
  }
  .candidate_revew_search_box.course {
    input.form-control {
      width: 80%;
    }
  }
  .courses_single_container {
    .cs_row_four {
      .about_ins_container {
        .details {
          .about_info_list {
            li {
              padding-right: 15px;
              span {
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
    .cs_row_one {
      .courses_big_thumb {
        .thumb {
          .overlay_icon {
            a {
              top: 30%;
            }
          }
        }
      }
    }
    .cs_row_five {
      .student_feedback_container {
        .s_feeback_content {
          .sonny_progressbar {
            max-width: 350px;
          }
        }
      }
    }
  }
  .mbp_first.media.csv1 {
    display: block;
    .media-body {
      margin-top: 20px;
    }
  }
  .mbp_sub.media.csv1 {
    display: block;
    .media-body {
      margin-top: 20px;
    }
  }
  .mbp_second.media.csv1 {
    display: block;
    .media-body {
      margin-top: 20px;
    }
  }
  .inner_page_breadcrumb.csv2 {
    .breadcrumb_content {
      margin-top: 85px;
    }
  }
  .inner_page_breadcrumb.csv3 {
    .breadcrumb_content {
      margin-top: 85px;
    }
  }
  .header_user_notif {
    li {
      &:nth-child(2) {
        margin-right: 0;
      }
    }
    .user_notif {
      .dropdown-menu.notification_dropdown_content.show {
        margin-left: -200px !important;
      }
      .so_heading {
        &:before {
          right: 100px;
        }
      }
    }
  }
  .my_course_content_list {
    .mc_content_list {
      .details {
        .mc_footer.style2 {
          margin-bottom: 40px;
        }
        .mc_footer {
          ul.skills {
            float: left !important;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width:500px) {
  .mbp_pagination_comments {
    .total_review {
      display: block;
      position: relative;
      text-align: center;
    }
    a.write_review {
      position: relative;
      right: auto;
    }
  }
}
@media only screen and (max-width:479px) {
  .ulockd-mrgn1225 {
    margin-top: inherit !important;
  }
  .footer_menu_widget {
    ul {
      li {
        padding-right: 5px;
      }
    }
  }
  .logo-widget.home8 {
    margin-left: 55px;
  }
}
@media only screen and (max-width:414px) {
  .mt10-414 {
    margin-top: 10px;
  }
  .fn-414 {
    float: none !important;
  }
  .dn-414 {
    display: none !important;
  }
  .dropdown-content {
    width: 108%;
  }
  .home1-advnc-search {
    ul.apeartment_area_list {
      li {
        margin-right: 0;
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 385px;
          }
        }
      }
    }
    ul {
      li {
        &:nth-child(2) {
          margin-right: 0;
        }
        &:nth-child(4) {
          margin-right: 0;
        }
      }
    }
  }
  .dd_content2 {
    right: 0;
    &:before {
      left: auto;
      right: 20px;
    }
  }
  .cart_page_form {
    .table_body {
      td {
        &:nth-child(5) {
          display: inline;
          a {
            display: block;
            margin-top: 10px;
          }
        }
        padding: .75rem;
      }
      th {
        padding: .75rem;
      }
    }
  }
  .sp_search_content {
    display: table;
    margin-bottom: 30px;
    text-align: center;
  }
  .search_drpdwn {
    float: none;
  }
  .tes-nav {
    .slick-list {
      .slick-track {
        li.slick-slide.slick-current.slick-active.slick-center {
          width: 85px !important;
        }
      }
    }
    .slick-slide.slick-current.slick-active.slick-center {
      img {
        -webkit-transform: translate(-10%, 0) scale(.9);
        -moz-transform: translate(-10%, 0) scale(.9);
        -o-transform: translate(-10%, 0) scale(.9);
        transform: translate(-10%, 0) scale(.9);
      }
    }
  }
  li.slick-slide.slick-active {
    &:nth-child(6) {
      img {
        -webkit-transform: translate(-10%, 0) scale(1.1);
        -moz-transform: translate(-10%, 0) scale(1.1);
        -o-transform: translate(-10%, 0) scale(1.1);
        transform: translate(-10%, 0) scale(1.1);
      }
    }
    &:nth-child(8) {
      img {
        -webkit-transform: translate(3%, 0) scale(1.1);
        -moz-transform: translate(3%, 0) scale(1.1);
        -o-transform: translate(3%, 0) scale(1.1);
        transform: translate(3%, 0) scale(1.1);
      }
    }
  }
  .grid_list_search_result {
    .right_area {
      ul {
        li {
          &:first-child {
            margin-right: 0;
            &:before {
              display: none;
            }
          }
        }
      }
      li {
        display: block;
      }
    }
  }
  .sidebar_content_details.style2 {
    left: 0;
    .dropdown-menu.show {
      max-width: 350px;
      transform: translate(10px, 10px) !important;
      min-width: 300px !important;
    }
  }
  .message_container {
    .message_input {
      form {
        button.btn {
          right: 5px;
          width: auto;
        }
      }
    }
  }
  .iba_container {
    display: block;
    .icon_box_area {
      margin-bottom: 15px;
    }
  }
  .single_line {
    .para {
      float: none;
    }
    .review {
      float: none;
    }
  }
  .sticky-nav-tab {
    padding: 10px 0px 15px;
  }
  .sticky-nav-tabs-container {
    li {
      a {
        font-size: 13px;
      }
    }
  }
  .main-title {
    a {
      display: inline-block;
      float: none !important;
    }
  }
  .featurej_post {
    li {
      line-height: 30px;
    }
    span.pl20 {
      padding-left: inherit;
    }
  }
  .ui_kit_tooltip {
    .btn {
      margin-bottom: 10px;
    }
  }
  .candidate_revew_select.pjlv3 {
    ul {
      li {
        &:nth-child(2) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .t_icon.home3 {
    left: 30px;
  }
  .testimonial_content {
    .thumb {
      margin-bottom: 20px;
    }
  }
  .mobile-menu {
    .header_user_notif {
      right: 17%;
      li {
        &:first-child {
          display: none;
        }
      }
    }
  }
  .stylehome1.dashbord_mobile_logo {
    .nav_logo_img {
      margin-left: -35px;
    }
  }
  .mk-fullscreen-search-overlay {
    #mk-fullscreen-search-wrapper {
      #mk-fullscreen-searchform {
        #mk-fullscreen-search-input {
          max-width: 260px;
        }
      }
    }
    #mk-fullscreen-search-wrapper2 {
      #mk-fullscreen-searchform2 {
        #mk-fullscreen-search-input2 {
          max-width: 260px;
        }
      }
    }
  }
  .search_box_home4 {
    .header_search_widget {
      .mailchimp_form {
        .form-control {
          width: 300px;
        }
        button {
          right: 10px;
        }
      }
    }
  }
  .about_home3 {
    .partners_thumb_list {
      li {
        width: 40%;
        margin-bottom: 30px;
      }
    }
  }
  .logo-widget.home6 {
    margin-left: 20%;
  }
  .header.stylehome1 {
    .main_logo_home2.home8 {
      span {
        font-size: 16px;
      }
    }
  }
  .logo-widget.home8 {
    margin-left: 20px;
  }
  .funfact_two_details {
    li {
      margin-right: 0 !important;
    }
  }
  .candidate_revew_search_box {
    input.form-control {
      width: 85%;
    }
    margin-left: 0;
  }
  .shop_order_box {
    .order_list_raw {
      ul {
        li {
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }
  }
  ul.mc_parent_list {
    ul.mc_child_list.one {
      li {
        padding: 10px 10px;
      }
    }
  }
  .blog_grid_post.style2 {
    .details {
      padding: 20px 25px 25px 20px;
    }
  }
  .courses_single_container {
    .cs_row_five {
      .student_feedback_container {
        .s_feeback_content {
          .sonny_progressbar {
            max-width: 290px;
          }
        }
      }
    }
    .cs_row_one {
      .courses_big_thumb {
        .thumb {
          .overlay_icon {
            .title {
              top: 80%;
            }
          }
        }
      }
    }
    .cs_row_three {
      .course_content {
        .details {
          .cc_tab {
            .panel-body {
              .cs_list {
                li {
                  padding: 20px 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .csv1 {
    .breadcrumb {
      padding: .75rem 0rem;
    }
  }
  .header_user_notif.dashbord_pages_mobile_version {
    li.user_notif {
      &:first-child {
        display: none !important;
      }
    }
  }
  .my_course_content_list {
    .mc_content_list {
      .details {
        .mc_footer {
          ul.mc_review {
            li.fp_pdate {
              margin-left: 0;
            }
          }
        }
      }
    }
    .mc_content_list.style2 {
      padding: 30px 15px;
    }
  }
}
@media only screen and (max-width:400px) {
  .fn-400 {
    float: none !important;
  }
  .tal-400 {
    text-align: left !important;
  }
  .upad395xsd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .home1-advnc-search {
    ul.apeartment_area_list {
      li {
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 340px;
          }
        }
      }
    }
  }
  .footer_apps_widget.home4 {
    .mailchimp_form {
      .form-control {
        margin-left: 0;
        width: 100%;
      }
    }
  }
  .list-style-type-bullet {
    li {
      margin-left: 50px;
    }
  }
  .home_adv_srch_opt {
    .nav-pills {
      margin: 0;
    }
  }
  .home3_home_content {
    h1 {
      display: none;
    }
  }
  .home_adv_srch_opt.home4 {
    margin-top: 30px;
  }
  .home_content.home6 {
    padding: 10px 0 0 0 !important;
  }
  .home_adv_srch_opt.home6 {
    margin-top: 20px;
  }
  .feat_property {
    .details {
      .fp_footer {
        .fp_pdate {
          margin-left: 0;
        }
      }
    }
  }
  .home_content.home7 {
    padding: 170px 0 !important;
  }
  .bs_carousel {
    .main_title {
      font-size: 40px !important;
    }
  }
  .home1-advnc-search.home9 {
    height: auto;
    text-align: center;
    ul {
      li {
        width: 90%;
      }
    }
  }
  .main_blog_post_content {
    .mbp_thumb_post {
      padding: 30px 15px;
    }
  }
  .mbp_pagination_comments {
    .mbp_first.media {
      padding: 30px 15px;
    }
    .mbp_first {
      .media-body {
        .sub_title {
          display: block;
        }
      }
    }
  }
  .bsp_reveiw_wrt {
    padding: 30px 15px;
  }
  .blog_post_share {
    li {
      &:first-child {
        margin-right: 0;
      }
    }
  }
  .product_single_content {
    .mbp_pagination_comments {
      .mbp_first.media {
        .sub_title {
          ul {
            padding-left: 0;
          }
        }
      }
      .mbp_first {
        img {
          margin-bottom: 15px;
        }
      }
      .mbp_second {
        img {
          margin-bottom: 15px;
        }
      }
    }
  }
  .sidebar_feature_property_slider.owl-carousel.owl-theme {
    .owl-nav {
      display: none;
    }
  }
  .my_dashboard_review {
    .review_content {
      .media {
        display: block;
      }
      .media-body {
        margin-top: 15px;
      }
    }
  }
  .candidate_revew_search_box.course {
    input.form-control {
      width: 75%;
    }
  }
  #main2 {
    .filter_open_btn {
      top: -55px;
    }
  }
  .home_content.home4 {
    padding: 150px 0 30px !important;
  }
  .app_grid {
    .apple_btn {
      .icon {
        margin-left: 50px;
      }
    }
    .play_store_btn {
      .icon {
        margin-left: 50px;
      }
    }
  }
  .mk-fullscreen-search-overlay {
    #mk-fullscreen-search-wrapper {
      #mk-fullscreen-searchform {
        #mk-fullscreen-search-input {
          max-width: 220px;
        }
      }
    }
    #mk-fullscreen-search-wrapper2 {
      #mk-fullscreen-searchform2 {
        #mk-fullscreen-search-input2 {
          max-width: 220px;
        }
      }
    }
  }
  .logo-widget.home6 {
    margin-left: 15%;
  }
  .header.stylehome1 {
    .main_logo_home2.home7 {
      span {
        font-size: 16px;
      }
    }
    .main_logo_home2.home8 {
      span {
        font-size: 14px;
      }
    }
  }
  .logo-widget.home8 {
    margin-left: 10px;
  }
  .home1-mainslider {
    .home-content {
      margin-top: -10px;
      padding: 0px 0 80px;
    }
  }
  .error_page.footer_apps_widget {
    .mailchimp_form {
      .form-control {
        width: 290px;
      }
    }
  }
  .shop_single_product_details {
    .cart_btns {
      input {
        margin-bottom: 15px;
      }
    }
    .sspd_social_icon {
      li {
        padding-right: 10px;
      }
    }
  }
  .shop_single_tab_content {
    .mbp_pagination_comments {
      .mbp_first.media {
        display: inline-block;
      }
    }
  }
  .courses_single_container {
    .cs_row_three {
      .course_content {
        .details {
          .cc_tab {
            h4.panel-title {
              padding: 10px 8px;
            }
          }
        }
      }
    }
    .cs_row_five {
      .student_feedback_container {
        .s_feeback_content {
          .sonny_progressbar {
            max-width: 200px;
          }
        }
      }
    }
  }
  .header_user_notif {
    .user_notif {
      .dropdown-menu.notification_dropdown_content.show {
        margin-left: -180px !important;
      }
      .so_heading {
        &:before {
          right: 120px;
        }
      }
    }
  }
  .breadcrumb_widgets {
    .title {
      float: none !important;
    }
    .breadcrumb {
      float: none !important;
    }
    padding: 10px 30px;
  }
  .my_course_content_list {
    .mc_content_list {
      .details {
        .mc_footer {
          ul.mc_review {
            li.fp_pdate {
              margin-left: 15px;
              margin-top: 15px;
            }
          }
        }
        .mc_content {
          h5.title {
            span {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .order_key_status {
    li {
      span {
        margin-left: 15px;
      }
      &:last-child {
        span {
          margin-left: 15px;
        }
      }
    }
  }
}
@media only screen and (max-width:360px) {
  .home1_adsrchfrm {
    padding: 30px 10px;
  }
  .home1-advnc-search {
    ul.apeartment_area_list {
      li {
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 320px;
          }
        }
      }
    }
  }
  .home_content {
    padding: 25px 0 400px !important;
  }
  .home_adv_srch_opt {
    margin-top: 160px;
  }
  .single_product {
    .single_item {
      .thumb {
        img {
          height: auto;
        }
      }
    }
  }
  .error_page.footer_apps_widget {
    .mailchimp_form {
      .form-control {
        width: 245px;
      }
    }
  }
  .login_form {
/*    padding: 30px 20px 0;*/
  }
  .sign_up_form {
    padding: 30px 20px 0;
  }
  .ui_kit_message_box {
    .alert {
      display: inline-table;
    }
  }
  .message_container {
    .message_input {
      form {
        input.form-control {
          padding-left: 10px;
        }
      }
    }
  }
  .t_icon.home3 {
    left: 15px;
  }
  .testimonial_content {
    .thumb {
      margin-bottom: 10px;
    }
  }
  .mobile-menu {
    .header_user_notif {
      right: 20%;
    }
  }
  .header.stylehome1 {
    .main_logo_home2.home8 {
      span {
        font-size: 12px;
      }
    }
  }
  .logo-widget.home8 {
    span {
      font-size: 16px;
    }
  }
  .home1-mainslider {
    .banner-style-one {
      .banner-btn {
        padding: 12px 20px;
      }
    }
  }
  .courses_single_container {
    .cs_row_three {
      .course_content {
        .details {
          .cc_tab {
            h4.panel-title {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .header_user_notif {
    li {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  .my_course_content_list {
    .mc_content_list {
      .details {
        .mc_footer {
          ul.mc_meta {
            margin-right: 10px;
            li {
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
  .mbp_first.media.csv1.style2 {
    .media-body {
      .ssp_reply {
        float: none !important;
        margin-bottom: 15px;
      }
    }
  }
  .mbp_second.media.csv1.style2 {
    .media-body {
      .ssp_reply {
        float: none !important;
        margin-bottom: 15px;
      }
    }
  }
}
@media only screen and (max-width:340px) {
  .home3_home_content {
    a.popup_video_btn {
      top: -40px;
    }
  }
  .mk-fullscreen-search-overlay {
    .mk-fullscreen-close {
      right: 20px;
    }
  }
  .feat_property {
    .details {
      .fp_footer {
        .fp_pdate {
          float: left !important;
        }
      }
    }
  }
  .tes-nav {
    .slick-list {
      .slick-track {
        li.slick-slide.slick-current.slick-active.slick-center {
          margin-top: 10px;
          width: 75px !important;
        }
      }
    }
    img {
      width: 50px;
      height: 50px;
    }
    .slick-slide.slick-current.slick-active.slick-center {
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
  .bsp_reveiw_wrt {
    padding: 15px;
  }
  .feat_property.list {
    .dtls_headr {
      display: block;
    }
    .details {
      .fp_footer {
        .fp_pdate {
          float: none !important;
          margin-top: -10px;
        }
        .fp_meta {
          float: none !important;
          li {
            a {
              vertical-align: super;
            }
          }
        }
      }
    }
  }
  .feat_property.list.agent {
    .details {
      .fp_footer {
        .fp_pdate {
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }
  .feat_property.list.agency {
    .details {
      .fp_footer {
        .fp_pdate {
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }
  .home-text.home5 {
    ul {
      li {
        margin-right: 5px;
      }
    }
  }
  .sidebar_content_details {
    width: 320px;
  }
  .grid_list_search_result.style2 {
    padding: 0;
  }
  .logo-widget.home6 {
    margin-left: 10%;
  }
  .blog_post_home6_date {
    .post_grid {
      padding: 30px 10px 30px 10px;
    }
  }
  .header.stylehome1 {
    .main_logo_home2.home7 {
      span {
        font-size: 14px;
      }
    }
  }
  .half_map_advsrch_navg.style2 {
    ul {
      li {
        margin-right: 5px;
      }
    }
  }
  .shop_single_product_details {
    .sspd_social_icon {
      li {
        padding-right: 6px;
      }
    }
  }
  .product_single_content {
    .mbp_pagination_comments {
      .mbp_first {
        img {
          margin-right: 10px !important;
        }
      }
      .mbp_second {
        img {
          margin-right: 10px !important;
        }
      }
    }
  }
  .inner_page_breadcrumb.csv2 {
    .breadcrumb_content {
      margin-top: 60px;
    }
  }
  .inner_page_breadcrumb.csv3 {
    .breadcrumb_content {
      margin-top: 60px;
    }
  }
  .cs_row_one.csv2 {
    .cs_title {
      font-size: 22px;
    }
  }
  .cs_row_one.csv3 {
    .cs_title {
      font-size: 22px;
    }
    .cs_review_seller {
      margin-bottom: 5px;
    }
  }
  .header_user_notif {
    li.user_setting {
      .dropdown-menu.show {
        margin-left: -180px !important;
        &:before {
          right: 60px;
        }
      }
    }
  }
}
@media only screen and (max-width:320px) {
  .home1-advnc-search {
    ul.apeartment_area_list {
      li {
        .dropdown.bootstrap-select {
          >.dropdown-toggle {
            width: 290px;
          }
        }
      }
    }
  }
  .feat_property {
    .details {
      .tc_content {
        .prop_details {
          li {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .message_container {
    .message_input {
      form {
        button.btn {
          right: 0;
        }
      }
    }
  }
  .list-style-type-bullet {
    li {
      margin-left: 30px;
    }
  }
  .home3_home_content {
    a.popup_video_btn {
      display: none;
    }
  }
  .home-text.home5 {
    ul {
      li {
        margin-right: 0;
      }
    }
  }
  .properti_city.home5 {
    .overlay {
      .details {
        p {
          margin-right: 10px;
        }
      }
    }
  }
  .mk-search-trigger.style2 {
    right: 60px;
  }
  .tes-nav {
    .slick-list {
      .slick-track {
        li.slick-slide.slick-current.slick-active.slick-center {
          width: 65px;
        }
      }
    }
  }
  .grid_list_search_result {
    .half_map_advsrch_navg {
      ul {
        li {
          &:first-child {
            &:before {
              right: -10px;
            }
            margin-right: 20px;
          }
        }
      }
    }
  }
  .search_box_home4 {
    .header_search_widget {
      .mailchimp_form {
        .form-control {
          width: 270px;
        }
        button {
          right: 10px;
        }
      }
    }
  }
  .logo-widget.home6 {
    margin-left: 7%;
  }
  .header.stylehome1 {
    .main_logo_home2.home7 {
      span {
        font-size: 12px;
      }
    }
    .main_logo_home2.home8 {
      span {
        font-size: 10px;
        margin-left: 5px;
      }
    }
  }
  .our-media.pb0 {
    .container-fluid.p0 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  .logo-widget.home8 {
    span {
      font-size: 16px;
    }
  }
  .courses_single_container {
    .cs_row_three {
      .course_content {
        .details {
          .cc_tab {
            h4.panel-title {
              padding: 10px 4px;
            }
          }
        }
      }
    }
  }
}
.nav_logo_img {
  @media (min-width: 768px) and (max-width: 992px) {
    height: 50px;
  }
  @include screen-sm {
    height: 40px;
  }
  @include screen-xs {
    height: 40px;
  }
  @media (max-width: 500px) {
    margin-left: 50px;
  }
  @media (max-width: 425px) {
    margin-left: 40px;
    height: 30px;
  }
}
.main_logo_home2 {
  @include screen-xs {
    width: 100%;
  }
}

.justify-content-start-xs {
  @include screen-xs {
    justify-content: start !important;
  }
}

.mm-listitem {
  .btn-thm {
    &:hover, &:focus, &:active {
      background-color: transparent;
    }
  }
}

//navbar
@media (max-width: 1550px) and (min-width: 1440px){
  .header_search_widget {
    .mailchimp_form {
      .form-control {
        width: 220px;
      }
    }
  }
  .default-logo {
    width: 220px;
  }
  header.header-nav.menu_style_home_one.style2 a.navbar_brand {
    margin-top: 22px;
  }
  header.header-nav.menu_style_home_one .ace-responsive-menu > li > a {
    padding: 37px 10px 30px 6px;
  }
}
@include screen-lg {
  .property_city_home6 {
    .thumb {
      img {
        height: 100%;
        width: 150px;
      }
    }
  }
}
@include screen-sm {
  .property_city_home6 {
    .thumb {
      img {
        width: 100%
      }
    }
  }
}
@include screen-xs {
  .property_city_home6 {
    display: flex;
    .thumb, .details {
      display: inline-block;
    }
    .details {
      padding-top: 0;
      padding-bottom: 0;
      align-self: center;
      p {
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 400px) {
  .property_city_home6 {
    .thumb {
      width: 150px;
      height: 120px;
    }
  }
}
@media (max-width: 350px) {
  .property_city_home6 {
    .thumb {
      width: 130px;
      height: 100%;
    }
  }
}

.container-for-search {
  padding-top:90px;
  @media (max-width: 1340px) {
    max-width: 1170px !important;
    padding-top: 0px !important;
  }
}

.h1ads_1st_list {
  @media (max-width: 1340px) {
    .h-search {
      height: 100%;
      display: block !important;
      margin-left: auto !important;
    }
    .search-dd-wrap {
      margin-left: auto !important;
    }
  }
  @media (max-width: 1100px) {
    .search-dd-wrap {
      padding-left: 0 !important;
    }
  }
  @include screen-md-down {
    //display: block !important;
    .h-search {
      display: inline-flex !important;
      vertical-align: top;
    }
    .search-main-button {
      float: right !important;
    }
    .search-dd-wrap {
      padding-left: 20px !important;
      display: inline-block !important;
    }
  }
  @media (max-width: 950px) {
    display: inline-flex !important;
    width: 100%;
    .search-dd-wrap {
      .mega-dropdown {
        a {
          text-align: left !important;
        }
        i {
          &.flaticon-more {
            padding-left: 0 !important;
          }
        }
      }
    }
    .ui_kit_checkbox {
      margin-bottom: 20px !important;
    }
    .search-main-button {
      display: inline-flex !important;
      float: right !important;
    }
  }
  @media (max-width: 950px) and (min-width: 651px) {
    .search-dd-wrap {
      display: inline-flex !important;
      padding-left: 30px !important;
    }
  }
  @media (max-width: 900px) {
    display: block !important;
    .search-main-button {
      display: block !important;
      float: unset !important;
      margin-top: 25px;
    }
    .h1ads-list-wrap {
      display: inline-block !important;
    }
    .h1ads_1st_list {
      .search-dd-wrap {
        display: inline-block;
        float: right;
      }
    }
  }
  @media (max-width: 650px) {
    .search-dd-wrap {
      width: auto !important;
      padding-left: 0 !important;
    }
    .ui_kit_checkbox {
      display: inline-block !important;
    }
    #searchform-price {
      width: auto !important;
    }
  }
  @media (max-width: 376px) {
    #searchform-price {
      width: 90px !important;
    }
  }
  @media (max-width: 650px) {
    .home1_adsrchfrm {
      padding: 25px !important;
    }
    .h1ads_1st_list {
      .search-dd-wrap {
        display: block !important;
        padding-left: 0 !important;
      }
    }
    .h1ads-list-wrap {
      padding-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100%;
    }
    .search-main-button {
      display: block !important;
      div {
        display: block !important;
      }
      .btn {
        width: 100% !important;
      }
    }
  }
  @media (max-width: 421px) {
    .search-dd-wrap {
      padding-left: 0 !important;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 767.98px) and (min-width: 521px) {
  .home-six {
    height: 550px !important;
    &:before {
      height: 550px !important;
    }
  }
}
@include screen-md {
  .home-six {
    height: 500px !important;
    &:before {
      height: 500px !important;
    }
  }
}

@include screen-lg {
  .home_adv_srch_opt.home6 {
    margin-top: 25px !important;
  }
  .home_content.home6 {
    padding: 30px 0 30px
  }
  .home-six {
    height: 500px !important;
    &:before {
      height: 500px !important;
    }
  }
}

@media (max-width: 1350px) {
  .navbered {
    .dropdown-content {
      top: 265px !important;
    }
  }
}
@include screen-xl-down {
  .feat_property {
    .details {
      .tc_content {
        &.estate-content {
          height: 245px;
        }
      }
    }
  }
}
@include screen-lg-down {
  .search-row {
    .pr-5 {
      padding-right: 15px !important;
    }
  }
}
@include screen-md-down{
  .navbered {
    .dropdown-content {
      top: 355px !important;
      .search-row {
        .border-right {
          border-right: none !important;
        }
      }
    }
    .accordion-btn i {
      padding-left: 0.5rem !important;
    }
  }
  .no-pl-mdd {
    padding-left: 15px !important;
  }
  .search-row {
    .no-pr-xs {
      padding-right: 3rem !important;
    }
  }
}
@include screen-xs {
  .navbered {
    .dropdown-content {
      top: 405px !important;
    }
  }
}
@media (max-width: 520px) {
  .navbered {
    .dropdown-content {
      top: 0 !important;
    }
  }
}

@include screen-md-down {
    .tematikus {
        .satellite-list-item-image {
            min-width: 40px !important;
            img {
                border-radius: 4px !important;
            }
        }
    }

    .adatkezelesi {
        h1 {
            font-size: 24px;
        }
    }
}

@media (max-width: 1315px) and (min-width: 1291px) {
  header.header-nav.menu_style_home_one.style2 .ace-responsive-menu li a {
    padding-right: 10px !important;
  }
}
@media (max-width: 1125px) and (min-width: 1025px) {
  .ht_left_widget ul li.list-inline-item:nth-child(2) {
    margin-right: 50px;
  }
}
@media (max-width: 580px) {
  .owl-nav {
    display: none;
  }
}

.list-search-result {
  @include screen-lg {
    height: auto;
    padding: 15px;
    .email-report-show {
      margin-bottom: 15px;
    }
  }

  @include screen-xs {
    height: auto;
    padding: 15px;
    .email-report-show {
      margin-bottom: 15px;
    }
  }
}

.single_property_social_share {
  @include screen-sm-down {
    .spss {
      text-align: left !important;
      margin-bottom: 20px;
    }
  }
}

.spls_style_two {
  .overlay {
    &.mobile-overlay {
      display: none;
    }
  }
  @include screen-md {
    .overlay {
      padding-top: 25%;
    }
  }
  @include screen-sm-down {
    .overlay {
      &.mobile-overlay {
        width: 100%;
        height: 50px;
        padding-top: 0;
        top: unset;
        left: unset;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
}

.sidebar_feature_listing {
  &.similar-office {
    .media {
      @media (max-width: 400px) {
        img {
          width: 90px !important;
        }
      }
    }
  }
}

@include screen-xs {
    .modal-dialog {
        margin: 1.75rem;
        
        .modal-body {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

.media-responsive {
    img {
        align-self: center;
        margin-bottom: 10px;
    }
    .email {
        word-break: break-all;
    }
    @include screen-xs {
        flex-direction: column;
        img {
            align-self: start;
        }
    }
    @include screen-lg {
        flex-direction: column;
        img {
            align-self: start;
        }
    }
}

.map-selector-wrap {
    //@include screen-sm-down {
    //    display: none !important;
    //}
}

@include media-down(1444) {
    .nav-search {
        display: none;
    }
}