.swal2-icon.swal2-desktop {
  border: none;
  color: #db1c1f !important;
}

.swal2-styled.swal2-confirm {
  border: 2px solid #db1c1f !important;
  background-color: #db1c1f !important;
  transition: .2s;
  color: white !important;
  padding: 10px 32px;
  cursor: pointer;
  webkit-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
}
.swal2-styled.swal2-confirm:focus {
  outline: none;
}
.swal2-styled.swal2-confirm:hover {
  background-color: #ffffff !important;
  border: 2px solid #db1c1f !important;
  color: #db1c1f !important;
}
.swal2-styled.swal2-confirm:active {
  background-color: #ffffff !important;
  border: 2px solid #db1c1f !important;
  color: #db1c1f !important;
}

.swal2-actions button {
  font-weight: bold;
  padding: 12px 32px;
  border-radius: .25em !important;
}

.swal2-popup {
  border-radius: 5px !important;
}

.swal2-title {
  color: #434343 !important;
  font-weight: bold !important;
}

.swal2-content {
  color: inherit !important;
}