.sign_up_modal, .default-modal, .calculator_result_modal {
    &:before {
        background-color: rgba(0, 0, 0, .9);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        min-height: 520px;
    }
    .tab-content {
        .tab-pane.active {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
        }
    }
    .modal-header {
        background-color: $red;
        border-radius: 50%;
        border: none;
        height: 50px;
        position: absolute;
        right: -20px;
        text-align: center;
        top: -20px;
        width: 50px;
        z-index: 9;
        button.close {
            margin-top: -5px;
            margin-right: 0;
            margin-left: 3px;
            opacity: 1;
            padding: 0;
            span {
                color: #ffffff;
                &:focus {
                    border: none;
                    outline: none;
                    text-shadow: none;
                }
            }
        }

        &:hover {
            cursor: pointer;
            .close:not(:disabled):not(.disabled), .close:not(:disabled):not(.disabled) {
                opacity: .75;

            }
        }

    }
    .modal-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
    }
}

.sign_up_modal.modal {
    .close {
        &:focus {
            outline: none;
        }
    }
}

.calculator_result_modal{
    #resultPercent{
        font-size: 25px;
        font-weight: bold;
        color:#434343;
    }

    .sub-text-green{
        color: #3bde20;
    }

    #resultText{
        color: #a2a2a2;
    }
    .buttons{
        border: 1px solid #a2a2a2;
    }

    .modal-button{
        color: #ebebeb;
        height: 60px;
        border-top: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        
        button{
            height: 58px;
            display:block;
            width: 100%;
            font-size: 20px;
            div{
                padding-top: 17px;
            }
            &:hover{
                background-color: #f5f5f5;
            }
        }
    }
}