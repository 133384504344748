section {
  &.newsletter-subscribe {
    padding: 0 0 60px 0;
  }
  @include screen-sm-down {
    padding: 10px 0;
  }
}

.calculator-lead{
    font-size: 16px;
    margin-bottom: 30px;
    color: #777777;
}

.calculator{
    padding: 30px 0;
}