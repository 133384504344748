.estate-office-container {
    font-size: 14px;
    font-family: Arial;
    color: #484848;
    line-height: 1.5;

    .office-name {
        font-weight: bold;
        font-size: 16px;
    }

    .office-link {
        a {
            color: #db1c1f !important
        }
    }
}