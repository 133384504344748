.feedback {
  .thanks {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .rate-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .rate-items input {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: url('/images/star.svg') center center no-repeat #f3f1d6;
    border: solid 2px #bfb220;
    filter: grayscale(100%);
    margin-right: 5px;
  }
  .usable .rate-items input {
    cursor: pointer;
  }
  .rate-items input.active {
    filter: grayscale(0%);
  }
  .rate-items input:hover {
    filter: grayscale(0%);
  }
  .rate-type {
    float: left;
    width: 300px;
    margin-right: 20px;
  }
}