.newsletter-subscribe {
  .subscribe {
    padding-top: 3rem;
  }
  .mar-tobb-mint-65-000-en-kapjak {
    font-size: 16px;
    font-weight: 400;
  }
  .ugye-nem-akar-lemaradni {
    color: $red;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .toltse-ki-majd-kattintson-a-pi {
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 20px;
  }
  .korabbi-levelek {
    ul {
      padding-left: 15px;
      li {
        list-style: initial;
      }
    }
    .utolso-5 {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 10px;
      color: $dark-grey;
    }
    .level {
      display: block;
      color: $dark-grey;
      text-decoration: none;
      font-size: 14px;
      margin: 10px 0;
      &:hover {
        color: $red;
        text-decoration: underline;
      }
    }
  }
}