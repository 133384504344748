/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2020. szept. 30., 16:38:26
    Author     : hejjagyorgy
*/

.bemutatkozas {
    p {
        font-size: 16px; 
    }


    .welcome-title, .why-title {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0;
        font-weight: bold;
    }

    .welcome-title {
        margin-bottom: 30px;
    }

    ul {
        list-style-position: inside;
        li {
            list-style-type: disc;
            color: $red;
            span {
                color: $list-grey;
            }
        }
    }
    .ffff {
        font-size: 20px;
    }

    .email, .csoport a {
        color: $red;
    }
    
    .csoport {
      
    }

    .rounds {
        a {
            transition: .3s ease;
            display: flex;
            justify-content: center;
            color: $dark-grey;
            font-weight: 700;
            font-size: 18px;
            text-align: center;

            &:hover {
                color: $red;
            }
            
            figure {
                height: 120px;
                width: 120px;
                background-image: url('/images/bemutatkozas/icon_sprite.png');
            }
        }
        
        .ingatlanirodaknak {
            figure {
                background-position-x: -240px;
                &:hover {
                   background-position-x: -360px; 
                }
            }
        }
        
        .maganszemelyeknek {
             figure {
                background-position-x: -480px;
                &:hover {
                   background-position-x: -600px; 
                }
            }
        }
        
        .reklamfelulet {
             figure {
                background-position-x: -720px;
                &:hover {
                   background-position-x: -840px; 
                }
            }
        }
    }
    
    .csoport {
        margin-top: 50px;
        a {
            font-weight: bold;
        }
    }
}