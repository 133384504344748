.blog_post_container {
    table{
        margin-bottom: 20px;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        scroll-margin-top: 90px;
        font-size: 22px;
        margin-bottom: 20px;
    }
    h3 {
        scroll-margin-top: 90px;
        font-size: 18px;
    }
    h4 {
        font-size: 16px !important;
        font-weight: 700;
    }
    .blog_sp_post_meta {
        li {
            font-size: 16px !important;
            a {
                font-size: 16px !important;
            }
        }
    }

    .lined-box {
        border-left: 10px solid #b63232;
        font-family: Arial;
        color: #3c3535;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        background-color: #fff;
        padding: 20px;
    }
    p {
        font-size: 16px !important;
        line-height: 24px;
        margin-bottom: 15px;
    }
    .main_blog_post_content {
        .mbp_thumb_post {
            .details {
                p {
                    line-height: 24px;
                }
                li {
                    display: list-item;
                }
                ul {
                    font-size: 16px !important;
                    color: #434343;
                    margin: -5px 0 10px 40px;
                    list-style: disc;
                }
                ol {
                    font-size: 16px !important;
                    color: #434343;
                    margin: 10px 0 10px 40px;
                    list-style: decimal;
                }
            }
        }
    }
    .blog_post_share {
        margin-top: 48px;
    }
}