.ui_kit_checkbox {
  &.selectable-list {
    .custom-control {
      &.custom-checkbox {
        display: inline-block;
      }
    }
  }
}
.suffixed {
  position: relative;
  .input-group-text {
    background-color: transparent;
    border: 1px solid #ebebeb;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    font-weight: 700;
  }
  .form-control {
    padding-right: 0;
    &:focus {
      border-color: #ebebeb;
    }
    border-right: none;
  }
}

#input-price-label {
  width: 111px;
  justify-content: end;
}