/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2020. okt. 1., 10:03:47
    Author     : hejjagyorgy
*/

.line {
    background-color: $line-grey;
    opacity: .3;
    height: 1px;
    width: 100%;
    margin: 50px 0;
}

.forgot-password {
    h3 {
        font-size: 20px;
    }
}

.gyik {
    p {
        font-size: 16px;   
    }
}
.bold-20 {
    font-weight: bold;
    font-size: 20px !important;
}

.hover-red {
    transition: .3s ease;
    &:hover {
        color: $red !important;
    }
}

.panel{ 
    .faq-heading{
        background-color: transparent;
        border: 1px solid #ebebeb;
        color: #484848; 
        border-radius: 8px;
        margin: 30px 0; 
        cursor: pointer;
        &:hover{
            background-color: #f7f7f7;
            color:white;
        }
        .panel-title{
            margin-bottom: 0;
            padding: 10px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .faq-down-arrow {
                display: inline-block;
                transition: transform 0.3s ease;
                transform: rotate(0deg);
                margin-left: 20px;
            }
        }

        .panel-title[aria-expanded="true"] {
            .faq-down-arrow {
                transform: rotate(-90deg);

            }
        }
    }

    .faq-body, .panel-body{
        padding: 0 10px;
        a{
            color: #db1c1f;
        }
    }
}

.faq-width{
    max-width: 800px;
}

.tematikus {
    .satellite-list-item {
        margin-bottom: 30px;

        .satellite-list-item-image {

            min-width: 180px; 
            img {
                border-radius: 8px;
                width: 180px;
            }
        }
    }
}

.ingatlanirodaknak {
    color: $dark-grey;
    p {
        font-size: 16px;
    }
}
.office-seller {
    .media {
        &.media-responsive {
            align-items: flex-start;
        }
    }
}

.submit-mobile {
    @media (min-width: 802px){
        display: none !important;
    }
}

.short-form {
    select {
        border: none;
        text-align-last: center;
    }
}
[style*="--aspect-ratio"] > :first-child {
    width: 100%;
}
[style*="--aspect-ratio"] > img {  
    height: auto;
} 
@supports (--custom:property) {
    [style*="--aspect-ratio"] {
        position: relative;
    }
    [style*="--aspect-ratio"]::before {
        content: "";
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }  
    [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }  
}
@media only screen and (max-width:992px) {
    .vertical-resp {
        min-height: 360px !important;
        max-height: 360px !important;
        height: 360px !important;
    }
    .inline-horizontal-reszponziv {
        min-height: 301px !important;
        max-height: 301px !important;
        height: 301px !important;
    }
    #zoneframe {
        min-height: 360px !important;
        max-height: 360px !important;
        height: 360px !important;
    }
}
@media (min-width: 992px) {
    .vertical-resp {
        min-height: 662px !important;
        max-height: 662px !important;
        height: 662px !important;
    }
    #zoneframe {
        min-height: 662px !important;
        max-height: 662px !important;
        height: 662px !important;
    }
    .inline-horizontal-reszponziv {
        min-height: 280px !important;
        max-height: 280px !important;
        height: 280px !important;
    }
}
.main-title {
    p {
        a.all-estate-link {
            color: $red;
        }
    }
}

