/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2020. okt. 2., 10:31:58
    Author     : hejjagyorgy
*/

.map-selector {
    
    /*display: none;*/
    
    .map-transparent-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }

    #layer-budapest {
        .map-container {
            background: url('/images/map/map_budapest.png') no-repeat center center;
            width: 257px;
            height: 245px;
            position: relative;
        }
    }
    
    .line {
        margin: 20px 0;
    }
    
    .item {
        display: flex;
        align-items: center;
        
    }
    
    input[type=checkbox] {
        margin-bottom: 5px;
        margin: 0 6px 10px;
    }
}
