.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $red !important;
  background-color: transparent;
  box-shadow: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: '';
  background-color: $red;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 9px;
  left: -19px;
  outline: none;
  box-shadow: none;
  background-image: none;
}

.custom-control.custom-radio {
  label {
    padding-left: 7px;
    font-size: 14px;
    color: #4f4f4f;
    height: 28px;
    margin-bottom: 10px;
  }
}

.ui_kit_checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $red !important;
}

.fz11 {
  font-size: 11px !important;
}
.fz12 {
  font-size: 12px !important;
}
.fz13 {
  font-size: 13px !important;
}
.fz14 {
  font-size: 14px !important;
}
.fz15 {
  font-size: 15px !important;
}
.fz16 {
  font-size: 16px !important;
}
.fz17 {
  font-size: 17px !important;
}
.fz18 {
  font-size: 18px !important;
}
.fz19 {
  font-size: 19px !important;
}
.fz20 {
  font-size: 20px !important;
}
.fz22 {
  font-size: 22px !important;
}
.fz24 {
  font-size: 24px !important;
}
.fz26 {
  font-size: 26px !important;
}
.fz30 {
  font-size: 30px !important;
}
.fz40 {
  font-size: 40px !important;
}
.fz45 {
  font-size: 45px !important;
}
.fz48 {
  font-size: 48px !important;
}
.fz50 {
  font-size: 50px !important;
}
.fz55 {
  font-size: 55px !important;
}
.fz60 {
  font-size: 60px !important;
}
.fz72 {
  font-size: 72px !important;
}
.fz100 {
  font-size: 100px !important;
}
.lh-unset {
  line-height: unset !important;
}
.lh0 {
  line-height: 0 !important;
}
.lh30 {
  line-height: 30px;
}
.h05 {
  height: 5px !important;
}
.h10 {
  height: 10px;
}
.h20 {
  height: 20px;
}
.h25 {
  height: 25px;
}
.h30 {
  height: 30px;
}
.h35 {
  height: 35px;
}
.h40 {
  height: 40px;
}
.h45 {
  height: 45px;
}
.h50 {
  height: 50px;
}
.h55 {
  height: 55px;
}
.h60 {
  height: 60px;
}
.h65 {
  height: 65px;
}
.h70 {
  height: 70px;
}
.h75 {
  height: 75px;
}
.h80 {
  height: 80px;
}
.h85 {
  height: 85px;
}
.h90 {
  height: 90px;
}
.h95 {
  height: 95px;
}
.h100p {
  height: 100%;
}
.h100 {
  height: 100px;
}
.h150 {
  height: 150px;
}
.h200 {
  height: 200px;
}
.h250 {
  height: 250px !important;
}
.h300 {
  height: 300px;
}
.h320 {
  height: 320px;
}
.h345 {
  height: 345px;
}
.h350 {
  height: 350px;
}
.h355 {
  height: 355px;
}
.h400 {
  height: 400px;
}
.h450 {
  height: 450px;
}
.h500 {
  height: 500px;
}
.h550 {
  height: 550px;
}
.h600 {
  height: 600px;
}
.h650 {
  height: 650px;
}
.h660 {
  height: 660px;
}
.h700 {
  height: 700px;
}
.h750 {
  height: 750px;
}
.h800 {
  height: 800px;
}
.h850 {
  height: 850px;
}
.h900 {
  height: 900px;
}
.h950 {
  height: 950px;
}
.h1000 {
  height: 1000px;
}
.wa {
  width: auto;
}
.w60 {
  width: 60px !important;
}
.w80 {
  width: 80px !important;
}
.w100 {
  width: 100% !important;
}
.maxw100 {
  max-width: 100%;
}
.maxw1600 {
  max-width: 1600px;
}
.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.fw800 {
  font-weight: 800 !important;
}
.fw900 {
  font-weight: 900 !important;
}
.fwb {
  font-weight: bold !important;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.dif {
  display: inline-flex;
}
.df {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.dfr {
  display: flow-root;
}
.dn {
  display: none;
}
.ovh {
  overflow: hidden;
}
.ovv {
  overflow: visible;
}
.posa {
  position: absolute;
}
.posr {
  position: relative;
}
.ulockd-pmz {
  margin: 0;
  padding: 0;
}
.p0 {
  padding: 0 !important;
}
.p1 {
  padding: 1;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}
.p35 {
  padding: 35px;
}
.p40 {
  padding: 40px;
}
.p45 {
  padding: 45px;
}
.p50 {
  padding: 50px;
}
.p55 {
  padding: 55px;
}
.p60 {
  padding: 60px;
}
.p65 {
  padding: 65px;
}
.p70 {
  padding: 70px;
}
.p75 {
  padding: 75px;
}
.p80 {
  padding: 80px;
}
.pad10 {
  padding: 10% 5%;
}
.pad17 {
  padding-top: 17% !important;
}
.pt0 {
  padding-top: 0px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt25 {
  padding-top: 25px;
}
.pt30 {
  padding-top: 30px !important;
}
.pt35 {
  padding-top: 35px;
}
.pt40 {
  padding-top: 40px;
}
.pt45 {
  padding-top: 45px;
}
.pt50 {
  padding-top: 50px;
}
.pt55 {
  padding-top: 55px;
}
.pt60 {
  padding-top: 60px;
}
.pt65 {
  padding-top: 65px;
}
.pt70 {
  padding-top: 70px;
  padding-top: 70px;
  padding-top: 70px;
  padding-top: 70px;
}
.pt75 {
  padding-top: 75px;
}
.pt80 {
  padding-top: 80px;
}
.pt85 {
  padding-top: 85px;
}
.pt90 {
  padding-top: 90px;
}
.pt95 {
  padding-top: 95px;
}
.pt100 {
  padding-top: 100px;
  padding-top: 100px;
}
.pt120 {
  padding-top: 120px;
}
.pt130 {
  padding-top: 130px;
}
.pt140 {
  padding-top: 140px;
}
.pt150 {
  padding-top: 150px;
}
.pt160 {
  padding-top: 160px;
}
.pt170 {
  padding-top: 170px;
}
.pt180 {
  padding-top: 180px;
}
.pt190 {
  padding-top: 190px;
}
.pt200 {
  padding-top: 200px;
}
.pr0 {
  padding-right: 0;
}
.pr1 {
  padding-right: 1px;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr25 {
  padding-right: 25px;
}
.pr30 {
  padding-right: 30px;
}
.pr35 {
  padding-right: 35px;
}
.pr40 {
  padding-right: 40px;
}
.pr45 {
  padding-right: 45px;
}
.pr50 {
  padding-right: 50px;
}
.pr55 {
  padding-right: 55px;
}
.pr60 {
  padding-right: 60px;
}
.pr65 {
  padding-right: 65px;
}
.pr70 {
  padding-right: 70px;
}
.pb0 {
  padding-bottom: 0px !important;
}
.pb15 {
  padding-bottom: 15px;
  padding-bottom: 15px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb20 {
  padding-bottom: 20px;
}
.pb25 {
  padding-bottom: 25px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb45 {
  padding-bottom: 45px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb55 {
  padding-bottom: 55px;
}
.pb60 {
  padding-bottom: 60px;
}
.pb65 {
  padding-bottom: 65px;
}
.pb70 {
  padding-bottom: 70px;
}
.pb75 {
  padding-bottom: 75px;
}
.pb80 {
  padding-bottom: 80px;
}
.pb85 {
  padding-bottom: 85px;
}
.pb90 {
  padding-bottom: 90px;
}
.pb95 {
  padding-bottom: 95px;
}
.pb100 {
  padding-bottom: 100px;
}
.pl0 {
  padding-left: 0 !important;
}
.pl1 {
  padding-left: 1px;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl25 {
  padding-left: 25px;
}
.pl30 {
  padding-left: 30px;
}
.pl35 {
  padding-left: 35px;
}
.pl40 {
  padding-left: 40px;
}
.pl45 {
  padding-left: 45px;
}
.pl50 {
  padding-left: 50px;
}
.pl55 {
  padding-left: 55px;
}
.pl60 {
  padding-left: 60px;
}
.pl65 {
  padding-left: 65px;
}
.pl70 {
  padding-left: 70px;
}
.pl75 {
  padding-left: 75px;
}
.pl80 {
  padding-left: 80px;
}
.pl85 {
  padding-left: 85px;
}
.pl90 {
  padding-left: 90px;
}
.pl95 {
  padding-left: 95px;
}
.pl100 {
  padding-left: 100px;
}
.prpl0 {
  padding-left: 0;
  padding-right: 0;
}
.prpl5 {
  padding-left: 5px;
  padding-right: 5px;
}
.prpl10 {
  padding-left: 10px;
  padding-right: 10px;
}
.prpl15 {
  padding-left: 15px;
  padding-right: 15px;
}
.prpl20 {
  padding-left: 20px;
  padding-right: 20px;
}
.prpl25 {
  padding-left: 25px;
  padding-right: 25px;
}
.prpl30 {
  padding-left: 30px;
  padding-right: 30px;
}
.prpl35 {
  padding-left: 35px;
  padding-right: 35px;
}
.prpl40 {
  padding-left: 40px;
  padding-right: 40px;
}
.prpl2 {
  padding-left: 2px;
  padding-right: 2px;
}
.ptpb50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.ptpb60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.plpr0 {
  padding-left: 0px;
  padding-right: 0px;
}
.m0a {
  margin: 0 auto;
}
.mt-5 {
  margin-top: -5px;
}
.mt-10 {
  margin-top: -10px;
}
.mt-15 {
  margin-top: -15px;
}
.mt-20 {
  margin-top: -20px;
}
.mt-25 {
  margin-top: -25px;
}
.mt-30 {
  margin-top: -30px;
  margin-top: -30px;
}
.mt-35 {
  margin-top: -35px;
}
.mt-40 {
  margin-top: -40px;
}
.mt-45 {
  margin-top: -45px;
}
.mt-50 {
  margin-top: -50px;
}
.mt-55 {
  margin-top: -55px;
}
.mt-60 {
  margin-top: -60px;
}
.mt-65 {
  margin-top: -65px;
}
.mt-70 {
  margin-top: -70px;
}
.mt-75 {
  margin-top: -75px;
}
.mt-80 {
  margin-top: -80px;
}
.mt-85 {
  margin-top: -85px;
}
.mt-90 {
  margin-top: -90px;
}
.mt-95 {
  margin-top: -95px;
}
.mt-100 {
  margin-top: -100px;
}
.mt-150 {
  margin-top: -150px;
  margin-top: -150px;
}
.mt-200 {
  margin-top: -200px;
}
.m0 {
  margin: 0;
}
.m5 {
  margin: 5px;
}
.m10 {
  margin: 10px !important;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}
.m25 {
  margin: 25px;
}
.m30 {
  margin: 30px;
}
.m35 {
  margin: 35px;
}
.m40 {
  margin: 40px;
}
.m45 {
  margin: 45px;
}
.m50 {
  margin: 50px;
}
.mt0 {
  margin-top: 0px !important;
}
.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt13 {
  margin-top: 13px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px;
}
.mt35 {
  margin-top: 35px;
}
.mt40 {
  margin-top: 40px;
}
.mt45 {
  margin-top: 45px;
}
.mt50 {
  margin-top: 50px;
}
.mt60 {
  margin-top: 60px;
  margin-top: 60px;
}
.mt65 {
  margin-top: 65px;
}
.mt70 {
  margin-top: 70px;
}
.mt75 {
  margin-top: 75px;
}
.mt80 {
  margin-top: 80px;
}
.mt85 {
  margin-top: 85px;
}
.mt90 {
  margin-top: 90px;
}
.mt95 {
  margin-top: 95px;
}
.mt100 {
  margin-top: 100px;
}
.mt150 {
  margin-top: 150px;
}
.mt200 {
  margin-top: 200px;
}
.mt250 {
  margin-top: 250px !important;
}
.mt300 {
  margin-top: 3000px;
}
.mr0 {
  margin-right: 0px;
}
.ulockd-mrgb35 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr25 {
  margin-right: 25px;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb45 {
  margin-bottom: 45px;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb55 {
  margin-bottom: 55px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb65 {
  margin-bottom: 65px;
}
.mb70 {
  margin-bottom: 70px;
}
.mb75 {
  margin-bottom: 75px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb85 {
  margin-bottom: 85px;
}
.mb90 {
  margin-bottom: 90px;
}
.mb95 {
  margin-bottom: 95px;
}
.mb100 {
  margin-bottom: 100px;
}
.ml0 {
  margin-left: 0px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}
.ml30 {
  margin-left: 30px;
}
.ml35 {
  margin-left: 35px;
}
.ml40 {
  margin-left: 40px;
}
.ml45 {
  margin-left: 45px;
}
.ml50 {
  margin-left: 50px;
}
.ml55 {
  margin-left: 55px;
}
.ml60 {
  margin-left: 60px;
}
.zi1 {
  z-index: 1;
}
.zi9 {
  z-index: 9;
}
.zi-1 {
  z-index: -1;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57 !important;
}

.op1 {
  opacity: 1;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}

.select2-container--krajee-bs4 .select2-selection--single {
  height: 50px !important;
  line-height: 38px !important;
}

.p-fz-16 {
    p {
        font-size: 16px;
    }
}

.modal-body {
    padding: 2rem;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: inherit !important;
}
.p-absolute {
  position: absolute;
  &.r-20 {
    right: 20px;
  }
}
