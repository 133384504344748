/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2020. okt. 12., 13:51:47
    Author     : hejjagyorgy
*/

.under-maintenance {
    
    background-image: url(/images/background/hero-search.png);
    background-position: center;
    background-size: cover;
    
    .content-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
    }
    .flexbox-container {
        min-height: 100vh;
        
        .card-padding {
            padding: 10px;
            background-color: rgba(255,255,255, 0.15);
            border-radius: 8px;

            .card {
                border-radius: 8px;
                p {
                    color: $list-grey;
                }
                .spinner {
                    animation: spin 2s linear infinite;
                    color: $red;
                }
                .font-large-2 {
                    font-size: 2rem;
                }
                
                .fa-facebook-square {
                    color: $list-grey;
                    transition: .3s;
                    &:hover {
                        color: $line-grey;
                    }
                }
            }
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0); 
        }
        100% {
            transform: rotate(360deg);
        }
    }
}