.float-u {
  &.smd {
    @include screen-sm-down {
      float: unset !important;
    }
  }
}
.m-resp-smd {
  @include screen-sm-down {
    &.mmb-10 {
      margin-bottom: 10px !important;
    }
  }
}