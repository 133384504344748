.information-guid {
  ol {
    list-style: decimal;
    margin: revert;
    padding: revert;
  }

  ul {
    list-style: circle;
    margin: revert;
    padding: revert;
  }

  p {
    font-size: 16px;
    margin-bottom: 14px;
  }

  .image {
    margin-top: 14px;
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  .list {
    color: #434343;

    & > li {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 14px;
    }
  }

  .shadowimg {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .title {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 400;
  }
}