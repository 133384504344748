.office-seller {
  .media-body {
    font-size: 13px;
  }
  .media {
    align-items: center;
  }
}

.similar-office {
  .post-title {
    margin-bottom: 0px;
  }
  .address {
    font-size: 13px !important;
  }
  .region-name {
    font-size: 13px !important;
  }
}