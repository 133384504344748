#main-map-region-results {
  position:absolute;
  z-index: 10;
  width: 90%;
  border: 1px solid #aaa;
  display: none;
}

#main-map-region-results div {
  padding: 5px;
  cursor: pointer;
}

#map {
  position: relative;
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

#popup-content {
  overflow-y: auto;
  max-height: 200px;
  width: 400px;
}

.infobar {
  height: 26px;
  font-size: 11px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  opacity: 0.6;
  z-index: 10;
  background: #000;
  color: #fff;
  padding: 5px;
}
.info-text {
  color: #434343;
  font-size: 13px;
}