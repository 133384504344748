@include screen-sm-down {
    .breadcrumb_title {
        font-size: 20px !important;
    }
    .breadcrumb {
        font-size: 14px;
    }
}

.breadcrumb {
    padding: 10px 1rem;
}

li.breadcrumb-item:last-child {
    a {
        color: $red;
    }
}