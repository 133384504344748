.footer-upper {
  background-color: $very-light-gray !important;
  color:$dark-grey;
  padding: 50px 0;
  h4, p {
    text-align: center;
  }
  .footer_about_widget {
    padding-right: 15px;
    position: relative;
    &.first-block, &.second-block {
      @include screen-lg-up {
        &:before {
          content: '';
          position: absolute;
          display: block;
          height: calc(100% + 45px);
          width: 1px;
          top: -22px;
          background-color: $light-grey;
        }
      }
    }
    &.first-block {
      &:before {
        right: -5px;
      }
    }
    &.second-block {
      &:before {
        right: -10px;
      }
    }
  }
}
.footer-bottom-right {
  flex-direction: column;
}

.footer_one{
    .media-link{
        a{
            color: #c5c9d0;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}