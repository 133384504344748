.message-sent {
  .message-sent-flash {
    background-color: $light-green;
    border: 1px solid $dark-green;
    padding: 17px 15px;
  }
  .message-form-container {
    padding: 30px;
    border: 1px solid #b9bbbd;
    background-color: #ebebeb;
  }
  
  @include screen-sm-down {
        .message-form-container {
            padding: 30px 15px;
        }
  }
}
