$screen-sm-min: 576px !default;
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-md-min: 768px !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-lg-min: 992px !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-xl-min: 1200px !default;
$screen-lg-max: ($screen-xl-min - 1) !default;
$screen-xl-max: 1599px !default;

$dark-grey : #434343;
$red : #db1c1f;
$light-grey : #c5c9d0;
$color-biztositok : #8dc04a;
$list-grey: #777777;
$line-grey: #c9c9c9;
$dark-green : #20bf55;
$light-green : #eaf9ef;
$very-light-gray : #f0f0f0;