/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2020. aug. 19., 13:30:08
    Author     : hejjagyorgy
*/

@mixin screen-xs {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
}
@mixin screen-sm {
    @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin screen-sm-up {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}
@mixin screen-sm-down {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin screen-md {
    @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin screen-md-up {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}
@mixin screen-md-down {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin screen-lg {
    @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
        @content;
    }
}
@mixin screen-lg-up {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
@mixin screen-lg-down {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}
@mixin screen-xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}
@mixin screen-xl-down {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

@mixin hover-red {
    transition: .3s ease;
    &:hover {
        color: $red !important;
    }
}

@mixin media-down($width) {
    @media (max-width: #{$width}px) {
        @content;
    }
}
