.btn {
  border-radius: 4px;
  &:hover {
    box-shadow: none;
    outline: none;
  }
  &:active {
    box-shadow: none;
    outline: none;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.small-btn {
    padding: 2px 5px 2px 5px;
  }
}
.btn.dbxshad {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
}
.btn.circle {
  border-radius: 27px;
}
.btn.white {
  background-color: #ffffff;
  color: #ff5a5f;
}
.btn-thm {
  background-color: $red;
  border: 2px solid $red;
  border-radius: 8px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ffffff;
    border-color: $red;
    color: $red;
  }
  &:active {
    background-color: #ffffff;
    border-color: $red;
    color: $red;
  }
  &:focus {
    background-color: #ffffff;
    border-color: $red;
    color: $red;
  }
}
.btn-thm-simple {
  background-color: rgba(219, 28, 31, 0.7);
  border-radius: 8px;
  color: #ffffff;
}
.btn-thm.rounded {
  background-color: #ff5a5f;
  border-radius: 8px;
  color: #ffffff;
}
.btn-thm.white {
  &:hover {
    background-color: #ff5a5f;
    border-color: #ff5a5f;
    color: #ffffff;
  }
  &:active {
    background-color: #ff5a5f;
    border-color: #ff5a5f;
    color: #ffffff;
  }
  &:focus {
    background-color: #ff5a5f;
    border-color: #ff5a5f;
    color: #ffffff;
  }
}
.btn-thm2 {
  background-color: #ff787c;
  border-color: #ff787c;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ffffff;
    border-color: #ff787c;
    color: #ff787c;
  }
  &:active {
    background-color: #ffffff;
    border-color: #ff787c;
    color: #ff787c;
  }
  &:focus {
    background-color: #ffffff;
    border-color: #ff787c;
    color: #ff787c;
  }
}
.btn-thm3 {
  background-color: #ff1053;
  border: 2px solid #ff1053;
  border: none;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ffffff;
    border-color: #ff1053;
    color: #ff1053;
  }
  &:active {
    background-color: #ffffff;
    border-color: #ff1053;
    color: #ff1053;
  }
  &:focus {
    background-color: #ffffff;
    border-color: #ff1053;
    color: #ff1053;
  }
}
.btn-dark {
  background-color: #051925;
  border-color: #051925;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ffffff;
    border-color: #051925;
    color: #051925;
  }
  &:active {
    background-color: #ffffff;
    border-color: #051925;
    color: #051925;
  }
  &:focus {
    background-color: #ffffff;
    border-color: #051925;
    color: #051925;
  }
}
.btn-yellow {
  background-color: #F0C900;
  border-color: #F0C900;
  color: #333333;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: #333333;
    color: #ffffff;
  }
  &:active {
    color: #333333;
    color: #ffffff;
  }
  &:focus {
    color: #333333;
    color: #ffffff;
  }
}
.btn-white {
  background-color: #ffffff;
  border-color: #eeeeee;
  color: #303030;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: #ff5a5f;
  }
  &:active {
    color: #ff5a5f;
  }
  &:focus {
    color: #ff5a5f;
  }
}
.btn-gray {
  background-color: #D3D3D3;
  border-color: #D3D3D3;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ff5a5f;
    color: #ffffff;
  }
  &:active {
    background-color: #ff5a5f;
    color: #ffffff;
  }
  &:focus {
    background-color: #ff5a5f;
    color: #ffffff;
  }
}
.btn-green {
  background-color: #28B928;
  border-color: #28B928;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    color: #ffffff;
  }
  &:active {
    color: #ffffff;
  }
  &:focus {
    color: #ffffff;
  }
}
.btn-blue {
  background-color: #1c327e;
  border-color: #1c327e;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ff5a5f;
    border-color: #ff5a5f;
    color: #ffffff;
  }
  &:active {
    background-color: #ff5a5f;
    border-color: #ff5a5f;
    color: #ffffff;
  }
  &:focus {
    background-color: #ff5a5f;
    border-color: #ff5a5f;
    color: #ffffff;
  }
}
.btn-transparent {
  background-color: transparent;
  border: 2px solid $red;
  color: $red;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &.bigger {
    height: 50px;
    line-height: 36px;
  }
  &:hover {
    background-color: $red;
    color: #ffffff;
  }
  &:active {
    background-color: $red;
    color: #ffffff;
  }
  &:focus {
    background-color: $red;
    color: #ffffff;
  }
}
.btn-transparent2 {
  background-color: transparent;
  border-color: -webkit-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
  border-color: -o-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
  border-color: -ms-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
  border-color: -moz-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
  border-color: linear-gradient(60deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
  color: #4568dc;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background: -webkit-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -o-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -ms-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -moz-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: linear-gradient(60deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    color: #ffffff;
  }
  &:active {
    background: -webkit-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -o-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -ms-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -moz-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: linear-gradient(60deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    color: #ffffff;
  }
  &:focus {
    background: -webkit-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -o-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -ms-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: -moz-linear-gradient(30deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    background: linear-gradient(60deg, rgb(69, 104, 220) 0%, rgb(123, 105, 199) 50%, rgb(176, 106, 179) 100%);
    color: #ffffff;
  }
}
.btn-default {
  font-size: 14px;
  padding: 8px 15px;
}
.btn-adv {
  padding: unset !important;
  top: unset !important;
  line-height: 51px !important;
  .flaticon-plus {
    padding-left: 0 !important;
  }
}

.btn-50 {
  height: 50px !important;
}

.btn-hollow {
    line-height: 34px;
    border: 2px solid $red;
    border-radius: 8px;
    color: $red !important;
    
    &:hover {
        background-color: $red;
        color: #fff !important
    }
}