/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2020. okt. 1., 9:32:33
    Author     : hejjagyorgy
*/

.office-signup {
    
    color: $dark-grey;
    a {
        color: $red;
    }
    
    .form-alt {
        color: $red;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    
    .form-text-1 {
        
    }
    
    .circle-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        figure {
            margin-right: 0;
        }
            .circle-text {
                text-align: center;
                font-weight: bold;
        }
    }
    
    .form-lead {
        
    }
    
}