/* Table of contents */
/*== Typography ==*/
/* Search Overlay */
/*== Header Top Style ==*/
/* Header Top Search Field css home2 */
/*== Menu Style For Home V1 & V2 ==*/
/*== Menu Style For Home V2 ==*/
/*== Menu Style For Home V3 ==*/
/*== Menu Style For Home V4 ==*/
/*== Menu Style For Home V5 ==*/
/*== Menu Style For Home V6 ==*/
/*== Menu Style For Home V7 ==*/
/*== Mobile Menu Css ==*/
/*== Home Css ==*/
/* ==== Home 2 Main Sliders === */
/*== Home Icon Box ==*/
/*== Home Five Overlay Slider ==*/
/*== School Category Courses ==*/
/*== Feature Property ==*/
/*== Property Cities ==*/
/*== Search Smart Property ==*/
/*== Single Property ==*/
/*== Listing Single V5 Page Sticky Nav ==*/
/*== Why Choose Us ==*/
/*== Popular Job Categories => Icon Hover Image Box ==*/
/*== Funfact Counter ==*/
/*== Footer Social Widget ==*/
/*== 1st Layout Footer ==*/
/*== Footer Widget ==*/
/*== Inner Pages Breadcrumb ==*/
/*== About Page About Content ==*/
/*== About home3 ==*/
/*== Testimonials Grid ==*/
/*== Blog Post Home V1 ==*/
/*== Team Slider Code ==*/
/*== Pricing Table Four ==*/
/*== Our Partners ==*/
/*== Error/404 Page ==*/
/*== Invoice Page ==*/
/*== Service Pages Style Code ==*/
/*== LogIn Register Page ==*/
/* Create a custom checkbox */
/*== Search Widget Faq Page ==*/
/*== Question Widget Faq Page ==*/
/*== Terms & Condition Page ==*/
/*== Tab Widget UI Page ==*/
/*== Table Widget UI Page ==*/
/*== Message Box Widget UI Page ==*/
/*== Form Input Widget UI Page ==*/
/*== Form Textarea Widget UI Page ==*/
/*== Form Tooltip Widget UI Page ==*/
/*== Select Search Box Widget UI Page ==*/
/*== Radiobox, Checkbox, Switch Widget UI Page ==*/
/*== CheckBox Widget UI Page ==*/
/*== SwithcBox Widget UI Page ==*/
/*== Select Box Widget UI Page ==*/
/*== MultiSelect CheckBox Widget UI Page ==*/
/*== Blog Post Main Page ==*/
/*== Blog Grid Post ==*/
/*== Search Widget Blog Page ==*/
/*== Category Widget Blog Page ==*/
/*== Recent Post Widget Blog Page ==*/
/*== Tag Widget Blog Page ==*/
/*== BlokQuote Blog Sinlge Page ==*/
/*== Next Prev Pagination Blog Sinlge Page ==*/
/*== Commenting Blog Sinlge Page ==*/
/*== Commenting Form Blog Sinlge Page ==*/
/*== Dashbord details Page ==*/
/*== Header User Notification & User ID ==*/
/*== Header User Setting ID ==*/
/*== Candidate Job Review Page Table ==*/
/*== My Resume Page ==*/
/*== Message Page ==*/
/*== DashBoard Pages ==*/
/*== sidebar about widget ==*/
/*== Pricing SLider ==*/
/*== Profile Avatar ==*/
/*== UI Page Elements ==*/
/*== Alpha v2 Page ==*/
/*== Testimonials Home V3 ==*/
/*== Contact Page ==*/
/*== Maximage_slider ==*/
/*== Color Area ==*/
/*== Button Area ==*/
/*== Fonts Size, Font Weights, Height, Display & Position ==*/
/*== Custome Margin Padding ==*/
/*== BackGround Images ==*/
/*== BackGround Color White Overlay ==*/
/*== Parallax Css ==*/
/*== Divider Css ==*/
/*== Chart Js Css ==*/
/*== Tags Bar Css ==*/
/*== arrow fontawesome icon to the right */
/*== Shop Page Style ==*/
/*== Shop Order Page Style ==*/
/*== Shop Chaeckout Page Style ==*/
/*== Shop Single Page Style ==*/
/*== Typography ==*/
/* Search Overlay */
/*== Header Top Style ==*/
/* Header Top Search Field css home2 */
/*== Menu Style For Home V1 & V2 ==*/
/*== Menu Style For Home V2 ==*/
/*== Menu Style For Home V3 ==*/
/*== Menu Style For Home V4 ==*/
/*== Menu Style For Home V5 ==*/
/*== Menu Style For Home V4 ==*/
/*== Menu Style For Home V6 ==*/
/*== Menu Style For Home V7 ==*/
/*== Mobile Menu Css ==*/
/*== Home Css ==*/
/* MegaDropDown Content Style/Css Code */
/* Home Two Featured Row */
/* ==== Home 2 Main Sliders === */
/*== Home Icon Box ==*/
/*== Home Five Overlay Slider ==*/
/*== School Category Courses ==*/
/*== Feature Property ==*/
/*== Property Cities ==*/
/*== Search Smart Property ==*/
/*== Single Property ==*/
//@keyframes "pulse-red" {
//  0% {
//    transform: scale(0.9);
//    box-shadow: 0 0 0 0 rgba(255, 90, 95, 0.7);
//  }
//  70% {
//    transform: scale(1);
//    box-shadow: 0 0 0 15px rgba(255, 90, 95, 0);
//  }
//  100% {
//    transform: scale(0.9);
//    box-shadow: 0 0 0 0 rgba(255, 90, 95, 0);
//  }
//}
/*== Listing Single V5 Page Sticky Nav ==*/
/*== Why Choose Us ==*/
/*== Why Choose Us ==*/
/*== Popular Job Categories => Icon Hover Image Box ==*/
/*== Funfact Counter ==*/
/*== Footer Social Widget ==*/
/*== 1st Layout Footer ==*/
/*== Footer Widget ==*/
/*== Inner Pages Breadcrumb ==*/
/*== About Page About Content ==*/
/*== About home3 ==*/
/*== Testimonials Grid ==*/
/*== Blog Post Home V1 ==*/
/*== Team Slider Code ==*/
/*== Pricing Table Four ==*/
/*== Our Partners ==*/
/*== Our Newslatters ==*/
/*== Error/404 Page ==*/
/*== Service Pages Style Code ==*/
/*== Pricing Table Pages Style Code ==*/
/*== LogIn Register Page ==*/
/* Create a custom checkbox */
/*== Question Widget Faq Page ==*/
/*== Terms & Condition Page ==*/
/*== Tab Widget UI Page ==*/
/*== Table Widget UI Page ==*/
/*== Message Box Widget UI Page ==*/
/*== Form Input Widget UI Page ==*/
/*== Form Textarea Widget UI Page ==*/
/*== Form Tooltip Widget UI Page ==*/
/*== Select Search Box Widget UI Page ==*/
/*== Radiobox, Checkbox, Switch Widget UI Page ==*/
/*== CheckBox Widget UI Page ==*/
/*== SwithcBox Widget UI Page ==*/
/*== Select Box Widget UI Page ==*/
/*== BlockQueto UI Page ==*/
/*== MultiSelect CheckBox Widget UI Page ==*/
/*== Blog Post Main Page ==*/
/*== Blog Grid Post ==*/
/*== Search Widget Blog Page ==*/
/*== Category Widget Blog Page ==*/
/*== Recent Post Widget Blog Page ==*/
/*== Tag Widget Blog Page ==*/
/*== BlokQuote Blog Sinlge Page ==*/
/*== Next Prev Pagination Blog Sinlge Page ==*/
/*== Commenting Blog Sinlge Page ==*/
/*== Commenting Form Blog Sinlge Page ==*/
/*== Dashbord details Page ==*/
/*== Header User Notification & User ID ==*/
/*== Header User Setting ID ==*/
/*== Candidate Job Review Page Table ==*/
/*== My Resume Page ==*/
/*== Message Page ==*/
/*== My Profile Page ==*/
/*== DashBoard Pages ==*/
/*== sidebar about widget ==*/
/*== Pricing SLider ==*/
/*== Profile Avatar ==*/
/*== UI Page Elements ==*/
/*== Alpha v2 Page ==*/
/*== Carrer Tips Home V3 ==*/
/*== Testimonials Home V3 ==*/
/*== Contact Page ==*/
/*== Maximage_slider ==*/
/*== Color Area ==*/
/*== Button Area ==*/
/*== Fonts Size, Font Weights, Height, Display & Position ==*/
/*== Custome Margin Padding ==*/
/*== BackGround Images ==*/
/*== BackGround Color White Overlay ==*/
/*== BackGround Color Black Overlay ==*/
/*== Parallax Css ==*/
/*== Divider Css ==*/
/*== Chart Js Css ==*/
/*== Tags Bar Css ==*/
/* arrow fontawesome icon to the right */
/* Shop Page Style*/
/* Shop Order Page Style */
/*== Shop Chaeckout Page Style ==*/
/* Shop Single Page Style */
/* Google map Multiple marker with content box */
html {
    font-size: 100%;
}
body {
    background-attachment: fixed;
    background-color: #ffffff;
    color: #777777;
    font-family: Arial;
    font-size: 16px;
    line-height: 1.642;
    overflow-x: hidden;
    transition: inherit;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: all .4s ease, padding 0s;
    -moz-transition: all .4s ease, padding 0s;
    -ms-transition: all .4s ease, padding 0s;
    -o-transition: all .4s ease, padding 0s;
    transition:  all .4s ease, padding 0s;
}
body.body_overlay {
    position: fixed;
    transform: translate(0, 0);
    width: 100%;
    &:before {
        background-color: rgba(0,108,112,.5);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        -webkit-transition: all .8s ease;
        -moz-transition: all .8s ease;
        -ms-transition: all .8s ease;
        -o-transition: all .8s ease;
        transition: all .8s ease;
        transform: translate(360px, 0);
    }
}
img {
    max-width: 100%;
}
p {
    font-size: 14px;
    color: $dark-grey;
    font-weight: 400;
    margin: 0 0 10px;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0 0 10px;
}
ol {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0 0 10px;
}
iframe {
    border: none;
    width: 100%;
    border: none;
}
a {
    color: #555555;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    &:hover {
        color: #333333;
        text-decoration: none;
        outline: none;
    }
    &:focus {
        color: #333333;
        text-decoration: none;
        outline: none;
    }
    img {
        border: none;
    }
}
.form-control {
    height: 50px;
    &:active {
        box-shadow: none;
        outline: none;
    }
    &:focus {
        box-shadow: none;
        outline: none;
    }
    &::selection {
        background: #222222;
        color: #ffffff;
        text-shadow: none;
    }
    &::-moz-selection {
        background: #222222;
        color: #ffffff;
        text-shadow: none;
    }
    &::placeholder{
        color:#434343 !important;
    }

}
h1 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 36px;
    @include screen-sm-down {
        font-size: 20px;
    }
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
h2 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 30px;
    @include screen-sm-down {
        font-size: 20px;
    }
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
h3 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 24px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
h4 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-size: 18px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
    .h1 {
        font-weight: bold;
    }
}
h5 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 15px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
h6 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 12px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
.h1 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-size: 36px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
.h2 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 30px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
.h3 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 24px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
.h4 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 18px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
.h5 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 15px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
.h6 {
    font-family: Arial;
    line-height: 1.2;
    color: $dark-grey;
    font-weight: bold;
    font-size: 12px;
    small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    .small {
        font-weight: normal;
        line-height: 1;
        color: $dark-grey;
        font-weight: 400;
    }
    a {
        color: $dark-grey;
        font-weight: 400;
    }
}
dl {
    margin: 0 0 10px;
}
dt {
    margin: 0 0 10px;
}
dd {
    margin: 0 0 10px;
}
blockquote {
    margin: 0 0 10px;
}
address {
    margin: 0 0 10px;
}
.ff-nunito {
    font-family: Arial;
}
.ff-fontawesome {
    font-family: FontAwesome !important;
}
.sign_up_tab {
    li.nav-item {
        text-align: center;
        width: 50%;
        .nav-link {
            background-color: #f5f5f5;
            height: 70px;
            line-height: 65px;
            margin: 0;
            padding: 0;
            border: none;
            color: $dark-grey;
            font-family: Arial;
            font-size: 18px;
            font-weight: bold;
        }
        .nav-link.active {
            background-color: #ffffff;
            border: none;
        }
    }
}
.modal-backdrop {
    display: none;
}
.cart {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 0;
    position: relative;
    >li {
        cursor: pointer;
        font-size: 14px;
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        &:hover {
            .dropdown_content {
                opacity: 1;
                visibility: visible;
                z-index: 0;
                -webkit-transform: translate(0, 0);
                -moz-transform: translate(0, 0);
                -o-transform: translate(0, 0);
                transform: translate(0, 0);
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    position: absolute;
                    top: -10px;
                    width: 20px;
                    right: 15px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }
}
.dropdown_content {
    background: #ffffff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    display: flex;
    height: 425px;
    flex-direction: column;
    margin: 0;
    opacity: 0;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 120%;
    -webkit-transform: translate(0, -60px);
    -moz-transform: translate(0, -60px);
    -o-transform: translate(0, -60px);
    transform: translate(0, -60px);
    transition: opacity 0.2s, z-index 0s 0.2s, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s, z-index 0s 0.2s;
    transition: transform 0.2s ease-out, opacity 0.2s, z-index 0s 0.2s, -webkit-transform 0.2s ease-out;
    visibility: hidden;
    width: 414px;
    z-index: -1;
    >li {
        &:first-child {
            padding-top: 0;
        }
        border-bottom: 1px solid #dedede;
        cursor: pointer;
        font-size: 14px;
        padding: 20px 0;
        &:last-child {
            border-bottom: none;
        }
    }
    .list_content {
        a {
            img {
                border-radius: 50%;
                margin-right: 20px;
            }
            p {
                color: $dark-grey;
                font-family: Arial;
                margin: 0;
            }
            small {
                font-size: 14px;
                margin: 0;
            }
            .close_icon {
                font-size: 16px;
                margin: -10px 0;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        h5 {
            color: #212121;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 15px;
        }
        .cart_btns {
            border: 1px solid transparent;
            margin-right: 10px;
            border-radius: 25px;
            color: #ffffff !important;
            height: 50px;
            line-height: 40px;
            width: 170px;
            &:hover {
                background-color: #2ac4ea;
                border: 1px solid #2ac4ea;
            }
        }
        .checkout_btns {
            border-radius: 25px;
            color: #ffffff !important;
            height: 50px;
            line-height: 40px;
            width: 170px;
            &:hover {
                background-color: #fbbc05;
            }
        }
    }
}
.search_overlay {
    height: 100%;
    width: 100%;
}
.mk-search-trigger {
    position: absolute;
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    top: -4px;
}
.mk-search-trigger.style2 {
    position: absolute;
    right: 72px;
    top: 26px;
    @include screen-lg-up {
        &:before {
            background-color: rgb(232, 238, 242);
            content: "";
            height: 38px;
            position: absolute;
            right: -15px;
            top: 0;
            width: 1px;
        }
    }
}
.search_overlay.home3 {
    .mk-search-trigger {
        position: relative;
        top: 0;
    }
}
.search_overlay.home5 {
    .mk-search-trigger {
        position: relative;
        top: 0;
        margin-left: 0;
    }
    #search-button {
        i {
            color: $dark-grey;
        }
    }
    #search-button2 {
        i {
            color: $dark-grey;
        }
    }
}
.mk-fullscreen-search-overlay {
    #mk-fullscreen-search-wrapper {
        #mk-fullscreen-searchform {
            input[type=submit] {
                position: absolute;
                width: 100px;
                height: 100%;
                background-color: transparent;
                border: 0;
                right: 0;
                top: 0;
            }
            .fullscreen-search-icon {
                font-size: 25px;
                position: absolute;
                right: 15px;
                width: 25px;
                top: 15px;
                color: $dark-grey;
                -webkit-transition: all .2s ease-out;
                -moz-transition: all .2s ease-out;
                -ms-transition: all .2s ease-out;
                -o-transition: all .2s ease-out;
                transition: all .2s ease-out;
            }
            #mk-fullscreen-search-input {
                background-color: #ffffff;
                border: 0;
                border-radius: 5px;
                color: #262626;
                font-size: 17px;
                padding: 20px;
                width: 800px;
                -webkit-transition: all .3s ease-out;
                -moz-transition: all .3s ease-out;
                -ms-transition: all .3s ease-out;
                -o-transition: all .3s ease-out;
                transition: all .3s ease-out;
                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
        display: inline-block;
        max-width: 1000px;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
        ms-transform: scale(0.9);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all ease-in-out .3s;
        -moz-transition: all ease-in-out .3s;
        -ms-transition: all ease-in-out .3s;
        -o-transition: all ease-in-out .3s;
        transition: all ease-in-out .3s;
    }
    #mk-fullscreen-search-wrapper2 {
        #mk-fullscreen-searchform2 {
            input[type=submit] {
                position: absolute;
                width: 100px;
                height: 100%;
                background-color: transparent;
                border: 0;
                right: 0;
                top: 0;
            }
            .fullscreen-search-icon {
                font-size: 25px;
                position: absolute;
                right: 15px;
                width: 25px;
                top: 15px;
                color: $dark-grey;
                -webkit-transition: all .2s ease-out;
                -moz-transition: all .2s ease-out;
                -ms-transition: all .2s ease-out;
                -o-transition: all .2s ease-out;
                transition: all .2s ease-out;
            }
            #mk-fullscreen-search-input2 {
                background-color: #ffffff;
                border: 0;
                border-radius: 5px;
                color: #262626;
                font-size: 17px;
                padding: 20px;
                width: 800px;
                -webkit-transition: all .3s ease-out;
                -moz-transition: all .3s ease-out;
                -ms-transition: all .3s ease-out;
                -o-transition: all .3s ease-out;
                transition: all .3s ease-out;
                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
        display: inline-block;
        max-width: 1000px;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
        ms-transform: scale(0.9);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all ease-in-out .3s;
        -moz-transition: all ease-in-out .3s;
        -ms-transition: all ease-in-out .3s;
        -o-transition: all ease-in-out .3s;
        transition: all ease-in-out .3s;
    }
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, .94);
    text-align: center;
    -webkit-transition: all ease-in-out .25s;
    -moz-transition: all ease-in-out .25s;
    -ms-transition: all ease-in-out .25s;
    -o-transition: all ease-in-out .25s;
    transition: all ease-in-out .25s;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 9999;
    .mk-fullscreen-close {
        &:link {
            border: none;
            color: #fff;
        }
        &:visited {
            border: none;
            color: #fff;
        }
        background-color: transparent;
        border: none;
        color: #fff;
        position: absolute;
        right: 50px;
        top: 70px;
        font-size: 26px;
        -webkit-transition: transform ease-out .2s;
        -moz-transition: transform ease-out .2s;
        -ms-transition: transform ease-out .2s;
        -o-transition: transform ease-out .2s;
        transition: transform ease-out .2s;
    }
}
#mk-fullscreen-searchform {
    &:focus {
        outline: none;
        border: none;
    }
    position: relative;
    vertical-align: middle;
}
#mk-fullscreen-searchform2 {
    &:focus {
        outline: none;
        border: none;
    }
    position: relative;
    vertical-align: middle;
}
.fullscreen-search-icon {
    &:hover {
        border: none;
        outline: none;
        -webkit-transition: transform ease-out .2s;
        -moz-transition: transform ease-out .2s;
        -ms-transition: transform ease-out .2s;
        -o-transition: transform ease-out .2s;
        transition: transform ease-out .2s;
    }
    &:focus {
        border: none;
        outline: none;
        -webkit-transition: transform ease-out .2s;
        -moz-transition: transform ease-out .2s;
        -ms-transition: transform ease-out .2s;
        -o-transition: transform ease-out .2s;
        transition: transform ease-out .2s;
    }
}
.mk-fullscreen-search-overlay.mk-fullscreen-search-overlay-show {
    visibility: visible;
    opacity: 1;
    #mk-fullscreen-search-wrapper {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        ms-transform: scale(1);
    }
    #mk-fullscreen-search-wrapper2 {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        ms-transform: scale(1);
    }
}
.mk-animate-element {
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.mk-effect-bg-layer {
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
#search-button {
    cursor: pointer;
    opacity: 1;
    position: relative;
    vertical-align: middle;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    i {
        color: $dark-grey;
        font-size: 22px;
    }
}
#search-button2 {
    cursor: pointer;
    opacity: 1;
    position: relative;
    vertical-align: middle;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    i {
        color: $dark-grey;
        font-size: 22px;
    }
}
.header_top.home2 {
    background-color: #000000;
    height: 80px;
    position: relative;
    z-index: 9;
}
.header_top.home3 {
    height: 70px;
    .container-fluid {
        padding: 0 70px;
        position: relative;
        z-index: 9;
    }
}
.home3_header_top_contact {
    margin-bottom: 0;
    margin-top: 25px;
    li {
        &:first-child {
            margin-right: 35px;
        }
        a {
            color: #ffffff;
            font-family: Arial;;
        }
    }
}
.home4_header_top_contact {
    li {
        &:first-child {
            margin-right: 35px;
        }
        a {
            color: #ffffff;
            font-family: Arial;
            font-size: 14px;
            line-height: initial;
        }
    }
    margin-bottom: 0;
    margin-top: 5px;
}
ul.sign_up_btn.home4 {
    li {
        a {
            font-family: Arial;
            font-size: 14px;
            line-height: initial;
        }
    }
}
ul.sign_up_btn.home6 {
    a {
        font-family: Arial;
        font-size: 14px;
        line-height: initial;
    }
}
.header_top.home6 {
    background-color: #ffffff;
    height: 90px;
    position: relative;
}
.sign_up_btn.home4 {
    .btn {
        height: auto;
        line-height: inherit;
    }
}

.header_top.home7 {
    background-color: transparent;
    height: 40px;
    padding: 10px 0;
    position: relative;
    z-index: 9;
    .header_top_lang_widget_home6 {
        button.btn.dropdown-toggle {
            font-size: 14px;
            font-family: Arial;;
            color: rgba(255, 255, 255, 0.502);
            height: auto;
            line-height: 1.2;
            &::after {
                margin-top: 5px;
            }
        }
    }
}
ul.sign_up_btn.home7 {
    a {
        font-size: 14px;
        font-family: Arial;;
    }
}
.light_white {
    color: rgba(255,255,255, 0.5);
    &:hover {
        color: rgba(255,255,255, 0.5);
    }
}
.header_search_widget {
    .mailchimp_form {
        .form-control {
            background-color: #f7f7f7;
            border: none;
            border-radius: 8px;
            height: 50px;
            padding-left: 30px;
            width: 250px;
        }
        input[type="text"].form-control {
            &::-webkit-input-placeholder {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                margin-bottom: 0;
            }
        }
        button {
            background-color: transparent;
            border: none;
            border-radius: 50%;
            color: $dark-grey;
            font-size: 22px;
            height: 45px;
            margin-left: -47px;
            line-height: 30px;
            &:active {
                color: $dark-grey !important;
                border: none;
                box-shadow: none !important;
                outline: none;
            }
            &:focus {
                color: $dark-grey !important;
                border: none;
                box-shadow: none !important;
                outline: none;
            }
        }
    }
}
.ht_left_widget {
    margin-top: 19px;
    ul {
        margin-bottom: 0;
        li {
            position: relative;
            vertical-align: middle;
        }
        li.list-inline-item {
            &:first-child {
                margin-right: 25px;
            }
        }
    }
}
.ht_right_widget {
    margin-top: 19px;
    ul {
        margin-bottom: 0;
        margin-bottom: 0;
        li.list-inline-item {
            &:nth-child(2) {
                border-left: 1px solid #333333;
                border-right: 1px solid #333333;
                margin-left: 10px;
                padding-right: 15px;
                text-align: center;
            }
        }
        li {
            a {
                color: #a4a4a4;
                font-size: 14px;
                font-family: Arial;;
            }
        }
    }
    a.btn.cart_btn {
        color: #a4a4a4;
        font-size: 14px;
        span {
            background-color: #ffffff;
        }
    }
    .cart_btn {
        a.btn {
            span {
                border-radius: 50%;
                height: 17px;
                line-height: 15px;
                position: absolute;
                text-align: center;
                width: 17px;
                line-height: 20px;
                margin: -5px auto 0 -5px;
                sup {
                    color: #141414;
                    font-size: 10px;
                    margin-left: 0;
                    vertical-align: sub;
                }
            }
        }
    }
}
.header_top_lang_widget {
    ul {
        li {
            .btn.cart_btn {
                color: #a4a4a4;
                font-size: 14px;
            }
            a {
                color: #a4a4a4;
                font-size: 16px;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
            }
            a.dropdown-item.active {
                background-color: #000000;
            }
            a.dropdown-item {
                &:active {
                    background-color: #000000;
                }
            }
        }
    }
    button.btn.dropdown-toggle {
        background-color: transparent;
        border: none;
        bottom: -5px;
        color: #a4a4a4;
        float: right;
        margin-top: 10px;
        padding: 0 8px;
        width: 90px;
        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
        .filter-option {
            &:focus {
                box-shadow: none !important;
                outline: none !important;
            }
        }
    }
    .btn-light {
        &:not(:disabled) {
            &:not(.disabled).active {
                &:focus {
                    box-shadow: none !important;
                    outline: none !important;
                }
            }
            &:not(.disabled) {
                &:active {
                    &:focus {
                        box-shadow: none !important;
                        outline: none !important;
                    }
                }
            }
        }
    }
    .show {
        >.btn-light.dropdown-toggle {
            &:focus {
                box-shadow: none !important;
                outline: none !important;
            }
        }
    }
    button.btn {
        &:active {
            box-shadow: none !important;
            outline: none !important;
        }
        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    }
    .dropdown.bootstrap-select.show-tick {
        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
        width: auto;
    }
}
header.header-nav.menu_style_home_three.navbar-scrolltofixed {
    .ht_left_widget.home3 {
        .header_top_lang_widget {
            margin-top: 5px;
        }
    }
}
.ht_left_widget.home3 {
    .cd-dropdown-trigger {
        background-color: #ffffff !important;
        color: $dark-grey;
        text-transform: capitalize;
        &::before {
            background-color: $dark-grey;
        }
        &::after {
            background-color: $dark-grey;
        }
    }
    .no-touch {
        .cd-dropdown-trigger.dropdown-is-active {
            &:hover {
                background-color: #ffffff !important;
                color: $dark-grey;
            }
        }
        .cd-dropdown-trigger {
            &:hover {
                background-color: #ffffff !important;
                color: $dark-grey;
            }
        }
    }
}
.header_top_lang_widget_home4 {
    button.btn.dropdown-toggle {
        background-color: transparent;
        border: none;
        color: #ffffff;
        line-height: inherit;
        width: 90px;
        font-size: 14px;
        &:active {
            background-color: transparent !important;
            outline: none;
            box-shadow: none;
            box-shadow: none !important;
            outline: none !important;
        }
        &:focus {
            background-color: transparent !important;
            outline: none;
            box-shadow: none;
            box-shadow: none !important;
            outline: none !important;
        }
    }
    .bootstrap-select {
        &:not([class*=col-]) {
            &:not([class*=form-control]) {
                &:not(.input-group-btn) {
                    width: auto;
                }
            }
        }
    }
    button {
        .btn-light {
            &:not(:disabled) {
                &:not(.disabled).active {
                    &:focus {
                        background-color: transparent !important;
                        outline: none;
                        box-shadow: none;
                    }
                }
                &:not(.disabled) {
                    &:active {
                        &:focus {
                            background-color: transparent !important;
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
        .show {
            >.btn-light.dropdown-toggle {
                &:focus {
                    background-color: transparent !important;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
}
.header_top_lang_widget_home6 {
    button.btn.dropdown-toggle {
        background-color: transparent;
        border: none;
        color: #ffffff;
        line-height: inherit;
        width: 90px;
        font-size: 14px;
        &::after {
            vertical-align: middle;
        }
        &:active {
            box-shadow: none !important;
            outline: none !important;
        }
        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    }
    .dropdown.bootstrap-select.show-tick.show {
        .dropdown-menu {
            display: inline;
            margin-top: -17px;
            .dropdown-item.active {
                background-color: #192675;
            }
            .dropdown-item {
                &:active {
                    background-color: #192675;
                }
            }
        }
    }
    .bootstrap-select {
        &:not([class*=col-]) {
            &:not([class*=form-control]) {
                &:not(.input-group-btn) {
                    width: auto;
                }
            }
        }
    }
}
.header_top_lang_widget_home6.home7 {
    .dropdown.bootstrap-select.show-tick.show {
        .dropdown-menu {
            margin-top: 0;
        }
    }
    button.btn.dropdown-toggle {
        &::after {
            vertical-align: top;
        }
    }
}
.menu6_search.home6 {
    .mk-search-trigger {
        margin-left: -25px;
        top: 22px;
        top: 17px;
    }
    #search-button {
        i {
            color: $dark-grey;
        }
    }
}
.menu7_search.home7 {
    .mk-search-trigger {
        margin-left: -25px;
        top: 22px;
    }
}
.cd-dropdown-content {
    .cd-secondary-dropdown {
        a {
            font-family: Arial;
        }
    }
}
li.has-children {
    a {
        line-height: 51px;
    }
}
.menu-style-one {
    nav.transparent {
        background-color: transparent;
        .wrap-core-nav-list {
            background-color: transparent;
        }
        .nav-header {
            background-color: transparent;
        }
    }
}
nav {
    .wrap-core-nav-list {
        background: #fff;
    }
    .nav-header {
        background: #fff;
    }
    .brand {
        display: block;
        position: relative;
        width: 170px;
        top: 20px;
        img {
            width: 100%;
        }
    }
    .menu {
        li {
            a {
                display: block;
                padding: 20px 25px;
                text-decoration: none;
                color: #3a3a3a;
                &:hover {
                    color: #e74c3c;
                }
            }
        }
        li.active {
            >a {
                color: #e74c3c;
            }
        }
    }
    button {
        background: transparent;
        border: none;
        outline: none;
    }
}
header.header-nav {
    padding: 0 30px;
    a.navbar_brand {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        margin-right: 37px;
        margin-top: 20px;
        position: relative;
        .logo2 {
            display: none;
        }
    }
}
header.header-nav.dashbord_pages {
    padding: 0 5px 0 15px;
}
.menu_style_home_one.stricky-fixed {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
header.header-nav.menu_style_home_one {
    ul.ace-responsive-menu {
        li.add_listing {
            background-color: #ffffff;
            border-radius: 8px;
            height: 50px;
            position: relative;
            text-align: center;
            vertical-align: middle;
            width: 180px;
            a {
                font-family: Arial;;
                color: $red;
                line-height: 1.2;
                top: -20px;
            }
        }
        li.add_listing.home5 {
            top: 20px;
        }
        li.add_listing.home2.style2 {
            top: 20px;
        }
        li.add_listing.home2.style10 {
            top: 20px;
        }
    }
    a.navbar_brand {
        margin-top: 25px;
        span {
            color: #ffffff;
            font-weight: bold;
            font-size: 22px;
            margin-top: 10px;
            padding-left: 15px;
            text-transform: capitalize;
            vertical-align: middle;
        }
    }
    .ace-responsive-menu {
        li {
            a {
                color: #ffffff;
            }
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                padding-left: 0 !important;
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                }
                li {
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    ul.sub-menu {
                        &:before {
                            display: none;
                        }
                        color: $red;
                        padding-left: 20px;
                        box-shadow: none !important;
                    }
                    a {
                        border-bottom: 1px solid #dedede;
                        color: $dark-grey;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        &:hover {
                            color: $red;
                            padding-left: 20px;
                            box-shadow: none !important;
                        }
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
        }
        >li {
            &:first-child {
                ul.sub-menu {
                    margin-left: 0;
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    margin-left: 0;
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) { 
                ul.sub-menu {
                    margin-left: 0;
                    &:before {
                        left: 25px;
                    }
                }
            }
            >ul.sub-menu {
                margin-left: -180px;
            }
            >a {
                font-family: Arial;
                padding: 33px 16px 30px 10px;
            }
        }
    }
    ul.sign_up_btn {
        margin-right: 35px;
    }
    .sign_up_btn {
        li.list_s {
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            margin-right: 0 !important;
            a.btn.flaticon-user {
                line-height: 30px;
            }
        }
    }
}
header.header-nav.menu_style_home_one.home9 {
    ul.ace-responsive-menu {
        li.add_listing {
            background-color: #5f1e8f;
            a {
                color: #ffffff;
            }
        }
    }
}

header.header-nav.menu_style_home_one.stricky-fixed {
    .ace-responsive-menu {
        li.add_listing {
            background-color: $red;
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            a {
                color: #ffffff;
                line-height: 1.2;
            }
        }
        >li {
            >a.active {
                span {
                    color: $dark-grey;
                }
            }
        }
        li {
            a.active {
                span {
                    color: $dark-grey;
                }
            }
            a {
                color: $dark-grey;
            }
        }
    }
    li.list_s {
        &:before {
            background-color: #e8eef2;
            opacity: 1;
        }
    }
    a.navbar_brand {
        .logo1 {
            display: none;
        }
        .logo2 {
            display: inline-block;
        }
        &:before {
            background-color: #dadada;
        }
        span {
            color: $dark-grey;
        }
    }
    .sign_up_btn {
        .btn {
            color: $dark-grey;
        }
        li.list_s {
            border-right: 1px solid #dadada;
        }
    }
    #search-button {
        i {
            color: $dark-grey;
        }
    }
}
header.header-nav.menu_style_home_one.dashbord_pages {
    background-image: -moz-linear-gradient( 0deg, rgb(52,82,255) 0%, rgb(255,16,83) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(52,82,255) 0%, rgb(255,16,83) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(52,82,255) 0%, rgb(255,16,83) 100%);
}
header.header-nav.menu_style_home_one.navbar-scrolltofixed.stricky.animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}
header.header-nav.home2.style_one.navbar-scrolltofixed.stricky.animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}
header.header-nav.menu_style_home_eight.navbar-scrolltofixed.stricky.animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}
header.header-nav.menu_style_home_four.stricky-fixed {
    a.navbar_brand {
        .logo1 {
            display: none;
        }
        .logo2 {
            display: inline-block;
        }
        span {
            color: $dark-grey;
        }
    }
    .cart_btnes.home4 {
        a.btn.cart_btn {
            color: $dark-grey;
        }
    }
    background-color: #ffffff;
    border-bottom: none;
    padding: 10px 0;
    .ace-responsive-menu {
        li {
            a {
                color: $dark-grey;
            }
        }
    }
}
header.header-nav.menu_style_home_three {
    a.navbar_brand {
        span {
            color: #ffffff;
            font-weight: bold;
            font-size: 22px;
            margin-top: 10px;
            padding-left: 15px;
            text-transform: capitalize;
            vertical-align: middle;
        }
        margin-top: 10px;
        &:before {
            background-color: #6077dd;
            height: 80px;
            display: none;
        }
    }
    .ace-responsive-menu {
        li {
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                background-color: #051925;
                background-color: #051925;
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                    background-color: #051925;
                }
                li {
                    ul.sub-menu {
                        li {
                            &:hover {
                                a {
                                    padding-left: 30px;
                                    -webkit-transition: all 0.3s ease 0s;
                                    -moz-transition: all 0.3s ease 0s;
                                    -o-transition: all 0.3s ease 0s;
                                    transition: all 0.3s ease 0s;
                                }
                            }
                        }
                        &:before {
                            display: none;
                        }
                    }
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: #051925;
                    a {
                        color: #ffffff;
                        font-family: Arial;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        border-bottom: 1px solid #2e2e2e;
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
                &:nth-child(3) {
                    left: 0;
                    margin-left: 0;
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(6) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            a {
                color: #ffffff;
                font-family: Arial;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
            }
        }
        >li {
            >a {
                font-family: Arial;
                padding: 38px 16px 30px 10px;
                padding: 27px 35px 30px 10px;
            }
            float: right;
            .sub-menu {
                li {
                    &:hover {
                        a {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
        >li.last {
            a {
                padding-right: 5px;
            }
        }
    }
    .ht_left_widget.home3 {
        ul {
            li {
                &:last-child {
                    margin-top: 5px;
                }
            }
        }
    }
    .header_search_widget {
        .mailchimp_form {
            .form-control {
                margin-top: 10px;
            }
            button {
                background-color: transparent;
                margin-top: 10px;
            }
        }
    }
    background-color: #051925;
    padding: 0;
    .container-fluid {
        padding: 0 70px;
    }
}
header.header-nav.menu_style_home_five {
    a.navbar_brand {
        span {
            color: #ffffff;
            font-weight: bold;
            font-size: 22px;
            margin-top: 10px;
            padding-left: 15px;
            text-transform: capitalize;
            vertical-align: middle;
            color: $dark-grey;
        }
        margin-top: 8px;
        &:before {
            background-color: #6077dd;
            height: 80px;
            display: none;
        }
    }
    .ace-responsive-menu {
        li {
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                background-color: #ffffff;
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                    left: 25px;
                }
                li {
                    ul.sub-menu {
                        li {
                            &:hover {
                                a {
                                    padding-left: 30px;
                                    -webkit-transition: all 0.3s ease 0s;
                                    -moz-transition: all 0.3s ease 0s;
                                    -o-transition: all 0.3s ease 0s;
                                    transition: all 0.3s ease 0s;
                                }
                            }
                        }
                        &:before {
                            display: none;
                        }
                    }
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: transparent;
                    a {
                        border-bottom: 1px solid #dedede;
                        color: $dark-grey;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        padding: 12px 20px 12px 15px;
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(6) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            ul.cart.sub-menu {
                background-color: transparent;
                box-shadow: none;
                padding: 0;
                display: block;
                li {
                    width: auto;
                }
                .dropdown_content {
                    height: auto;
                    right: 10px;
                    top: 110%;
                    .list_content {
                        .cart_btns {
                            background-color: $red;
                            border: 1px solid $red;
                            float: left;
                            line-height: 20px;
                        }
                        .checkout_btns {
                            background-color: #ff1053;
                            border: 1px solid #ff1053;
                            float: right;
                            line-height: 0;
                            line-height: 20px;
                        }
                    }
                }
            }
            ul.cart {
                a.btn.cart_btn {
                    font-size: 22px;
                    padding-top: 21px;
                    span {
                        background-color: #192675;
                        border-radius: 50%;
                        height: 17px;
                        line-height: 17px;
                        margin: -2px auto 0 -10px;
                        position: absolute;
                        text-align: center;
                        width: 17px;
                        &:before {
                            display: none;
                        }
                        sup {
                            color: #ffffff;
                            font-size: 10px;
                            z-index: 9;
                        }
                    }
                }
            }
            a {
                color: $dark-grey;
                padding-top: 27px;
                font-family: Arial;
                text-transform: capitalize;
            }
        }
        >li {
            >a {
                font-family: Arial;
                padding: 38px 16px 30px 10px;
                padding: 27px 35px 30px 10px;
                padding: 27px 20px 30px 10px;
            }
            .sub-menu {
                li {
                    &:hover {
                        a {
                            padding-left: 20px;
                        }
                    }
                }
            }
            float: right;
            &:first-child {
                padding-left: 10px;
            }
        }
    }
    background-color: #ffffff;
    height: 98px;
    padding: 10px 0;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    .container-fluid {
        padding: 0 70px;
    }
    .header_search_widget {
        .mailchimp_form {
            button {
                background-color: transparent;
                border: none;
                border-radius: 50%;
                color: $dark-grey;
                font-size: 24px;
                height: 45px;
                margin-left: -65px;
                &:active {
                    color: #000000 !important;
                    border: none;
                    box-shadow: none !important;
                    outline: none;
                }
                &:focus {
                    color: #000000 !important;
                    border: none;
                    box-shadow: none !important;
                    outline: none;
                }
            }
            .form-control {
                background-color: #edeff7;
                border: 1px solid #edeff7;
                border-radius: 25px;
                color: #555555;
                font-size: 14px;
                height: 45px;
                margin-top: 5px;
                padding-left: 30px;
                width: 450px;
                &:focus {
                    border: 1px solid #edeff7;
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
    .sign_up_btn {
        margin-bottom: 0;
        .btn {
            color: $dark-grey;
            margin-top: 10px;
        }
    }
    .ht_left_widget.home5 {
        .cd-dropdown-trigger {
            background-color: $dark-grey;
            color: #ffffff;
        }
    }
}
header.header-nav.menu_style_home_one.dashbord_pages.stricky-fixed {
    a.navbar_brand {
        span {
            color: #ffffff;
        }
    }
    li {
        a {
            color: #ffffff;
        }
    }
}
header.header-nav.menu_style_home_four {
    .ace-responsive-menu {
        li {
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                color: $dark-grey;
                background-color: #ffffff;
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                    background-color: #ffffff;
                }
                li {
                    ul.sub-menu {
                        li {
                            &:hover {
                                a {
                                    padding-left: 30px;
                                    -webkit-transition: all 0.3s ease 0s;
                                    -moz-transition: all 0.3s ease 0s;
                                    -o-transition: all 0.3s ease 0s;
                                    transition: all 0.3s ease 0s;
                                }
                            }
                        }
                        &:before {
                            display: none;
                        }
                    }
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: transparent;
                    a {
                        border-bottom: 1px solid #dedede;
                        color: $dark-grey;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        color: $dark-grey;
                        background-color: #ffffff;
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(6) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            a {
                color: #ffffff;
                font-family: Arial;
            }
        }
        >li {
            >a {
                font-family: Arial;
                padding: 38px 16px 30px 10px;
                padding: 27px 35px 30px 10px;
                color: #ffffff;
            }
            .sub-menu {
                li {
                    &:hover {
                        a {
                            padding-left: 20px;
                        }
                    }
                }
            }
            float: right;
        }
    }
    background: transparent;
    border-bottom: none;
    padding: 10px 0;
    a.navbar_brand {
        margin-top: 10px;
        span {
            color: #ffffff;
            font-weight: bold;
            font-size: 22px;
            margin-top: 10px;
            padding-left: 15px;
            text-transform: uppercase;
            vertical-align: middle;
        }
        &:before {
            background-color: transparent !important;
        }
    }
    .sign_up_btn {
        .btn {
            background-color: transparent;
            border: 1px solid #ffffff;
            border-radius: 20px;
            color: #ffffff;
            height: 45px;
            line-height: 30px;
            margin-top: 10px;
            &:hover {
                color: #ffffff;
            }
        }
    }
}
header.header-nav.menu_style_home_six {
    .ace-responsive-menu {
        li {
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                color: $dark-grey;
                background-color: #ffffff;
                background-color: #ffffff;
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                }
                li {
                    ul.sub-menu {
                        li {
                            &:hover {
                                a {
                                    padding-left: 30px;
                                    -webkit-transition: all 0.3s ease 0s;
                                    -moz-transition: all 0.3s ease 0s;
                                    -o-transition: all 0.3s ease 0s;
                                    transition: all 0.3s ease 0s;
                                }
                            }
                        }
                        &:before {
                            display: none;
                        }
                    }
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: transparent;
                    a {
                        border-bottom: 1px solid #dedede;
                        color: $dark-grey;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        color: $dark-grey;
                        background-color: #ffffff;
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(6) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            a {
                color: #ffffff;
                font-family: Arial;
                color: #251730;
            }
        }
        >li {
            >a {
                font-family: Arial;
                padding: 38px 16px 30px 10px;
                padding: 27px 35px 30px 10px;
                color: #ffffff;
                padding: 25px 25px 25px 5px;
            }
            .sub-menu {
                li {
                    &:hover {
                        a {
                            padding-left: 20px;
                        }
                    }
                }
            }
            float: right;
        }
        >li.last {
            margin-right: 40px;
        }
    }
    display: -webkit-box;
    background: #ffffff;
    height: 80px;
    padding: 10px 0;
    position: relative;
    z-index: 1 !important;
    background-color: #ffffff;
    border-bottom: none;
    padding: 0;
    a.navbar_brand {
        margin-top: 10px;
        span {
            color: $dark-grey;
            font-weight: bold;
            font-size: 22px;
            margin-top: 10px;
            padding-left: 15px;
            text-transform: uppercase;
            vertical-align: middle;
        }
        &:before {
            background-color: transparent !important;
            background-color: transparent;
        }
    }
    .sign_up_btn {
        .btn {
            background-color: transparent;
            border: 1px solid #ffffff;
            border-radius: 20px;
            color: #ffffff;
            height: 45px;
            line-height: 30px;
            margin-top: 10px;
            border: none;
            color: #ffffff;
            margin-top: 10px;
            &:hover {
                color: #ffffff;
            }
        }
    }
}
header.header-nav.menu_style_home_seven {
    .ace-responsive-menu {
        li {
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                color: $dark-grey;
                background-color: #ffffff;
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                }
                li {
                    ul.sub-menu {
                        li {
                            &:hover {
                                a {
                                    padding-left: 30px;
                                    -webkit-transition: all 0.3s ease 0s;
                                    -moz-transition: all 0.3s ease 0s;
                                    -o-transition: all 0.3s ease 0s;
                                    transition: all 0.3s ease 0s;
                                }
                            }
                        }
                        &:before {
                            display: none;
                        }
                    }
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: transparent;
                    a {
                        border-bottom: 1px solid #dedede;
                        color: $dark-grey;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        color: $dark-grey;
                        background-color: #ffffff;
                        &:hover {
                            padding-left: 20px;
                        }
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(6) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            a {
                color: #ffffff;
                font-family: Arial;
                padding: 18px 20px 18px 15px;
            }
        }
        >li {
            >a {
                font-family: Arial;
                padding: 38px 16px 30px 10px;
                padding: 27px 35px 30px 10px;
                color: #ffffff;
            }
            float: right;
        }
        >li.last {
            margin-right: 40px;
        }
    }
    display: -webkit-box;
    background-color: transparent;
    height: 80px;
    padding: 10px 0;
    position: relative;
    z-index: 1 !important;
    a.navbar_brand {
        margin-top: 0;
        position: relative;
        &:before {
            display: none;
        }
        .logo1.home7 {
            border-radius: 0 0 5px 5px;
            margin-top: -50px;
        }
    }
    .sign_up_btn {
        .btn {
            background-color: transparent;
            border: 1px solid #ffffff;
            border-radius: 20px;
            color: #ffffff;
            height: 45px;
            line-height: 30px;
            margin-top: 10px;
            &:hover {
                color: #ffffff;
            }
        }
    }
}
header.header-nav.menu_style_home_eight {
    .ace-responsive-menu {
        li {
            ul.sub-menu {
                background-color: #ffffff;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                }
                li {
                    ul.sub-menu {
                        li {
                            &:hover {
                                a {
                                    padding-left: 30px;
                                    -webkit-transition: all 0.3s ease 0s;
                                    -moz-transition: all 0.3s ease 0s;
                                    -o-transition: all 0.3s ease 0s;
                                    transition: all 0.3s ease 0s;
                                }
                            }
                        }
                        &:before {
                            display: none;
                        }
                    }
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: transparent;
                    a {
                        border-bottom: 1px solid #dedede;
                        color: $dark-grey;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(6) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            margin-left: 0;
                            margin-left: -445px;
                        }
                    }
                    &:before {
                        left: 25px;
                    }
                }
            }
        }
        >li {
            >a {
                font-family: Arial;
                padding: 38px 16px 30px 10px;
                padding: 27px 35px 30px 10px;
            }
        }
    }
}
header.header-nav.home2.style_one {
    .ace-responsive-menu {
        >li {
            &:first-child {
                ul.sub-menu {
                    margin-left: 0;
                    &:before {
                        left: 25px;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    margin-left: 0;
                    &:before {
                        left: 25px;
                    }
                }
            }
            >ul.sub-menu {
                margin-left: -180px;
            }
            >a {
                padding: 27px 40px 27px 5px;
            }
        }
        li {
            &:nth-child(2) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            &:nth-child(3) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            &:nth-child(4) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            &:nth-child(5) {
                ul.sub-menu {
                    li {
                        ul.sub-menu {
                            left: 260px;
                        }
                    }
                }
            }
            ul.sub-menu {
                padding-left: 0 !important;
                background-color: #191919;
                border-radius: 5px;
                padding: 15px 0;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                &:before {
                    background-color: #ffffff;
                    content: "";
                    height: 20px;
                    right: 25px;
                    position: absolute;
                    top: -10px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 20px;
                    -webkit-transition: all 0.1s ease 0s;
                    -moz-transition: all 0.1s ease 0s;
                    -o-transition: all 0.1s ease 0s;
                    transition: all 0.1s ease 0s;
                    background-color: #191919;
                }
                li {
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0 30px;
                    width: 260px;
                    background-color: #191919;
                    a {
                        border-bottom: 1px solid #dedede;
                        color: #a4a4a4;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        border-bottom: 1px solid #555;
                        color: #a4a4a4;
                        font-size: 16px;
                        -webkit-transition: all 0.3s ease 0s;
                        -moz-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        &:hover {
                            color: #ffffff;
                            padding-left: 20px;
                            box-shadow: none !important;
                            box-shadow: none !important;
                            color: #ffffff;
                            padding-left: 20px;
                        }
                    }
                    ul.sub-menu {
                        color: #ffffff;
                        padding-left: 20px;
                        box-shadow: none !important;
                        box-shadow: none !important;
                        color: #ffffff;
                        padding-left: 20px;
                        &:before {
                            display: none;
                        }
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
    display: -webkit-box;
    background-color: #141414;
    position: relative;
    z-index: 1 !important;
    ul.sign_up_btn {
        li {
            position: relative;
            &:first-child {
                &:before {
                    background-color: #434343;
                    content: "";
                    height: 30px;
                    right: -5px;
                    position: absolute;
                    top: 5px;
                    width: 1px;
                }
            }
            a {
                color: #a4a4a4;
                font-family: Arial;;
                font-size: 14px;
                line-height: 1.2;
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
}
header.header-nav.menu_style_home_one.style2.home10 {
    .ace-responsive-menu {
        >li {
            >a {
                padding: 35px 10px 30px 10px;
                color: #ffffff;
            }
            &:first-child {
                ul.sub-menu {
                    background-color: #1d293e;
                    background-color: #1d293e;
                    &:before {
                        background-color: #1d293e;
                    }
                }
            }
            &:nth-child(2) {
                ul.sub-menu {
                    background-color: #1d293e;
                    &:before {
                        background-color: #1d293e;
                    }
                }
            }
        }
        li {
            ul.sub-menu {
                background-color: #1d293e;
                &:before {
                    background-color: #1d293e;
                }
                li {
                    background-color: #1d293e;
                    a {
                        color: #ffffff;
                    }
                }
            }
            a {
                color: #ffffff;
            }
        }
    }
    background-color: #1d293e;
    a.navbar_brand {
        span {
            color: #ffffff;
        }
    }
    ul.ace-responsive-menu {
        li.list_s {
            &:before {
                display: none;
            }
        }
        li.add_listing.home2 {
            border-radius: 8px;
            background-color: rgb(53, 71, 101);
            box-shadow: none;
        }
    }
}
header.header-nav.menu_style_home_one.style2 {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    a.navbar_brand {
        margin-top: 20px;
        span {
            color: $dark-grey;
            font-family: Arial;;
            font-size: 28px;
            font-weight: bold;
            line-height: 1.2;
        }
    }
    .ace-responsive-menu {
        li {
            a {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                span {
                    padding-left: 5px;
                }
            }
        }
    }
    ul.ace-responsive-menu {
        li.list_s {
            position: relative;
            &:before {
                background-color: #e8eef2;
                content: "";
                height: 48px;
                left: -5px;
                position: absolute;
                right: 0;
                top: 20px;
                width: 1px;
            }
        }
        li.add_listing {
            background-color: $red;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            a {
                font-size: 16px;
                font-family: Arial;;
                color: #ffffff;
                line-height: 1;
            }
        }
        li.add_listing.home2 {
            background-color: rgb(36, 50, 74);
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        }
    }
}
header.header-nav.menu_style_home_one.style2.home3 {
    background-color: #ffffff;
    box-shadow: none;
}
header.header-nav.menu_style_home_one.style3 {
    ul.ace-responsive-menu {
        li.add_listing.home6 {
            background-color: $red;
            margin-bottom: 5px;
        }
    }
}
header.header-nav.menu_style_home_one.style2.listing_single_page5 {
    position: absolute;
    width: 100%;
    z-index: 9;
}
header.header-nav.menu_style_home_one.home4 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.102);
}
.menu_style_home_one.style2.menu-fixed {
    position: fixed;
    width: 100%;
    z-index: 9;
}
.sign_up_btn {
    .btn {
        box-shadow: none;
        border-radius: 4px;
        color: #ffffff;
        height: auto;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            color: #ffffff;
            color: #ffffff;
            color: #ffffff;
        }
    }
    li {
        &:first-child {
            .btn {
                span {
                    padding-left: 10px;
                }
            }
        }
        .btn {
            .cart_btn {
                background-color: #192675;
                font-size: 10px;
                height: 17px;
                width: 17px;
                text-align: center;
            }
        }
    }
    a.btn.cart_btn {
        font-size: 24px;
        line-height: normal;
        span {
            background-color: #192675;
            margin: 0 auto 0 -10px;
            border-radius: 50%;
            height: 17px;
            line-height: 15px;
            position: absolute;
            text-align: center;
            width: 17px;
            sup {
                color: #ffffff;
                font-size: 10px;
                margin-left: -10px;
                vertical-align: baseline;
            }
        }
    }
    a.btn.search_btn {
        font-size: 24px;
        line-height: normal;
    }
    li.list_s {
        &:last-child {
            border: none;
        }
    }
}
.sign_up_btn.home3 {
    .btn {
        font-family: Arial;;
        font-size: 14px;
        line-height: 1.2;
        i {
            font-size: 18px;
            margin-right: 5px;
        }
    }
    a.btn.cart_btn {
        span {
            line-height: 17px;
        }
    }
}
.cart_btnes.home4 {
    a.btn.cart_btn {
        font-size: 24px;
        line-height: normal;
        color: #ffffff;
        margin-top: 15px;
        span {
            background-color: #192675;
            margin: 0 auto 0 -10px;
            border-radius: 50%;
            border-radius: 50%;
            height: 17px;
            height: 17px;
            line-height: 15px;
            line-height: 15px;
            position: absolute;
            position: absolute;
            text-align: center;
            text-align: center;
            width: 17px;
            width: 17px;
            sup {
                color: #ffffff;
                font-size: 10px;
                margin-left: -10px;
                vertical-align: baseline;
                margin-left: 0;
            }
        }
    }
}
.cart_btn.home5 {
    a.btn.cart_btn {
        span {
            background-color: #192675;
            margin: 0 auto 0 -10px;
            border-radius: 50%;
            height: 17px;
            line-height: 15px;
            position: absolute;
            text-align: center;
            width: 17px;
            color: #ffffff;
            margin-top: -5px;
            z-index: 9;
            sup {
                font-size: 10px;
                line-height: 1.7rem;
                margin-left: 0;
            }
        }
    }
}
.ht_left_widget.home5 {
    .cd-dropdown-trigger {
        &::before {
            background-color: #ffffff;
        }
        &::after {
            background-color: #ffffff;
        }
    }
}
.home5_shop_reg_widget {
    li {
        &:first-child {
            margin-right: 0;
        }
    }
}
header.header-nav.menu_style_home_six.stricky-fixed {
    .cart_btnes.home4 {
        a.btn.cart_btn {
            color: $dark-grey;
        }
    }
    background-color: #ffffff;
    border-bottom: none;
    padding: 10px 0;
    a.navbar_brand {
        span {
            color: $dark-grey;
        }
    }
    .ace-responsive-menu {
        li {
            a {
                color: $dark-grey;
            }
        }
    }
}
.home7_search.home7 {
    #search-button {
        i {
            color: #ffffff;
        }
    }
}
header.header-nav.menu_style_home_four.home8 {
    .container-fluid {
        padding: 0 70px;
    }
}
header.header-nav.menu_style_home_four.home8.stricky-fixed {
    a.navbar_brand {
        span {
            background: linear-gradient(to right, #835bb4 0%, #d7cf96 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
header.header-nav.menu_style_home_seven.navbar-scrolltofixed.main-menu.scroll-to-fixed-fixed {
    background-color: #ffffff;
}
header.header-nav.menu_style_home_seven.stricky-fixed {
    .cart_btnes.home4 {
        a.btn.cart_btn {
            color: $dark-grey;
        }
    }
}
.stylehome1 {
    .mm-navbar {
        color: $dark-grey;
        height: 86px;
        line-height: 4;
        background-color: #ffffff;
    }
    .mm-panel {
        background-color: #f7f7f7;
        ul.mm-listview {
            li {
                &:first-child.mm-listitem {
                    margin-top: 30px;
                }
            }
            li.mm-listitem.cl_btn {
                border-bottom: none;
                margin: 50px auto;
                max-width: 90%;
                a.btn {
                    color: #ffffff;
                    font-size: 16px;
                }
            }
        }
    }
    a.mm-listitem__text {
        color: $dark-grey;
        &:hover {
            background-color: transparent;
            color: $red !important;
        }
    }
    a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
        color: $dark-grey;
        &:hover {
            background-color: #e5e5e5;
            color: $dark-grey;
        }
    }
    li.mm-listitem {
        &:hover {
            color: #ffffff;
        }
        &:active {
            color: #ffffff;
        }
        &:focus {
            color: #ffffff;
        }
    }
    .mm-btn_prev {
        &:before {
            background-color: #ffffff;
        }
    }
}
.stylehome1.home3 {
    a.mm-listitem__text {
        color: #cecff9;
        &:hover {
            background-color: #e5e5e5;
            color: $dark-grey;
        }
    }
    a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
        color: #cecff9;
        &:hover {
            background-color: #e5e5e5;
            color: $dark-grey;
        }
    }
}
.stylehome1.home4 {
    .mm-navbar {
        background-color: #201b72;
    }
    .mm-panel {
        background-color: #201b72;
    }
    a.mm-listitem__text {
        color: #cecff9;
        &:hover {
            background-color: #151156;
            color: #ffffff;
        }
    }
    a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
        color: #cecff9;
        &:hover {
            background-color: #151156;
            color: #ffffff;
        }
    }
}
.stylehome1.home6 {
    .mm-navbar {
        background-color: #ffffff;
        color: #252834;
    }
    .mm-panel {
        background-color: #ebf9f4;
    }
    a.mm-listitem__text {
        color: #252834;
        &:hover {
            background-color: #43d4b6;
            color: #ffffff;
        }
    }
    a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
        color: #252834;
        &:hover {
            background-color: #43d4b6;
            color: #ffffff;
        }
    }
    .mm-btn_next {
        &::after {
            border-color: #252834;
        }
    }
}
.home_content {
    padding: 380px 0 400px;
    .home-text {
        p {
            font-weight: 600;
        }
    }
    .home-text.home5 {
        h4 {
            font-family: Arial;;
            color: #ffffff;
            font-style: italic;
            line-height: 1.2;
        }
    }
}
.home_content.home4 {
    padding: 55px 0;
}
.home_content.home5 {
    padding: 330px 0 240px;
    position: relative;
}
.home_content.home5.style2 {
    padding-top: 290px;
}
.home-text.home5 {
    .discounts_para {
        margin-bottom: 100px;
    }
}
.home_content.home6 {
    padding: 64px 0 64px;
    position: relative;
}
.home_content.home7 {
    padding: 350px 0 90px;
    position: relative;
}
.icon_home5 {
    background-color: rgba(255, 255, 255, 0.149);
    border-radius: 3px;
    cursor: pointer;
    height: 120px;
    padding: 10px 0;
    position: relative;
    text-align: center;
    width: 130px;
    span {
        color: #ffffff;
        font-size: 40px;
    }
    p {
        font-size: 16px;
        font-family: Arial;;
        color: #ffffff;
        line-height: 1.2;
        margin-bottom: 0;
    }
}
.home1_adsrchfrm {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px 20px;
    position: relative;
    border: 1px solid $light-grey;
    z-index: 2;
    &:before {
        background-color: rgba(255,255,255, .15);
        border-radius: 8px;
        bottom: -10px;
        content: "";
        left: -10px;
        position: absolute;
        right: -10px;
        top: -10px;
        z-index: -1;
    }
}
.home_adv_srch_opt {
    margin-top: 145px;
    position: relative;
    .nav-pills {
        border-bottom: none;
        margin: 0 auto;
        text-align: center;
        width: 250px;
        li.nav-item {
            a.nav-link {
                &:first-child {
                    margin-right: 15px;
                }
                background-color: #ffffff;
                border-radius: 8px;
                color: #1b2032;
                font-size: 16px;
                height: 50px;
                width: 110px;
                text-align: center;
                line-height: 35px;
                cursor: pointer;
            }
            a.nav-link.active {
                background-color: $red;
                border-color: $red;
                color: #ffffff;
                &:before {
                    background-color: $red;
                    content: "";
                    height: 20px;
                    margin-left: 5px;
                    position: absolute;
                    top: 35px;
                    width: 20px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }
    .tab-content {
        margin-top: 30px;
    }
}
.home_adv_srch_opt.home3 {
    margin-top: -17px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    .nav-pills {
        margin: 0;
    }
}
.home_adv_srch_opt.home4 {
    margin-top: 40px;
    .nav-pills {
        li.nav-item {
            a.nav-link {
                border-radius: 25px;
            }
        }
    }
    .home1_adsrchfrm {
        border-radius: 45px;
        height: 90px;
        padding: 20px;
        &:before {
            border-radius: 60px;
        }
    }
}
.home_adv_srch_opt.home6 {
    .nav-pills {
        margin: 0;
        li.nav-item {
            a.nav-link.active {
                background-color: $red;
                border-color: $red;
                color: #ffffff;
                &:before {
                    background-color: $red;
                }
            }
        }
    }
    margin-top: 0;
}
.home_adv_srch_opt.home7 {
    margin-top: 25px;
}
.home_adv_srch_opt.home9 {
    margin-top: 25px;
}
.home1-advnc-search {
    ul {
        li:not(.select2-selection__choice) {
            margin-right: 15px !important;
        }
        li {
            display: inline-block;
            line-height: initial;
            vertical-align: text-top;
            &:first-child {
                .form-control {
                    background-color: #ffffff;
                    border: 1px solid #ebebeb;
                    border-radius: 8px;
                    font-size: 14px;
                    color: $dark-grey;
                    line-height: 1.2;
                    height: 50px;
                }
                input[type="text"].form-control {
                    &::-webkit-input-placeholder {
                        background-color: #ffffff;
                        color: $dark-grey;
                        font-size: 14px;
                        font-family: Arial;;
                        line-height: 1.2;
                    }
                }
            }
            .form-group {
                margin-bottom: 0;
            }
            &:nth-child(2) {
                margin-right: 0;
            }
            &:nth-child(3) {
                .form-group {
                    position: relative;
                    .form-control {
                        border: 1px solid #ebebeb;
                        border-radius: 8px;
                        background-color: #ffffff;
                        font-size: 14px;
                        height: 50px;
                        width: 190px;
                    }
                    label {
                        background-color: #ffffff;
                        font-size: 16px;
                        height: 40px;
                        line-height: 40px;
                        position: absolute;
                        right: 1px;
                        text-align: center;
                        top: 5px;
                        width: 40px;
                        span {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0 !important;
            }
            .search_option_button {
                button {
                    border-radius: 8px;
                    background-color: $red;
                    font-size: 16px;
                    color: #ffffff;
                    font-weight: bold;
                    line-height: 1.2;
                    height: 50px;
                    width: 170px;
                    &:hover {
                        background-color: #ffffff !important;
                        border: 2px solid $red !important;
                        color: $dark-grey !important;
                    }
                }
            }
        }
    }
    .search_option_two.home2.style2 {
        .dropdown.bootstrap-select {
            .dropdown-toggle {
                .filter-option {
                    color: #006c70;
                }
            }
        }
    }
    ul.apeartment_area_list {
        text-align: left;
        li {
            margin-right: 15px;
            &:nth-child(2) {
                margin-right: 15px;
            }
        }
    }
    .dropdown-menu.pricing {
        .dropdown-item {
            &:hover {
                background-color: transparent;
            }
        }
    }
    .slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
        background-color: #f7f7f7;
        border: none;
        border-radius: 3.5px;
        height: 7px;
        margin-top: 25px;
        width: 240px;
    }
    .ui-slider-range.ui-corner-all.ui-widget-header {
        background-color: $red;
    }
    span.ui-slider-handle.ui-corner-all.ui-state-default {
        background-color: #ffffff;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        -moz-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        -o-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        height: 25px;
        top: -10px;
        width: 25px;
        &:before {
            background-color: $red;
            border-radius: 10px;
            content: "";
            height: 11px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: 6px;
            width: 11px;
        }
        &:hover {
            background-color: #ffffff;
            cursor: pointer;
        }
        &:active {
            background-color: #ffffff;
            border: none;
            outline: none;
        }
        &:focus {
            background-color: #ffffff;
            border: none;
            outline: none;
        }
    }
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                background-color: #ffffff;
                border-radius: 8px;
                border: 1px solid #ebebeb;
                height: 50px;
                line-height: 35px;
                width: 180px;
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
                &:active {
                    box-shadow: none;
                    outline: none;
                }
            }
            .dropdown-toggle {
                .filter-option {
                    font-size: 14px;
                    color: $dark-grey;
                }
            }
            .dropdown-menu.show {
                min-width: 250px !important;
            }
        }
        ul.dropdown-menu.inner.show {
            li {
                font-size: 14px;
                margin-right: 0 !important;
            }
        }
        .dropdown-menu {
            top: 50px !important;
            button.btn {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
            }
        }
        .dropdown-menu.show {
            &:before {
                background-color: #ffffff;
                content: "";
                height: 20px;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                top: -5px;
                width: 20px;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    .dropdown.bootstrap-select {
        >.dropdown-toggle {
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #ebebeb;
            height: 50px;
            line-height: 40px;
            font-size: 14px;
            width: 190px;
        }
        .dropdown-menu.show {
            min-width: 250px !important;
        }
    }
    ul.dropdown-menu.inner.show {
        li {
            font-size: 14px;
            margin-right: 0 !important;
        }
    }
    .search_option_two.home2 {
        .dropdown-menu {
            top: 35px !important;
        }
    }
    .bootstrap-select {
        .bs-ok-default {
            &:after {
                color: $red;
                color: $red;
            }
        }
        .dropdown-menu {
            li {
                a {
                    color: $dark-grey;
                    font-size: 14px;
                    line-height: 2;
                    width: 250px;
                }
            }
        }
    }
    .dropdown-menu.show {
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        border-radius: 8px;
        overflow: visible !important;
    }
}
.home1-advnc-search.home2 {
    padding-left: 8px;
    ul {
        li {
            margin-right: 15px !important;
        }
    }
}
.home1-advnc-search.home10 {
    .small_dropdown2 {
        margin-right: 10px;
    }
    .mega-dropdown {
        .dropbtn {
            i {
                padding-left: 10px;
            }
        }
    }
    ul {
        li {
            margin-right: 18px !important;
            &:last-child {
                margin-right: 0 !important;
            }
            .search_option_button {
                button {
                    width: 180px;
                }
            }
        }
    }
}
.home1-advnc-search.home5 {
    ul {
        li {
            margin-right: 5px !important;
        }
    }
    .slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
        width: 100%;
    }
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                width: 100%;
            }
            .dropdown-menu.show {
                min-width: 100% !important;
            }
        }
    }
    .dropdown.bootstrap-select {
        .dropdown-menu.show {
            min-width: 100% !important;
        }
    }
    .form-group.df {
        label {
            font-size: 24px;
            line-height: 2;
            position: absolute;
            right: 10%;
        }
    }
    padding: 0 15px;
    .bootstrap-select {
        .dropdown-menu {
            li {
                a {
                    width: 100%;
                }
            }
        }
    }
}
.home1-advnc-search.home2.style2 {
    ul {
        li {
            &:first-child {
                .form-control {
                    &::placeholder {
                        color: #006c70;
                    }
                }
            }
            &:nth-child(3) {
                .form-group {
                    .form-control {
                        &::placeholder {
                            color: #006c70;
                        }
                    }
                }
            }
        }
    }
}
.small_dropdown2.home2.style2 {
    .dd_btn {
        color: #006c70;
    }
}
.mega-dropdown.home2.style2 {
    .dropbtn {
        color: #006c70;
    }
}
.home1-advnc-search.home4 {
    ul {
        li {
            &:first-child {
                .form-control {
                    border-radius: 25px;
                    padding-left: 20px;
                }
                input[type="text"].form-control {
                    &::-webkit-input-placeholder {
                        color: #006c70;
                        font-size: 14px;
                        font-family: Arial;;
                        line-height: 1.2;
                    }
                }
            }
            &:nth-child(3) {
                .form-group {
                    label {
                        border-radius: 0 25px 25px 0;
                        right: 5px;
                    }
                    input[type="text"].form-control {
                        &::-webkit-input-placeholder {
                            color: #006c70;
                            font-size: 14px;
                            font-family: Arial;;
                            line-height: 1.2;
                        }
                    }
                    .form-control {
                        border-radius: 25px;
                        padding-left: 20px;
                    }
                }
            }
            .search_option_button {
                button {
                    border-radius: 25px;
                    padding-left: 20px;
                }
            }
        }
    }
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                border-radius: 25px;
                padding-left: 20px;
                .filter-option-inner-inner {
                    color: #006c70;
                }
            }
        }
    }
}
.home1-advnc-search.home3 {
    ul {
        li {
            &:first-child {
                input[type="text"].form-control {
                    &::-webkit-input-placeholder {
                        color: #006c70;
                        font-size: 14px;
                        font-family: Arial;;
                        line-height: 1.2;
                    }
                }
            }
            &:nth-child(3) {
                .form-group {
                    input[type="text"].form-control {
                        &::-webkit-input-placeholder {
                            color: #006c70;
                            font-size: 14px;
                            font-family: Arial;;
                            line-height: 1.2;
                        }
                    }
                }
            }
            .search_option_button {
                button {
                    background-color: #3e4c66;
                    &:hover {
                        background-color: #ffffff;
                        border: 1px solid #3e4c66;
                        color: #3e4c66;
                    }
                }
            }
        }
    }
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                .filter-option-inner-inner {
                    color: #006c70;
                }
            }
        }
    }
}
.home1-advnc-search.home6 {
    ul {
        li {
            &:first-child {
                input[type="text"].form-control {
                    &::-webkit-input-placeholder {
                        color: #006c70;
                        font-size: 14px;
                        font-family: Arial;;
                        line-height: 1.2;
                    }
                }
            }
            &:nth-child(3) {
                .form-group {
                    input[type="text"].form-control {
                        &::-webkit-input-placeholder {
                            color: #006c70;
                            font-size: 14px;
                            font-family: Arial;;
                            line-height: 1.2;
                        }
                    }
                }
            }
            .search_option_button {
                button {
                    background-color: $red;
                    border: 1px solid $red;
                    -webkit-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
                    -moz-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
                    -o-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
                    box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
                }
            }
            .search_option_button.style2 {
                button {
                    background-color: $red;
                    border: 1px solid $red;
                }
            }
        }
    }
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                .filter-option-inner-inner {
                    color: #006c70;
                }
            }
        }
    }
}
.home1-advnc-search.home9 {
    ul {
        li {
            &:first-child {
                input[type="text"].form-control {
                    &::-webkit-input-placeholder {
                        color: #006c70;
                        font-size: 14px;
                        font-family: Arial;;
                        line-height: 1.2;
                    }
                }
                .form-control {
                    border: none;
                    width: 400px;
                }
            }
        }
    }
    background-color: #ffffff;
    border-radius: 8px;
    height: 91px;
    line-height: 90px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 632px;
    ul.h1ads_1st_list {
        padding: 0 0 0 15px;
    }
}
.home5_advanced_search_form {
    .form-group {
        input[type="text"].form-control {
            &::-webkit-input-placeholder {
                color: #006c70;
                font-size: 14px;
                font-family: Arial;;
                line-height: 1.2;
            }
        }
        input.form-control {
            border: 1px solid #ebebeb;
            border-radius: 8px;
        }
    }
    .dropdown.bootstrap-select {
        >.dropdown-toggle {
            .filter-option-inner-inner {
                color: #006c70;
                color: #006c70;
            }
        }
    }
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px;
    position: relative;
    z-index: 9;
    &:before {
        background-color: rgba(255, 255, 255, 0.149);
        border-radius: 8px;
        content: "";
        bottom: -10px;
        left: -10px;
        position: absolute;
        right: -10px;
        top: -10px;
        z-index: -1;
    }
}
.home1-advnc-search.home7 {
    background-color: rgba(255, 255, 255, 0.102);
    border-radius: 8px;
    height: 91px;
    line-height: 90px;
    ul {
        li {
            .search_option_button {
                button {
                    background-image: -moz-linear-gradient( 120deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
                    background-image: -webkit-linear-gradient( 120deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
                    background-image: -ms-linear-gradient( 120deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
                }
            }
            display: inline-block;
            &:nth-child(2) {
                vertical-align: middle;
                width: 500px;
            }
        }
    }
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                width: 194px;
                height: 50px;
            }
        }
    }
    ul.dropdown-menu.inner.show {
        li {
            display: block;
        }
    }
}
.search_option_button.home5 {
    button {
        background-image: -moz-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
        background-image: -webkit-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
        background-image: -ms-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
        border-radius: 8px;
        border: 1px solid rgb(255,101,101);
        color: #ffffff;
        height: 50px;
    }
}
input.amount2 {
    text-align: right;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #006c70;
    font-size: 16px;
    font-family: Arial;;
    font-weight: bold;
    line-height: 1.2;
    width: 48%;
    &:hover {
        border: none;
        outline: none;
    }
    &:active {
        border: none;
        outline: none;
    }
    &:focus {
        border: none;
        outline: none;
    }
}
input.amount4 {
    text-align: right;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #006c70;
    font-size: 16px;
    font-family: Arial;;
    font-weight: bold;
    line-height: 1.2;
    width: 48%;
    &:hover {
        border: none;
        outline: none;
    }
    &:active {
        border: none;
        outline: none;
    }
    &:focus {
        border: none;
        outline: none;
    }
}
input.amount {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #006c70;
    font-size: 16px;
    font-family: Arial;;
    font-weight: bold;
    line-height: 1.2;
    width: 48%;
    &:hover {
        border: none;
        outline: none;
    }
    &:active {
        border: none;
        outline: none;
    }
    &:focus {
        border: none;
        outline: none;
    }
}
input.amount3 {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #006c70;
    font-size: 16px; 
    font-family: Arial;;
    font-weight: bold;
    line-height: 1.2;
    width: 48%;
    &:hover {
        border: none;
        outline: none;
    }
    &:active {
        border: none;
        outline: none;
    }
    &:focus {
        border: none;
        outline: none;
    }
}
.sasw_list {
    .slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
        background-color: #f7f7f7;
        border: none;
        border-radius: 3.5px;
        height: 7px;
        margin-top: 25px;
        width: 240px;
    }
    .ui-slider-range.ui-corner-all.ui-widget-header {
        background-color: $red;
    }
    span.ui-slider-handle.ui-corner-all.ui-state-default {
        background-color: #ffffff;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        -moz-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        -o-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
        height: 25px;
        top: -10px;
        width: 25px;
        &:before {
            background-color: $red;
            border-radius: 10px;
            content: "";
            height: 11px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: 6px;
            width: 11px;
        }
        &:hover {
            background-color: #ffffff;
            cursor: pointer;
        }
        &:active {
            background-color: #ffffff;
            border: none;
            outline: none;
        }
        &:focus {
            background-color: #ffffff;
            border: none;
            outline: none;
        }
    }
    .search_area {
        .field-searchform-locations {
            .select2-selection {
                border: 1px solid #ebebeb;
                box-shadow: none;
                .select2-search__field {
                    /*height: 50px;*/
                    width: auto !important;
                }
            }
        }
        position: relative;
        input {
            background-color: #ffffff;
            border: 1px solid rgb(235, 235, 235);
            border-radius: 8px;
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.2;
            height: 52px;
            padding-left: 20px;
            &::placeholder {
                color: $dark-grey;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        textarea {
            border: 1px solid rgb(235, 235, 235);
            border-radius: 8px;
            box-shadow: none;
            font-size: 14px;
            line-height: 2.2;
            &::placeholder {
                color: $dark-grey;
              font-size: 90%;
            }
        }
        label {
            font-size: 18px;
            position: absolute;
            right: 20px;
            top: 10px;
        }
    }
    .min_area {
        .form-control {
            &::placeholder {
                color: $dark-grey;
            }
            background-color: #ffffff;
            border: 1px solid rgb(235, 235, 235);
            border-radius: 8px;
            font-size: 14px;
            height: 52px;
            padding-left: 20px;
        }
        margin-right: 20px;
        width: 135px;
    }
    .max_area {
        .form-control {
            &::placeholder {
                color: $dark-grey;
            }
            background-color: #ffffff;
            border: 1px solid rgb(235, 235, 235);
            border-radius: 8px;
            font-size: 14px;
            height: 52px;
            padding-left: 20px;
        }
        width: 135px;
        float: right;
        margin-right: 0;
    }
    button.btn.dropdown-toggle {
        outline: none;
        box-shadow: none;
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        color: $dark-grey;
        font-size: 14px;
        font-family: Arial;;
        height: 52px;
        line-height: 42px;
        padding-left: 20px;
    }
    .search_option_two {
        margin-bottom: 20px;
        .selectpicker {
            width: 100%;
        }
    }
    .small_dropdown2 {
        position: relative;
        .dd_content2 {
            right: 0;
            top: 65px;
            z-index: 9;
        }
        .dd_btn {
            margin-bottom: 20px;
            padding-left: 20px;
            width: 100%;
        }
    }
    .panel-heading {
        h4.panel-title {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 25px;
            i {
                color: #bdc6ce;
            }
        }
    }
    #accordion {
        .panel-heading {
            a.accordion-toggle {
                &:before {
                    display: none;
                }
            }
        }
        .panel {
            margin-bottom: 10px;
        }
    }
    .ui_kit_checkbox {
        .custom-control-label {
            padding-right: 0;
        }
    }
    .search_option_button {
        button.btn {
            height: 50px;
        }
    }
}
.small_dropdown2.home4 {
    .dd_btn {
        border-radius: 25px;
        padding-left: 20px;
        color: #006c70;
    }
}
.small_dropdown2.home3 {
    .dd_btn {
        color: #006c70;
    }
}
.mega-dropdown.home3 {
    .dropbtn {
        color: #006c70;
    }
}
.small_dropdown2.home5 {
    .dd_btn {
        color: #006c70;
        width: 100%;
    }
    position: relative;
}
.small_dropdown2.home6 {
    .dd_btn {
        color: #006c70;
    }
}
.mega-dropdown.home4 {
    .dropbtn {
        color: #006c70;
    }
}
.mega-dropdown.home5 {
    .dropbtn {
        color: #006c70;
    }
    .dropdown-content {
        top: 0;
        &:before {
            display: none;
        }
    }
    span.dropbtn {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        display: block;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        width: 100%;
        i {
            float: right;
            margin-right: 10px;
        }
    }
}
.mega-dropdown.home6 {
    .dropbtn {
        color: #006c70;
    }
}
form.home5_advanced_search_form {
    .search_option_two {
        .dropdown-menu {
            top: 10px !important;
        }
    }
    .form-group {
        input.form-control {
            &::placeholder {
                color: #006c70;
            }
            &:-ms-input-placeholder {
                color: #006c70;
            }
            &::-ms-input-placeholder {
                color: #006c70;
            }
        }
    }
}
.small_dropdown {
    .dd_btn {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        color: $dark-grey;
        font-size: 14px;
        font-family: Arial;;
        height: 50px;
        line-height: 2.5;
        width: 180px;
        text-align: left;
        label {
            float: right;
        }
        &:hover {
            cursor: pointer;
        }
    }
    &:hover {
        .dd_content {
            display: block;
        }
    }
}
.small_dropdown2 {
    .dd_btn {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        color: $dark-grey;
        height: 50px;
        line-height: 2.5;
        margin: 0;
        outline: none;
        text-align: left;
        width: 150px;
        label {
            float: right;
        }
    }
}
.dd_content {
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    display: none;
    height: 240px !important;
    left: -40% !important;
    margin: 0 auto;
    padding: 40px 30px;
    position: absolute;
    right: 0 !important;
    top: 140px;
    width: 250px;
    &:before {
        background-color: #ffffff;
        content: "";
        height: 20px;
        left: auto;
        margin: 0 auto;
        position: absolute;
        right: 25%;
        top: -5px;
        width: 20px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &:after {
        background-color: transparent;
        content: "";
        height: 42px;
        left: auto;
        margin: 0 auto;
        right: 0 !important;
        top: -25% !important;
        width: 200px !important;
        position: absolute;
    }
}
.dd_content2 {
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    display: none;
    height: 147px;
    left: auto;
    margin: 0 auto;
    max-width: 304px;
    padding: 40px 30px;
    position: absolute;
    right: 26%;
    top: 140px;
    &:before {
        background-color: #ffffff;
        content: "";
        height: 20px;
        left: auto;
        margin: 0 auto;
        position: absolute;
        right: 25%;
        top: -5px;
        width: 20px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &:after {
        background-color: transparent;
        content: "";
        height: 90px;
        left: auto;
        margin: 0 auto;
        position: absolute;
        right: 15%;
        top: -29%;
        width: 100px;
        z-index: -1;
    }
}
.dd_content2.home2 {
    top: 110px;
    z-index: 9;
}
.dd_content2.home5 {
    max-width: 100%;
    right: 0;
    top: 70px;
    z-index: 9;
}
.mega-dropdown {
    .dropbtn {
        color: $dark-grey;
        border: none;
        font-size: 14px;
        margin: 0;
        outline: none;
        position: relative;
        &:hover {
            cursor: pointer;
        }
    }
}
.dropdown-content {
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    display: none;
    min-height: 254px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 100%;
    z-index: 9;
    padding: 15px;
    @include screen-xl-down {
        top: 160px;
    }
    @include screen-md {
        top: 235px !important;
    }
    &:before {
        background-color: #ffffff;
        content: "";
        height: 20px;
        left: auto;
        margin: 0 auto;
        position: absolute;
        right: 20%;
        top: -5px;
        width: 20px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.dropdown-content.home2 {
    top: 110px;
}
.featured_row {
    border-radius: 8px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
    height: 90px;
    margin-bottom: 60px;
    margin-left: 0;
    margin-right: 0;
    margin-top: -100px;
}
.home10-mainslider {
    margin-top: 0;
    .banner-style-one {
        .slide {
            &:before {
                background-color: rgba(29, 41, 62, 0.6);
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .banner-title {
            font-size: 45px;
            font-family: Arial;;
            color: #ffffff;
            font-weight: bold;
            line-height: 1.2;
            text-transform: capitalize;
        }
    }
    .home-content {
        padding-top: 15px;
    }
}
.home_iconbox_container {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 5%;
}
.animated {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}
.animate-out {
    -webkit-animation-delay: 0ms;
    animation-delay: 0ms;
}
.search_box_home4 {
    form.form-inline.mailchimp_form {
        display: inline-block;
        margin-top: 25px;
        position: relative;
    }
    .header_search_widget {
        .mailchimp_form {
            .form-control {
                border-radius: 30px;
                border: none;
                height: 60px;
                padding-left: 30px;
                width: 450px;
            }
            button {
                position: absolute;
                right: 20px;
                top: 7px;
            }
        }
    }
}
.home-text {
    h1 {
        color: #ffffff;
        margin-top: 0;
    }
    h2 {
        color: #ffffff;
        margin-top: 0;
    }
    h3 {
        color: #ffffff;
        margin-top: 0;
    }
}
.home-text.home6 {
    h2 {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
    }
    p {
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.2;
    }
}
.mouse_scroll {
    position: absolute;
    right: -350px;
    top: -130px;
    .icon {
        cursor: pointer;
        display: inline-block;
        padding-right: 14px;
        text-align: right;
        h4 {
            color: #ffffff;
            line-height: 1.2;
            margin: 0;
        }
        p {
            color: rgba(255, 255, 255, 0.42);
            line-height: 1.2;
            margin: 0;
        }
    }
    .thumb {
        float: right;
    }
}
.mouse_scroll.home5 {
    z-index: 9;
}
.mouse_scroll.home8 {
    z-index: 9;
}
.main-banner-wrapper {
    position: relative;
    .carousel-btn-block {
        cursor: pointer;
        position: absolute;
        top: 45%;
        width: 100%;
        z-index: 9;
        .carousel-btn {
            border: 1px solid #ffffff;
            border-radius: 8px;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            height: 82px;
            width: 42px;
            line-height: 82px;
            text-align: center;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            vertical-align: middle;
            z-index: 9;
            &:hover {
                background-color: #ffffff;
                color: #006c70;
                opacity: 1;
            }
        }
        .carousel-btn.left-btn {
            position: absolute;
            left: 30px;
        }
        .carousel-btn.right-btn {
            position: absolute;
            right: 30px;
        }
    }
}
.select2-container .select2-search--inline .select2-search__field {
    font-size:90% !important;
}

.listing_single_property_slider {
    .owl-controls {
        .owl-nav {
            cursor: pointer;
            position: absolute;
            top: 45%;
            width: 100%;
            z-index: 9;
            top: 41%;
            .owl-prev {
                border: 1px solid #ffffff;
                border-radius: 8px;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                height: 82px;
                width: 42px;
                line-height: 82px;
                text-align: center;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
                vertical-align: middle;
                z-index: 9;
                background-color: #ffffff;
                color: #006c70;
                left: 30px;
                position: absolute;
            }
            .owl-next {
                border: 1px solid #ffffff;
                border-radius: 8px;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                height: 82px;
                width: 42px;
                line-height: 82px;
                text-align: center;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
                vertical-align: middle;
                z-index: 9;
                background-color: #ffffff;
                color: #006c70;
                right: 30px;
                position: absolute;
            }
        }
    }
}
.banner-style-one {
    .slide {
        padding: 165px 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        &:before {
            background-color: rgba(0,0,0,.7);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .owl-nav {
        display: none;
    }
    h3 {
        margin: 0;
    }
    p {
        margin: 0;
        font-size: 18px;
        line-height: 35px;
        color: #ffffff;
        font-weight: 300;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
    }
    .banner-title {
        color: #ffffff;
        font-size: 50px;
        font-weight: 700;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        font-weight: normal;
        text-transform: uppercase;
    }
    .banner_top_title {
        color: #ffffff;
        font-size: 50px;
        font-weight: 700;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        font-size: 28px;
        color: #ffffff;
        font-weight: bold;
        line-height: 1.2;
        small {
            color: #ffffff;
            font-size: 50px;
            font-weight: 700;
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
            font-size: 14px;
            color: #ffffff;
        }
    }
    .banner-btn {
        display: inline-block;
        vertical-align: middle;
        color: #FFFFFF;
        font-size: 18px;
        padding: 19.5px 50px;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        margin-top: 20px;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        &:hover {
            background-color: #ffffff;
            color: #2D174F;
        }
    }
    .active {
        .banner-title {
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
            -webkit-animation-delay: .5s;
            animation-delay: .5s;
        }
        .banner_top_title {
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
            -webkit-animation-delay: .5s;
            animation-delay: .5s;
        }
        p {
            -webkit-animation-name: fadeInDown;
            animation-name: fadeInDown;
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }
        .banner-btn {
            border: 2px solid #ffffff;
            border-radius: 8px;
            color: #ffffff;
            font-size: 18px;
            padding: 12px 50px;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
            -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
            &:hover {
                background-color: #ffffff;
                color: $red;
            }
            &:active {
                background-color: #ffffff;
                color: $red;
            }
            &:focus {
                background-color: #ffffff;
                color: $red;
            }
        }
    }
    .owl-dots {
        display: none !important;
        margin: 0;
        position: absolute;
        bottom: 110px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
        max-width: 1170px;
        text-align: center;
        .owl-dot {
            span {
                background-color: #eeeeee;
                width: 8px;
                height: 8px;
                margin: 0;
                -webkit-transition: all .4s ease;
                -moz-transition: all .4s ease;
                -o-transition: all .4s ease;
                transition: all .4s ease;
            }
            + {
                .owl-dot {
                    margin-left: 7px;
                }
            }
        }
        .owl-dot.active {
            span {
                height: 13px;
                width: 13px;
                background-color: #ffffff;
            }
        }
    }
    .slide.slide-one.home6 {
        .banner-title {
            font-size: 50px;
            font-weight: normal;
            line-height: initial;
            text-transform: uppercase;
        }
        p {
            margin: 0 auto;
            max-width: 450px;
        }
    }
    .slide.home6 {
        padding: 295px 0;
        &:before {
            background-color: rgba(0,0,0,.5);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
.main-banner-wrapper.home7 {
    .carousel-btn-block {
        .carousel-btn {
            top: 70px;
        }
    }
}
.banner-style-one.no-dots {
    .owl-dots {
        display: none !important;
    }
}
.home-one {
    margin-top: -96px;
    padding: 0;
    .home-text {
        margin: 0 auto;
        left: 0;
        position: absolute;
        right: 0;
        a.btn {
            border: 2px solid #ffffff;
            border-radius: 27px;
            color: #ffffff;
            font-size: 18px;
            margin-top: 15px;
            padding: 10px 50px;
            -webkit-transition: all .4s ease;
            -moz-transition: all .4s ease;
            -o-transition: all .4s ease;
            transition: all .4s ease;
            &:hover {
                background-color: #ffffff;
                color: #2D174F;
            }
        }
    }
}
.home-four {
    margin-top: -96px;
    padding: 0;
    .banner-style-one {
        .slide {
            &:before {
                background-image: -moz-linear-gradient( -36deg, rgb(62,62,165) 0%, rgb(255,90,95) 100%);
                background-image: -webkit-linear-gradient( -36deg, rgb(62,62,165) 0%, rgb(255,90,95) 100%);
                background-image: -ms-linear-gradient( -36deg, rgb(62,62,165) 0%, rgb(255,90,95) 100%);
                left: 0;
                opacity: 0.902;
                position: absolute;
                right: 0;
                content: "";
                top: 0;
                bottom: 0;
            }
        }
    }
}
.home-five {
    margin-top: -96px;
    padding: 0;
    margin-top: 0;
    .home-text {
        padding: 230px 0 210px;
        h2 {
            color: $dark-grey;
            font-size: 45px;
        }
        p {
            font-family: Arial;
            font-size: 18px;
            line-height: 30px;
            max-width: 495px;
        }
        a.btn {
            border: 2px solid $dark-grey;
            border-radius: 27px;
            color: $dark-grey;
            font-size: 18px;
            margin-top: 15px;
            padding: 10px 50px;
            -webkit-transition: all .4s ease;
            -moz-transition: all .4s ease;
            -o-transition: all .4s ease;
            transition: all .4s ease;
            &:hover {
                background-color: #ffffff;
                border: 2px solid #ffffff;
                color: #2D174F;
            }
        }
    }
}
.home-seven {
    margin-top: -96px;
    padding: 0;
    background-image: url(../images/background/10.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 800px;
}
.home-nine {
    margin-top: -96px;
    padding: 0;
    background-image: url(../images/background/12.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 960px;
    z-index: 0 !important;
    &:before {
        background-image: -moz-linear-gradient( 120deg, rgb(100,17,147) 0%, rgb(35,0,90) 100%);
        background-image: -webkit-linear-gradient( 120deg, rgb(100,17,147) 0%, rgb(35,0,90) 100%);
        background-image: -ms-linear-gradient( 120deg, rgb(100,17,147) 0%, rgb(35,0,90) 100%);
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.6;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.home-six {
    //margin-top: -90px;
    padding: 0;
    background-color: #ffffff;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    min-height: 480px;
}
.home-three {
    margin-top: 0;
    &:before {
        background-color: rgba(29, 41, 62, .702);
        bottom: 0;
        border-radius: 8px;
        content: "";
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.home3_home_content {
    padding: 160px 0;
    position: relative;
    h1 {
        font-size: 55px;
        font-family: Arial;;
        color: #ffffff;
        font-weight: bold;
        line-height: 1.2;
    }
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: #ffffff;
        line-height: 1.2;
    }
    a.popup_video_btn {
        background: $red;
        border-radius: 50%;
        color: #ffffff;
        font-size: 30px;
        height: 74px;
        line-height: 74px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 56%;
        width: 74px;
        z-index: 9;
        &:after {
            background-color: rgba(255, 90, 95, 0.5);
            border-radius: 100%;
            content: "";
            height: 89px;
            left: -7px;
            position: absolute;
            top: -7px;
            width: 89px;
            z-index: -1;
        }
    }
}
.row_style.home8 {
    bottom: -8px;
    left: -15px;
    width: 105%;
}
.home4_overlay {
    &:before {
        background-color: rgba(10,10,10, .5);
        bottom: 97px;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.slide.slide-one.home6 {
    height: 730px;
}
.home1_bgi1 {
    background-image: url(../images/background/1.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 960px;
}
.home1-overlay {
    position: relative;
    &:before {
        content: "";
        background-color: rgb(29, 41, 62);
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }
}
.home5_bgi5 {
    background-image: url(../images/background/5.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 960px;
}
.home5-overlay {
    &:before {
        content: "";
        background-color: rgb(35, 39, 51);
        opacity: 0.9;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }
}

.home6-overlay {
    &:before {
        background-image: url(/images/background/rsz_hero-search.jpg);
        @media (max-width: 800px) {
            background-image: url(/images/background/rsz_hero-search-800.jpg);
        }
        @media (max-width: 450px) {
            background-image: url(/images/background/rsz_hero-search-450.jpg);
        }
        background-size: cover;
        bottom: 0;
        content: "";
        opacity: 0.9;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        min-height: 480px;
        background-position: center top;
    }
}
.home8-overlay {
    position: relative;
    &:before {
        content: "";
        background-color: rgb(29, 41, 62);
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }
}
.listing-home-bg {
    background-image: url(../images/background/13.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 600px;
    &:before {
        background-color: rgba(29, 41, 62, 0.6);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}
.subscribe-box-overlay {
    background-image: url(../images/background/13.jpg);
    z-index:1;
    -webkit-background-size: cover;

    background-size: cover;
    background-position: center center;
}
.home_content.listing {
    padding: 90px 0;
}
.home_content.listing.slider_style {
    padding: 120px 0 90px;
}
.home_adv_srch_opt.listing {
    margin-top: 45px;
}
.home-listing-slider {
    margin-top: 0;
    position: relative;
    .banner-style-one {
        .slide {
            &:before {
                background-color: rgba(29, 41, 62, 0.6);
                left: 0;
                position: absolute;
                right: 0;
                content: "";
                top: 0;
                bottom: 0;
            }
        }
    }
}
.home4_iconbox {
    cursor: pointer;
    position: relative;
    text-align: center;
    li {
        background-color: rgba(255, 255, 255, 0.149);
        border-radius: 22.5px;
        height: 45px;
        padding: 0 20px;
        text-align: center;
        width: 180px;
    }
    .icon {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        span {
            color: #ffffff;
            font-size: 25px;
            padding-right: 10px;
        }
        p {
            color: #ffffff;
            font-size: 16px;
            font-family: Arial;;
            color: #ffffff;
            line-height: 3;
            margin-bottom: 0;
        }
    }
}
.divider.home5 {
    padding: 170px 135px;
}
.divider-btn {
    line-height: 40px;
}
.home1-divider2 {
    background-image: url(../images/background/3.jpg);
    -webkit-background-size: cover;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    padding: 145px 0 145px;
    position: relative;
    &:before {
        background-color: rgba(36, 65, 231, .7);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.app_grid {
    h1 {
        margin-bottom: 5px;
        color: #ffffff;
    }
    p {
        color: #ffffff;
        margin-bottom: 40px;
    }
    button {
        &:last-child {
            margin-right: 0;
        }
    }
    .apple_btn {
        background-color: transparent;
        border-radius: 5px;
        border: 2px solid rgba(255,255,255, .4);
        color: #ffffff;
        height: 75px;
        line-height: normal;
        margin-right: 30px;
        position: relative;
        -ms-text-align-last: auto;
        text-align: left;
        width: 210px;
        width: 230px;
        &:hover {
            border: 2px solid transparent;
            cursor: pointer;
        }
        .icon {
            font-size: 36px;
            float: left;
            margin-right: 20px;
            margin-left: 15px;
        }
        .title {
            display: block;
            font-size: 16px;
        }
        .subtitle {
            font-size: 13px;
        }
    }
    .play_store_btn {
        background-color: transparent;
        border-radius: 5px;
        border: 2px solid rgba(255,255,255, .4);
        color: #ffffff;
        height: 75px;
        line-height: normal;
        margin-right: 30px;
        position: relative;
        -ms-text-align-last: auto;
        text-align: left;
        width: 210px;
        &:hover {
            border: 2px solid transparent;
            cursor: pointer;
        }
        .icon {
            font-size: 36px;
            float: left;
            margin-right: 20px;
            margin-left: 15px;
        }
    }
}
.play_store_btn {
    .title {
        display: block;
        font-size: 16px;
    }
    .subtitle {
        font-size: 13px;
    }
}
.phone_img {
    position: absolute;
    right: 0;
    top: -80px;
}
.img_hvr_box {
    -webkit-background-size: cover;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 60px 30px 55px;
    position: relative;
    text-align: center;
    &:before {
        background-color: rgba(10, 10, 10, .5);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    &:hover {
        cursor: pointer;
        &:before {
            background-color: rgba(36, 65, 231, .8);
        }
    }
    .overlay {
        .details {
            display: block;
            position: relative;
            h5 {
                color: #ffffff;
                margin-bottom: 0;
                font-size: 18px;
            }
            p {
                color: #ffffff;
                margin-bottom: 0;
                font-size: 15px;
            }
        }
    }
}
.img_hvr_box.home3 {
    &:hover {
        &:before {
            background-color: rgba(54, 159, 219, .8);
        }
    }
}
.home7_row {
    margin-top: -105px;
}
.img_hvr_box.home7 {
    height: 200px;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    width: 307px;
    .overlay {
        margin-top: 30px;
        .details {
            h4 {
                color: #ffffff;
                font-family: "Nunito SemiBold";
                font-size: 20px;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }
    &:before {
        background-color: rgba(236, 208, 111, .9);
    }
}
.img_hvr_box.home7.two {
    &:before {
        background-color: rgba(255, 16, 83, .9);
    }
}
.img_hvr_box.home7.three {
    &:before {
        background-color: rgba(0, 167, 142, .9);
    }
}
.img_hvr_box.home7.four {
    &:before {
        background-color: rgba(0, 188, 228, .9);
    }
}
.courses_all_btn {
    .btn {
        border: 2px solid;
        border-radius: 25px;
        font-size: 15px;
        height: 50px;
        line-height: 35px;
        margin-top: 30px;
        width: 235px;
    }
}
.mbp_pagination ul.page_navigation li .page-link{
    display:flex;
    justify-content: space-evenly;
}
.courses_all_btn.home3 {
    .btn {
        border-color: #369fdb;
        color: #369fdb;
        &:hover {
            background-color: #369fdb;
            color: #ffffff;
        }
        &:active {
            background-color: #369fdb;
            color: #ffffff;
        }
        &:focus {
            background-color: #369fdb;
            color: #ffffff;
        }
    }
}
.course-single2 {
    background-color: #f9fafc;
}
.courses_single_container {
    position: relative;
    width: 100%;
}
.cs_row_one {
    .cs_instructor {
        clear: both;
        display: inline-block;
        position: relative;
        width: 100%;
        .cs_instrct_list {
            li {
                margin-right: 15px;
                .thumb {
                    height: 50px;
                    width: 50px;
                }
                a {
                    font-size: 15px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                    font-size: 15px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                }
            }
        }
        .cs_watch_list {
            li {
                a {
                    font-size: 15px;
                    font-family: Arial;
                    color: $dark-grey;
                    line-height: 1.2;
                }
                &:first-child {
                    a {
                        font-size: 20px;
                    }
                }
                &:last-child {
                    a {
                        border-radius: 5px;
                        background-color: rgb(36, 65, 231);
                        color: #ffffff;
                        display: block;
                        height: 30px;
                        text-align: center;
                        width: 90px;
                        span {
                            font-size: 13px;
                            font-family: Arial;
                            color: #ffffff;
                            line-height: 2.2;
                        }
                    }
                }
            }
        }
    }
    .cs_title {
        font-size: 26px;
        font-family: "Nunito SemiBold";
        color: $dark-grey;
        line-height: 1.2;
    }
    .cs_review_seller {
        margin-bottom: 15px;
        li {
            margin-right: 4px;
            a {
                font-size: 13px;
                font-family: "FontAwesome";
                color: #bcc52a;
                line-height: 1.2;
            }
            &:first-child {
                margin-right: 15px;
                a {
                    border-radius: 5px;
                    background-color: rgb(255, 16, 83);
                    color: #ffffff;
                    display: block;
                    height: 30px;
                    text-align: center;
                    width: 90px;
                    span {
                        font-size: 13px;
                        font-family: Arial;
                        color: #ffffff;
                        line-height: 2.2;
                    }
                }
            }
            &:last-child {
                a {
                    font-size: 14px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                }
            }
        }
    }
    .cs_review_enroll {
        margin-bottom: 30px;
        li {
            margin-right: 15px;
            a {
                font-size: 14px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                line-height: 1.2;
                span {
                    padding-right: 6px;
                }
            }
        }
    }
    .courses_big_thumb {
        margin-bottom: 30px;
        .thumb {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            overflow: hidden;
            position: relative;
            img {
                border-radius: 5px;
            }
            .overlay_icon {
                &:before {
                    bottom: 0;
                    background-color: rgba(0,0,0, .3);
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 100%;
                }
                a {
                    color: #ffffff;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 40%;
                }
                .title {
                    color: #ffffff;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 40%;
                    top: 58%;
                }
            }
        }
    }
}

.cs_row_one.csv3 {
    .cs_watch_list {
        li {
            a {
                font-size: 15px;
                font-family: Arial;
                color: $dark-grey;
                line-height: 1.2;
            }
            &:last-child {
                a {
                    border-radius: 5px;
                    background-color: rgb(36, 65, 231);
                    color: #ffffff;
                    display: block;
                    height: 30px;
                    text-align: center;
                    width: 90px;
                    span {
                        font-size: 13px;
                        font-family: Arial;
                        color: #ffffff;
                        line-height: 2.2;
                    }
                }
            }
        }
    }
    .cs_review_enroll {
        display: inline;
    }
}
.cs_ins_container {
    .courses_big_thumb {
        .iframe_video {
            border-radius: 5px;
            min-height: 540px;
            max-width: 960px;
        }
    }
}
.cs_row_two {
    .cs_overview {
        border-style: solid;
        border-width: 2px;
        border-color: rgb(237, 239, 247);
        border-radius: 5px;
        background-color: rgba(42, 53, 59, 0);
        margin-bottom: 30px;
        padding: 30px;
        .title {
            font-family: "Nunito SemiBold";
            font-size: 20px;
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 22px;
        }
        h4 {
            font-family: Arial;;
            font-size: 18px;
            color: rgb(59, 59, 59);
            line-height: 1.333;
            margin-bottom: 10px;
        }
        p {
            font-family: Arial;
            font-size: 15px;
            color: rgb(126, 126, 126);
            line-height: 1.6;
        }
        ul.cs_course_syslebus {
            margin-bottom: 17px;
            margin-right: 100px;
            display: inline-block;
            li {
                p {
                    font-family: Arial;
                    font-size: 15px;
                    color: rgb(126, 126, 126);
                    display: inline;
                    line-height: 2.8;
                    margin-bottom: 0;
                    padding-left: 10px;
                }
            }
        }
        ul.cs_course_syslebus2 {
            display: inline-block;
            li {
                p {
                    font-family: Arial;
                    font-size: 15px;
                    color: rgb(126, 126, 126);
                    display: inline;
                    line-height: 2.8;
                    margin-bottom: 0;
                    padding-left: 10px;
                }
            }
        }
        ul.list_requiremetn {
            li {
                i {
                    font-size: 10px;
                    color: rgb(126, 126, 126);
                }
                p {
                    color: rgb(126, 126, 126);
                    font-family: Arial;
                    font-size: 15px;
                    display: inline;
                    line-height: 2.667;
                    margin-bottom: 0;
                    padding-left: 13px;
                }
            }
        }
    }
}
.cs_row_two.csv2 {
    .cs_overview {
        border-radius: 5px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-top: 30px;
    }
}
.cs_row_three.csv2 {
    .course_content {
        border-radius: 5px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-top: 30px;
    }
}
.cs_row_four.csv2 {
    .about_ins_container {
        border-radius: 5px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-top: 30px;
        .details {
            .review_list {
                margin-right: 50px;
            }
            .about_info_list {
                li {
                    margin-right: 15px;
                }
            }
        }
    }
}
.cs_row_five.csv2 {
    .student_feedback_container {
        border-radius: 5px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-top: 30px;
    }
}
.cs_row_six.csv2 {
    .sfeedbacks {
        border-radius: 5px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-top: 30px;
    }
}
.cs_row_seven.csv2 {
    .sfeedbacks {
        border-radius: 5px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-top: 30px;
    }
}
.cs_row_three {
    .course_content {
        border-style: solid;
        border-width: 2px;
        border-color: rgb(237, 239, 247);
        border-radius: 5px;
        background-color: rgba(42, 53, 59, 0);
        margin-bottom: 30px;
        position: relative;
        padding: 30px;
        .cc_headers {
            position: relative;
        }
        h4.title {
            font-size: 20px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            float: left;
            line-height: 1.2;
        }
        .details {
            display: inline-block;
            position: relative;
            width: 100%;
            .cc_tab {
                margin-top: 30px;
                h4.panel-title {
                    font-size: 18px;
                    font-family: Arial;;
                    color: rgb(59, 59, 59);
                    line-height: 1.7;
                    border-radius: 5px;
                    background-color: rgb(237, 239, 247);
                    height: 50px;
                    padding: 10px 20px;
                }
                .panel-body {
                    .cs_list {
                        li {
                            border: 1px solid rgb(235, 235, 235);
                            padding: 20px 25px;
                            &:last-child {
                                border-bottom: none;
                            }
                            a {
                                font-size: 15px;
                                font-family: Arial;
                                color: rgb(79, 79, 79);
                                line-height: 1.6;
                                span {
                                    padding-right: 4px;
                                }
                                span.cs_preiew {
                                    float: right;
                                    margin-right: 50px;
                                }
                                span.cs_time {
                                    float: right;
                                }
                            }
                            &:hover {
                                a {
                                    color: $red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.cs_row_four {
    .about_ins_container {
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
        .aii_title {
            font-size: 20px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            margin-bottom: 25px;
            line-height: 1.2;
        }
        .about_ins_info {
            float: left;
        }
        .details {
            padding-left: 160px;
            h4 {
                font-size: 20px;
                font-family: Arial;;
                color: $dark-grey;
                margin-bottom: 0;
                line-height: 1.5;
            }
            .subtitle {
                font-size: 14px;
                font-family: Arial;
                color: rgb(137, 137, 137);
                margin-bottom: 15px;
                line-height: 1.714;
            }
            p {
                font-size: 15px;
                font-family: Arial;
                color: rgb(79, 79, 79);
                line-height: 1.6;
            }
            .about_ins_list {
                li {
                    &:first-child {
                        p {
                            font-size: 15px;
                            font-family: Arial;
                            color: rgb(79, 79, 79);
                            margin-bottom: 0;
                            line-height: 1.6;
                        }
                    }
                    a {
                        font-size: 15px;
                        font-family: Arial;
                        color: rgb(79, 79, 79);
                        line-height: 1.6;
                    }
                }
            }
            .review_list {
                float: left;
                margin-right: 80px;
                li {
                    margin-right: 4px;
                    i {
                        font-size: 13px;
                        color: #bcc52a;
                        line-height: 1.2;
                    }
                }
            }
            .about_info_list {
                li {
                    font-size: 14px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                    span {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}
.cs_row_five {
    .student_feedback_container {
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
        .aii_title {
            font-size: 20px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            margin-bottom: 25px;
            line-height: 1.2;
        }
        .s_feeback_content {
            .sonny_progressbar {
                border-radius: 5px;
                max-width: 470px;
                padding-left: 100px;
                .bar {
                    background-color: #d0d23c !important;
                }
                .bar-container {
                    border-radius: 5px;
                    height: 10px !important;
                    overflow: hidden;
                }
                p.title {
                    float: right;
                    margin-right: -50px;
                    margin-top: -10px;
                }
            }
            ul.skills {
                margin-bottom: 30px;
                li {
                    color: rgb(79, 79, 79);
                    font-size: 14px;
                    font-family: Arial;
                    float: left;
                    line-height: 1.2;
                }
            }
        }
        .aii_average_review {
            border-radius: 5px;
            background-color: rgb(237, 239, 247);
            height: 180px;
            position: absolute;
            right: 30px;
            top: 75px;
            width: 230px;
            .av_content {
                margin-top: 45px;
                h2 {
                    font-size: 50px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 0.6;
                }
                .aii_rive_list {
                    li {
                        font-size: 13px;
                        font-family: "FontAwesome5FreeSolid";
                        color: #bcc52a;
                        margin-right: 3px;
                        line-height: 1.2;
                    }
                }
                p {
                    font-size: 15px;
                    font-family: Arial;
                    color: rgb(79, 79, 79);
                    line-height: 1.2;
                }
            }
        }
    }
}
.cs_row_six {
    .sfeedbacks {
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
        .sspd_review_liked {
            background-color: rgb(237, 239, 247);
            border-radius: 20px;
            height: 40px;
            text-align: center;
            max-width: 200px;
            a {
                font-size: 14px;
                font-family: Arial;
                line-height: 2.714;
            }
        }
    }
}
.cs_row_seven {
    .sfeedbacks {
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
    }
}
.cs_row_six.csv2.style2 {
    overflow: hidden;
}
.my_course_content_list {
    .mc_content_list {
        .s_feeback_content {
            .sonny_progressbar {
                border-radius: 5px;
                max-width: 170px;
                padding-left: 100px;
            }
        }
        .sonny_progressbar {
            .bar-container {
                margin-left: -190px;
                margin-right: 130px;
                margin-top: 10px;
            }
            p.title {
                float: right;
                margin-bottom: 0;
                margin-right: 0;
                margin-top: -10px;
            }
        }
        border: 1px solid rgb(235, 235, 235);
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        padding: 30px;
        &:hover {
            background-color: #f9fafc;
            .thumb {
                .overlay {
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -o-transform: scale(1);
                    transform: scale(1);
                    text-align: center;
                }
            }
        }
        &:last-child {
            border-bottom: none;
        }
        .thumb {
            display: -webkit-inline-flex;
            display: -moz-inline-flex;
            display: -ms-inline-flex;
            display: -o-inline-flex;
            display: inline-flex;
            margin-right: 20px;
            min-height: 150px;
            min-width: 250px;
            overflow: hidden;
            position: relative;
            .overlay {
                background-color: rgba(0,0,0, .5);
                border-radius: 5px;
                bottom: 0;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -o-transform: scale(0);
                transform: scale(0);
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
                ul {
                    margin-top: 110px;
                    li {
                        border-radius: 17.5em;
                        border-style: solid;
                        border-width: 2px;
                        border-color: #ffffff;
                        background-color: transparent;
                        height: 35px;
                        text-align: center;
                        width: 100px;
                        a {
                            color: #ffffff;
                            line-height: 2.0;
                        }
                    }
                }
            }
            img {
                border-radius: 5px;
            }
        }
        .details {
            .mc_footer {
                ul {
                    display: inline;
                    &:first-child {
                        margin-right: 25px;
                    }
                }
                ul.mc_review {
                    li {
                        margin-right: 3px;
                        &:nth-child(6) {
                            a {
                                font-size: 14px;
                                font-family: Arial;
                                color: rgb(126, 126, 126);
                                line-height: 1.2;
                            }
                        }
                        a {
                            i {
                                color: #bcc52a;
                                line-height: 1.2;
                            }
                        }
                    }
                    li.fp_pdate {
                        font-size: 18px;
                        font-family: Arial;;
                        color: rgb(199, 85, 51);
                        font-weight: bold;
                        line-height: 1.2;
                        margin-left: 15px;
                        a {
                            color: rgb(199, 85, 51);
                        }
                    }
                }
                margin-top: 23px;
            }
            .mc_content {
                .subtitle {
                    font-size: 15px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                    margin-bottom: 5px;
                    .title {
                        font-size: 18px;
                        font-family: Arial;;
                        color: rgb(10, 10, 10);
                        line-height: 1.333;
                    }
                }
                h5.title {
                    span {
                        border-radius: 5px;
                        background-color: rgb(27, 213, 75);
                        display: inline-block;
                        height: 30px;
                        margin-left: 10px;
                        text-align: center;
                        width: 90px;
                        .tag {
                            color: #ffffff;
                            font-size: 13px;
                            font-family: Arial;
                            line-height: 2.2;
                        }
                    }
                    span.style2 {
                        background-color: #ff1053;
                    }
                }
                p {
                    font-size: 14px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.714;
                }
            }
        }
    }
    position: relative;
}
.r_course_title {
    font-size: 26px;
    font-family: "Nunito SemiBold";
    color: $dark-grey;
    line-height: 1.2;
    margin-bottom: 30px;
}
.mbp_first.media.csv1.style2 {
    position: relative;
    &:before {
        bottom: -30px;
        background-color: #dedede;
        content: "";
        position: absolute;
        height: 1px;
        left: -30px;
        right: -30px;
        width: 105%;
    }
}
.latest-property {
    border-bottom: 1px solid #ebebeb;
}
.best-property {
    .container {
        max-width: 1560px;
    }
}
.feat_property {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
    }
    .thumb {
        border-radius: 8px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        overflow: hidden;
        margin: 10px 10px 0 10px;
        position: relative;
        img {
            max-height: 238px;
            object-fit: cover;
            width: 100%;
        }
        .blog_tag {
            background-color: $red;
            border-radius: 3px;
            color: rgb(254, 254, 254);
            font-family: Arial;;
            font-size: 14px;
            height: 25px;
            line-height: 25px;
            left: 10px;
            position: absolute;
            text-align: center;
            top: 10px;
            width: 110px;
        }
        .thmb_cntnt {
            bottom: 0;
            left: 10px;
            position: absolute;
            right: 10px;
            top: 10px;
            ul.tag {
                position: absolute;
                left: 12px;
                top: 10px;
                li {
                    border-radius: 3px;
                    height: auto;
                    line-height: 25px;
                    text-align: center;
                    width: auto;
                    padding: 0 10px;
                    &:first-child {
                        background-color: rgb(62, 76, 102);
                    }
                    &:last-child {
                        background-color: $red;
                        margin-right: 0;
                    }
                    a {
                        font-size: 14px;
                        font-family: Arial;;
                        color: #fefefe;
                        line-height: 1.2;
                    }
                }
            }
            .icon {
                bottom: 15px;
                position: absolute;
                right: 20px;
                li {
                    border-radius: 8px;
                    background-color: rgb(15, 21, 31);
                    height: 35px;
                    line-height: 35px;
                    margin-right: 5px;
                    opacity: 0.502;
                    text-align: center;
                    width: 35px;
                    a {
                        color: #ffffff;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            a.fp_price {
                bottom: 15px;
                font-size: 22px;
                font-family: Arial;;
                color: #ffffff;
                font-weight: bold;
                left: 10px;
                line-height: 1.2;
                position: absolute;
                small {
                    font-size: 14px;
                }
            }
        }
    }
    .details {
        .tc_content {
            &.estate-content {
                height: 225px;
            }
            &.article-content {
                height: 106.8px;
                display: flex;
                align-items: center;
            }
            ul.icon {
                float: right;
                margin-bottom: 0;
            }
            padding: 20px;
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
            }
            h4 {
                font-size: 18px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.2;
            }
            .prop_details {
                margin-bottom: 0;
                li {
                    margin-right: 30px;
                    a {
                        font-size: 14px;
                        font-family: Arial;;
                        color: $dark-grey;
                        line-height: 1.2;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        position: relative;
        .date_div{
            padding: 10px 20px !important;
        }
        .fp_footer {
            height: 50px;
            &.estate-footer {
                height: 81px;
                padding: 12px 20px;

                .fp_pdate {
                    margin-top: 0px !important;
                }
            }
            border-top: 1px solid #eeeeee;
            //display: inline-block;
            padding: 20px;
            position: relative;
            width: 100%;
            .fp_meta {
                margin-bottom: 0;
                li {
                    margin-right: 10px;
                    vertical-align: middle;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-size: 14px;
                        font-family: Arial;;
                        color: #777777;
                        line-height: 1.2;
                    }
                    &:nth-child(2) {
                        margin-right: 10px;
                    }
                }
            }
            .fp_pdate {
                font-size: 14px;
                font-family: Arial;;
                color: #777777;
                line-height: 1.2;
                display: inline-flex;
                div, a {
                    margin-right: 10px;
                    z-index: 9;
                }
            }
        }
    }
}
.for_blog.feat_property.home7 {
    .thumb {
        border-radius: 8px 8px 0 0;
        margin: 0;
    }

    .details {
        background-color: red !important;
    }
}
.for_blog.feat_property {
    .thumb {
        img {
            opacity: 1;
        }
    }
    .details {
        .fp_footer {
            .fp_meta {
                li {
                    &:nth-child(2) {
                        //margin-right: 30px;
                    }
                }
            }
        }
        .tc_content {
            .bpg_meta {
                li {
                    a {
                        font-size: 14px;
                        font-family: Arial;;
                        color: rgb(119, 119, 119);
                        line-height: 1.2;
                    }
                }
            }
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.714;
                margin-bottom: 0;
            }
        }
    }
}
.properti_city.home6 {
    .thumb {
        .thmb_cntnt {
            bottom: 0;
            left: 10px;
            position: absolute;
            right: 10px;
            top: 10px;
            left: 8px;
            position: absolute;
            top: 20px;
            ul.tag {
                position: absolute;
                left: 12px;
                top: 10px;
                li {
                    border-radius: 3px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    //width: 75px;
                    padding-left: 5px;
                    padding-right: 5px;
                    &:first-child {
                        background-color: rgb(62, 76, 102);
                    }
                    &:last-child {
                        background-color: $red;
                        margin-right: 0;
                    }
                    a {
                        font-size: 14px;
                        font-family: Arial;;
                        color: #fefefe;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
    .details {
        .tc_content {
            h4 {
                color: #ffffff;
                font-weight: 600;
            }
        }
        a.fp_price {
            color: #ffffff;
            font-weight: 600;
            font-size: 22px;
            font-family: Arial;;
            color: #ffffff;
            font-weight: bold;
            line-height: 2;
        }
    }
    ul.prop_details {
        li {
            a {
                color: #ffffff;
                font-weight: 300;
            }
        }
    }
    .overlay {
        .details {
            h4 {
                font-size: 16px;
                font-family: Arial;;
                color: #ffffff;
                font-weight: bold;
                line-height: 1.2;
            }
            padding-left: 20px;
            text-align: left;
        }
    }
}
.feat_property.home8 {
    ul.tag {
        li {
            border-radius: 3px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            width: 75px;
            &:first-child {
                background-color: rgb(62, 76, 102);
            }
            &:last-child {
                background-color: $red;
                margin-right: 0;
            }
            a {
                font-size: 14px;
                font-family: Arial;;
                color: #fefefe;
                line-height: 1.2;
            }
        }
        margin-bottom: 10px;
    }
    .icon {
        li {
            border-radius: 8px;
            background-color: rgb(247, 247, 247);
            height: 35px;
            line-height: 35px;
            margin-right: 5px;
            opacity: 1;
            text-align: center;
            width: 35px;
            a {
                color: $dark-grey;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .details {
        .tc_content {
            display: inline-block;
            .prop_details {
                margin-bottom: 10px;
            }
        }
    }
    overflow: visible;
    position: relative;
    &:before {
        background-color: rgba(255, 255, 255, .2);
        border-radius: 8px;
        bottom: -10px;
        content: "";
        position: absolute;
        left: -10px;
        right: -10px;
        top: -10px;
        z-index: -1;
    }
    a.fp_price {
        color: $red;
        float: left;
        font-size: 22px;
        font-family: Arial;;
        font-weight: bold;
        line-height: 1.2;
        margin-top: 0;
        position: relative;
    }
    &:hover {
        .thumb {
            .overlay {
                &:before {
                    background-color: rgba(25, 38, 117, .7);
                }
            }
        }
    }
    .wc_age_seat {
        li {
            a {
                color: #7e7e7e;
                font-size: 13px;
            }
        }
    }
}
.feat_property.list {
    &.agency {
        width: 100%;
        .thumb {
            img {
                width: 100%;
            }
        }
    }
    .dtls_headr {
        ul.tag {
            li {
                border-radius: 3px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                width: 75px;
                &:first-child {
                    background-color: rgb(62, 76, 102);
                }
                &:last-child {
                    background-color: $red;
                    margin-right: 0;
                }
            }
        }
        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        display: -ms-inline-flex;
        display: -o-inline-flex;
        display: inline-flex;
        a.fp_price {
            font-size: 22px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.2;
            position: absolute;
            right: 20px;
        }
    }
    ul.tag {
        li {
            a {
                font-size: 14px;
                font-family: Arial;;
                color: #fefefe;
                line-height: 1.2;
            }
        }
    }
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    .thumb {
        width: 45%;
        margin: 10px;
        position: relative;
    }
    .details {
        min-height: 170px; 
        width: 100%; 
        position: inherit; 
        .fp_footer {
            text-align: right;
            border: none;
            padding: 0 20px 10px;

        }
        .tc_content { 
            height: 83%;
            padding: 25px 20px 10px;
        }
    }
}
.feat_property.list.style2 {
    .thumb {
        height: auto;
        max-width: -webkit-fill-available;
    }
}
.feat_property.list.favorite_page {
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #ebebeb;
    padding: 0 0 30px;
    .thumb {
        height: 126px;
        max-width: 150px;
        margin: 0;
        position: relative;
    }
    .view_edit_delete_list {
        position: absolute;
        right: 0;
    }
}
.feat_property.list.favorite_page.style2 {
    border-bottom: none;
    margin: 10px 0;
    margin-bottom: 0;
    padding-bottom: 0;
    .details {
        .tc_content {
            p {
                margin-bottom: 0;
            }
        }
    }
}
.status_tag.badge {
    border-radius: 3px;
    background-color: $red;
    font-size: 14px;
    font-family: Arial;;
    color: rgb(254, 254, 254);
    height: 25px;
    line-height: 1.2;
    padding: 5px 8px;
    text-align: center;
}
.status_tag.badge2 {
    border-radius: 3px;
    background-color: rgb(57, 218, 138);
    font-size: 14px;
    font-family: Arial;;
    color: rgb(254, 254, 254);
    height: 25px;
    line-height: 20px;
    padding: 5px 8px;
    text-align: center;
}
.status_tag.badge3 {
    border-radius: 3px;
    background-color: rgb(253, 57, 122);
    font-size: 14px;
    font-family: Arial;;
    color: rgb(254, 254, 254);
    height: 25px;
    line-height: 20px;
    padding: 5px 8px;
    text-align: center;
}
.bs_carousel {
    .feat_property.home8 {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: all .6s ease-in-out;
        -moz-transition: all .6s ease-in-out;
        -o-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
    }
    .carousel-item.active {
        .feat_property.home8 {
            opacity: 1;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
        }
    }
}
.feat_property.home2 {
    .details {
        .tc_content {
            h5 {
                color: $dark-grey;
                font-family: Arial;;
                line-height: 1.333;
            }
        }
        .fp_footer {
            .fp_pdate {
                color: #192675;
            }
        }
    }
}
.feat_property.home3 {
    border: none;
    .thumb {
        padding: 0;
    }
    .details {
        .fp_footer {
            border-top: none;
        }
    }
}
.feat_property.home7 {
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    &:hover {
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    }
    .thumb {
        border-radius: 8px 8px 0 0;
        margin: 0;
    }
    .details {
        background-color: #ffffff;
        border-radius: 0 0 8px 8px;
    }
}
.feat_property.home7.style2 {
    border: none;
    overflow: visible;
    &:before {
        background-color: rgba(255,255,255, 0.1);
        border-radius: 8px;
        bottom: -5px;
        content: "";
        left: -5px;
        position: absolute;
        right: -5px;
        top: -5px;
    }
}
.feat_property.home7.style3 {
    box-shadow: none;
    .details {
        .tc_content {
            padding: 20px 0;
        }
    }
    .thumb {
        border-radius: 8px;
    }
}
.feat_property.home7.style4 {
    .thumb {
        border-radius: 8px;
        margin: 10px;
        max-height: 215px;
        .thmb_cntnt.style2 {
            bottom: auto;
        }
        .thmb_cntnt.style3 {
            top: auto;
        }
    }
    &:hover {
        .fp_single_item_slider.owl-carousel.owl-theme.owl-loaded {
            .owl-prev {
                left: 20px;
            }
            .owl-next {
                right: 20px;
            }
        }
    }
}
.feat_property.home7.agent {
    .thumb {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 10px;
        img {
            border-radius: 8px;
        }
    }
}
.feat_property.agent {
    .thumb {
        img {
            opacity: 1;
            object-fit: cover;
        }
    }
    .details {
        .fp_footer {
            .fp_pdate {
                margin-top: 6px;
            }
        }
    }
}
.feat_property.agency {
    .thumb {
        img {
            opacity: 1;
            object-fit: cover;
            margin: 0 auto;
        }
        background-color: #ffffff;
        border-bottom: 1px solid #ebebeb;
    }
    .details {
        .fp_footer {
            .fp_pdate {
                margin-top: 6px;
            }
        }
    }
}
.feat_property.list.agency {
    .thumb {
        border-bottom: none;
        height: 235px;
        width: 40%;
    }
    .details {
        border-left: 1px solid #ebebeb;
        padding-left: 10px;
    }
}
.fp_single_item_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        background-color: transparent;
        color: #ffffff;
        font-size: 16px;
        left: -30px;
        position: absolute;
        top: 40%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .owl-next {
        background-color: transparent;
        color: #ffffff;
        font-size: 16px;
        right: -30px;
        position: absolute;
        top: 40%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}
.fp_single_item_slider.owl-theme {
    .owl-nav {
        margin: 0;
    }
}
.properti_city_home8 {
    position: relative;
    margin-bottom: 30px;
    .details {
        p {
            margin-bottom: 0;
        }
    }
    .thumb {
        background-color: #f7f7f7;
        border-radius: 50%;
        height: 200px;
        margin: 0 auto 20px;
        position: relative;
        width: 200px;
        img {
            bottom: 0;
            left: 0;
            margin: 0 auto;
            right: 0;
            position: absolute;
        }
    }
}
.feature_property_slider.owl-carousel.owl-theme {
    .owl-nav {
        margin: 0;
    }
    .owl-dots {
        .owl-dot {
            background-color: transparent;
            margin-right: 15px;
            vertical-align: middle;
            span {
                background-color: #cccccc;
                border-radius: 50%;
                margin: 3px;
                width: 6px;
                height: 6px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &:hover {
                span {
                    background-color: #202020 !important;
                    border: 5px solid #e0e2e3;
                    height: 15px;
                    width: 15px;
                }
            }
        }
        .owl-dot.active {
            span {
                background-color: #202020 !important;
                border: 5px solid #e0e2e3;
                height: 15px;
                width: 15px;
            }
        }
    }
    overflow: visible;
}
.sidebar_feature_property_slider.owl-carousel.owl-theme {
    .owl-nav {
        margin: 0;
    }
    .owl-dots {
        .owl-dot {
            background-color: transparent;
            margin-right: 15px;
            vertical-align: middle;
            span {
                background-color: #cccccc;
                border-radius: 50%;
                margin: 3px;
                width: 6px;
                height: 6px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &:hover {
                span {
                    background-color: #202020 !important;
                    border: 5px solid #e0e2e3;
                    height: 15px;
                    width: 15px;
                }
            }
        }
        .owl-dot.active {
            span {
                background-color: #202020 !important;
                border: 5px solid #e0e2e3;
                height: 15px;
                width: 15px;
            }
        }
    }
}
.best_property_slider.owl-carousel.owl-theme {
    .owl-dots {
        .owl-dot {
            background-color: transparent;
            margin-right: 15px;
            vertical-align: middle;
            span {
                background-color: rgb(206, 52, 56);
                border-radius: 50%;
                margin: 3px;
                width: 6px;
                height: 6px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &:hover {
                span {
                    background-color: rgb(154, 34, 38) !important;
                    border: 5px solid rgb(255, 155, 158);
                    width: 15px;
                    height: 15px;
                }
            }
        }
        .owl-dot.active {
            span {
                background-color: rgb(154, 34, 38) !important;
                border: 5px solid rgb(255, 155, 158);
                width: 15px;
                height: 15px;
            }
        }
    }
    overflow: visible;
}
.best_property_slider.style2.owl-carousel.owl-theme {
    .owl-dots {
        .owl-dot {
            span {
                background-color: #cccccc;
                border-radius: 50%;
                margin: 3px;
                width: 6px;
                height: 6px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &:hover {
                span {
                    background-color: #202020 !important;
                    border: 5px solid #e0e2e3;
                    height: 15px;
                    width: 15px;
                }
            }
        }
        .owl-dot.active {
            span {
                background-color: #202020 !important;
                border: 5px solid #e0e2e3;
                height: 15px;
                width: 15px;
            }
        }
    }
}
.properti_city {
    margin-bottom: 30px;
    position: relative;
    &:hover {
        cursor: pointer;
        .thumb {
            img {
                -webkit-transform: scale(1.1) rotate(-1deg);
                -moz-transform: scale(1.1) rotate(-1deg);
                -o-transform: scale(1.1) rotate(-1deg);
                -ms-transform: scale(1.1) rotate(-1deg);
                transform: scale(1.1) rotate(-1deg);
            }
        }
    }
    .thumb {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 370px;
        img {
            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;
            transition: all 0.6s ease;
        }
    }
    .overlay {
        background: -moz-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, $dark-grey 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255,255,255,0.01)), color-stop(100%, rgba(29,41,62,1)));
        background: -webkit-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, $dark-grey 100%);
        background: -o-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, $dark-grey 100%);
        background: -ms-linear-gradient(270deg, rgba(255,255,255,0.01) 0%, $dark-grey 100%);
        background: linear-gradient(180deg, rgba(255,255,255,0.01) 0%, $dark-grey 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1d293e',GradientType=0 );
        border-radius: 0px 0px 10px 10px;
        bottom: 0;
        height: 244px;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        .details {
            bottom: 20px;
            left: 0;
            position: absolute;
            right: 0;
            h4 {
                color: #ffffff;
                font-size: 18px;
                font-family: Arial;;
                font-weight: bold;
                line-height: 1.2;
            }
            p {
                font-size: 14px;
                font-family: Arial;;
                color: #ffffff;
                line-height: 1.2;
            }
        }
    }
}
.properti_city.home5 {
    .overlay {
        .details {
            .left {
                background-color: #ffffff;
                border-radius: 17.5px;
                -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
                float: left;
                height: 35px;
                margin-left: 30px;
                padding: 0 30px;
                position: relative;
                text-align: center;
                &:before {
                    background-color: rgba(255,255,255, .15);
                    border-radius: 17.5px;
                    bottom: -5px;
                    content: "";
                    left: -5px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                }
                h4 {
                    font-size: 16px;
                    font-family: Arial;;
                    color: $dark-grey;
                    font-weight: bold;
                    line-height: 2.3;
                    margin: 0;
                }
            }
            p {
                float: right;
                margin-bottom: 0;
                margin-right: 30px;
                margin-top: 10px;
            }
            bottom: 30px;
        }
    }
}
.properti_city.style2 {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 10px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    }
    .details {
        padding: 20px 20px 10px;
        text-align: center;
        p {
            margin-bottom: 0;
        }
    }
}
.property_city_home6 {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    background-color: #ffffff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    }
    .thumb {
        position: relative;
        img {
            height: 150px;
            width: 170px;
        }
    }
    .details {
        padding: 50px 25px 0;
    }
}
.modern_apertment {
    border-radius: 3px;
    background-color: #ffffff;
    padding: 55px 15px 50px 45px;
    position: relative;
    .title {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
    }
    .subtitle {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 15px;
    }
    p {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.429;
        margin-bottom: 30px;
    }
    .booking_btn {
        border-radius: 8px;
        background-color: $red;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        font-size: 16px;
        font-family: Arial;;
        color: #ffffff;
        font-weight: bold;
        line-height: 35px;
        height: 50px;
        width: 170px;
    }
}
.properti_city.home7 {
    .details {
        padding-top: 20px;
        p {
            font-size: 14px;
            font-family: Arial;;
            color: rgba(119, 119, 119, 0.8);
            line-height: 1.2;
        }
    }
}
.search_smart_property {
    padding: 75px 0;
    position: relative;
    h2 {
        font-size: 30px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
    }
    p {
        font-size: 16px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.875;
        margin-bottom: 30px;
    }
    button.ssp_btn {
        background-color: #ffffff;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        border-radius: 8px;
        color: $dark-grey;
        font-size: 16px;
        font-family: Arial;;
        font-weight: bold;
        height: 60px;
        line-height: 1.2;
        width: 200px;
        &:hover {
            background-color: #3e4c66;
            color: #ffffff;
        }
    }
}
.icon_hvr_img_box {
    -webkit-background-size: cover;
    background-size: cover;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    cursor: pointer;
    height: 200px;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 30px 0;
    position: relative;
    text-align: center;
    &:before {
        background-color: #ffffff;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }
    &:hover {
        &:before {
            background-color: rgba(29, 41, 62, 0.8);
        }
        .details {
            h4 {
                color: #ffffff;
            }
            p {
                color: #ffffff;
            }
        }
        .overlay {
            .icon {
                color: #ffffff;
            }
        }
    }
    .icon {
        color: $red;
        font-size: 50px;
        margin-bottom: 5px;
        position: relative;
        text-align: center;
    }
    .details {
        position: relative;
    }
}
.single_property_title {
    position: relative;
    .upload_btn {
        border-radius: 8px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        color: rgb(72, 72, 72);
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-family: Arial;;
        height: 45px;
        line-height: 2.1;
        outline: none;
        position: relative;
        text-align: center;
        width: 150px;
        span {
            font-size: 20px;
            padding-right: 5px;
        }
    }
}
.listing_single_row {
    margin-bottom: 25px;
    margin-top: -100px;
}
.single_property_title.multicolor_style {
    h2 {
        color: #ffffff;
    }
    p {
        color: #ffffff;
    }
}
.single_property_social_share.multicolor_style {
    h2 {
        color: #ffffff;
        small {
            color: #ffffff;
        }
    }
}
.single_property_social_share {
    h2 {
        font-size: 30px;
        font-family: Arial;;
        color: rgb(72, 72, 72);
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 0;
        small {
            font-size: 18px;
        }
    }
}
.single_property_social_share.style2 {
    position: absolute;
    right: 30px;
}
.spss {
    ul {
        li {
            border-radius: 8px;
            background-color: rgb(0, 106, 112);
            height: 35px;
            line-height: 35px;
            text-align: center;
            width: 35px;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            &:hover {
                background-color: rgb(62, 76, 102);
                cursor: pointer;
                a {
                    color: $red;
                }
            }
            a {
                font-size: 24px;
                color: #ffffff;
            }
        }
    }
}
.spss.style2 {
    ul {
        li {
            background-color: #ffffff;
            a {
                color: $dark-grey;
                transition: 0.15s;
            }
            &:hover {
                a {
                    color: $red;
                }
            }

            &.favorite-element {
                span.fa-heart { 
                    color: $red !important;
                }
            }
        }
    }
}
.spls_style_two {
    position: relative;
    .overlay {
        bottom: 0;
        border-radius: 8px;
        background-color: rgba(29, 41, 62, 0.8);
        cursor: pointer;
        left: 0;
        margin-bottom: 0;
        padding-top: 35%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        .title {
            font-size: 26px;
            font-family: Arial;;
            color: rgb(255, 255, 255);
            font-weight: bold;
            line-height: 1;
        }
    }
    img {
        border-radius: 8px;
    }
    .walkthrough_button {
        display: inline-block;
        padding: 10px 5px 10px 5px;
        background: #FFFFFF;
        border-radius: 11px;
        position: absolute;
        z-index: 100;
        left: .5rem;
        bottom: .5rem;
        border-style: solid;
        border-width: 1px;
        font-weight: 600;
        font-size: 14px;
        text-decoration: none;
        color: #232323 !important;

        .disable{
            pointer-events: none;
        }
    }
    
    .walkthrough_button_wrapper {
        color: #232323 !important;
        opacity: 80%;
    }

    .walkthrough_button_wrapper:hover {
        color: #232323 !important;
        opacity: 100%;
    }

    .walkthrough_button:hover {
        .walkthrough_icon {
            color: #db1c1f !important;
        }
    }
    
    .walkthrough_icon {
      display: block;
      text-align: center;
      font-size: 16px;
    }
}
.faq_according {
    .accordion {
        .card.floor_plan {
            outline: none;
            .card-header.active {
                .btn-link {
                    color: $dark-grey;
                }
                border: 1px solid #ebebeb;
            }
            .card-header {
                .btn-link {
                    ul {
                        li {
                            display: -webkit-inline-flex;
                            display: -moz-inline-flex;
                            display: -ms-inline-flex;
                            display: -o-inline-flex;
                            display: inline-flex;
                        }
                    }
                }
            }
        }
        .card {
            border: none;
            .card-header {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px !important;
                height: 52px;
                margin-bottom: 20px;
                padding: 10px 15px;
                .btn-link {
                    font-size: 16px;
                    font-family: Arial;;
                    color: $dark-grey;
                    display: block;
                    font-weight: bold;
                    padding: 0;
                    &:active {
                        text-decoration: none;
                    }
                    &:focus {
                        text-decoration: none;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                &::before {
                    font-family: 'FontAwesome';
                    color: $dark-grey;
                    content: "\f107";
                    float: right;
                    font-weight: lighter;
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                    -o-transition: all 0.2s ease-in;
                    transition: all 0.2s ease-in;
                    margin-right: 10px;
                    margin-top: 0;
                }
            }
            .card-header.active {
                background-color: #ffffff;
                border: 1px solid $red;
                .btn-link {
                    color: $red;
                }
                &::before {
                    color: $red;
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                    -o-transition: all 0.2s ease-in;
                    transition: all 0.2s ease-in;
                }
            }
            .card-body {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.714;
                padding: 10px 0 30px;
                p {
                    font-size: 14px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.714;
                }
            }
        }
    }
    .panel {
        .panel-body {
            padding: 15px 0;
            p {
                font-size: 15px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                line-height: 1.6;
            }
            h4 {
                font-size: 18px;
                font-family: Arial;;
                color: rgb(59, 59, 59);
                line-height: 1.333;
            }
        }
    }
    .panel-heading {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        height: 50px;
        margin-bottom: 20px;
        .panel-title {
            font-family: Arial;;
            font-size: 16px;
            font-weight: bold;
            line-height: 3;
            a {
                padding: 0 20px;
            }
        }
    }
}
.single_page_listing_tab {
    position: relative;
    .nav-tabs {
        border: none;
        margin-top: 45px;
        position: absolute;
        z-index: 9;
        .nav-item {
            border: none;
            margin-right: 10px;
            .nav-link {
                border-radius: 8px;
                background-color: rgba(15, 21, 31, 0.502);
                border: 1px solid transparent;
                font-size: 20px;
            }
        }
        .nav-link.active {
            border: none;
        }
    }
}
.single_page_listing_style {
    position: relative;
}
.property_video {
    padding: 30px;
    position: relative;
    .thumb {
        border-radius: 8px;
        overflow: hidden;
        .pro_img {
            min-height: 390px;
        }
        .overlay_icon {
            a {
                background-color: $red;
                border-radius: 50%;
                color: #ffffff;
                font-size: 22px;
                height: 70px;
                left: 0;
                line-height: 70px;
                margin: 0 auto;
                position: absolute;
                right: 0;
                text-align: center;
                top: 40%;
                width: 70px;
                z-index: 99;
                &:after {
                    background-color: rgba(255, 90, 95, 0.2);
                    border-radius: 100%;
                    content: "";
                    height: 90px;
                    left: -10px;
                    position: absolute;
                    top: -10px;
                    width: 90px;
                    z-index: -1;
                }
                .map_img_icon {
                    width: 23px;
                }
            }
        }
    }
}
.video_popup_btn {
    border-radius: 50%;
    display: block;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;
    text-align: center;
    width: 70px;
    animation: pulse-black 2s infinite;
    span {
        color: #ffffff;
        font-size: 22px;
    }
}
.video_popup_btn.red {
    background: rgba(255, 90, 95, 1);
    box-shadow: 0 0 0 0 rgba(255, 90, 95, 1);
    animation: pulse-red 2s infinite;
}
.lsd_list {
    margin-bottom: 40px;
    position: relative;
    li {
        border-radius: 8px;
        background-color: rgb(247, 247, 247);
        height: 40px;
        padding: 6px 25px;
        text-align: center;
        a {
            color: $dark-grey;
            font-size: 14px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            line-height: 1.2;
        }
        &:hover {
            a {
                color: $red;
            }
        }
    }
}
.listing_single_description {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px 8px 0 0;
    padding: 30px;
    word-wrap: break-word;
    h4 {
        font-weight: bold;
    }

    .card.card-body {
        border: none;
        padding: 0;
    }
}
.listing_single_description.style2 {
    border-radius: 0;
}
.listing_single_description2 {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(235, 235, 235);
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    position: relative;
    padding: 30px;
}
.white_goverlay {
    position: relative;
    &:before {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255, 0.2) 20%, rgba(255,255,255, 0.3) 30%, rgba(255,255,255, 0.8) 20%);
        bottom: 0;
        content: "";
        height: 90px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: 1;
    }
}
.white_goverlay.z-1 {
    &:before {
        z-index: -1;
    }
}
.additional_details {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(235, 235, 235);
    padding: 30px;
    position: relative;
    border-radius: 0 0 8px 8px;

    h4 {
        font-weight: bold;
    }

    ul {
        li {
            p {
                font-size: 14px;
                font-family: Arial;;
                color: rgb(72, 72, 72);
                line-height: 2.857;
                margin-bottom: 0;
                span {
                    font-size: 14px;
                    font-family: Arial;;
                    color: rgb(72, 72, 72);
                    font-weight: bold;
                    line-height: 2.857;
                }
            }
        }
    }
}
.estate_social {
    .spss.style2 ul li {
        background-color: transparent !important;
    }
}
.property_attachment_area {
    border-radius: 0 0 8px 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    padding: 30px;
    position: relative;
}
.walkscore_area {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    padding: 30px;
    position: relative;
    .more_info {
        color: rgb(72, 72, 72);
        font-size: 14px;
        font-family: Arial;;
        line-height: 1.714;
        text-decoration: underline;
    }
}
.iba_container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 25px;
}
.icon_box_area {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    .score {
        border-radius: 8px;
        background-color: rgb(247, 247, 247);
        height: 80px;
        line-height: 80px;
        width: 80px;
        text-align: center;
    }
    .details {
        padding: 15px 20px;
        h5 {
            font-size: 16px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 0;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            margin-bottom: 0;
        }
    }
}
.icon_box_area.style2 {
    .details {
        padding: 25px 20px 0;
    }
}
.whats_nearby {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    padding: 30px;
    position: relative;
}
.education_distance {
    display: grid;
    position: relative;
    h5 {
        font-size: 16px;
        font-family: Arial;;
        color: rgb(72, 72, 72);
        font-weight: bold;
        line-height: 2;
        margin-bottom: 0;
        span {
            color: #4585ff;
            font-size: 24px;
            margin-right: 5px;
        }
    }
}
.education_distance.style2 {
    h5 {
        span {
            color: #fb8855;
        }
    }
}
.education_distance.style3 {
    h5 {
        span {
            color: #92d060;
        }
    }
}
.single_line {
    .para {
        color: rgb(72, 72, 72);
        font-size: 14px;
        font-family: Arial;;
        float: left;
        line-height: 2.143;
        margin-bottom: 0;
        span {
            color: #767676;
        }
    }
    .review {
        float: right;
        margin-bottom: 0;
        li {
            margin-right: 3px;
            &:nth-child(5) {
                margin-right: 25px;
                a {
                    color: #e1e1e1;
                }
            }
            a {
                font-size: 14px;
                color: rgb(188, 197, 42);
                line-height: 2.143;
            }
        }
        span.total_rive_count {
            font-size: 14px;
            font-family: Arial;;
            color: rgb(118, 118, 118);
            line-height: 2.143;
        }
    }
}
.sticky_heading {
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs {
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs-container {
    background-color: #ffffff;
    display: inline-block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    li {
        &:active {
            border-bottom: 1px solid $red;
        }
        &:hover {
            border-bottom: 1px solid $red;
        }
        &:visited {
            border-bottom: 1px solid $red;
            border-bottom: 1px solid $red;
            a {
                color: $red;
            }
        }
        a {
            font-size: 16px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            font-weight: bold;
        }
    }
    li.active {
        border-bottom: 1px solid $red;
        a {
            color: $red;
        }
    }
}
.sticky-nav-tabs-container--top-first {
    padding-top: 13px;
    position: fixed;
    top: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    text-align: center;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs-container--top-second {
    background-color: #ffffff;
    padding-top: 13px;
    height: 60px;
    position: fixed;
    text-align: center;
    top: 0;
    z-index: 9;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tab {
    color: #221f1f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 15px 25px 18px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.sticky-nav-tab-slider {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 6px;
    background-color: #66B1F1;
    -webkit-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    transition: left 0.3s ease;
}
.listing_single_property_compare {
    position: relative;
    text-align: center;
    ul {
        li.list-inline-item {
            margin-right: 25px;
            text-align: center;
        }
        li {
            .icon {
                background-color: #e3e3e3;
                border-radius: 50%;
                height: 50px;
                line-height: 50px;
                margin: 0 auto 15px;
                width: 50px;
                span {
                    color: $dark-grey;
                }
            }
            p {
                font-size: 14px;
                font-family: Arial;;
                color: rgb(72, 72, 72);
                line-height: 1.857;
                margin-bottom: 0;
            }
        }
    }
}
.why_chose_us {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 50px 41px 30px 41px;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        .icon {
            background-color: $red;
            span {
                color: #ffffff;
            }
        }
    }
    .icon {
        background-color: #ffe8e9;
        border-radius: 50%;
        height: 130px;
        line-height: 130px;
        margin: 0 auto;
        width: 130px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        span {
            color: $red;
            font-size: 68px;
            line-height: 130px;
        }
    }
    .details {
        margin-top: 50px;
        h4 {
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            line-height: 1.714;
        }
    }
}
.why_chose_us.style2 {
    border: 1px solid #ebebeb;
    overflow: hidden;
    .icon {
        background-color: #f7f7f7;
    }
    &:hover {
        &:before {
            background-color: $red;
            bottom: 0;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
        }
        .icon {
            background-image: -moz-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
            background-image: -webkit-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
            background-image: -ms-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
        }
    }
}
.features_row {
    border-radius: 8px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    margin-bottom: 70px;
    margin-top: -220px;
}
.why_chose_us.home6 {
    border-radius: 0;
    border-right: 1px solid #ebebeb;
    margin-bottom: 0;
    overflow: hidden;
    padding: 30px 20px;
    box-shadow: none;
    &:hover {
        box-shadow: none;
        .icon {
            background-color: $light-grey;
            span {
                color: #ffffff;
            }
        }
        .details {
            h4 {
                color: $light-grey;
            }
        }
    }
    .icon {
        background-color: $red;
        border-radius: 50%;
        height: 100px;
        margin: 0 auto;
        width: 100px;
        span {
            color: #ffffff;
            font-size: 50px;
            line-height: 100px;
        }
    }
    .details {
        margin-top: 30px;
    }
}
.why_chose_us.home6.bdrrn {
    border-right: none;
}
.why_chose_us.home7 {
    padding: 25px 50px 0 41px;
    &:hover {
        box-shadow: none;
    }
    .icon {
        background-image: -moz-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        background-image: -webkit-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        background-image: -ms-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        height: 110px;
        margin: 0 auto;
        width: 110px;
        position: relative;
        &:before {
            background-color: rgba(254, 59, 117, 0.1);
            border-radius: 50%;
            bottom: -10px;
            content: "";
            left: -10px;
            position: absolute;
            right: -10px;
            top: -10px;
        }
        span {
            color: #ffffff;
            font-size: 50px;
            line-height: 110px;
        }
    }
}
.why_chose_us.home10 {
    &:hover {
        background-color: $red;
        .icon {
            background-color: #ffffff;
            span {
                color: $red;
            }
        }
        .details {
            h4 {
                color: #ffffff;
            }
            p {
                color: #ffffff;
            }
        }
    }
}
.our-testimonial {
    background-color: #1d293e;
    &:before {
        background-image: url("../images/background/2.jpg");
        bottom: 0;
        content: "";
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.our-testimonial.home5 {
    background-color: #232733;
    &:before {
        background-image: url("../images/background/6.jpg");
        bottom: 0;
        content: "";
        left: 0;
        opacity: .149;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.you-looking-for {
    background-color: rgb(8, 15, 29);
    height: 365px;
    &:before {
        background-image: url("/images/background/property-type.jpg");
        @media (max-width: 1000px) {
            background-image: url("/images/background/property-type-1000.jpg");
        }
        @media (max-width: 450px) {
            background-image: none;
            background-color: #fff;
        }
        bottom: 0;
        content: "";
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.modern-apertment {
    background-color: rgb(29, 41, 62);
    height: 549px;
    &:before {
        background-image: url("/images/background/9.jpg");
        bottom: 0;
        content: "";
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.our-hot-offer {
    background-image: url("../images/background/11.jpg");
    height: 581px;
    &:before {
        background-image: -moz-linear-gradient( 0deg, rgb(60,4,135) 0%, rgb(76,21,148) 100%);
        background-image: -webkit-linear-gradient( 0deg, rgb(60,4,135) 0%, rgb(76,21,148) 100%);
        background-image: -ms-linear-gradient( 0deg, rgb(60,4,135) 0%, rgb(76,21,148) 100%);
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.851;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.our_hotoffer {
    position: relative;
    margin-top: 120px;
    h2 {
        font-size: 30px;
        font-family: Arial;;
        color: #ffffff;
        font-weight: bold;
        line-height: 1.2;
    }
    p {
        font-size: 16px;
        font-family: Arial;;
        color: #ffffff;
        line-height: 1.2;
        margin-bottom: 25px;
    }
    button.btn.ht_offer {
        border-radius: 8px;
        background-image: -moz-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        background-image: -webkit-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        background-image: -ms-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        color: #ffffff;
        font-size: 16px;
        font-family: Arial;;
        font-weight: bold;
        height: 50px;
        line-height: 1.2;
        width: 170px;
    }
}
.View_All {
    position: absolute;
    left: 443.484px;
    top: 3835.4px;
    z-index: 419;
}
.our-testimonials {
    border-bottom: 1px solid #ebebeb;
}
.testimonial_grid {
    position: relative;
    text-align: center;
    .thumb {
        position: relative;
        img {
            border: 10px solid rgba(255, 255, 255, 0.1);
            border-radius: 50%;
            display: inline;
            height: 114px;
            width: 114px;
        }
    }
    .details {
        padding: 20px 40px;
        h4 {
            color: #ffffff;
            margin-bottom: 0;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: #ffffff;
            line-height: 1.429;
            margin-bottom: 0;
        }
    }
}
.testimonial_grid.style2 {
    padding: 40px 0 0;
    .details {
        h4 {
            color: $dark-grey;
        }
        p {
            color: $dark-grey;
        }
    }
    .thumb {
        position: relative;
        .tg_quote {
            border-radius: 50%;
            background-image: -moz-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
            background-image: -webkit-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
            background-image: -ms-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
            -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            height: 43px;
            left: 0;
            line-height: 43px;
            margin: 0 auto;
            position: absolute;
            right: -100px;
            top: 70px;
            width: 43px;
            span {
                color: #ffffff;
            }
        }
        img {
            border: 5px solid #ffffff;
            -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        }
    }
}
.testimonial_grid_slider.owl-carousel.owl-theme {
    .owl-nav {
        margin: 0;
    }
    .owl-dots {
        .owl-dot {
            background-color: transparent;
            margin-right: 15px;
            vertical-align: middle;
            span {
                background-color: #ffffff;
                border-radius: 50%;
                margin: 3px;
                width: 6px;
                height: 6px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &:hover {
                span {
                    background-color: #ffffff !important;
                    border: 5px solid rgb(92, 106, 124);
                    height: 15px;
                    width: 15px;
                }
            }
        }
        .owl-dot.active {
            span {
                background-color: #ffffff !important;
                border: 5px solid rgb(92, 106, 124);
                height: 15px;
                width: 15px;
            }
        }
    }
}
.testimonial_grid_slider.style2.owl-carousel.owl-theme {
    .owl-dots {
        .owl-dot {
            span {
                background-color: #dedede;
            }
            &:hover {
                span {
                    background-color: #24324a !important;
                    border: 5px solid #dedede;
                }
            }
        }
        .owl-dot.active {
            span {
                background-color: #24324a !important;
                border: 5px solid #dedede;
            }
        }
    }
}
.start_partner {
    h2 {
        color: #ffffff;
        line-height: 1.2;
    }
    p {
        color: #ffffff;
        margin-bottom: 0;
        line-height: 1.2;
    }
}
.parner_reg_btn {
    a.btn {
        border-radius: 8px;
        background-color: rgb(255, 120, 124);
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        height: 60px;
        line-height: 50px;
        width: 200px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
            background-color: #ffffff;
            color: $red;
        }
        &:active {
            background-color: #ffffff;
            color: $red;
        }
        &:focus {
            background-color: #ffffff;
            color: $red;
        }
    }
}
.parner_reg_btn.home5 {
    a.btn {
        background-color: #ffffff;
        color: #fa7a44;
        &:hover {
            background-color: #fa7a44;
            border: 1px solid #ffffff;
            color: #ffffff;
        }
        &:active {
            background-color: #fa7a44;
            border: 1px solid #ffffff;
            color: #ffffff;
        }
        &:focus {
            background-color: #fa7a44;
            border: 1px solid #ffffff;
            color: #ffffff;
        }
    }
}
.parner_reg_btn.home7 {
    a.btn {
        background-image: -moz-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        background-image: -webkit-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        background-image: -ms-linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        color: #ffffff;
    }
}
.container-fluid.style2 {
    max-width: 1720px;
    width: 90%;
}
.courses-list {
    background-color: #f9fafc;
}
.my_course_content {
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    position: relative;
    padding: 20px 0 0;
}
.courses_list_heading {
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    padding: 20px;
}
.my_course_content_header {
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    padding: 20px;
    box-shadow: none;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    padding: 20px 20px 30px;
}
.my_setting_content {
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    position: relative;
}
.my_setting_content_header {
    border: 1px solid rgb(235, 235, 235);
    padding: 30px;
}
.my_profile_setting_input.form-group {
    margin-bottom: 30px;
}
.my_profile_setting_input {
    label {
        font-size: 16px;
        font-family: Arial;;
        color: rgb(72, 72, 72);
        font-weight: bold;
        line-height: 1.5;
    }
    .form-control {
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        height: 52px;
        padding-left: 20px;
        &::placeholder {
            font-size: 14px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            line-height: 1.2;
        }
        &:focus {
            outline: none;
        }
    }
    .btn1 {
        background-color: rgba(255, 90, 95, 0);
        border: 2px solid $red;
        border-radius: 8px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        font-size: 16px;
        font-family: Arial;;
        color: $red;
        font-weight: bold;
        line-height: 1.2;
        height: 50px;
        margin-right: 30px;
        width: 200px;
        &:hover {
            background-color: $red;
            color: #ffffff;
        }
    }
    .btn2 {
        background-color: $red;
        border: 2px solid $red;
        border-radius: 8px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        font-size: 16px;
        font-family: Arial;;
        color: rgb(255, 255, 255);
        font-weight: bold;
        line-height: 1.2;
        height: 50px;
        width: 200px;
        &:hover {
            color: $red;
            background-color: #ffffff;
            border: 2px solid $red;
        }
    }
    .btn3 {
        background-color: rgb(29, 41, 62);
        border: 2px solid rgb(29, 41, 62);
        border-radius: 8px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        font-size: 16px;
        font-family: Arial;;
        color: rgb(255, 255, 255);
        font-weight: bold;
        line-height: 1.2;
        height: 50px;
        width: 200px;
        &:hover {
            border: 2px solid rgb(29, 41, 62);
            color: rgb(29, 41, 62);
            background-color: #ffffff;
        }
    }
}
.my_profile_setting_textarea {
    label {
        font-size: 16px;
        font-family: Arial;;
        color: rgb(72, 72, 72);
        font-weight: bold;
        line-height: 1.5;
    }
    margin-bottom: 25px;
}
.my_profile_setting_input2 {
    label {
        font-size: 14px;
        font-family: Arial;
        color: rgb(10, 10, 10);
        line-height: 1.2;
    }
}
.admore_btn {
    border-radius: 8px;
    background-color: rgb(236, 236, 236);
    font-size: 16px;
    font-family: Arial;;
    color: rgb(72, 72, 72);
    font-weight: bold;
    line-height: 1.2;
    height: 50px;
    width: 160px;
}
.my_setting_savechange_btn {
    border-radius: 5px;
    background-color: rgb(36, 65, 231);
    font-size: 15px;
    font-family: Arial;
    color: #ffffff;
    line-height: 1.2;
    height: 55px;
    width: 150px;
}
.view_edit_delete_list {
    li {
        &:hover {
            cursor: pointer;
        }
        background-color: rgb(247, 247, 247);
        border-radius: 8px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        width: 45px;
        a {
            span {
                color: $red;
                font-size: 20px;
            }
        }
    }
}
.courses_list_heading.style2 {
    box-shadow: none;
}
.courses_container {
    position: relative;
    border-radius: 0 0 5px 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.09);
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.09);
    .courses_list_content {
        border: 1px solid rgb(235, 235, 235);
        padding: 20px;
        position: relative;
        &:hover {
            background-color: #f9fafc;
        }
    }
}
.courses_container.style2 {
    box-shadow: none;
}
.courses_list_content {
    .feat_property.list {
        border: none;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        margin-bottom: 0;
        background-color: transparent;
        &:hover {
            box-shadow: none;
            .details {
                .tc_content {
                    h5 {
                        color: $red;
                    }
                }
            }
        }
        .details {
            .tc_content {
                p {
                    font-size: 14px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.714;
                }
                padding: 0px 0px 20px 15px;
            }
            .fp_footer {
                ul.tc_review {
                    margin-bottom: 0;
                    margin-right: 20px;
                    li {
                        margin-right: 4px;
                        a {
                            font-size: 13px;
                            font-family: "FontAwesome";
                            color: #bcc52a;
                            line-height: 1.2;
                        }
                        &:last-child {
                            a {
                                color: #7e7e7e;
                            }
                        }
                    }
                }
                padding: 0 20px;
                border: none;
            }
        }
        .thumb {
            border-radius: 5px;
            overflow: hidden;
            width: 450px;
        }
    }
}
.original_price {
    font-size: 13px;
    font-family: Arial;;
    color: rgb(126, 126, 126);
    line-height: 1.2;
}
.hvr_img_box_container {
    -webkit-background-size: cover;
    background-size: cover;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 35px;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 0;
    &:before {
        background-color: #ffffff;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 1;
    }
    .hvr_img_box {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    &:hover {
        .hvr_img_box {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -o-transform: scale(1.2);
            transform: scale(1.2);
        }
        &:before {
            background-color: rgba(0,0,0, .5);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        .overlay {
            .details {
                h5 {
                    color: #ffffff;
                }
                p {
                    color: #ffffff;
                }
                a {
                    color: #ffffff;
                }
            }
        }
    }
    .overlay {
        .details {
            display: block;
            height: 250px;
            padding: 60px 50px;
            position: relative;
            width: 300px;
            z-index: 9;
            h5 {
                color: $dark-grey;
                font-family: "Nunito SemiBold";
                font-size: 18px;
                margin-bottom: 20px;
                text-transform: uppercase;
            }
            p {
                color: #7e7e7e;
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 20px;
            }
            a {
                color: #192675;
                font-size: 14px;
                line-height: 24px;
                span.span {
                    padding-left: 15px;
                }
            }
        }
    }
}
.hvr_img_box {
    position: relative;
    padding: 60px 50px;
}
.hvr_img_box.imgs {
    height: 100%;
    position: absolute;
    width: 100%;
}
.pjc_all_btn {
    margin-top: 25px;
    a.btn {
        border-radius: 4px;
        line-height: 45px;
        padding: 5px 40px;
    }
}
.pjc_all_btn.home5 {
    a.btn {
        border: 1px solid #262626;
        color: #262626;
        &:hover {
            border: 1px solid $red;
            color: #ffffff;
        }
    }
}
.funfact_one {
    .icon {
        color: #ffffff;
        font-size: 70px;
        line-height: initial;
    }
    .details {
        ul {
            margin-bottom: 0;
            li {
                color: #ffffff;
                font-family: Arial;
                font-size: 30px;
                font-weight: bold;
                margin-right: -2px;
                &:last-child {
                    margin: 0;
                }
            }
        }
        .timer {
            color: #ffffff;
            font-family: Arial;
            font-size: 30px;
            font-weight: bold;
            margin-right: -2px;
        }
        h5 {
            color: #ffffff;
            font-size: 18px;
            margin-bottom: 0;
        }
    }
    .timer {
        color: #ffffff;
        font-size: 25px;
    }
    p {
        margin-bottom: 0;
    }
}
.funfact_one.home6 {
    text-align: center;
    .icon {
        color: #1fbda5;
        font-size: 60px;
        line-height: normal;
        margin-bottom: 30px;
        margin-top: 6px;
    }
    .timer {
        color: #252834;
        font-size: 50px;
        font-weight: bold;
        line-height: normal;
    }
    &:hover {
        .icon {
            color: #252834;
        }
    }
}
.brdr_left_right {
    position: relative;
    &:before {
        background-color: #383838;
        bottom: 0;
        content: "";
        height: 40px;
        left: -30px;
        position: absolute;
        top: 25px;
        width: 1px;
    }
}
.brdr_left_right.home6 {
    &:before {
        left: -15px;
    }
}
.brdr_left_right.home3 {
    &:before {
        background-color: #d5d6d8;
    }
}
.footer_middle_area.home5 {
    background-color: #232733;
}
.footer_middle_area.home6 {
    background-color: #353535;
    position: relative;
}
.footer_social_widget {
    position: relative;
    li {
        padding-right: 10px;
        a {
            color: #8a99b3;
            font-size: 16px;
            font-family: "FontAwesome";
            line-height: 1.2;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
        }
        &:hover {
            a {
                color: #ffffff;
            }
        }
    }
    h4 {
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 25px;
    }
}
.footer_social_widget.home3 {
    li {
        a {
            color: #777777;
        }
        &:hover {
            a {
                color: $red;
            }
        }
    }
    h4 {
        color: $dark-grey;
        font-weight: bold;
        margin-bottom: 25px;
    }
}
.footer_mailchimp_form {
    position: relative;
    .col-auto {
        input.form-control {
            background-color: #354765;
            border: 1px solid #354765;
            border-radius: 25px;
            color: #ffffff;
            height: 50px;
            padding-left: 30px;
            width: 195px;
            &:hover {
                box-shadow: none;
                outline: none;
            }
            &:focus {
                box-shadow: none;
                outline: none;
            }
            &:active {
                box-shadow: none;
                outline: none;
            }
            &::placeholder {
                color: #8a99b3;
            }
            &:-ms-input-placeholder {
                color: #8a99b3;
            }
            &::-ms-input-placeholder {
                color: #8a99b3;
            }
        }
        button {
            background-color: #354765;
            border: 1px solid #354765;
            border-radius: 50%;
            height: 50px;
            width: 50px;
        }
    }
}
.footer_mailchimp_form.home3 {
    .col-auto {
        input.form-control {
            background-color: #e8eef2;
            border: 1px solid #e8eef2;
            color: #777777;
        }
        button {
            background-color: #3e4c66;
            border: 1px solid #3e4c66;
            color: #ffffff;
        }
    }
}
.footer_mailchimp_form.home5 {
    .col-auto {
        input.form-control {
            background-color: #292d39;
            border: 1px solid #292d39;
        }
        button {
            background-image: -moz-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
            background-image: -webkit-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
            background-image: -ms-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
            border-radius: 50%;
            height: 50px;
            width: 50px;
        }
    }
}
.footer_mailchimp_form.home6 {
    .col-auto {
        input.form-control {
            background-color: #ffffff;
            -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            border: 1px solid #ffffff;
            color: $dark-grey;
            &:hover {
                -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            }
            &:focus {
                -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            }
            &:active {
                -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
                box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            }
        }
        button {
            background-color: #ffffff;
            border: 1px solid #ffffff;
            -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            color: $dark-grey;
        }
    }
}
.footer_middle_area.home3 {
    .footer_social_widget {
        p {
            color: #969ca9;
        }
        li {
            a {
                color: #969696;
            }
            &:hover {
                a {
                    color: $dark-grey;
                }
            }
        }
    }
    background-color: #ffffff;
}
.footer_middle_area.home4 {
    .footer_social_widget {
        li {
            &:hover {
                a {
                    color: $dark-grey;
                }
            }
        }
    }
    border-top: 1px solid #f0f0f0;
    background-color: #ffffff;
}
.footer_social_widget.home8 {
    li {
        a {
            color: #969696;
        }
        &:hover {
            a {
                color: $dark-grey;
            }
        }
    }
}
.footer_one {
    padding: 50px 0;
    background-color: #434343;
    text-color: $light-grey;
    .nav-item {
        .nav-link.active {
            color:#FFFFFF !important;
            font-size: 17px !important;
            font-weight: bold;
            background-color: #434343 !important;
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            border-bottom: 2px solid #ffff !important;
        }
        .nav-link{
            color: #c5c9d0 !important;;
            border: none !important;;
        }

    }
}
.footer_middle_area {
    background-color: #1d293e;
}
.footer_bottom_area {
    background-color: $dark-grey;
}
.copyright-widget {
    p {
        font-size: 11px;
        font-family: Arial;;
        color: #8a99b3;
        line-height: 2.143;
        margin-bottom: 0;
    }
}
.copyright-widget.home3 {
    p {
        color: #777777;
    }
}
.copyright-widget.home6 {
    p {
        color: #c5c9d0;
    }
}
.footer_one.home3 {
    background-color: #ffffff;
    border-bottom: 1px solid #e8eef2;
    .footer_qlink_widget {
        li {
            &:hover {
                a {
                    color: #051925;
                }
            }
        }
    }
    .footer_about_widget {
        li {
            &:hover {
                a {
                    color: #051925;
                }
            }
        }
    }
    .footer_contact_widget {
        li {
            &:hover {
                a {
                    color: #051925;
                }
            }
        }
    }
    .footer_apps_widget {
        .mailchimp_form {
            button {
                background-color: #1c327e;
            }
        }
    }
}
.footer_bottom_area.home3 {
    background-color: #ebeef4;
    .copyright-widget {
        p {
            color: #969696;
        }
    }
    .footer_menu {
        ul {
            li {
                .bootstrap-select {
                    >.dropdown-toggle {
                        background-color: #1f2532;
                    }
                }
            }
        }
    }
}
.footer_one.home4 {
    background-color: #f9fafc;
    .footer_qlink_widget {
        li {
            &:hover {
                a {
                    color: #051925;
                }
            }
        }
    }
    .footer_about_widget {
        li {
            &:hover {
                a {
                    color: #051925;
                }
            }
        }
    }
    .footer_contact_widget {
        li {
            &:hover {
                a {
                    color: #051925;
                }
            }
        }
    }
}
.footer_bottom_area.home4 {
    .copyright-widget {
        p {
            color: #969696;
        }
    }
    background-color: #fafafa;
    .footer_menu {
        ul {
            li {
                .bootstrap-select {
                    >.dropdown-toggle {
                        background-color: #ededed;
                        border-radius: 22.5px;
                        color: #8b8b8b;
                    }
                }
            }
        }
    }
}
.footer_one.home5 {
    background-color: #232733;
    border-bottom: 1px solid rgba(255,255,255, 0.102);
}
.footer_one.home8 {
    background-color: transparent;
    &:before {
        background-image: url(../images/background/3.png);
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center center;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: -75px;
        z-index: -1;
    }
    &:after {
        background-color: #e2e9f0;
        bottom: 80px;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
    }
}
.footer_about_widget {
    p {
        font-size: 14px;
        font-family: Arial;;
        color: #8a99b3;
        line-height: 2.143;
        margin-bottom: 0;
    }
    h4 {
        color: #434343;
        font-weight: bold;
        margin-bottom: 25px;
    }
    li {
        a {
            font-size: 14px;
            font-family: Arial;;
            color: #8a99b3;
            line-height: 2.429;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
        }
        &:hover {
            a {
                color: #ffffff;
                padding-left: 8px;
            }
        }
    }
}
.footer_about_widget.home5 {
    p {
        color: #98a2aa;
    }
}
.footer_qlink_widget.home5 {
    li {
        a {
            color: #98a2aa;
        }
    }
    h4 {
        color: #ffffff;
    }
}
.footer_contact_widget.home5 {
    li {
        a {
            color: #98a2aa;
        }
    }
}
.footer_social_widget.home5 {
    li {
        a {
            color: #98a2aa;
        }
    }
}
.footer_about_widget.home6 {
    p {
        color: $dark-grey;
    }
    h4 {
        font-family: Arial;;
        color: #434343;
        font-weight: bold;
        line-height: 1.333;
        font-size: 24px;
    }
}
.footer_qlink_widget.home6 {
    li {
        text-align:left;
        a {
            color: $light-grey;
            font-size: 14px;
        }
        &:hover {
            a {
                text-decoration: underline;
            }
        }
    }
    h4 {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.333;
    }
}
.footer_contact_widget.home6 {
    li {
        a {
            color: #777777;
        }
    }
    h4 {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.333;
    }
}
.footer_social_widget.home6 {
    font-size: 20px;

    li {
        a {
            color: $light-grey;
            font-size: 20px; 
            i{
                font-size: 30px;
            }
        }
    }
    h4 {
        font-family: Arial;;
        color: $light-grey;
        font-weight: bold;
        line-height: 1.333;
    }
}
.footer_about_widget.home3 {
    p {
        color: #777777;
    }
    h4 {
        color: $dark-grey;
        font-weight: bold;
        margin-bottom: 25px;
    }
    li {
        a {
            color: #777777;
        }
    }
}
.footer_contact_widget {
    li {
        padding-bottom: 0;
        a {
            font-size: 14px;
            font-family: Arial;;
            color: #8a99b3;
            line-height: 2.429;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
        }
        &:hover {
            a {
                color: #ffffff;
                padding-left: 8px;
            }
        }
    }
    h4 {
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 25px;
    }
}
.footer_qlink_widget {
    padding-left: 70px;
    h4 {
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 25px;
    }
    li {
        a {
            font-size: 14px;
            font-family: Arial;;
            color: #8a99b3;
            line-height: 2.429;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
        }
        &:hover {
            a {
                padding-left: 8px;
            }
        }
    }
}
.footer_apps_widget {
    h4 {
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 25px;
    }
    .app_grid {
        .apple_btn {
            background-color: $dark-grey;
            border: none;
            border-radius: 4px;
            height: 75px;
            margin-bottom: 15px;
            margin-right: 0;
            width: 220px;
            &:hover {
                background-color: $red;
                span {
                    color: #ffffff;
                }
                span.subtitle {
                    color: #ffffff;
                }
            }
            span {
                margin-right: 5px;
                color: #969696;
            }
            span.title {
                color: #ffffff;
            }
            span.subtitle {
                color: #969696;
            }
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
        .play_store_btn {
            background-color: $dark-grey;
            border: none;
            border-radius: 4px;
            height: 75px;
            margin-bottom: 15px;
            margin-right: 0;
            width: 220px;
            margin-bottom: 0;
            &:hover {
                background-color: $red;
                span {
                    color: #ffffff;
                }
                span.subtitle {
                    color: #ffffff;
                }
            }
            span {
                margin-right: 5px;
                color: #969696;
            }
            span.title {
                color: #ffffff;
            }
            span.subtitle {
                color: #969696;
            }
        }
    }
    .mailchimp_form {
        .form-control {
            background-color: #1a1a1a;
            border: 1px solid transparent;
            border-radius: 4px;
            box-shadow: none;
            color: #707070;
            height: 52px;
            width: 80%;
        }
        button {
            background-color: transparent;
            border: transparent;
            color: $dark-grey;
            height: 45px;
            margin-left: -62px;
            width: 45px;
        }
    }
}
.footer_qlink_widget.home3 {
    h4 {
        color: $dark-grey;
        font-weight: bold;
        margin-bottom: 25px;
    }
    li {
        a {
            color: #777777;
        }
    }
}
.footer_contact_widget.home3 {
    h4 {
        color: $dark-grey;
        font-weight: bold;
        margin-bottom: 25px;
    }
    li {
        a {
            color: #777777;
        }
    }
}
.footer_apps_widget.home3 {
    h4 {
        color: $dark-grey;
        font-weight: bold;
        margin-bottom: 25px;
        color: #051925;
    }
    .app_grid {
        .apple_btn {
            &:hover {
                background-color: #369fdb;
            }
            &:active {
                box-shadow: none;
                outline: none;
            }
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
        .play_store_btn {
            &:hover {
                background-color: #369fdb;
            }
            &:active {
                box-shadow: none;
                outline: none;
            }
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }
}
.footer_apps_widget.home6 {
    h4 {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.333;
    }
    .mailchimp_form {
        .form-control {
            background-color: #101219;
        }
    }
}
.logo-widget {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    span {
        color: #ffffff;
        font-family: Arial;
        font-size: 22px;
        font-weight: bold;
        padding-left: 15px;
        margin-top: 10px;
        text-transform: uppercase;
        vertical-align: middle;
    }
}
.logo-widget.home3 {
    span {
        color: #051925;
    }
}
.logo-widget.home8 {
    span {
        background: linear-gradient(to right, #835bb4 0%, #d7cf96 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    margin-left: 150px;
}
.footer_menu_widget {

    ul {
        margin-bottom: 0;
        li {
            margin-right: 0 !important;
            padding-right: 25px;
            vertical-align: middle;
            a {
                font-size: 13px;
                font-family: Arial;;
                color: #8a99b3;
                line-height: 2.143;
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
}
.footer_menu_widget.home3 {
    ul {
        li {
            a {
                color: #777777;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
    }
}
.footer_menu_widget.home6 {
    ul {
        li {
            a {
                color: #c5c9d0;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.footer_menu_widget.home5 {
    ul {
        li {
            a {
                color: #98a2aa;
            }
        }
    }
}
.copyright-widget.home5 {
    p {
        font-size: 12px;
        color: #98a2aa;
    }
}
.footer_about_widget.home4 {
    h4 {
        color: #051925;
    }
}
.footer_qlink_widget.home4 {
    h4 {
        color: #051925;
    }
}
.footer_contact_widget.home4 {
    h4 {
        color: #051925;
    }
}
.footer_apps_widget.home4 {
    h4 {
        color: #051925;
    }
}
.footer_about_widget.home8 {
    p {
        line-height: 30px;
    }
}
.footer_apps_widget.home5 {
    h4 {
        color: #ffffff;
    }
}
.mailchimp_form.home5 {
    .form-control {
        background-color: #fafafa;
    }
}
.footer_menu {
    padding-left: 60px;
    ul {
        li {
            &:last-child {
                border-radius: 4px;
                a {
                    background-color: transparent !important;
                    color: #7c7c7c;
                    padding-left: 20px;
                }
                &:hover {
                    a {
                        color: #7c7c7c;
                    }
                }
            }
            .bootstrap-select {
                >.dropdown-toggle {
                    background-color: #2f2b2b;
                    box-shadow: none !important;
                    border: transparent;
                    color: #ffffff;
                    height: 45px;
                    line-height: 35px;
                    margin-left: 15px;
                    outline: none !important;
                    padding-left: 25px;
                    padding-right: 20px;
                    position: relative;
                    text-align: right;
                    white-space: nowrap;
                    width: 130px;
                    z-index: 1;
                }
                .dropdown-toggle {
                    .filter-option {
                        outline: none;
                    }
                }
                width: auto !important;
            }
        }
    }
}
.btn-light {
    &:not(:disabled) {
        &:not(.disabled).active {
            background-color: transparent;
            box-shadow: none;
        }
        &:not(.disabled) {
            &:active {
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
}
.show {
    >.btn-light.dropdown-toggle {
        background-color: transparent;
        box-shadow: none;
    }
}
.footer_bottom_area.home5 {
    background-color: #ffffff;
    .footer_menu {
        ul {
            li {
                .bootstrap-select {
                    >.dropdown-toggle {
                        background-color: #e6e6e6;
                        color: #8b8b8b;
                    }
                }
            }
        }
    }
}
.inner_page_breadcrumb {
    background-image: url(../images/background/inner-pagebg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 410px;
    position: relative;
    @include screen-sm-down {
        height: 200px;
        margin-top: 0 !important;
    }
    &:before {
        background-color: $dark-grey;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.6;
        position: absolute;
        right: 0;
        top: 0;
    }
    .breadcrumb_content {
        margin-top: 120px;
        @include screen-sm-down {
            margin-top: 50px;
        }
        .breadcrumb {
            background-color: transparent;
            display: -webkit-inline-flex;
            display: -moz-inline-flex;
            display: -ms-inline-flex;
            display: -o-inline-flex;
            display: inline-flex;
            margin-bottom: 0;
            padding-left: 0;
        }
    }
    .breadcrumb_title {
        font-size: 48px;
        font-family: Arial;;
        color: #ffffff;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 0;
        li {
            &:first-child {
                color: #7f7f7f;
            }
            &:last-child {
                color: #221f1f;
            }
        }
    }
    .breadcrumb-item {
        a {
            color: #ffffff;
            text-transform: capitalize;
        }
    }
    .breadcrumb-item.active {
        color: #ffffff;
        text-transform: capitalize;
    }
    .breadcrumb-item+.breadcrumb-item {
        &::before {
            color: #ffffff;
            text-transform: capitalize;
        }
    }
}
.inner_page_breadcrumb.csv2 {
    .breadcrumb_content {
        margin-top: 100px;
    }
}
.inner_page_breadcrumb.csv3 {
    .breadcrumb_content {
        margin-top: 100px;
    }
}
.breadcrumb_content.style2 {
    margin-bottom: 30px;
    ol.breadcrumb {
        background-color: transparent;
        margin: auto;
        padding-left: 0;
        padding-top: 0;
    }
}
.about_content {
    p.large {
        color: $dark-grey;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 45px;
    }
    p {
        font-size: 14px;
        color: $dark-grey;
        line-height: 1.714;
        margin-bottom: 40px;
    }
}
ul.ab_counting {
    li {
        margin-right: 30px !important;
        &:last-child {
            margin-right: 0;
        }
    }
}
.about_counting {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    .icon {
        span {
            color: $red;
            font-size: 40px;
            line-height: 1.2;
        }
    }
    .details {
        padding-left: 12px;
        h3 {
            font-size: 22px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            margin-bottom: 0;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
        }
    }
}
.about_thumb {
    position: relative;
    overflow: hidden;
    img {
        border-radius: 8px;
    }
    a {
        background: $red;
        border-radius: 100%;
        font-size: 22px;
        height: 70px;
        left: 0;
        line-height: 70px;
        margin: 0 auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: 43%;
        width: 70px;
        z-index: 99;
        &:after {
            background-color: rgba(255, 90, 95, 0.2);
            border-radius: 100%;
            content: "";
            height: 87px;
            left: -7px;
            position: absolute;
            top: -7px;
            width: 87px;
            z-index: -1;
        }
    }
}
.home3_about {
    overflow: hidden;
}
.home3_about2 {
    overflow: hidden;
}
.about_home3 {
    margin-top: 100px;
    h3 {
        font-size: 26px;
        margin-bottom: 10px;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 40px;
    }
    p {
        margin-bottom: 0;
        max-width: 560px;
    }
    a.about_btn_home3 {
        background-color: #ffffff;
        border: 2px solid #369fdb;
        border-radius: 25px;
        color: #369fdb;
        height: 50px;
        line-height: 35px;
        margin-top: 40px;
        width: 180px;
        &:hover {
            background-color: #369fdb;
            color: #ffffff;
        }
        &:active {
            background-color: #369fdb;
            color: #ffffff;
        }
        &:focus {
            background-color: #369fdb;
            color: #ffffff;
        }
    }
    .partners_thumb_list {
        margin-bottom: 0;
        margin-top: 50px;
        li {
            margin-right: 55px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.home3_about_icon_box {
    border-radius: 5px;
    display: block;
    margin-bottom: 45px;
    padding: 30px 60px 35px 40px;
    position: relative;
    z-index: 1;
    .icon {
        font-size: 70px;
        span {
            color: #ffffff;
        }
    }
    h4 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #ffffff;
    }
    p {
        color: #ffffff;
        font-size: 15px;
        line-height: 30px;
    }
}
.home3_about_icon_box.home8 {
    padding: 70px 60px 60px 45px;
}
.home3_about_icon_box.one {
    background-color: #ecd06f;
    margin-top: -50px;
}
.home3_about_icon_box.two {
    background-color: #ff1053;
    margin-top: -80px;
}
.home3_about_icon_box.three {
    background-color: #00a78e;
    margin-bottom: 0;
}
.home3_about_icon_box.four {
    background-color: #00bce4;
    margin-bottom: 0;
    margin-top: -25px;
}
.home3_about_icon_box.five {
    background-color: #2ac4ea;
    margin-top: -50px;
}
.home3_about_icon_box.six {
    background-color: $red;
    margin-bottom: 0;
}
.home3_about_icon_box.seven {
    background-color: #fbbc05;
    margin-bottom: 0;
    margin-top: -25px;
}
.about_home4_shape4 {
    bottom: 550px;
    position: absolute;
    left: -225px;
    z-index: -1;
}
.about2_home3 {
    margin-top: 100px;
    h3 {
        font-size: 30px;
        margin-bottom: 10px;
    }
    p {
        font-size: 18px;
        margin-bottom: 0;
    }
    a.about_btn_home3 {
        background-color: #ffffff;
        border: 2px solid #051925;
        border-radius: 25px;
        color: #051925;
        height: 50px;
        line-height: 35px;
        margin-top: 30px;
        width: 180px;
        &:hover {
            background-color: #051925;
            color: #ffffff;
        }
        &:active {
            background-color: #051925;
            color: #ffffff;
        }
        &:focus {
            background-color: #051925;
            color: #ffffff;
        }
    }
}
.instructor_col {
    text-align: center;
    margin-bottom: 40px;
    .thumb {
        img {
            height: 150px;
            margin: 0 auto;
            width: 150px;
        }
    }
    .details {
        p {
            font-size: 14px;
            margin-bottom: 0;
        }
        ul {
            margin-top: 10px;
            li {
                margin-right: 4px;
                a {
                    color: #d0d23c;
                }
                &:last-child {
                    a {
                        color: #7e7e7e;
                    }
                }
            }
        }
    }
}
.about_box_home6 {
    .details {
        position: relative;
        h3 {
            font-size: 26px;
            font-family: "Nunito SemiBold";
            margin-bottom: 20px;
        }
        p {
            font-size: 15px;
            line-height: 2;
            margin-bottom: 35px;
            max-width: 540px;
        }
        a {
            border-radius: 5px;
            box-shadow: none;
            font-size: 15px;
            height: 50px;
            line-height: 30px;
            width: 200px;
        }
    }
}
.about_box_home7 {
    .thumb {
        margin-bottom: 20px;
        position: relative;
        z-index: 9;
        &:before {
            background-color: #ffffff;
            border-radius: 5px;
            content: "";
            height: 410px;
            left: 20px;
            position: absolute;
            top: 22px;
            width: 630px;
            z-index: -1;
            background-color: #f9f9f9;
        }
    }
}
.smp_icon_box {
    position: relative;
    .icon {
        margin-bottom: 15px;
    }
    .details {
        h4 {
            font-size: 18px;
            font-family: Arial;;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 10px;
        }
        p {
            color: #131313;
            font-size: 15px;
            line-height: 30px;
        }
    }
}
.smp_icon_box.box1 {
    .details {
        h4 {
            color: #f25141;
        }
    }
}
.smp_icon_box.box2 {
    .details {
        h4 {
            color: #93c524;
        }
    }
}
.smp_icon_box.box3 {
    .details {
        h4 {
            color: #28a8e3;
        }
    }
}
.smp_icon_box.box4 {
    .details {
        h4 {
            color: #00a78e;
        }
    }
}
.about_home3.home8 {
    margin-top: 30px;
}
.our-team.instructor-page {
    background-color: #f9fafc;
}
.team_member {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &:hover {
        cursor: pointer;
        .thumb {
            .overylay {
                opacity: 1;
            }
        }
    }
    .thumb {
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        .overylay {
            background-color: rgba(255, 90, 95, .8);
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            .social_icon {
                bottom: 15px;
                left: 0;
                position: absolute;
                right: 0;
                text-align: center;
                li {
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        i {
                            color: #ffffff;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    .details {
        padding: 20px 0 5px;
        p {
            font-size: 14px;
            color: rgba(72, 72, 72, 0.8);
            line-height: 1.2;
            margin-bottom: 0;
        }
    }
    .instructor_col {
        margin-bottom: 15px;
        .thumb {
            img {
                height: 120px;
                margin: 0 auto 20px;
                width: 120px;
            }
        }
        .details {
            p {
                font-size: 15px;
            }
        }
    }
    .tm_footer {
        border-top: 1px solid #eeeeee;
        ul {
            margin-bottom: 0;
            padding: 10px 0 10px;
            li {
                &:first-child {
                    padding-right: 10px;
                }
                a {
                    font-size: 14px;
                }
            }
        }
    }
}
.team_member.home4 {
    .thumb {
        .overylay {
            opacity: 0;
        }
    }
    &:hover {
        .thumb {
            .overylay {
                background-image: -moz-linear-gradient( -36deg, rgb(62,62,165) 0%, rgb(255,90,95) 100%);
                background-image: -webkit-linear-gradient( -36deg, rgb(62,62,165) 0%, rgb(255,90,95) 100%);
                background-image: -ms-linear-gradient( -36deg, rgb(62,62,165) 0%, rgb(255,90,95) 100%);
                opacity: 0.902;
            }
        }
    }
}
.our_agent {
    .thumb {
        img {
            width: 204px;
            height: 255px;
        }
        .overylay {
            p {
                color: #fff;
                padding: 20px 10px 10px 25px;
            }
            .social_icon {
                bottom: 15px;
                left: 0;
                position: absolute;
                right: 0;
                text-align: center;
                li {
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        i {
                            color: #ffffff;
                            font-size: 13px;
                        }
                    }
                }
            }
            background-color: rgba(67, 67, 67, .9);
            border-radius: 8px;
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            height: 352px;
        }
        margin-bottom: 15px;
        position: relative;
    }
    margin-bottom: 30px;
    position: relative;
    .details {
        p {
            margin-bottom: 0;
        }
    }
    &:hover {
        .thumb {
            .overylay {
                opacity: 1;
            }
            .overlay-url{
                cursor: pointer;
            }
        }
    }
}
.team_member.style2 {
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.team_member.style3 {
    &:hover {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
    }
}
.our-agents {
    border-bottom: 1px solid #e8eef2;
    padding-bottom: 90px;
    z-index: 0;
}
.testimonial_item {
    .thumb {
        margin-bottom: 30px;
        text-align: center;
        img {
            height: 60px;
            width: 60px;
        }
    }
    .details {
        margin: 0 0 0 6%;
        padding: 0 35px;
        position: relative;
        text-align: center;
        h4 {
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 0;
        }
        .small {
            font-size: 16px;
            font-family: Arial;;
            line-height: 1.5;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: rgb(119, 119, 119);
            font-style: italic;
            line-height: 1.714;
            margin-bottom: 0;
            margin-top: 30px;
        }
    }
}
.testimonialsec {
    float: left;
    position: relative;
    width: 100%;
    &:before {
        bottom: -50px;
        color: #1b2032;
        content: "\f10d";
        font-size: 270px;
        font-family: fontawesome;
        left: 0;
        margin: 0 auto;
        opacity: 0.03;
        position: absolute;
        right: 0;
        text-align: center;
    }
}
.tes-for {
    float: left;
    margin: 0;
    position: relative;
    text-align: center;
    width: 100%;
}
.tes-nav {
    float: left;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
    left: 0;
    img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        float: none;
        height: 60px;
        margin: 0 auto;
        opacity: .6;
        width: 60px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .slick-list {
        .slick-track {
            li.slick-slide.slick-active {
                margin: 20px 0 0;
                &:first-child {
                    img {
                        height: 60px;
                        width: 60px;
                    }
                }
                &:last-child {
                    img {
                        height: 60px;
                        width: 60px;
                    }
                }
                &:nth-child(2) {
                    img {
                        height: 80px;
                        width: 80px;
                    }
                }
                &:nth-child(4) {
                    img {
                        height: 80px;
                        width: 80px;
                    }
                }
            }
            li.slick-slide.slick-current.slick-active.slick-center {
                margin: 0;
                width: 130px !important;
            }
        }
    }
    .slick-slide.slick-current.slick-active.slick-center {
        img {
            height: 100px;
            margin: 0 auto;
            opacity: 1;
            width: 100px;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
            -webkit-transform: translate(5%, 0) scale(.9);
            -moz-transform: translate(5%, 0) scale(.9);
            -o-transform: translate(5%, 0) scale(.9);
            transform: translate(5%, 0) scale(.9);
        }
    }
}
li.slick-slide.slick-active {
    &:nth-child(6) {
        img {
            -webkit-transform: translate(5%, 0) scale(1.3);
            -moz-transform: translate(5%, 0) scale(1.3);
            -o-transform: translate(5%, 0) scale(1.3);
            transform: translate(5%, 0) scale(1.3);
        }
    }
    &:nth-child(8) {
        img {
            -webkit-transform: translate(5%, 0) scale(1.3);
            -moz-transform: translate(5%, 0) scale(1.3);
            -o-transform: translate(5%, 0) scale(1.3);
            transform: translate(5%, 0) scale(1.3);
        }
    }
}
.testimonial_item.home2 {
    position: relative;
    text-align: center;
    opacity: .5;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    .details {
        border-radius: 5px;
        margin-bottom: 25px;
        padding: 13px 60px 35px;
        padding-left: 120px;
        text-align: left;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        .icon {
            font-size: 40px;
            left: 50px;
            line-height: normal;
            position: absolute;
            top: 70px;
        }
    }
    .thumb {
        .title {
            color: #060606;
            font-size: 18px;
        }
        .subtitle {
            color: $red;
            font-size: 13px;
            margin-bottom: 40px;
        }
        img {
            display: inline-block;
            margin-bottom: 25px;
        }
    }
}
.testimonial_post {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px 20px 30px 30px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
    -moz-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
    -o-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
    box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
    position: relative;
    .details {
        .icon {
            margin-bottom: 20px;
            font-size: 30px;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.714;
            margin-bottom: 22px;
        }
    }
    .thumb {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        position: relative;
        img {
            width: 90px;
        }
        h4.title {
            margin-top: 25px;
            padding-left: 20px;
        }
    }
}
.testimonial_slider_home9 {
    .testimonial_post {
        margin: 0px 10px 50px;
    }
}
.feature_property_slider.owl-carousel {
    .owl-stage-outer {
        overflow: visible;
    }
}
.feature_property_home3_slider.owl-carousel.owl-theme {
    overflow: visible;
    .owl-nav {
        margin: 0;
    }
}
.feature_property_home3_slider.owl-carousel {
    .owl-stage-outer {
        overflow: visible;
    }
}
.best_property_slider.owl-carousel {
    .owl-stage-outer {
        overflow: visible;
    }
}
.testimonial_slider_home9.owl-carousel.owl-theme {
    overflow: visible;
    .owl-dots {
        .owl-dot {
            margin-right: 10px;
            vertical-align: middle;
            span {
                background-color: #b9b9b9;
                border-radius: 50%;
                margin: 3px;
                width: 6px;
                height: 6px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
        .owl-dot.active {
            span {
                border: 5px solid #ffffff;
                height: 15px;
                width: 15px;
            }
        }
    }
    .owl-nav {
        display: none;
    }
}
.testimonial_slider_home9.owl-carousel {
    .owl-stage-outer {
        overflow: visible;
    }
}
.becomea_instructor {
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 65px 15px 55px 200px;
    h3 {
        font-size: 28px;
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 30px;
    }
    a {
        border-radius: 5px;
        font-size: 14px;
        padding: 20px 25px 20px 40px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
        span {
            padding-left: 15px;
        }
    }
}
.becomea_instructor.style2 {
    background-color: #f9f9f9;
    padding: 65px 200px 55px 15px;
}
.becomea_instructor_home3 {
    border-radius: 5px;
    padding: 65px 0 55px 35px;
    h3 {
        color: #ffffff;
        font-size: 26px;
        margin-bottom: 15px;
    }
    p {
        color: #ffffff;
        font-size: 16px;
    }
    a {
        border: none;
        border-radius: 5px;
        font-size: 14px;
        height: 50px;
        line-height: 35px;
        margin-top: 20px;
        width: 198px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        span {
            padding-left: 15px;
        }
    }
}
.becomea_instructor_home3.style1 {
    background-color: #369fdb;
    a {
        color: #369fdb;
        &:hover {
            background-color: #051925;
            color: #ffffff;
        }
        &:active {
            background-color: #051925;
            color: #ffffff;
        }
        &:focus {
            background-color: #051925;
            color: #ffffff;
        }
    }
}
.becomea_instructor_home3.style2 {
    background-color: #051925;
    a {
        color: #051925;
        &:hover {
            background-color: #369fdb;
            color: #ffffff;
        }
        &:active {
            background-color: #369fdb;
            color: #ffffff;
        }
        &:focus {
            background-color: #369fdb;
            color: #ffffff;
        }
    }
}
.blog_post {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .thumb {
        display: grid;
        overflow: hidden;
        position: relative;
        img {
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        .post_date {
            color: rgba(255,255,255, .8);
            font-size: 14px;
            left: 30px;
            position: absolute;
            top: 30px;
            z-index: 9;
        }
        &:before {
            background-color: rgba(0,0,0, .6);
            bottom: 0;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }
    &:hover {
        .thumb {
            img {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
                z-index: 0;
            }
        }
    }
    .details {
        bottom: 20px;
        left: 30px;
        position: absolute;
        z-index: 9;
        h4 {
            font-size: 20px;
            margin-top: 8px;
            width: 90%;
            color: #ffffff;
        }
        h5 {
            color: rgba(255,255,255, .8);
            font-size: 14px;
        }
    }
}
.blog_post.one {
    .thumb {
        .post_title {
            font-size: 5vw;
            color: #ffffff;
            left: 20px;
            opacity: .15;
            position: absolute;
            top: 0;
        }
        .post_date {
            background-color: #ffffff;
            border-radius: 5px;
            height: 64px;
            left: auto;
            padding-left: 15px;
            padding-top: 10px;
            right: 30px;
            text-align: center;
            width: 80px;
            span {
                color: $dark-grey;
                font-family: Arial;
                font-size: 18px;
                font-weight: bold;
                display: table-caption;
                line-height: 22px;
            }
        }
        &:before {
            background-color: rgba(25, 38, 117, .7);
            bottom: 0;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
        }
    }
    .details {
        .post_meta {
            position: relative;
            ul {
                li {
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: #ffffff;
                        font-size: 14px;
                        i {
                            font-size: 16px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
.blog_post_home2 {
    .bph2_header {
        .bph2_date_meta {
            background-color: #ffffff;
            border-radius: 5px;
            height: 64px;
            left: auto;
            padding-left: 15px;
            padding-top: 10px;
            right: 30px;
            text-align: center;
            width: 80px;
            border-radius: 5px;
            overflow: hidden;
            position: absolute;
            right: 25px;
            top: 30px;
            text-align: center;
            span {
                color: $dark-grey;
                font-family: Arial;
                font-size: 18px;
                font-weight: bold;
                display: table-caption;
                line-height: 22px;
            }
            &:hover {
                background-color: $dark-grey;
                color: #ffffff;
                span {
                    background-color: $dark-grey;
                    color: #ffffff;
                }
            }
        }
        &:before {
            background-color: rgba(0, 0, 0, .3);
            border-radius: 5px;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
        img {
            height: 100%;
        }
    }
    border-radius: 5px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    height: 450px;
    margin: 0 13px 45px;
    overflow: hidden;
    position: relative;
    &:hover {
        cursor: pointer;
        .bph2_header {
            &:before {
                background-color: rgba(36, 65, 231, .8);
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }
        }
    }
    .details {
        position: absolute;
        bottom: 50px;
        padding: 0 30px;
        h4 {
            color: #fafafa;
            font-family: Arial;;
            font-size: 20px;
            font-weight: normal;
            line-height: 1.5;
        }
        .post_meta {
            ul {
                li {
                    a {
                        color: #fafafa;
                        font-size: 14px;
                        i {
                            font-size: 18px;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.read_more_home {
    a {
        color: #c75533;
    }
}
.blog_post_home4 {
    position: relative;
    .bph4_thumb {
        border-radius: 4px;
        -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
        -moz-box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
        -o-box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
        box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        overflow: hidden;
        position: relative;
        &:before {
            background-color: rgba(0, 0, 0, .4);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
        .bph4_date_meta {
            border-radius: 4px;
            left: 30px;
            overflow: hidden;
            position: absolute;
            top: 30px;
            text-align: center;
            .year {
                background-color: #f8faf9;
                color: #221f1f;
                font-size: 17px;
                font-weight: bold;
                padding: 5px 20px 3px;
                margin-bottom: 0;
            }
            .date {
                margin-bottom: 0;
                background-color: #ffffff;
                color: #221f1f;
                font-size: 15px;
                font-weight: normal;
                padding: 3px 13px 5px;
            }
        }
    }
    .details {
        padding-top: 25px;
        h4 {
            font-size: 18px;
            line-height: 30px;
        }
        p {
            font-size: 15px;
            line-height: 28px;
            max-width: 342px;
        }
    }
}
.blog_post_home4.home8 {
    .bph4_thumb {
        box-shadow: none;
        &:before {
            background-color: transparent;
        }
        .bph4_date_meta {
            .year {
                background-color: $red;
                color: #ffffff;
            }
            .date {
                background-color: #192675;
                color: #ffffff;
            }
        }
    }
}
.blog_post_home6 {
    position: relative;
    h3.title {
        font-size: 26px;
        margin-bottom: 50px;
    }
    .thumb {
        h4 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 5px;
            margin-top: 20px;
        }
        a {
            font-size: 15px;
            line-height: 30px;
        }
    }
}
.blog_post_home6_media_objects {
    .title {
        font-size: 15px;
        margin-bottom: 60px;
        padding-top: 5px;
        position: relative;
        text-align: right;
        a {
            color: $dark-grey;
            font-size: 15px;
            span {
                padding-left: 15px;
            }
        }
    }
}
.blog_post_home6_date {
    cursor: pointer;
    position: relative;
    .title {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        a {
            span {
                padding-left: 15px;
            }
            font-size: 15px;
            position: absolute;
            right: 0;
            top: 5px;
        }
        h3 {
            float: left;
            font-size: 26px;
            margin-bottom: 50px;
        }
    }
    .post_grid {
        background-color: #f9f9f9;
        border-radius: 5px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        padding: 30px 15px 30px 15px;
        position: relative;
        &:hover {
            .post_date {
                background-color: #192675;
            }
        }
        .post_date {
            border-radius: 5px;
            background-color: #ff1053;
            color: #ffffff;
            float: left;
            height: 60px;
            line-height: normal;
            margin-right: 15px;
            padding-top: 7px;
            position: relative;
            text-align: center;
            width: 70px;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
        }
        .post_meta {
            ul {
                margin-bottom: 0;
                li {
                    a {
                        font-size: 13px;
                        span {
                            font-size: 16px;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .post_grid.home7 {
        background-color: #ffffff;
    }
}
.about_home6 {
    .partners_thumb_list {
        margin-bottom: 0;
        margin-top: 70px;
        li {
            margin-right: 55px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.footer_apps_widget_home1.home6 {
    input.form-control {
        margin: 0 45px 0 0;
    }
    button.btn {
        border-radius: 5px;
    }
}
.footer_apps_widget_home1.home7 {
    button.btn {
        border-radius: 5px;
    }
    input.form-control {
        background-color: #f9f9f9;
    }
}
.team_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        left: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: #006c70;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
    .owl-next {
        right: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: #006c70;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
}
.feature_post_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        left: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: #006c70;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
    .owl-next {
        right: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: #006c70;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
}
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        left: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: #006c70;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
    .owl-next {
        right: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: #006c70;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
}
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        left: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: $dark-grey;
        height: 40px;
        line-height: 35px;
        position: absolute; 
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
    .owl-next {
        background-color: #ffffff !important;
        border-radius: 50%;
        color: $dark-grey;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        right: -2%;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }

}
.feature_property_home6_slider_shorted.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        left: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: $dark-grey;
        height: 40px;
        line-height: 35px;
        position: absolute; 
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
    .owl-next {
        background-color: #ffffff !important;
        border-radius: 50%;
        color: $dark-grey;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        right: -2%;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }

    }
}
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded {
    .owl-prev {
        left: -25px;
        background-color: #ffffff !important;
        border-radius: 50%;
        color: $dark-grey;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
    .owl-next {
        background-color: #ffffff !important;
        border-radius: 50%;
        color: $dark-grey;
        height: 40px;
        line-height: 35px;
        position: absolute;
        top: 40%;
        width: 40px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        right: -2%;
        &:hover {
            background-color: $red !important;
            color: #ffffff;
        }
    }
}
.team_slider.owl-carousel.owl-theme {
    .owl-nav {
        margin: 0;
    }
}
.feature_property_home6_slider{
    .owl-carousel.owl-theme {
        .owl-nav {
            margin: 0;
        }
        .owl-stage-outer {
            width: 100%;
            display: inline-block;
        }
    }
}
.feature_property_home6_slider_shorted{
    .owl-carousel.owl-theme {
        .owl-nav {
            margin: 0;
        }
        .owl-stage-outer {
            width: 100%;
            display: inline-block;
        }
    }
}
.our_agents_home6_slider.owl-carousel.owl-theme {
    .owl-nav {
        margin: 0;
    }
    .owl-stage-outer {
        width: 100%;
        display: inline-block;
    }
}
.feature_post_slider {
    .shop_grid {
        .thumb {
            img {
                margin: 0 auto;
                width: auto;
            }
        }
    }
}
.membership_container {
    background-color: #ffffff;
    padding: 30px 0 0;
    position: relative;
}
ul.mc_parent_list {
    .membership_header {
        .thumb {
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            &:before {
                background-color: rgba(0,0,0, .6);
                bottom: 0;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
            a {
                background-color: #ffffff;
                border-radius: 8px;
                height: 30px;
                line-height: 25px;
                position: absolute;
                right: 10px;
                text-align: center;
                top: 10px;
                width: 30px;
                span {
                    font-size: 10px;
                }
            }
            .price {
                bottom: 15px;
                color: #ffffff;
                font-family: Arial;;
                font-size: 22px;
                font-weight: bold;
                left: 0;
                line-height: 1.2;
                margin: 0 auto;
                position: absolute;
                right: 0;
            }
        }
        .details {
            padding-top: 12px;
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $red;
                line-height: 1.2;
            }
        }
    }
    li {
        margin-right: -3px !important;
    }
    ul.mc_child_list.one {
        li {
            font-weight: bold;
            padding: 10px 50px 10px 50px;
            border-bottom: 1px solid #ebebeb;
            color: $dark-grey;
            font-size: 16px;
            font-family: Arial;;
            line-height: 3.333;
            &:first-child {
                padding-top: 25px;
            }
            &:last-child {
                padding-top: 25px;
                border-bottom: none;
            }
        }
    }
    ul.mc_child_list.two {
        li {
            border-bottom: 1px solid #ebebeb;
            color: $dark-grey;
            font-size: 16px;
            font-family: Arial;;
            line-height: 3.333;
            padding: 10px 20px;
            &:first-child {
                padding-top: 25px;
            }
            &:last-child {
                padding-top: 25px;
                border-bottom: none;
            }
        }
    }
    ul.mc_child_list.three {
        li {
            border-bottom: 1px solid #ebebeb;
            color: $dark-grey;
            font-size: 16px;
            font-family: Arial;;
            line-height: 3.333;
            padding: 10px 20px;
            &:first-child {
                padding-top: 25px;
            }
            &:last-child {
                padding-top: 25px;
                border-bottom: none;
            }
        }
    }
    ul.mc_child_list.four {
        li {
            border-bottom: 1px solid #ebebeb;
            color: $dark-grey;
            font-size: 16px;
            font-family: Arial;;
            line-height: 3.333;
            padding: 10px 20px;
            &:first-child {
                padding-top: 25px;
            }
            &:last-child {
                padding-top: 25px;
                border-bottom: none;
            }
        }
    }
    ul.mc_child_list {
        .membership_header {
            h4 {
                font-size: 18px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.333;
                margin: 0;
            }
        }
    }
}
.our-partner {
    padding: 40px 0;
}
.our_partner {
    text-align: center;
}
.start-partners.home5 {
    background-image: -moz-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(250,124,65) 0%, rgb(255,101,101) 100%);
}
.start-partners.home7 {
    background-image: -moz-linear-gradient( 0deg, rgb(60,4,135) 0%, rgb(76,21,148) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(60,4,135) 0%, rgb(76,21,148) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(60,4,135) 0%, rgb(76,21,148) 100%);
}
.our-newslatters {
    background-color: #f9fafc;
}
.footer_apps_widget_home1 {
    input.form-control {
        border: none;
        border-radius: 5px;
        height: 50px;
        margin: 0 auto;
        width: 65%;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        &:focus {
            border: none;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        }
    }
    button.btn {
        font-size: 14px;
        height: 50px;
        margin-left: -15px;
        width: 150px;
        span {
            margin-left: 10px;
        }
    }
}
.our-newslatters.home7 {
    background-color: #ffffff;
}
.our-error {
    padding: 80px 0;
    position: relative;
}
.logo-widget.error_paged {
    padding-left: 30px;
}
.erro_code {
    margin-top: 50px;
    h1 {
        font-size: 45px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
    }
}
.error_page.footer_apps_widget {
    position: relative;
    margin-bottom: 25px;
    p {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.714;
    }
    .form-inline {
        display: inline-block;
        margin-top: 20px;
    }
    .mailchimp_form {
        .form-control {
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #ebebeb;
            height: 52px;
            width: 600px;
        }
    }
}
a.btn.btn_error {
    background-color: $red;
    border-radius: 8px;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    font-family: Arial;;
    height: 50px;
    line-height: 35px;
    width: 200px;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
}
.service_grid {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 10px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    }
    .details {
        padding: 20px 0 10px;
        h4 {
            line-height: 1.444;
            margin: 0;
        }
    }
}
.pricing_table {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 30px;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    }
    .pricing_header {
        padding: 35px 0;
        .price {
            font-size: 40px;
            color: $red;
            font-weight: bold;
            line-height: 0.65;
            margin-bottom: 20px;
        }
        h4 {
            font-size: 18px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.444;
        }
    }
    .pricing_content {
        ul {
            li {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 3.571;
            }
        }
    }
    .pricing_footer {
        margin-top: 20px;
        a.btn.pricing_btn {
            background-color: #ffffff;
            border: 2px solid $red;
            border-radius: 8px;
            color: $red;
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
            line-height: 30px;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            height: 46px;
            &:hover {
                background-color: $red;
                color: #ffffff;
            }
        }
    }
}
.our-log {
    background-color: #f9fafc;
}
.login_form, .forgot-password {
    padding: 0;
    hr {
        margin-bottom: 25px;
        position: relative;
        &:before {
            background-color: #ffffff;
            bottom: 0;
            color: #006c70;
            content: "Or";
            font-size: 14px;
            position: absolute;
            left: 0;
            margin: 0 auto;
            right: 0;
            text-align: center;
            top: -12px;
            width: 10%;
        }
    }
    .input-group-text {
        background-color: transparent;
        border: none;
        color: $dark-grey;
        height: 40px;
        position: absolute;
        right: 2px;
        top: 6px;
    }
    .form-group {
        .custom-control-label {
            font-size: 14px;
            padding-top: 2px;
        }
    }
    .btn-fpswd {
        color: $red;
        font-size: 14px;
        font-family: Arial;;
        line-height: 2.214;
    }
    .divide {
        position: relative;
        .lf_divider {
            background-color: #f9fafc;
            height: 20px;
            left: 0;
            line-height: 20px;
            margin: 0 auto;
            position: absolute;
            right: 0;
            text-align: center;
            top: -10px;
            width: 62px;
        }
    }
    .heading {
        margin-bottom: 25px;
        h4 {
            color: $dark-grey;
            font-family: Arial;
            font-size: 18px;
            font-weight: bold;
        }
    }
    button.btn-fb {
        background-color: #ffffff;
        border: 1px solid #506dab;
        border-radius: 8px;
        color: #506dab;
        font-size: 14px;
        height: 52px;
        margin-bottom: 20px;
        padding-left: 20px;
        &:hover {
            background-color: #506dab;
            border: 1px solid #506dab;
            color: #ffffff;
        }
    }
    button.btn-googl {
        background-color: #ffffff;
        border: 1px solid #dd4b39;
        border-radius: 8px;
        color: #dd4b39;
        font-size: 14px;
        height: 52px;
        margin-bottom: 10px;
        padding-left: 20px;
        &:hover {
            background-color: #dd4b39;
            border: 1px solid #dd4b39;
            color: #ffffff;
        }
    }
    button {
        i {
            font-size: 16px;
        }
    }
    .form-control {
        &:focus {
            box-shadow: none;
            outline: none;
        }
        margin-bottom: 20px;
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        color: $dark-grey;
        font-size: 14px;
        font-family: Arial;;
        height: 52px;
        line-height: 1.2;
    }
    .form-check-input {
        height: 17px;
        width: 17px;
    }
    .form-check-label {
        color: $dark-grey;
        font-size: 14px;
        padding-left: 8px;
    }
    .btn-log {
        border-radius: 8px;
        background-color: $red;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        color: #ffffff;
        font-family: Arial;;
        font-size: 16px;
        font-weight: bold;
        height: 50px;
        line-height: 1.2;
        margin-bottom: 15px;
    }
}
.sign_up_form {
    hr {
        margin-bottom: 25px;
        position: relative;
        &:before {
            background-color: #ffffff;
            bottom: 0;
            color: #006c70;
            content: "Or";
            font-size: 14px;
            position: absolute;
            left: 0;
            margin: 0 auto;
            right: 0;
            text-align: center;
            top: -12px;
            width: 10%;
        }
    }
    .input-group-text {
        background-color: transparent;
        border: none;
        color: #006c70;
        height: 40px;
        position: absolute;
        right: 2px;
        top: 6px;
    }
    .form-group {
        .custom-control-label {
            font-size: 14px;
            padding-top: 2px;
        }
    }
    .divide {
        position: relative;
        .lf_divider {
            background-color: #f9fafc;
            height: 20px;
            left: 0;
            line-height: 20px;
            margin: 0 auto;
            position: absolute;
            right: 0;
            text-align: center;
            top: -10px;
            width: 62px;
        }
    }
    .heading {
        margin-bottom: 25px;
        h4 {
            color: #006c70;
        }
    }
    button.btn-fb {
        background-color: #ffffff;
        border: 1px solid #506dab;
        border-radius: 8px;
        color: #506dab;
        font-size: 14px;
        height: 52px;
        margin-bottom: 20px;
        padding-left: 20px;
        &:hover {
            background-color: #506dab;
            border: 1px solid #506dab;
            color: #ffffff;
        }
    }
    button.btn-googl {
        background-color: #ffffff;
        border: 1px solid #dd4b39;
        border-radius: 8px;
        color: #dd4b39;
        font-size: 14px;
        height: 52px;
        margin-bottom: 10px;
        padding-left: 20px;
        &:hover {
            background-color: #dd4b39;
            border: 1px solid #dd4b39;
            color: #ffffff;
        }
    }
    button {
        i {
            font-size: 16px;
        }
        margin-bottom: 10px;
    }
    .form-control {
        &:focus {
            box-shadow: none;
            outline: none;
        }
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        color: $dark-grey;
        font-size: 14px;
        font-family: Arial;;
        height: 52px;
        line-height: 1.2;
    }
    .bootstrap-select {
        .dropdown-toggle {
            .filter-option-inner-inner {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 3;
            }
        }
    }
    padding: 0;
    .form-check-input {
        border-radius: 2px;
        height: 15px;
        width: 15px;
    }
    .checkmark {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 2px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    }
    .form-check-label {
        color: $dark-grey;
        font-size: 14px;
        padding-left: 7px;
    }
    .btn-log {
        border-radius: 8px;
        background-color: $red;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        color: #ffffff;
        font-size: 16px;
        font-family: Arial;;
        height: 50px;
    }
}
.sign_up_form.inner_page {
    padding: 0 80px 0;
    .form-control {
        border-style: solid;
        border-width: 1px;
        border-color: rgb(221, 221, 221);
        border-radius: 5px;
        background-color: #ffffff;
    }
}
.login_form.inner_page {
    padding: 0 80px 0;
    .heading {
        h4 {
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .form-control {
        border-style: solid;
        border-width: 1px;
        border-color: rgb(221, 221, 221);
        border-radius: 5px;
        background-color: #ffffff;
    }
}
.shortcode_widget_accprdons {
    margin-bottom: 60px;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_tab {
    margin-bottom: 60px;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_table {
    margin-bottom: 60px;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_message_box {
    margin-bottom: 60px;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_buttons {
    margin-bottom: 60px;
    .ui_kit_button {
        button {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_form {
    h4 {
        font-size: 18px;
        font-size: 18px;
        font-family: Arial;;
        font-family: Arial;;
        color: $dark-grey;
        color: $dark-grey;
        font-weight: bold;
        font-weight: bold;
        line-height: 1.2;
        line-height: 1.2;
        margin-bottom: 30px;
        margin-bottom: 30px;
    }
}
.shortcode_widget_multiselect {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.ui_kit_select_box {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
    .custom-select {
        &:focus {
            box-shadow: none;
            border-color: #dddddd;
        }
    }
    .dropdown.bootstrap-select.custom-select-lg {
        padding-left: 0;
        padding-top: 0;
    }
    .bootstrap-select {
        .dropdown-menu {
            margin-top: 10px;
        }
        &:not([class*=col-]) {
            &:not([class*=form-control]) {
                &:not(.input-group-btn) {
                    width: 100%;
                }
            }
        }
        >.dropdown-toggle {
            background-color: #ffffff;
            border: 1px solid #dddddd;
            color: #7f7f7f;
            height: 50px;
            line-height: 40px;
            padding-left: 20px;
            &:focus {
                box-shadow: none;
                outline: none !important;
            }
        }
    }
    .filter-option-inner-inner {
        &:focus {
            outline: none;
        }
    }
    .btn-light {
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: #ffffff;
                color: #7f7f7f;
            }
            &:not(.disabled) {
                &:active {
                    background-color: #ffffff;
                    color: #7f7f7f;
                }
            }
        }
    }
    .show {
        >.btn-light.dropdown-toggle {
            background-color: #ffffff;
            color: #7f7f7f;
        }
    }
}
.shortcode_widget_radiobox {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_checkbox {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_switch {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_widget_progressbar {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.shortcode_form_title {
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
}
.faq_content {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 30px;
}
.terms_condition_grid {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 30px;
    position: relative;
    .grids {
        h4 {
            margin-bottom: 25px;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.714;
        }
    }
}
.terms_condition_widget {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 30px 30px 20px;
    position: relative;
    .title {
        margin-bottom: 20px;
    }
    ul {
        margin-bottom: 0;
        li {
            a {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 2.857;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
                span {
                    &:hover {
                        color: $red;
                    }
                    color: $dark-grey;
                }
            }
            &:hover {
                a {
                    color: $red;
                    span {
                        color: $dark-grey;
                    }
                }
            }
        }
    }
}
.ui_kit_tab {
    .nav.nav-tabs {
        background-color: $red;
        border-radius: 4px;
        height: 60px;
        .nav-link {
            color: #ffffff;
            height: 43px;
            line-height: 27px;
            margin-top: 10px;
            text-align: center;
            width: 104px;
            &:hover {
                border: 1px solid transparent;
            }
        }
        .nav-link.active {
            border-radius: 9px;
            background-color: #ff797d;
            border: none;
        }
    }
    .tab-content {
        .tab-pane {
            padding: 35px 0;
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.857;
                margin-bottom: 0;
            }
        }
    }
}
.Rounded_Rectangle_13 {
    position: absolute;
    left: 1076px;
    top: 618px;
    width: 95px;
    height: 43px;
    z-index: 63;
}
.ui_kit_table {
    .table {
        background-color: #ffffff;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        th {
            background-color: #24324a;
            border-bottom: transparent;
            color: #ffffff;
            font-weight: normal;
            padding-left: 30px;
        }
        td {
            padding-left: 30px;
        }
    }
}
.ui_kit_message_box {
    .alert {
        border-radius: 8px;
        height: 70px;
        line-height: 50px;
    }
}
.alert.alart_style_one {
    background-color: #4cbfd8;
    color: #ffffff;
}
.alert.alart_style_two {
    background-color: #8c689d;
    color: #ffffff;
}
.alert.alart_style_three {
    background-color: #35c2a5;
    color: #ffffff;
}
.alert.alart_style_four {
    background-color: #ff8480;
    color: #ffffff;
}
.ui_kit_button {
    .btn {
        border-radius: 4px;
        height: 55px;
        width: 170px;
    }
}
.ui_kit_input {
    .form-control {
        padding-left: 20px;
        &:focus {
            box-shadow: none;
            border-color: #dddddd;
        }
    }
}
.ui_kit_textarea {
    h5 {
        margin-bottom: 18px;
    }
    .form-control {
        &:focus {
            box-shadow: none;
            border-color: #dddddd;
        }
    }
}
.ui_kit_tooltip {
    .btn {
        border-radius: 4px;
        color: #7f7f7f;
        height: 50px;
        margin-right: 5px;
        width: 100px;
        &:hover {
            color: #7f7f7f;
        }
        &:focus {
            box-shadow: none;
            border-color: #dddddd;
        }
    }
}
.ui_kit_select_search {
    button.btn {
        background-color: #ffffff !important;
        border-color: #dddddd !important;
        color: #6d6d6d !important;
        height: 50px;
        line-height: 35px;
        padding-left: 20px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:focus {
            box-shadow: none !important;
            border-color: #dddddd;
            outline: none !important;
        }
    }
    .bs-searchbox {
        .form-control {
            height: 50px;
            &:focus {
                box-shadow: none;
                border-color: #dddddd;
            }
        }
        &:before {
            color: #d0d1d3;
            content: "\f002";
            font-family: FontAwesome;
            font-size: 20px;
            line-height: 45px;
            position: absolute;
            right: 25px;
            z-index: 999;
        }
    }
    .dropdown-item {
        color: $dark-grey;
        &:hover {
            background-color: transparent;
            color: #221f1f;
        }
        &:active {
            background-color: transparent;
            color: #7f7f7f;
        }
    }
    .dropdown-item.active {
        background-color: transparent;
        color: #7f7f7f;
    }
    .dropdown-menu.show {
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    }
    .bootstrap-select {
        .dropdown-menu {
            li {
                a {
                    span.text {
                        font-size: 14px;
                        font-family: Arial;;
                        color: $dark-grey;
                        line-height: 2.5;
                    }
                }
            }
        }
    }
}
.ui_kit_radiobox {
    .radio {
        display: inline-block;
        vertical-align: top;
        input[type=radio] {
            position: absolute;
            opacity: 0;
            &:checked+label {
                .radio-label {
                    background-color: $red;
                    box-shadow: inset 0 0 0 5px #fff;
                }
            }
        }
        label {
            cursor: pointer;
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.714;
            line-height: 25px;
            margin: 0px 0 10px;
            padding-left: 29px;
            position: relative;
        }
        input[type=radio]+label {
            .radio-label {
                content: '';
                background-color: #fff;
                border-radius: 100%;
                border: 1px solid #dedede;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
                box-shadow: inset 0 0 0 8px #fff;
                z-index: 100;
                position: absolute;
                top: 2px;
                left: 0;
                &:after {
                    background-color: $red;
                    box-sizing: border-box;
                    border-radius: 50%;
                    content: "";
                    height: 100%;
                    left: 0;
                    opacity: 1;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                    -webkit-transition: all 0.3s ease 0s;
                    -moz-transition: all 0.3s ease 0s;
                    -o-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                    -webkit-transform: scale(0);
                    -moz-transform: scale(0);
                    -o-transform: scale(0);
                    transform: scale(0);
                    width: 100%;
                    z-index: 99;
                }
            }
        }
    }
    input[type=radio] {
        box-shadow: none;
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
    label {
        display: block;
        font-size: 16px;
        margin-bottom: 8px;
        span {
            font-weight: 400;
            font-size: 14px;
            color: #444;
        }
    }
    .radio-label {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: transparent;
            display: block;
        }
    }
}
legend {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    span {
        font-weight: 400;
        font-size: 14px;
        color: #444;
    }
}
.ui_kit_checkbox {
    margin-bottom: 15px;
    margin-top: 2px;
    .custom-radio {
        label {
            margin-bottom: 3px;
        }
    }
    .form-group {
        margin-bottom: 3px;
    }
    input[type=checkbox] {
        height: 17px;
        width: 17px;
    }
    input[type=radio] {
        height: 17px;
        width: 17px;
    }
    .custom-control-label {
        &::after {
            left: -1.4rem;
            top: 5px;
        }
        color: #4f4f4f;
        font-size: 14px;
        justify-content: left;
        line-height: 28px;
        margin: 0 0 3px;
        outline: none;
        padding-left: 7px;
        padding-right: 15px;
        width: 100%;
        span {
            color: #c1c1c1;
            font-size: 14px;
        }
    }
    .custom-control-input {
        &:checked~.custom-control-label {
            &::before {
                background-color: $red;
                border-color: $red;
            }
        }
        &:focus~.custom-control-label {
            &::before {
                box-shadow: none;
            }
        }
    }
}
.ui_kit_checkbox.style2 {
    .custom-control-label {
        &::before {
            border-radius: 8.5px;
        }
    }
}
.custom-control-label {
    &:focus {
        outline: none;
    }
    &::before {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        height: 18px;
        top: .25rem;
        width: 18px;
    }
}
.custom-control {
    &:focus {
        outline: none;
    }
}
.ui_kit_whitchbox {
    .custom-control-label {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.714;
        line-height: 2;
        margin: 0 0 10px;
        outline: none;
        padding-left: 25px;
    }
    .custom-control-input {
        &:checked~.custom-control-label {
            &::before {
                background-color: $red;
            }
        }
        &:focus~.custom-control-label {
            &::before {
                box-shadow: none;
            }
        }
    }
    .custom-switch {
        .custom-control-label {
            &::before {
                height: 1.25rem;
                border: 1px solid #dddddd;
                width: 3.15rem;
            }
            &::after {
                height: 14px;
                top: 7px;
                width: 14px;
            }
        }
        .custom-control-input {
            &:checked~.custom-control-label {
                &::after {
                    -webkit-transform: translateX(2.0rem);
                    -moz-transform: translateX(2.0rem);
                    -o-transform: translateX(2.0rem);
                    transform: translateX(2.0rem);
                }
            }
        }
    }
}
.ui_kit_progress_bar {
    .skills {
        li {
            span {
                color: #262626;
            }
        }
    }
}
.ui_page_heading {
    position: relative;
    .blockquote {
        background-color: #f7f7f7;
        border-left: 8px solid $red;
        border-radius: 8px;
        margin-bottom: 25px;
        padding: 30px 30px 30px 50px;
        .blockquote-header {
            margin-bottom: 15px;
            span {
                font-size: 30px;
                color: rgb(187, 187, 187);
                font-style: italic;
                line-height: 0.333;
            }
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            font-style: italic;
            line-height: 1.714;
        }
    }
}
.order_list {
    margin-bottom: 0;
    li {
        padding-left: 30px;
        position: relative;
        span {
            color: $red;
            left: 0;
            position: absolute;
        }
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 2.857;

    }
}
.list-style-type-bullet {
    li {
        color: $red;
        list-style-type: disc;
        margin-left: 100px;
        padding-left: 0;
        position: relative;
    }
}
.ui_page_headings {
    margin-bottom: 40px;
    h1 {
        font-size: 45px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 35px;
    }
    h2 {
        font-size: 35px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 0.686;
        margin-bottom: 35px;
    }
    h3 {
        font-size: 30px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 0.8;
        margin-bottom: 35px;
    }
    h4 {
        font-size: 26px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 0.923;
        margin-bottom: 35px;
    }
    h5 {
        font-size: 22px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.091;
        margin-bottom: 35px;
    }
    h6 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.333;
        margin-bottom: 35px;
    }
}
.ui_kit_multi_select_box {
    .dropdown.bootstrap-select.show-tick.show {
        .dropdown-menu {
            border-radius: 8px;
            padding: 20px;
            -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
            box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
        }
    }
    .bootstrap-select.show-tick {
        .dropdown-menu {
            li {
                a {
                    span.text {
                        font-size: 14px;
                        font-family: Arial;;
                        color: $dark-grey;
                        line-height: 2.5;
                    }
                }
            }
        }
    }
    .bootstrap-select {
        &:not([class*=col-]) {
            &:not([class*=form-control]) {
                &:not(.input-group-btn) {
                    width: 100%;
                }
            }
        }
        >.dropdown-toggle {
            background-color: #ffffff;
            border: 1px solid #dddddd;
            color: #7f7f7f;
            height: 50px;
            line-height: 40px;
            padding-left: 20px;
            &:focus {
                box-shadow: none;
                outline: none !important;
            }
        }
    }
    .filter-option-inner-inner {
        &:focus {
            outline: none;
        }
    }
    .btn-light {
        &:not(:disabled) {
            &:not(.disabled).active {
                background-color: #ffffff;
                color: #7f7f7f;
            }
            &:not(.disabled) {
                &:active {
                    background-color: #ffffff;
                    color: #7f7f7f;
                }
            }
        }
    }
    .show {
        >.btn-light.dropdown-toggle {
            background-color: #ffffff;
            color: #7f7f7f;
        }
    }
}
.mbp_pagination {
    display: inline-block;
    position: relative;
    width: 100%;
    ul.page_navigation {
        height: 70px;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
        li.page-item {
            display: inline-block;
            margin-right: 6px;
            margin-top: 12px;
        }
        li {
            .page-link {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 50%;
                font-size: 14px;
                font-family: Arial;;
                color: #777777;
                line-height: 1.2;
                height: 45px;
                line-height: 30px;
                overflow: hidden;
                text-align: center;
                width: 45px;
                display:flex;
                justify-content: space-evenly;
            }
        }
        li.active {
            .page-link {
                background-color: $red;
                color: #ffffff;
            }
        }
    }
}
.mbp_thumb_post {
    .thumb {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
    }
    .details {
        .post_meta {
            li {
                &:nth-child(2) {
                    margin-right: 20px;
                    margin-left: 8px;
                }
                &:nth-child(4) {
                    margin-left: 8px;
                }
            }
        }
    }
    li {
        display: inline-block;
    }
}
.main_blog_post_content {
    position: relative;
    .mbp_thumb_post {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 30px;
        .details {
            padding-top: 30px;
            a, a:active, a:visited {
                color: #db1c1f;
            }
            a:hover {
                text-decoration: underline;
            }
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.714;
            }
            ul.post_meta {
                margin-bottom: 20px;
            }
            img {
                display: block;
                margin: 0 auto;
                height: auto;
            }
        }
    }
    .event_lists {
        &:hover {
            .blog_grid_post.event_lists {
                -webkit-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
                -moz-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
                -o-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
                box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
            }
        }
    }
}
.blog_sp_tag {
    background-color: $red;
    border-radius: 3px;
    height: 25px;
    margin-bottom: 30px;
    text-align: center;
    width: 110px;
    a {
        font-size: 14px;
        font-family: Arial;;
        color: #fefefe;
        line-height: 1.2;
    }
}
.blog_sp_title {
    font-family: Arial;;
    color: $dark-grey;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 30px;
}
.blog_sp_post_meta {
    margin-bottom: 30px;
    li {
        &:nth-child(2) {
            margin-right: 25px;
        }
        &:nth-child(4) {
            margin-right: 25px;
        }
        &:nth-child(6) {
            margin-right: 25px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.event_counter_plugin_container {
    border-radius: 5px;
    background-image: -moz-linear-gradient( 0deg, rgb(52,82,255) 0%, rgb(255,16,83) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(52,82,255) 0%, rgb(255,16,83) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(52,82,255) 0%, rgb(255,16,83) 100%);
    height: 100px;
    margin-bottom: 15px;
    margin-top: 30px;
    position: relative;
}
.event_counter_plugin_content {
    text-align: center;
    ul {
        margin-bottom: 0;
        text-align: center;
        li {
            color: #ffffff;
            display: inline-block;
            font-size: 15px;
            font-family: Arial;
            color: #ffffff;
            line-height: 2.667;
            margin-top: 10px;
            text-align: left;
            text-transform: capitalize;
            width: 22%;
            &:last-child {
                width: auto;
            }
            span {
                display: block;
                font-size: 26px;
                font-family: Arial;;
                color: #ffffff;
                line-height: 1.2;
            }
        }
    }
}
.blog_grid_post {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(237, 239, 247);
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;
    .thumb {
        position: relative;
        overflow: hidden;
        &:before {
            background-color: rgba(0,0,0, .3);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
        .tag {
            font-size: 14px;
            font-family: Arial;
            color: rgb(250, 250, 250);
            line-height: 1.2;
            left: 30px;
            line-height: 40px;
            position: absolute;
            top: 30px;
        }
        .post_date {
            bottom: 30px;
            font-weight: bold;
            max-width: 95px;
            position: absolute;
            right: 30px;
            text-align: center;
            h2 {
                font-size: 50px;
                font-family: Arial;;
                color: #ffffff;
                margin-bottom: 0;
            }
            span {
                font-size: 18px;
                font-family: Arial;;
                color: #ffffff;
            }
        }
    }
    .details {
        h3 {
            font-size: 26px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 15px;
        }
        padding: 20px 25px 30px 30px;
        ul.post_meta {
            margin-bottom: 18px;
            li {
                a {
                    span {
                        font-size: 15px;
                        font-family: Arial;
                        color: rgb(126, 126, 126);
                        line-height: 1.6;
                    }
                }
            }
        }
        p {
            font-size: 15px;
            font-family: Arial;
            color: rgb(126, 126, 126);
            line-height: 1.6;
            margin-bottom: 0;
        }
        .post_meta {
            li {
                &:nth-child(2) {
                    margin-right: 20px;
                    margin-left: 8px;
                }
                &:nth-child(4) {
                    margin-left: 8px;
                }
            }
        }
    }
    li {
        display: inline-block;
    }
}
.blog_grid_post.style2 {
    .details {
        border-radius: 0 5px 5px 0;
        padding: 60px 45px 55px 40px;
        p {
            font-size: 15px;
            font-family: Arial;
            color: rgb(126, 126, 126);
            line-height: 1.6;
        }
    }
}
.blog_grid_post.style2.event_lists {
    border-left: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    .thumb {
        img {
            max-width: 600px;
            max-height: 385px;
        }
    }
    ul {
        li {
            display: list-item;
        }
    }
    .details {
        padding: 50px 75px 60px 50px;
        h3 {
            font-size: 22px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.091;
        }
        p {
            font-size: 15px;
            font-family: Arial;
            color: rgb(126, 126, 126);
            line-height: 1.6;
        }
        ul {
            margin-top: 25px;
            li {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: 14px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                    span {
                        font-size: 18px;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}
.event_participator_tile {
    font-size: 20px;
    font-family: "Nunito SemiBold";
    color: $dark-grey;
    line-height: 1.2;
    margin-bottom: 50px;
}
.participator_team {
    .thumb {
        img {
            height: 100px;
            width: 100px;
        }
    }
    .details {
        h4 {
            font-size: 18px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.333;
            margin-bottom: 0;
            margin-top: 20px;
        }
        p {
            font-size: 15px;
            font-family: Arial;
            color: rgb(126, 126, 126);
            line-height: 1.2;
        }
    }
}
.sidebar_recent_product {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px;
    padding: 30px 30px 0;
    .title {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 25px;
    }
    .media {
        margin-bottom: 30px;
        img {
            border-radius: 6px;
            height: 80px;
            width: 90px;
        }
        .media-body {
            .post_title {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.2;
            }
            a {
                font-size: 16px;
                font-family: Arial;;
                color: $red;
                font-weight: bold;
                line-height: 1.2;
            }
        }
    }
}
.sidebar_feature_listing {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px;
    padding: 30px 30px 0;
    .title {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 25px;
    }
    .media {
        margin-bottom: 30px;
        .media-body {
            .post_title {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.2;
            }
            a {
                font-size: 16px;
                font-family: Arial;;
                color: $red;
                //font-weight: bold;
                line-height: 1.2;
            }
            ul {
                li {
                    font-size: 14px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.2;
                    margin-right: 0;
                }
            }
        }
    }
}
.sidebar_search_widget {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px;
}
.blog_search_widget {
    button.btn {
        height: 48px;
    }
    input.form-control {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        background-color: #ffffff;
        flex: 1;
        height: 52px;
        padding-left: 20px;
        width: 100%;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:focus {
            border-right: none;
        }
    }
    button#button-addon2 {
        background-color: #fff;
        border-bottom: 1px solid #ebebeb;
        border-left: transparent !important;
        border-right: 1px solid #ebebeb;
        border-top: 1px solid #ebebeb;
        height: 52px;
        &:focus {
            box-shadow: none;
        }
        &:hover {
            color: #6c757d;
        }
    }
}
.blog_category_widget {
    margin-bottom: 30px;
    .title {
        font-size: 20px;
        font-family: "Nunito SemiBold";
        color: $dark-grey;
        line-height: 1.2;
        margin-bottom: 0;
    }
    ul.list-group {
        border-style: solid;
        border-width: 2px;
        border-color: rgb(238, 238, 238);
        border-radius: 5px;
        background-color: rgba(243, 243, 246, 0);
        padding: 30px;
    }
    ul {
        li.list-group-item {
            background-color: transparent;
            border: none;
            font-size: 15px;
            font-family: Arial;
            color: $dark-grey;
            padding: 10px 0;
            span {
                font-size: 15px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                font-weight: normal;
            }
        }
    }
}
.blog_recent_post_widget {
    .title {
        font-size: 20px;
        font-family: "Nunito SemiBold";
        color: $dark-grey;
        line-height: 1.2;
        margin-bottom: 0;
        font-size: 20px;
        margin-bottom: 20px;
    }
    margin-bottom: 30px;
    .media-body {
        .post_title {
            font-size: 16px;
            font-family: Arial;;
            color: #212121;
            line-height: 1.5;
            margin-bottom: 0;
        }
        a {
            font-size: 14px;
            font-family: Arial;
            color: rgb(123, 123, 123);
            line-height: 1.2;
        }
    }
}
.event_details_widget {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    border-radius: 5px;
    background-color: rgba(243, 243, 246, 0);
    margin-bottom: 30px;
    padding: 30px;
    .title {
        font-size: 20px;
        font-family: "Nunito SemiBold";
        color: $dark-grey;
        line-height: 1.2;
        margin-bottom: 25px;
    }
    ul {
        margin-bottom: 0;
        li {
            font-size: 14px;
            font-family: Arial;
            color: rgb(126, 126, 126);
            line-height: 1.5;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-size: 18px;
                padding-right: 10px;
            }
        }
    }
}
.category_sidebar_widget {
    position: relative;
    .category_list {
        margin-bottom: 0;
        li {
            a {
                font-family: Arial;
                font-size: 15px;
                color: rgb(79, 79, 79);
                line-height: 2.667;
                span {
                    font-family: Arial;
                    font-size: 14px;
                    color: rgb(193, 193, 193);
                    line-height: 2.857;
                }
            }
        }
    }
}
.feature_course_widget {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    border-radius: 5px;
    background-color: rgba(243, 243, 246, 0);
    padding: 30px 0 15px;
    margin-bottom: 30px;
    h4 {
        font-family: "Nunito SemiBold";
        font-size: 20px;
        color: $dark-grey;
        line-height: 1.2;
        padding-left: 30px;
    }
    ul {
        li {
            border: 1px solid rgb(235, 235, 235);
            color: $dark-grey;
            font-family: Arial;
            font-size: 15px;
            line-height: 2.667;
            padding: 0 30px;
            span {
                font-size: 15px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                line-height: 2.667;
            }
            &:last-child {
                border-bottom: none;
                padding: 0 30px;
            }
        }
    }
}
.instructor_pricing_widget {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(237, 239, 247);
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px;
    text-align: center;
    .price {
        font-size: 30px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        span {
            font-size: 15px;
            font-family: Arial;
            color: rgb(79, 79, 79);
            line-height: 2.667;
        }
        small {
            font-size: 14px;
            font-family: Arial;
            color: rgb(193, 193, 193);
            line-height: 2.857;
        }
    }
    h5 {
        font-size: 15px;
        font-family: Arial;
        color: rgb(79, 79, 79);
        line-height: 2.667;
    }
    .price_quere_list {
        li {
            a {
                font-size: 15px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                line-height: 2.667;
                span {
                    color: $dark-grey;
                    font-size: 18px;
                    padding-right: 5px;
                }
            }
        }
    }
}
.instructor_pricing_widget.csv2 {
    margin-top: -300px;
}
.cart_btnss {
    border-radius: 27px;
    background-color: rgb(255, 16, 83);
    display: block;
    font-size: 15px;
    font-family: Arial;
    color: #ffffff;
    height: 50px;
    line-height: 3.2;
    margin-bottom: 23px;
    margin-top: 23px;
    max-width: 234px;
    &:hover {
        background-color: #ffffff;
        border: 1px solid #ff1053;
        color: #ff1053;
    }
}
.cart_btnss_white {
    background-color: #ffffff;
    border: 1px solid #ff1053;
    border-radius: 27px;
    color: #ff1053;
    display: block;
    font-size: 15px;
    font-family: Arial;
    line-height: 3.2;
    margin-bottom: 23px;
    height: 50px;
    max-width: 234px;
    &:hover {
        background-color: #ff1053;
        color: #ffffff;
    }
}
.blog_recent_post_widget.media_widget {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    border-radius: 5px;
    background-color: rgba(243, 243, 246, 0);
    padding: 30px 20px 30px 30px;
    .media {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        img {
            border-radius: 8px;
            max-width: 90px;
        }
    }
}
.blog_tag_widget {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    background-color: #ffffff;
    padding: 30px 30px 20px;
    .title {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    .tag_list {
        li {
            border-radius: 6px;
            background-color: rgb(227, 227, 227);
            height: 40px;
            line-height: 40px;
            margin-bottom: 10px;
            padding: 0px 25px;
            &:hover {
                cursor: pointer;
            }
            a {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
            }
        }
    }
}
.mbp_blockquote {
    background-color: rgb(247, 247, 247);
    border-left: 8px solid $red;
    border-radius: 8px;
    height: 147px;
    margin-bottom: 30px;
    margin-top: 25px;
    padding: 25px 30px 30px 40px;
    .blockquote {
        position: relative;
        &:before {
            background-color: transparent;
        }
        span {
            font-size: 16px;
            font-family: Arial;;
            color: rgb(187, 187, 187);
            font-style: italic;
            line-height: 0.333;
        }
        em {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            font-style: italic;
            line-height: 1.714;
        }
    }
}
.blog_post_share {
    li {
        background-color: #f7f7f7;
        border-radius: 8px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        width: 45px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:first-child {
            background-color: transparent;
            margin-right: 25px;
            width: auto;
            a {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 2.5;
            }
            &:hover {
                background-color: transparent;
            }
        }
        &:hover {
            cursor: pointer;
            a {
                color: rgb(72, 73, 77);
            }
        }
        a {
            font-size: 14px;
            color: $dark-grey;
            line-height: 1.2;
        }
        p {
            font-size: 18px;
            font-family: Arial;;
            color: rgb(59, 59, 59);
            line-height: 1.333;
        }
    }
}
.mbp_pagination_tab {
    padding: 35px 0;
    position: relative;
    .pag_prev {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        a {
            background-color: rgb(208, 208, 208);
            border-radius: 50%;
            height: 45px;
            line-height: 45px;
            padding-left: 0;
            text-align: center;
            width: 45px;
            &:hover {
                background-color: $red;
                color: $red;
            }
            span {
                color: #ffffff;
                font-size: 14px;
                line-height: 1.2;
                -moz-transform: matrix( 0,-1,1,0,0,0);
                -webkit-transform: matrix( 0,-1,1,0,0,0);
                -ms-transform: matrix( 0,-1,1,0,0,0);
            }
        }
        &:hover {
            cursor: pointer;
        }
        .detls {
            padding-left: 15px;
            display: inline-block;
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.75;
            margin-bottom: 0;
            h5 {
                font-size: 16px;
                font-family: Arial;;
                font-weight: bold;
                line-height: 1.5;
                margin-bottom: 0;
                &:hover {
                    color: $red;
                }
            }
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.714;
                margin-bottom: 0;
                &:hover {
                    color: $red;
                }
            }
        }
    }
    .pag_next {
        a {
            background-color: rgb(208, 208, 208);
            border-radius: 50%;
            height: 45px;
            line-height: 45px;
            padding-left: 0;
            text-align: center;
            width: 45px;
            display: inline-block;
            float: right;
            padding-right: 0;
            &:hover {
                background-color: $red;
                color: $red;
            }
            span {
                color: #ffffff;
                font-size: 14px;
                line-height: 1.2;
                -moz-transform: matrix( 0,-1,1,0,0,0);
                -webkit-transform: matrix( 0,-1,1,0,0,0);
                -ms-transform: matrix( 0,-1,1,0,0,0);
            }
        }
        &:hover {
            cursor: pointer;
        }
        .detls {
            padding-right: 15px;
            display: inline-block;
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.75;
            margin-bottom: 0;
            h5 {
                font-size: 16px;
                font-family: Arial;;
                font-weight: bold;
                line-height: 1.5;
                margin-bottom: 0;
                &:hover {
                    color: $red;
                }
            }
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.714;
                margin-bottom: 0;
                &:hover {
                    color: $red;
                }
            }
        }
    }
}
.mbp_pagination_comments {
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    position: relative;
    .total_review {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        padding: 30px 30px 0;
        position: relative;
        h4 {
            margin-bottom: 0;
        }
        ul.review_star_list {
            li {
                margin-right: 3px;
            }
        }
    }
    .title {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .mbp_first.media {
        padding: 30px 30px;
        .media-body {
            p {
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.714;
                margin-bottom: 0;
            }
        }
    }
    .mbp_first {
        img {
            margin-right: 1.6rem !important;
        }
        .media-body {
            .sub_title {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
    }
    .review_star_list {
        li {
            i {
                font-size: 14px;
                color: #bcc52a;
            }
        }
    }
    a.write_review {
        font-size: 14px;
        font-family: Arial;;
        color: $red;
        position: absolute;
        right: 30px;
    }
    .tr_outoff {
        font-size: 14px;
        font-family: Arial;;
        color: rgb(118, 118, 118);
    }
}
.mbp_comment_form {
    margin-top: 20px;
    h4 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .comments_form {
        background-color: #f1f1f1;
        padding: 30px 50px 50px;
        input {
            border: 1px solid #dddddd;
            height: 50px;
            &:focus {
                box-shadow: none;
            }
        }
        button.btn {
            &:focus {
                box-shadow: none;
            }
            border-radius: 4px;
            font-size: 16px;
            height: 55px;
            margin-top: 10px;
        }
        textarea {
            &:focus {
                box-shadow: none;
            }
            border: 1px solid #dddddd;
            height: auto;
        }
        .form-group {
            label {
                color: #221f1f;
            }
        }
        button {
            span {
                padding-left: 10px;
            }
        }
    }
}
.our-dashbord {
    position: relative;
}
.our-dashbord.dashbord {
    padding-top: 145px;
    .container-fluid {
        padding-left: 3%;
        padding-right: 60px;
    }
}
.breadcrumb_widgets {
    border-radius: 5px;
    background-color: rgb(225, 230, 239);
    height: 70px;
    margin-bottom: 30px;
    padding: 20px 30px;
    position: relative;
    .title {
        font-size: 22px;
        font-family: "Nunito SemiBold";
        color: rgb(10, 10, 10);
        line-height: 1.2;
        margin-bottom: 0;
    }
    .breadcrumb {
        background-color: transparent;
        padding: 0;
        position: relative;
        .breadcrumb-item {
            font-family: Arial;
            font-size: 15px;
            color: #7e7e7e;
        }
        .breadcrumb-item.active {
            color: rgb(126, 126, 126);
            line-height: 1.6;
        }
    }
}
.user_board {
    padding: 0 15px 0 0;
    position: relative;
}
.user_profile {
    margin-bottom: 10px;
    position: relative;
    .media {
        .media-body {
            h4 {
                font-size: 18px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                padding-left: 30px;
            }
            h5 {
                margin-bottom: 0;
            }
            p {
                margin-bottom: 0;
            }
        }
        img {
            width: 65px;
        }
    }
}
.dashbord_nav_list {
    h4 {
        position: relative;
        padding-left: 30px;
    }
    li {
        height: 50px;
        line-height: 45px;
        padding-left: 30px;
        position: relative;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        a {
            color: #7f7f7f;
            font-size: 16px;
            span {
                font-size: 23px;
                margin-right: 15px;
            }
        }
        &:hover {
            a {
                color: $red;
                span {
                    color: $red;
                }
            }
        }
    }
    li.active {
        background-color: #f9fafc;
        a {
            color: $red;
        }
        &:before {
            background-color: $red;
            bottom: 0;
            content: "";
            height: 50px;
            position: absolute;
            right: 0px;
            top: 0;
            width: 2px;
        }
    }
}
.my_dashboard_review {
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    padding: 30px;
    .review_content {
        .review_title {
            font-size: 18px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 0;
        }
        .review_date {
            font-size: 14px;
            font-family: Arial;;
            color: rgb(118, 118, 118);
            line-height: 2.143;
        }
        .para {
            margin-top: 10px;
        }
        .sspd_review {
            ul {
                li {
                    margin-right: 3px;
                    a {
                        font-size: 14px;
                        font-family: "FontAwesome";
                        color: rgb(188, 197, 42);
                        line-height: 2.143;
                    }
                }
            }
        }
    }
}
.my_listing_single {
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    padding: 30px;
}
.packages_table {
    .table {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        margin-bottom: 30px;
        .thead-light {
            th {
                background-color: rgb(36, 50, 74);
                border: none;
                font-size: 16px;
                font-family: Arial;;
                color: #ffffff;
                font-weight: bold;
                line-height: 2.2;
            }
        }
        td {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: 600;
            line-height: 3;
            padding-left: 30px;
        }
        th {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: 600;
            line-height: 3;
            padding-left: 30px;
        }
    }
}
.savesearched_table {
    .table {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        margin-bottom: 30px;
        .thead-light {
            th {
                background-color: rgb(36, 50, 74);
                border: none;
                font-size: 16px;
                font-family: Arial;;
                color: #ffffff;
                font-weight: bold;
                line-height: 2.2;
            }
        }
        td {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: 600;
            line-height: 3;
            padding-left: 30px;
        }
        th {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: 600;
            line-height: 3;
            padding-left: 30px;
        }
    }
    td.para {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
    }
}
.property_table {
    .table {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        margin-bottom: 30px;
        .thead-light {
            th {
                background-color: rgb(36, 50, 74);
                border: none;
                font-size: 16px;
                font-family: Arial;;
                color: #ffffff;
                font-weight: bold;
                line-height: 2.2;
            }
        }
        td {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: 600;
            line-height: 3;
            padding-left: 30px;
            vertical-align: middle;
        }
        th {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: 600;
            line-height: 3;
            padding-left: 30px;
            vertical-align: middle;
        }
    }
    td.para {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
    }
}
.pck_chng_btn {
    .btn {
        border-radius: 8px;
        background-color: $red;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        font-size: 16px;
        font-family: Arial;;
        color: #ffffff;
        font-weight: bold;
        line-height: 1.2;
        height: 50px;
        width: 200px;
    }
}
.skill_sidebar_widget {
    margin-top: 40px;
    margin-left: 15px;
    padding-right: 50px;
    h4 {
        font-size: 17px;
        margin-bottom: 20px;
    }
    .sonny_progressbar {
        .bar {
            border-radius: 5px;
            height: 10px;
        }
        .bar-container {
            border-radius: 5px;
            height: 10px !important;
            overflow: hidden;
        }
    }
}
.password_change_form {
    form {
        input {
            border-style: solid;
            border-width: 1px;
            border-color: rgb(221, 221, 221);
            border-radius: 5px;
            background-color: rgba(196, 14, 117, 0);
            height: 48px;
            margin-bottom: 30px;
            padding-left: 20px;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            &:focus {
                -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
                -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
                -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
                outline: none;
            }
        }
        button {
            border-radius: 4px;
            height: 55px;
            padding: 0 35px;
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }
}
.header_user_notif {
    margin-bottom: 0;
    margin-top: 10px;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    li {
        display: inline-block;
        vertical-align: middle;
        &:first-child {
            margin-right: 20px;
        }
        &:nth-child(2) {
            margin-right: 15px;
        }
        span {
            color: #ffffff;
            font-size: 20px;
            line-height: 2;
        }
        .dropdown {
            .notification_icon {
                border-radius: 50%;
                background-color: #e35a9a;
                display: block;
                height: 43px;
                line-height: 40px;
                text-align: center;
                width: 43px;
            }
        }
        img {
            width: 50px;
        }
    }
    .user_notif {
        position: relative;
        padding: 0;
        .dropdown-menu.notification_dropdown_content.show {
            border: none;
            border-radius: 5px;
            margin-top: 25px;
            margin-left: -270px !important;
            padding: 30px 10px 25px 30px;
            -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
            -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
            -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
            box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
            max-width: 340px;
            .view_all_noti {
                display: block;
                padding: 20px 0 0;
            }
        }
        .so_heading {
            &:before {
                background-color: #ffffff;
                content: "";
                height: 37px;
                position: absolute;
                right: 30px;
                width: 37px;
                top: -7px;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            p {
                border-bottom: 1px solid #dedede;
                color: $dark-grey;
                font-size: 18px;
                font-family: Arial;;
                line-height: 1.333;
                margin-bottom: 0;
                padding-bottom: 20px;
            }
        }
        .so_content {
            max-height: 200px;
            .simplebar-offset {
                width: auto;
            }
            ul {
                margin-bottom: 0;
                li {
                    border-bottom: 1px solid #dedede;
                    padding: 20px 0 20px;
                    h5 {
                        font-size: 15px;
                        margin-bottom: 0;
                    }
                    p {
                        font-size: 15px;
                        margin-bottom: 0;
                        color: #7f7f7f;
                    }
                    &:first-child {
                        margin-right: 0;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .simplebar-offset {
            &:focus {
                outline: none;
            }
            &::-webkit-scrollbar {
                margin-top: 15px;
                margin-right: 10px;
                width: 6px;
                z-index: 3;
                * {
                    background: transparent;
                }
            }
            &::-webkit-scrollbar-track {
                display: none;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #dfdfdf !important;
                // manage scrollbar thumb background color here border-radius: 6px;
            }
        }
    }
    .user_setting {
        .dropdown-menu.notification_dropdown_content.show {
            &:before {
                background-color: #ffffff;
                content: "";
                height: 37px;
                position: absolute;
                right: 40px;
                width: 37px;
                top: -7px;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}
.simplebar-placeholder {
    max-height: 355px !important;
    width: 325px !important;
}
.user_setting {
    position: relative;
    &:before {
        content: "";
        background-color: #e8eef2;
        height: 38px;
        position: absolute;
        top: 10px;
        width: 1px;
    }
    .btn {
        border: 1px solid transparent;
        color: #ffffff;
        &:focus {
            border: 1px solid transparent;
            box-shadow: none;
        }
    }
    a.btn.dropdown-toggle {
        &:after {
            display: none;
        }
    }
    .dropdown-menu.show {
        border: none;
        border-radius: 5px;
        margin-top: 19px;
        margin-left: -200px !important;
        padding: 20px 30px 10px;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        width: 300px;
        &:before {
            background-color: #ffffff;
            content: "";
            height: 37px;
            position: absolute;
            right: 40px;
            width: 37px;
            top: -7px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .user_set_header {
            padding: 10px 0;
            img {
                margin-right: 15px;
            }
        }
    }
    .dropdown-menu {
        .user_set_header {
            p {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                span {
                    font-size: 14px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.2;
                }
            }
        }
        .user_setting_content {
            padding: 15px 0 0;
            a {
                color: $dark-grey;
                font-size: 16px;
                font-family: Arial;;
                line-height: 2.125 !important;
                padding-left: 0;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
                &:hover {
                    padding-left: 12px;
                    background-color: transparent;
                    span {
                        background-color: transparent;
                    }
                }
                span {
                    margin-right: 10px;
                }
            }
            a.active {
                background-color: transparent;
                span {
                    background-color: transparent;
                }
            }
        }
    }
}
.candidate_revew_search_box {
    input.form-control {
        background-color: #ffffff;
        border: 1px solid rgb(235, 235, 235);
        border-right: none;
        border-radius: 8px 0px 0px 8px;
        height: 52px;
        margin-right: 0 !important;
        padding-left: 20px;
        padding-right: 0;
        width: 258px;
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    button {
        background-color: #ffffff;
        border: 1px solid rgb(235, 235, 235);
        border-left: none;
        border-radius: 0 8px 8px 0;
        color: $dark-grey;
        height: 52px;
        width: 70px;
        &:hover {
            color: $dark-grey;
        }
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}
.candidate_revew_select {
    .bootstrap-select {
        >.dropdown-toggle {
            background-color: #ffffff;
            border: 1px solid #dddddd;
            border-radius: 4px;
            color: #7f7f7f;
            height: 50px;
            line-height: 35px;
            &:hover {
                background-color: #ffffff;
                color: #7f7f7f;
                box-shadow: none;
                outline: none !important;
            }
            &:focus {
                background-color: #ffffff;
                color: #7f7f7f;
                box-shadow: none;
                outline: none !important;
            }
            &:active {
                background-color: #ffffff;
                color: #7f7f7f;
                box-shadow: none;
                outline: none !important;
            }
        }
    }
    .dropdown-item.active {
        background-color: #f7f7f7;
        color: $dark-grey;
    }
    .dropdown-item {
        &:active {
            background-color: #f7f7f7;
            color: $dark-grey;
        }
    }
}

.grid_list_search_result.style2 {
    width: 98%;
}
.half_map_advsrch_navg.style2 {
    ul {
        li {
            margin-right: 10px;
        }
    }
}
.half_map_advsrch_navg.style3 {
    ul {
        li {
            &:first-child {
                margin-right: 15px;
                &:before {
                    display: none;
                }
            }
            margin-right: 10px;
        }
    }
}
.half_map_area_content {
    height: 850px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 15px;
    @media (max-width: 1290px) {
        height: 400px;
    }
    @include screen-xs {
        height: 100vh;
    }
    &::-webkit-scrollbar {
        background: transparent;
        border-radius: 3px;
        padding-right: 10px;
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #f7f7f7;
        border-radius: 3px;
        height: 250px;
    }
    .search_option_two.home2 {
        .dropdown-menu {
            top: 0 !important;
        }
    }
    .grid_list_search_result {
        width: 97%;
    }
}
.half_map_area.version4 {
    bottom: 0;
    width: 100%;
    top: 0;
}
.our-listing-list-v4 {
    &::-webkit-scrollbar {
        background: transparent;
        border-radius: 3px;
        padding-right: 10px;
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #f7f7f7;
        border-radius: 3px;
    }
}
.half_style {
    .search_option_two {
        .dropdown.bootstrap-select {
            >.dropdown-toggle {
                background-color: #ffffff;
            }
        }
    }
}
.h1ads_1st_list.half_style {
    input.form-control {
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        background-color: #ffffff;
        height: 52px;
        line-height: 50px;
        padding-left: 20px;
        &::placeholder {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.2;
        }
    }
    li {
        &:last-child {
            margin-right: 0 !important;
        }
    }
    .navbered {
        background-color: #ffffff;
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        height: 52px;
        line-height: 50px;
        text-align: center;
        width: 142px;
        .dropbtn {
            i {
                color: #bdc6ce;
                float: right;
                margin-left: -15px;
                margin-right: 15px;
            }
        }
        .dropdown-content {
            &:before {
                right: 5%;
            }
        }
    }
    li.custome_fields_half {
        &:nth-child(2) {
            width: 69%;
        }
        &:nth-child(3) {
            width: 12%;
        }
    }
    .apeartment_area_list {
        li {
            &:nth-child(2) {
                width: auto;
            }
        }
    }
}
.feature_property_half_clist {
    li.extrawide {
        margin-left: 10px;
        margin-right: 0;
        width: 32%;
    }
}
.feature_property_half_clist.style2 {
    li.extrawide {
        width: 48%;
    }
}
.listing_list_style {
    margin-top: 18px;
    position: relative;
    text-align: right;
    ul {
        li {
            background-color: #ffffff;
            border: 1px solid rgb(235, 235, 235);
            border-radius: 8px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            width: 49px;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background-color: rgb(227, 227, 227);
                cursor: pointer;
            }
            a {
                color: $dark-grey;
            }
        }
    }
}
.sidebar_listing_list {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px;
}
.sidebar_listing_list.style2 {
    border: none;
    max-height: 960px;
    overflow-y: scroll;
    padding: 30px 25px;
    &::-webkit-scrollbar {
        background: transparent;
        border-radius: 3px;
        padding-right: 0;
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}
.sl_creator {
    margin-bottom: 30px;
    .media-body {
        h5 {
            font-size: 16px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            font-weight: bold;
            line-height: 1.2;
        }
        p {
            font-size: 14px;
            font-family: Arial;;
            color: rgb(72, 72, 72);
            line-height: 1.5;
            margin-bottom: 0;
        }
        a {
            font-size: 14px;
            font-family: Arial;;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 0;
        }
    }
}
.sasw_list.style2 {
    .search_area {
        input.form-control {
            &::placeholder {
                color: #006c70 !important;
            }
        }
    }
    .search_option_two {
        .filter-option-inner-inner {
            color: #006c70 !important;
        }
    }
    .small_dropdown2 {
        .dd_btn {
            span {
                color: #006c70 !important;
            }
        }
    }
    .min_area.style2 {
        input.form-control {
            &::placeholder {
                color: #006c70 !important;
            }
        }
    }
    .max_area {
        input.form-control {
            &::placeholder {
                color: #006c70 !important;
            }
        }
    }
    .panel-heading {
        h4.panel-title {
            a.accordion-toggle.link {
                color: #006c70 !important;
            }
        }
    }
}
.sidebar_feature_property_slider {
    .owl-prev {
        background-color: transparent !important;
        left: 0;
        position: absolute;
        top: 33%;
    }
    .owl-next {
        background-color: transparent !important;
        left: 0;
        position: absolute;
        top: 33%;
        left: auto;
        right: 0;
    }
    .feat_property {
        margin-bottom: 20px;
        .thumb {
            height: 220px;
            .thmb_cntnt {
                a.fp_price {
                    bottom: 50px;
                    left: 10px;
                }
                h4 {
                    bottom: 10px;
                    left: 10px;
                    position: absolute;
                }
            }
        }
    }
}
.portfolio_upload {
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    height: 223px;
    margin-bottom: 30px;
    text-align: center;
    .btn {
        color: #ffffff;
        cursor: pointer;
        padding: 8px 20px;
        font-size: 46px;
        font-weight: normal;
    }
    .icon {
        font-size: 48px;
        margin-top: 50px;
        color: $red;
        font-size: 48px;
        margin-top: 50px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    p {
        font-size: 22px;
        font-family: Arial;;
        color: rgb(72, 72, 72);
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 0;
        position: relative;
    }
    input[type=file] {
        font-size: 100px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}
.message_container {
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    height: 100%;
    position: relative;
    .inbox_chatting_box {
        position: relative;
        max-height: 575px !important;
        height: auto;
        margin-right: 10px;
        max-height: calc(100% - 93px);
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            background: transparent;
            border-radius: 3px;
            padding-right: 10px;
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ededed;
            border-radius: 3px;
        }
    }
    .user_heading {
        background-color: #ffffff;
        border-bottom: 1px solid rgb(235, 235, 235);
        border-radius: 8px 8px 0 0;
        padding: 30px;
        position: relative;
        .wrap {
            img {
                float: left;
                margin-right: 10px;
                border-radius: 50%;
            }
            h5.name {
                font-size: 16px;
                font-family: Arial;;
                color: rgb(10, 10, 10);
                line-height: 1.2;
                margin-bottom: 0;
            }
            p.preview {
                font-size: 15px;
                font-family: Arial;
                color: rgb(127, 127, 127);
                line-height: 1.867;
                margin-bottom: 0;
            }
        }
    }
    .message_input {
        background-color: #f9fafc;
        bottom: 30px;
        left: 30px;
        position: absolute;
        right: 30px;
        text-align: center;
        form {
            input.form-control {
                background-color: #f7f7f7;
                border-color: transparent;
                border-radius: 8px;
                height: 70px;
                width: 100%;
                padding-left: 30px;
                &::placeholder {
                    font-size: 14px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.714;
                }
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
            button.btn {
                background-color: $red;
                border: 1px solid #ffffff;
                border-radius: 8px;
                color: #ffffff;
                font-size: 16px;
                flex: 1;
                height: 50px;
                position: absolute;
                right: 20px;
                width: 190px;
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
                span {
                    font-size: 20px;
                    padding-left: 10px;
                }
            }
        }
    }
}
.inbox_user_list {
    display: inline-block;
    padding: 30px 30px 40px;
    width: 100%;
    ul {
        height: 625px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            background: transparent;
            border-radius: 3px;
            padding-right: 10px;
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #f7f7f7;
            border-radius: 3px;
            height: 250px;
        }
        li {
            padding: 10px 0;
        }
        li.contact {
            .wrap {
                .meta {
                    display: inline-block;
                    margin-top: 10px;
                }
                span {
                    background-color: #95a5a6;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    height: 13px;
                    left: 50px;
                    margin: 0px 0 0 1px;
                    position: absolute;
                    width: 13px;
                }
                span.online {
                    background-color: #2ecc71;
                }
                span.away {
                    background-color: #f1c40f;
                }
                span.busy {
                    background-color: #e74c3c;
                }
                img {
                    border-radius: 50%;
                    float: left;
                    margin-right: 10px;
                    width: 50px;
                }
            }
        }
    }
    .wrap {
        position: relative;
        img {
            border-radius: 50%;
            float: left;
            margin-right: 15px;
        }
        .meta {
            h5.name {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.2;
                margin-bottom: 0;
            }
            p.preview {
                font-size: 14px;
                font-family: Arial;;
                color: rgb(119, 119, 119);
                line-height: 2;
                margin-bottom: 0;
            }
        }
        .m_notif {
            border: 2px solid #ffffff;
            border-radius: 50%;
            background-color: rgb(252, 68, 90);
            color: #ffffff;
            font-size: 10px;
            font-family: "Lato";
            line-height: 17px;
            height: 21px;
            position: absolute;
            right: 5%;
            text-align: center;
            top: 10px;
            width: 21px;
        }
    }
    .iu_heading {
        padding-bottom: 25px;
    }
}
.iu_heading {
    .candidate_revew_search_box {
        input.form-control {
            width: 75%;
        }
        .candidate_revew_search_box {
            button {
                background-color: transparent;
            }
        }
    }
}
.inbox_chatting_box {
    .chatting_content {
        display: inline-block;
        padding: 15px 30px 0 30px;
        position: relative;
        width: 100%;
        li {
            padding: 20px 0;
            margin-bottom: 0;
        }
        li.media.sent {
            float: left;
            clear: both;
        }
        li.media {
            img {
                border-radius: 50px;
                margin-bottom: 10px;
            }
            .media-body {
                display: block;
                flex: auto;
                .date_time {
                    font-size: 14px;
                }
                p {
                    background-color: #f7f7f7;
                    border: 1px solid #f7f7f7;
                    border-radius: 3px;
                    color: $dark-grey;
                    font-size: 14px;
                    font-family: Arial;
                    margin-bottom: 0;
                    padding: 10px 15px;
                    max-width: 290px;
                }
            }
            span {
                background-color: #95a5a6;
                border: 1px solid #ffffff;
                border-radius: 50%;
                height: 13px;
                left: 80px;
                margin: 2px 0 0 -2px;
                position: absolute;
                width: 13px;
            }
            span.busy {
                background-color: #e74c3c;
            }
        }
        li.media.reply {
            float: right;
            clear: both;
            .media-body {
                p {
                    border-radius: 3px;
                    background-color: #eef1ff;
                    color: $dark-grey;
                    max-width: 320px;
                    padding: 10px 15px;
                }
            }
        }
        li.media.reply.first {
            margin: -15px 0;
        }
    }
}
.my_profile_select_box {
    label {
        color: #221f1f;
    }
    margin-bottom: 20px;
    .dropdown.bootstrap-select {
        display: inline;
        button.btn {
            background-color: transparent;
            border: 1px solid #dddddd;
            border-radius: 4px;
            color: #7f7f7f;
            height: 50px;
            line-height: 35px;
            &:focus {
                box-shadow: none;
                outline: none !important;
            }
        }
        &:focus {
            border: 1px solid #dddddd;
        }
    }
}
.ff_one {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(235, 235, 235);
    border-radius: 8px;
    height: 150px;
    margin-bottom: 30px;
    position: relative;
    padding: 20px 20px 20px 25px;
    .icon {
        border-radius: 50%;
        background-color: rgba(29, 41, 62, 0.102);
        float: right;
        height: 100px;
        line-height: 100px;
        text-align: center;
        width: 100px;
        span {
            color: #1d293e;
            font-size: 36px;
        }
    }
    .detais {
        padding-top: 25px;
        .timer {
            font-size: 36px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.2;
        }
        p {
            font-size: 16px;
            font-family: Arial;;
            color: rgb(119, 119, 119);
            margin-bottom: 0;
        }
    }
}
.ff_one.style2 {
    .icon {
        span {
            color: #9499da;
        }
        background-color: rgba(148, 153, 218, 0.149);
    }
}
.ff_one.style3 {
    .icon {
        span {
            color: $red;
        }
        background-color: rgba(255, 90, 95, 0.149);
    }
}
.ff_one.style4 {
    .icon {
        span {
            color: #ffbc7d;
        }
        background-color: rgba(255, 188, 125, 0.149);
    }
}
.application_statics {
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    padding: 30px;
    position: relative;
    h4 {
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
    }
}
.recent_job_activity {
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    padding: 30px;
    h4 {
        color: $dark-grey;
        font-size: 18px;
        font-family: Arial;;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    .grid {
        margin-bottom: 25px;
        ul.bb_none {
            border-bottom: none;
        }
        ul {
            li {
                &:first-child {
                    border-radius: 50%;
                    background-color: rgb(255, 232, 233);
                    height: 45px;
                    line-height: 45px;
                    margin-right: 15px;
                    text-align: center;
                    width: 45px;
                    .icon {
                        color: $red;
                        font-size: 20px;
                    }
                }
                p {
                    font-size: 14px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.714;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.dashboard_sidebar_menu {
    position: relative;
    z-index: 9999;
}
.sidebar-menu {
    background-color: #1d293e;
    bottom: 0;
    height: auto;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    width: 295px;
    z-index: 9;
    &::-webkit-scrollbar {
        border-radius: 6px;
        margin-top: 15px;
        margin-right: 10px;
        width: 6px;
        z-index: 3;
        * {
            background: transparent;
            border-radius: 6px;
        }
    }
    &::-webkit-scrollbar-track {
        background-color: #1d293e;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #1d293e !important;
        // manage scrollbar thumb background color here border-radius: 6px;
    }
    li {
        position: relative;
        margin: 0;
        padding: 0;
        a {
            border-left: 2px solid transparent;
            color: rgb(138, 153, 179);
            display: block;
            font-size: 14px;
            font-family: Arial;;
            line-height: 3.571;
            padding: 0 15px;
            .fa {
                width: 20px;
            }
            span {
                padding-left: 10px;
            }
            .fa-angle-down {
                width: auto;
                height: auto;
                padding: 0;
                margin-right: 10px;
                margin-top: 18px;
            }
        }
        &:hover {
            a {
                color: #ffffff;
                background-color: #172133;
                border-left-color: $red;
            }
        }
        .treeview-menu {
            margin: 0;
            background-color: #172133;
        }
        .label {
            margin-top: 3px;
            margin-right: 5px;
        }
        .badge {
            margin-top: 3px;
            margin-right: 5px;
        }
    }
    li.active {
        a {
            color: #ffffff;
            background-color: #172133;
            border-left-color: $red;
            .fa-angle-down {
                -webkit-transform: rotate(-180deg);
                -moz-transform: rotate(-180deg);
                -o-transform: rotate(-180deg);
                transform: rotate(-180deg);
            }
        }
        .treeview-menu {
            display: block;
        }
    }
    li.header {
        background-color: #24324a;
        color: #ffffff;
        font-family: Arial;;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.2;
        padding: 20px 0 10px 30px;
        text-align: left;
    }
    a {
        color: #b8c7ce;
        text-decoration: none;
    }
    .treeview-menu {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;
        .treeview-menu {
            padding-left: 20px;
        }
        li {
            margin: 0;
            a {
                color: rgb(138, 153, 179);
                display: block;
                font-size: 14px;
                font-family: Arial;;
                line-height: 2.5;
                padding-left: 30px;
                .fa {
                    width: 20px;
                }
                i {
                    font-size: 8px;
                }
                .fa-angle-down {
                    width: auto;
                }
                .fa-angle-up {
                    width: auto;
                }
                &:hover {
                    color: #fff;
                }
            }
        }
        li.active {
            a {
                color: #fff;
            }
        }
    }
    .title {
        font-size: 16px;
        font-family: Arial;;
        color: rgb(96, 112, 140);
        line-height: 1.5;
        margin-top: 30px;
        padding-left: 15px;
    }
}
.siderbar_contact_widget {
    h4 {
        font-size: 20px;
        font-family: "Nunito SemiBold";
        color: $dark-grey;
        margin-bottom: 15px;
        line-height: 1.2;
    }
    p {
        font-size: 15px;
        font-family: Arial;
        color: rgb(126, 126, 126);
        margin-bottom: 0;
        line-height: 2.667;
    }
    .scw_social_icon {
        li {
            a {
                font-size: 16px;
                font-family: "FontAwesome";
                color: rgb(126, 126, 126);
                line-height: 1.2;
            }
        }
    }
}
.cl_pricing_slider {
    position: relative;
    padding: 0 10px;
    p {
        margin-bottom: 0;
    }
    div#slider-range {
        background-color: #dfdfdf;
        border-radius: 3px;
        height: 6px;
        margin-bottom: 25px;
        margin-top: 25px;
    }
    .ui-slider-range.ui-corner-all.ui-widget-header {
        background-color: $red;
    }
    span.ui-slider-handle.ui-corner-all.ui-state-default {
        background-color: #fff;
        border: 2px solid $red;
        border-radius: 50%;
        height: 18px;
        outline: none;
        top: -7px;
        width: 18px;
    }
    .sl_input {
        background-color: transparent;
        border: none;
        color: #221f1f;
        text-align: center;
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}
.cl_skill_checkbox {
    .content.ui_kit_checkbox {
        border-radius: 6px;
        height: 250px;
        overflow: hidden scroll;
        text-align: left;
        &::-webkit-scrollbar {
            border-radius: 6px;
            margin-top: 15px;
            margin-right: 10px;
            width: 6px;
            z-index: 3;
            * {
                background: transparent;
                border-radius: 6px;
            }
        }
        &::-webkit-scrollbar-track {
            background-color: #dfdfdf;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a2a2a2 !important;
            // manage scrollbar thumb background color here border-radius: 6px;
        }
    }
}
.wrap-custom-file {
    position: relative;
    display: inline-block;
    width: 260px;
    height: 260px;
    text-align: center;
    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 2px;
        overflow: hidden;
        opacity: 0;
    }
    label {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        background-image: url(../images/team/upload_photo.jpg);
        border-radius: 5px;
        -webkit-transition: -webkit-transform 0.4s;
        transition: -webkit-transform 0.4s;
        transition: transform 0.4s;
        transition: transform 0.4s, -webkit-transform 0.4s;
        span {
            border-radius: 8px;
            background-color: #ffffff;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            bottom: 20px;
            color: $dark-grey;
            font-size: 14px;
            font-family: Arial;;
            line-height: 2.2;
            height: 50px;
            left: 20px;
            position: absolute;
            width: 160px;
        }
        i {
            color: $red;
            display: inline-block;
            font-size: 20px;
            margin-right: 5px;
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
            transform: rotate(-180deg);
        }
    }
    label.file-ok {
        background-size: cover;
        background-position: center;
        span {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0.3rem;
            font-size: 1.1rem;
            color: #ffffff;
            background-color: rgba(36, 65, 231, 0.9);
        }
        .fa {
            display: none;
        }
    }
}
.avatar-upload {
    position: relative;
    .avatar-edit {
        position: absolute;
        right: 0;
        top: 0;
        width: 27%;
        input {
            display: none;
            + {
                label {
                    height: 50px;
                    border-radius: 8px;
                    background-color: rgb(29, 41, 62);
                    cursor: pointer;
                    font-size: 16px;
                    font-family: Arial;;
                    color: rgb(255, 255, 255);
                    font-weight: bold;
                    line-height: 30px;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -o-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                    &:hover {
                        background-color: #ffffff;
                        border: 1px solid $red;
                        &:after {
                            color: $red;
                        }
                    }
                    &::after {
                        content: "Upload";
                        color: #ffffff;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        right: 0;
                        text-align: center;
                        margin: auto;
                    }
                }
            }
        }
    }
    .avatar-preview {
        height: 50px;
        left: 0;
        position: absolute;
        width: 70%;
    }
    #imagePreview {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        height: 100%;
    }
}
.portfolio_item {
    border-radius: 8px;
    background-color: rgb(247, 247, 247);
    height: 200px;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    width: 200px;
    img {
        height: 200px;
        width: 200px;
    }
    .edu_stats_list {
        border-radius: 8px;
        background-color: $red;
        cursor: pointer;
        height: 35px;
        line-height: 35px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 10px;
        width: 35px;
        span {
            color: rgb(255, 255, 255);
        }
    }
}
.resume_uploader {
    .upload {
        input[type="file"] {
            display: none;
        }
        border-radius: 8px;
        border: 1px solid rgb(29, 41, 62);
        background-color: rgb(29, 41, 62);
        color: #ffffff;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-family: Arial;;
        height: 50px;
        line-height: 50px;
        margin-top: 0;
        outline: 0;
        position: relative;
        text-align: center;
        width: 200px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: #ffffff;
            border: 1px solid $red;
            color: $red;
        }
        &:active {
            background-color: #ffffff;
        }
    }
    .upload-path {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        flex: 1;
        height: 50px;
        margin-right: 20px;
        outline: none;
        padding-left: 20px;
        width: 70%;
    }
}
.company_life {
    input.amount {
        text-align: right;
        color: #221f1f;
        background-color: transparent;
        box-shadow: none;
        border: none;
        display: inline-block;
        margin-top: 30px;
        width: 40%;
    }
    input.amount2 {
        text-align: left;
        color: #221f1f;
        background-color: transparent;
        box-shadow: none;
        border: none;
        display: inline-block;
        margin-top: 30px;
        width: 40%;
    }
    .cl_pricing_slider {
        span.ui-slider-handle.ui-corner-all.ui-state-default {
            top: -4px;
        }
    }
    .ui-slider-horizontal {
        background-color: #dfdfdf !important;
        height: 6px;
    }
    .slider-range {
        width: 100%;
    }
}
.alpha-pag {
    background: #ffffff;
    margin-bottom: 35px;
    text-align: center;
    ul {
        margin-bottom: 0;
    }
    ul.option-set {
        li {
            margin-right: 40px;
            &:last-child {
                margin-right: 0;
            }
            a {
                padding: 0 0 5px;
            }
            a.selected {
                border-bottom: 2px solid $red;
                color: $red;
            }
        }
    }
    li {
        a {
            color: #7d7d7d;
            padding: 0 8px;
            &:hover {
                color: #221f1f;
            }
        }
    }
}
.emply-text-sec {
    float: left;
    width: 100%;
}
.emply-text {
    float: left;
    margin-bottom: 40px;
    >h4 {
        margin-bottom: 30px;
    }
}
.blog_post_home2.one {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px 25px;
    .bph2_header {
        &:before {
            display: none;
        }
    }
    .details {
        bottom: 0;
        margin-top: 210px;
        padding: 0;
        position: relative;
        h4 {
            color: $dark-grey;
        }
        p {
            margin-bottom: 0;
        }
        .post_meta {
            ul {
                li {
                    a {
                        color: #898989;
                    }
                }
            }
        }
    }
}
.read_more_home5 {
    h4 {
        font-weight: bold;
    }
}
.testimonial_content {
    background-color: #ffffff;
    border-radius: 4px;
    height: 300px;
    margin: 70px 100px;
    position: relative;
    padding: 60px 100px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
    .thumb {
        display: inline-block;
        margin-bottom: 40px;
        margin-top: -120px;
        position: relative;
        h4 {
            margin-bottom: 0;
        }
        p {
            color: $red;
            margin-bottom: 0;
        }
        img {
            border-radius: 50%;
            margin-bottom: 20px;
        }
    }
    .details {
        p {
            font-size: 15px;
            line-height: 28px;
            margin: 0 auto;
            max-width: 510px;
        }
    }
}
.t_icon.home3 {
    color: #1c327e;
    left: 130px;
    top: 0px;
}
.form_grid {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    padding: 30px;
    h4 {
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    p {
        font-size: 14px;
        line-height: 1.714;
        margin-bottom: 30px;
    }
    .contact_form {
        input {
            border: 1px solid #ebebeb;
            border-radius: 8px;
            background-color: #ffffff;
            color: $dark-grey;
            font-size: 14px;
            height: 50px;
            line-height: 1.2;
            margin-bottom: 20px;
            padding-left: 20px;
        }
        textarea.form-control {
            background-color: #ffffff;
            border: 1px solid #ebebeb;
            border-radius: 8px;
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 25px;
            padding: 20px;
        }
        button {
            border-radius: 8px;
            background-color: $red;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            font-size: 16px;
            font-family: Arial;;
            color: #ffffff;
            font-weight: bold;
            line-height: 1.2;
            height: 50px;
            width: 200px;
        }
    }
}
.contact_localtion {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 30px;
    position: relative;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    p {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.714;
        margin-bottom: 20px;
    }
    .content_list {
        h5 {
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 30px;
        }
    }
}
.contact_form_social_area {
    margin-bottom: 0;
    position: relative;
    li {
        background-color: #f7f7f7;
        border-radius: 8px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        width: 45px;
        &:hover {
            cursor: pointer;
        }
        a {
            font-size: 14px;
            color: $dark-grey;
            line-height: 1.2;
        }
    }
}
.maximage-home {
    .mc-image {
        height: 920px !important;
        position: relative !important;
        img {
            margin: 0 !important;
            width: 100% !important;
        }
    }
}
.maxtext {
    bottom: 45%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 9;
    h1 {
        color: #ffffff;
        font-size: 50px;
        font-family: Arial;;
        line-height: 1.2;
        text-transform: uppercase;
    }
    p {
        color: #ffffff;
        font-family: Arial;;
        font-size: 18px;
        line-height: 1.2;
    }
}
.img-whp {
    width: 100% !important;
    min-height: 238px;
    background-color: $light-grey;
}
.text-thm {
    color: $red !important;
}
.hvr-text-thm {
    &:hover {
        color: $red !important;
    }
}
.text-thm2 {
    color: #ff787c !important;
}
.text-thm3 {
    color: #006c70 !important;
}
.text-thm4 {
    color: #1fbda6;
}
.text-thm5 {
    color: #192675;
}
.text-thm6 {
    color: #ff1053;
}
.color-white {
    color: #ffffff !important;
}
.color-lightgray {
    color: #D3D3D3;
}
.color-gray {
    color: gray;
}
.color-gray2 {
    color: #7e7e7e;
}
.color-green2 {
    color: #28B928;
}
.color-blue {
    color: #201b71;
}
.color-orose {
    color: #c75533;
}
.color-dark {
    color: $dark-grey;
}
.color-black {
    color: #000;
}
.color-black2 {
    color: $dark-grey;
}
.color-black11 {
    color: #111111 !important;
}
.color-black22 {
    color: #222222;
}
.color-black33 {
    color: #333333;
}
.color-black31 {
    color: #303030;
}
.color-black44 {
    color: #434343;
}
.color-black55 {
    color: #555555;
}
.color-black66 {
    color: #676767;
}
.color-primary {
    color: #337ab7;
}
.color-success {
    color: #5cb85c;
}
.color-info {
    color: #5bc0de;
}
.color-silver {
    color: #c0c0c0;
}
.color-warning {
    color: #f0ad4e;
}
.color-danger {
    color: #d9534f;
}
.color-green {
    color: #28B928;
}
.color-fb {
    color: #3B5998;
}
.color-golden {
    color: #febe42;
}
.color-red {
    color: #ff4b53;
}
.bgc-thm {
    background-color: $red !important;
}
.bgc-thm2 {
    background-color: #ff787c !important;
}
.bgc-fb {
    background-color: #3B5998;
}
.color-twtr {
    color: #1DA1F2;
}
.bgc-twtr {
    background-color: #1DA1F2;
}
.color-inst {
    color: #D31A77;
}
.bgc-inst {
    background-color: #D31A77;
}
.color-lnkdn {
    color: #007BB6;
}
.bgc-lnkdn {
    background-color: #007BB6;
}
.color-pint {
    color: #CB2027;
}
.bgc-pint {
    background-color: #CB2027;
}
.color-skyp {
    color: #00AFF0;
    color: #00AFF0;
}
.bgc-skyp {
    background-color: #00AFF0;
    background-color: #00AFF0;
}
.color-drbl {
    color: #EA4C89;
}
.bgc-drbl {
    background-color: #EA4C89;
}
.color-behnc {
    color: #0B7BFF;
}
.bgc-behnc {
    background-color: #0B7BFF;
}
.color-utube {
    color: #FF0000;
}
.bgc-utube {
    background-color: #FF0000;
}
.color-gogle {
    color: #EA4335;
}
.bgc-gogle {
    background-color: #EA4335;
}
.bgc-darkblue {
    background-color: #112360;
    background-color: #004274;
}
.bgc-seagreen {
    background-color: #43d4b6;
}
.bgc-black11 {
    background-color: #111111;
}
.bgc-split {
    background-color: #181413 !important;
}
.bgc-primary {
    background-color: #337ab7;
}
.bgc-success {
    background-color: #5cb85c;
}
.bgc-info {
    background-color: #5bc0de;
}
.bgc-warning {
    background-color: #f0ad4e;
}
.bgc-danger {
    background-color: #d9534f;
}
.bgc-white {
    background-color: #ffffff !important;
}
.bgc-lightgray {
    background-color: #D3D3D3;
}
.bgc-snowshade {
    background-color: #f9f9f9;
}
.bgc-snowshade2 {
    background-color: #fafafa;
}
.bgc-golden {
    background-color: #febe42;
}
.color-whitef1 {
    color: #f1f1f1;
}
.color-whitef2 {
    color: #f2f2f2;
}
.color-whitef3 {
    color: #f3f3f3;
}
.color-whitef4 {
    color: #f4f4f4;
}
.color-whitef5 {
    color: #f5f5f5;
}
.color-whitef6 {
    color: #f6f6f6;
}
.color-whitef7 {
    color: #f7f7f7;
}
.color-whitef8 {
    color: #f8f8f8;
}
.color-whitef9 {
    color: #f9f9f9;
}
.color-whitef0 {
    color: #f0f0f0;
}
.bgc-f0 {
    background-color: #f0f0f0;
}
.bgc-f1 {
    background-color: #f1f1f1;
}
.bgc-f2 {
    background-color: #f2f2f2;
}
.bgc-f3 {
    background-color: #f3f3f3;
}
.bgc-f4 {
    background-color: #f4f4f4;
}
.bgc-f5 {
    background-color: #f5f5f5;
}
.bgc-f6 {
    background-color: #f6f6f6;
}
.bgc-f7 {
    background-color: #f7f7f7;
}
.bgc-f8 {
    background-color: #f8f8f8;
}
.bgc-f9 {
    background-color: #f9f9f9;
}
.bgc-f10 {
    background-color: #434343;
}
.bgc-fa {
    background-color: #fafafa !important;
}
.bgc-transparent {
    background-color: transparent !important;
}
.bgc-darkcyan {
    background-color: darkcyan;
}
.bgc-green {
    background-color: #28B928;
}
.bdrrn {
    border: none;
}
.bdrs0 {
    border-radius: 0 !important;
}
.bdr1 {
    border: 1px solid #ebebeb;
}
.bdrs5 {
    border-radius: 5px;
}
.bdrs8 {
    border-radius: 8px;
}
.bdrs10 {
    border-radius: 10px;
}
.bdrs20 {
    border-radius: 20px;
}
.bdrs50 {
    border-radius: 50%;
}
.bxshd {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.hvr-bxshd {
    &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
.tdu {
    text-decoration: underline;
}
.tdu-hvr {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
.all-body-content {
    background-color: #ffffff;
    margin-bottom: 760px;
    position: relative;
    z-index: 9;
}
.h125 {
    height: 125px;
}
.bb1 {
    border-bottom: 1px solid #ebebeb;
}
.curp {
    cursor: pointer;
}
.bg-img2 {
    background-image: url("../images/background/2.jpg");
}
.bg-img3 {
    background-attachment: scroll;
    background-image: url("../images/background/3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    height: 600px;
}
.bg-img4 {
    background-attachment: scroll;
    background-image: url("../images/background/4.jpg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    height: 450px;
}
.bg-img5 {
    background-attachment: scroll;
    background-image: url("../images/background/5.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 650px;
}
.bg-img6 {
    background-attachment: scroll;
    background-image: url("../images/background/8.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 960px;
}
.bg-ptrn1 {
    background-attachment: scroll;
    background-image: url("../images/pattern/1.png");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.bg-ptrn2 {
    background-attachment: scroll;
    background-image: url("../images/pattern/2.png");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
section {
    padding: 60px 0;
    position: relative;
}
.main-title {
    position: relative;
    margin-bottom: 50px;
    h2 {
        color: $dark-grey;
        font-family: Arial;;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-top: 0;
    }
    h3 {
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 0;
    }
    p {
        font-size: 16px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.2;
        margin-bottom: 0;
        a {
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.5;
        }
    }
}
.preloader {
    background-color: #ffffff;
    background-image: url("/images/preloader.gif");
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9999;
}
.scrollToHome {
    &:hover, &:focus, &:active {
        color: #ffffff;
    }
    border-radius: 50%;
    background-color: $red;
    bottom: 60px;
    color: #fff !important;
    display: none;
    font-size: 14px;
    height: 45px;
    line-height: 40px;
    padding: 3px 10px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 45px;
    z-index: 99999;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
a.scrollToHome.home7 {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    color: #232733;
}
a.scrollToHome.home8 {
    right: 6%;
}
.bgc-overlay-white3 {
    &:before {
        background-color: rgba(255, 255, 255, 0.3);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white35 {
    &:before {
        background-color: rgba(255, 255, 255, 0.35);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white4 {
    &:before {
        background-color: rgba(255, 255, 255, 0.4);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white45 {
    &:before {
        background-color: rgba(255, 255, 255, 0.45);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white5 {
    &:before {
        background-color: rgba(255, 255, 255, 0.5);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white55 {
    &:before {
        background-color: rgba(255, 255, 255, 0.55);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white6 {
    &:before {
        background-color: rgba(255, 255, 255, 0.6);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white65 {
    &:before {
        background-color: rgba(255, 255, 255, 0.65);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white7 {
    &:before {
        background-color: rgba(255, 255, 255, 0.7);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white75 {
    &:before {
        background-color: rgba(255, 255, 255, 0.75);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white8 {
    &:before {
        background-color: rgba(255, 255, 255, 0.8);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white85 {
    &:before {
        background-color: rgba(255, 255, 255, 0.85);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white9 {
    &:before {
        background-color: rgba(255, 255, 255, 0.9);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-white95 {
    &:before {
        background-color: rgba(255, 255, 255, 0.95);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black3 {
    &:before {
        background-color: rgba(0, 0, 0, 0.3);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black35 {
    &:before {
        background-color: rgba(0, 0, 0, 0.35);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black4 {
    &:before {
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black45 {
    &:before {
        background-color: rgba(0, 0, 0, 0.45);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black5 {
    &:before {
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black55 {
    &:before {
        background-color: rgba(0, 0, 0, 0.55);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black6 {
    &:before {
        background-color: rgba(0, 0, 0, 0.6);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black65 {
    &:before {
        background-color: rgba(0, 0, 0, 0.65);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black7 {
    &:before {
        background-color: rgba(0, 0, 0, 0.7);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black75 {
    &:before {
        background-color: rgba(0, 0, 0, 0.75);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black8 {
    &:before {
        background-color: rgba(0, 0, 0, 0.8);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black85 {
    &:before {
        background-color: rgba(0, 0, 0, 0.85);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black9 {
    &:before {
        background-color: rgba(0, 0, 0, 0.9);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.bgc-overlay-black95 {
    &:before {
        background-color: rgba(0, 0, 0, 0.95);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.parallax {
    background-attachment: scroll;
    background-position: 50% 0px;
    z-index: 1;
    position: relative;
}
.home-seven.parallax {
    z-index: 0;
}
.divider {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    padding: 130px 0;
    position: relative;
    &:before {
        background-color: rgba(25, 38, 117, 0.8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
.divider_home2 {
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    overflow: hidden;
    padding: 130px 0;
    position: relative;
    &:before {
        background-color: rgba(0, 0, 0, 0.8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
.divider_home1 {
    background-color: rgb(29, 41, 62);
    &:before {
        background-image: url("../images/background/2.jpg");
        content: "";
        height: 100%;
        left: 0;
        opacity: .5;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
.form_control {
    border-radius: 0;
    box-shadow: none;
    height: 45px;
}
.divider-one {
    h1 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 50px;
    }
    .divider-btn {
        border: 2px solid #ffffff;
        border-radius: 25px;
        color: #ffffff;
        height: 50px;
        line-height: 35px;
        width: 215px;
        &:hover {
            border: 2px solid $red;
        }
    }
    p {
        margin-bottom: 15px;
    }
}
.divider-two {
    h1 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 50px;
        margin-bottom: 120px;
    }
    position: relative;
    h3 {
        font-size: 26px;
        font-weight: normal;
        margin-bottom: 50px;
    }
    p {
        margin-bottom: 0;
    }
    .wrapper {
        text-align: center;
    }
    .time {
        color: #fff;
        font-size: 30px;
        line-height: normal;
    }
    .label {
        color: #ffffff;
        display: block;
        font-size: 20px;
        text-transform: uppercase;
    }
}
.divider2 {
    padding-bottom: 110px;
    padding-top: 135px;
    &:before {
        background-image: url(../images/background/2.png);
        background-repeat: no-repeat;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.2;
        position: absolute;
        top: 30px;
        width: 100%;
    }
}
#mc_embed_signup {
    input {
        background-color: transparent;
        border-bottom: 1px solid #ffffff;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        color: #ffffff;
        font-weight: 300;
        height: 45px;
        width: 85%;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #ffffff;
        }
    }
    .mc-field-group {
        margin-bottom: 40px;
    }
    .mailchimp_btn {
        background-color: transparent;
        border: 2px solid #ffffff;
        border-radius: 25px;
        color: #ffffff;
        height: 50px;
        width: 190px;
        &:hover {
            background-color: #ffffff;
            color: #192675;
        }
    }
}
div#countdown {
    .col-xs-6.col-sm-3 {
        &:first-child {
            margin-right: 40px;
            text-align: left;
            width: auto;
        }
        display: inline-block;
    }
}
canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.chart-container {
    width: 100%;
}
.c_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.selected_filter_widget {
    border: 1px solid rgb(237, 239, 247);
    display: inline-block;
    height: auto;
    margin-bottom: 30px;
    padding: 30px 30px 25px;
    position: relative;
    width: 100%;
    .tags-bar.style2 {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
    }
}
.selected_filter_widget.style2 {
    border: 1px solid rgb(237, 239, 247);
    .accordion {
        .link {
            font-size: 20px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            line-height: 1.2;
        }
    }
}
.selected_filter_widget.style3 {
    border: none;
    padding: 0 15px;
}
.selected_filter_widget.style4 {
    background-color: #f9fafc;
    border-radius: 5px;
    border: none;
    padding: 35px;
}
.tags-bar {
    background-color: #f0f0f0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    float: left;
    padding: 10px;
    position: relative;
    width: 100%;
    >span {
        background-color: #edeff7;
        color: #4f4f4f;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        font-size: 13px;
        height: 30px;
        line-height: 55px;
        margin-right: 5px;
        padding: 10px 20px;
        position: relative;
        width: 125px;
        i {
            color: #383535;
            cursor: pointer;
            font-size: 12px;
            font-style: normal;
            height: 16px;
            padding-left: 10px;
        }
    }
    .action-tags {
        float: right;
        a {
            color: #939393;
            font-size: 13px;
            i {
                font-size: 16px;
            }
        }
    }
}
#accordion {
    .panel {
        border: 0;
        border-radius: 0;
        margin-top: 0px;
    }
    a {
        display: block;
        text-decoration: none;
    }
    .panel-heading {
        a.collapsed {
            &:hover {
                color: $dark-grey;
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
                &::before {
                    color: $dark-grey;
                }
            }
            &:focus {
                color: $dark-grey;
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
                &::before {
                    color: $dark-grey;
                }
            }
        }
        padding: 0;
        a {
            &:not(.collapsed) {
                color: $dark-grey;
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }
        }
        .accordion-toggle {
            &::before {
                font-family: 'FontAwesome';
                color: $dark-grey;
                content: "\f107";
                float: right;
                font-weight: lighter;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }
        }
        .accordion-toggle.collapsed {
            &::before {
                color: #444;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition: all 0.2s ease-in;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }
        }
    }
}
.body_content.translated {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translateX(380px);
    -ms-transform: translateX(380px);
    -o-transform: translateX(380px);
    transform: translateX(380px);
}
.sidebar_content_details {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    bottom: 0;
    height: auto;
    left: 0;
    margin-left: 0;
    overflow-y: scroll;
    padding: 80px 0 0;
    position: fixed;
    top: 0;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
    width: 360px;
    z-index: 9;
    &::-webkit-scrollbar {
        margin-top: 15px;
        margin-right: -10px;
        width: 5px;
        z-index: 3;
        * {
            background: transparent;
            margin-right: -10px;
        }
    }
    &::-webkit-scrollbar-track {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #dfdfdf !important;
        border-radius: 2.5px;
        margin-right: -10px;
    }
}
.sidebar_content_details.is-full-width {
    left: 0;
    margin-left: -100%;
    position: fixed;
}
.sidebar_content_details.is-full-width.style2 {
    margin-left: 0;
}
.sidebar_content_details.style2 {
    border: 1px solid transparent;
    padding: 0;
    left: -376px;
}
.sidebar_content_details.style3 {
    padding: 5px 0 0;
    margin-left: -200%;
    z-index: 9999;
}
.sidebar_content_details.style3.sidebar_ml0 {
    margin-left: 0;
}
.sidebar_switch {
    position: relative;
}
#main {
    .filteropen {
        background-color: $red;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        font-family: Arial;;
        font-weight: bold;
        height: 50px;
        left: auto;
        line-height: 50px;
        overflow: hidden;
        padding-left: 45px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 38px;
        width: 170px;
        z-index: 999;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    }
    .filteropen.style2 {
        left: auto;
        position: relative;
        right: auto;
        top: auto;
    }
}
#main2 {
    .filteropen2 {
        background-color: $red;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        font-family: Arial;;
        font-weight: bold;
        height: 50px;
        left: auto;
        line-height: 50px;
        overflow: hidden;
        padding-left: 45px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 38px;
        width: 170px;
        z-index: 999;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    }
    .filter_open_btn {
        background-color: $red;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
        font-family: Arial;;
        font-weight: bold;
        height: 50px;
        left: auto;
        line-height: 50px;
        overflow: hidden;
        padding-left: 45px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 38px;
        width: 170px;
        z-index: 999;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
        top: 5px;
    }
    .filteropen2.style2 {
        left: auto;
        position: relative;
        right: auto;
        top: auto;
    }
}
#open.flaticon-filter-results-button {
    &:before {
        background-color: #f53c41;
        left: 0;
        position: absolute;
        width: 48px;
    }
}
#open.flaticon-close {
    &:before {
        background-color: #f53c41;
        left: 0;
        position: absolute;
        width: 48px;
    }
}
#open2.flaticon-filter-results-button {
    &:before {
        background-color: #f53c41;
        left: 0;
        position: absolute;
        width: 48px;
    }
}
#open2.flaticon-close {
    &:before {
        background-color: #f53c41;
        left: 0;
        position: absolute;
        width: 48px;
    }
}
.sidebar_switch.style {
    #main {
        .filteropen {
            left: 55px;
            right: auto;
            top: 55px;
        }
    }
}
.sidebar_switch.style2 {
    #main2 {
        .filteropen2 {
            left: 55px;
            right: auto;
            top: 55px;
        }
    }
}
.listing_toogle_sidebar.sidenav {
    background-color: #fafafa;
    border: 1px solid #dddddd;
    border-top: none;
    height: 875px;
    left: 0;
    overflow-x: hidden;
    padding: 0 0 70px;
    position: fixed;
    top: 170px;
    width: 380px;
    z-index: 1;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transform: translate(-100%, -80px);
    -moz-transform: translate(-100%, -80px);
    -o-transform: translate(-100%, -80px);
    transform: translate(-100%, -80px);
    a {
        display: block;
        padding: 0;
        text-decoration: none;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .closebtn {
        font-size: 36px;
        margin-left: 50px;
        position: absolute;
        top: 0;
        right: 25px;
    }
    &::-webkit-scrollbar {
        background: transparent;
        border-radius: 10px;
        padding-right: 10px;
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ededed;
        border-radius: 3px;
        margin-right: 5px;
    }
}
.sp_search_content {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    height: 62px;
    padding: 15px 30px;
    position: relative;
}
.search_result {
    display: inline-block;
}
.search_drpdwn {
    display: inline-block;
    float: right;
    button.btn.dropdown-toggle.btn-light {
        background-color: transparent;
        border: none;
        &:hover {
            border: none;
            box-shadow: none;
            outline: none !important;
        }
        &:active {
            border: none;
            box-shadow: none;
            outline: none !important;
        }
        &:focus {
            border: none;
            box-shadow: none;
            outline: none !important;
        }
    }
    .filter-option-inner-inner {
        color: #777777;
    }
}
.shop_grid {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 30px;
    padding: 10px;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    .thumb {
        position: relative;
        .tag {
            border-radius: 3px;
            background-color: $red;
            font-size: 14px;
            font-family: Arial;;
            color: #fefefe;
            height: 25px;
            left: 10px;
            line-height: 25px;
            position: absolute;
            text-align: center;
            top: 10px;
            width: 70px;
        }
    }
    .details {
        padding: 20px 10px 10px 10px;
        position: relative;
        h4.item-tile {
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.444;
            margin-bottom: 15px;
            .price {
                font-family: Arial;;
                color: $red;
                font-weight: bold;
                float: right;
            }
        }
        .add_to_cart {
            border-radius: 8px;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
            line-height: 1.2;
            height: 45px;
            width: 150px;
        }
    }
}
.shop_order_box {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(237, 239, 247);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0);
    padding: 30px;
    .main_title {
        font-size: 20px;
        font-family: "Nunito SemiBold";
        color: $dark-grey;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }
    .order_list_raw {
        margin-bottom: 80px;
        margin-top: 60px;
        text-align: center;
        ul {
            margin-bottom: 0;
            li {
                margin-right: 60px;
                text-align: left;
                &:last-child {
                    margin-right: 0px;
                }
                h4 {
                    font-size: 18px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.333;
                    margin-bottom: 4px;
                }
                p {
                    font-size: 15px;
                    font-family: Arial;
                    color: rgb(126, 126, 126);
                    line-height: 1.6;
                }
            }
        }
    }
    .order_details {
        .title {
            font-size: 20px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 40px;
        }
        .title2 {
            font-size: 20px;
            font-family: "Nunito SemiBold";
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 20px;
        }
        .od_content {
            margin-bottom: 60px;
            li {
                font-size: 15px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                line-height: 1.6;
                margin-bottom: 25px;
            }
            .tamount {
                font-size: 18px;
                font-family: "Nunito SemiBold";
                color: rgb(255, 16, 83);
                line-height: 1.333;
            }
        }
    }
}
.checkout_form {
    position: relative;
    .checkout_coupon {
        .form1 {
            display: block;
            margin-bottom: 60px;
            padding-top: 10px;
            text-align: center;
        }
        form {
            input.coupon_input {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                height: 52px;
                margin-right: 24px;
                width: 300px;
            }
            label {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.75;
            }
            input {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                height: 52px;
                margin-right: 24px;
            }
            textarea {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                padding: 25px;
            }
            button {
                border-radius: 8px;
                background-color: #3e4c66;
                color: #ffffff;
                -webkit-box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
                -moz-box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
                -o-box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
                box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
                font-size: 16px;
                font-family: Arial;;
                font-weight: bold;
                height: 50px;
                line-height: 1.2;
                margin-bottom: 0;
                width: 170px;
                &:hover {
                    border: 1px solid #3e4c66;
                    background-color: #ffffff;
                    color: #3e4c66;
                }
            }
            button.btn2 {
                margin-right: 24px;
            }
            button.btn3 {
                background-color: $red;
                border-radius: 8px;
                color: #ffffff;
                -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
                -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
                -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
                box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
                font-size: 16px;
                font-family: Arial;;
                font-weight: bold;
                line-height: 1.2;
                height: 50px;
                position: absolute;
                right: 0;
                width: 170px;
                &:hover {
                    border: 1px solid $red;
                    background-color: #ffffff;
                    color: $red;
                }
            }
        }
        .form2 {
            .my_profile_select_box {
                .dropdown.bootstrap-select {
                    button.btn {
                        width: 100%;
                    }
                }
                .dropdown-item.active {
                    background-color: transparent;
                    color: #6f7074;
                }
                .dropdown-item {
                    &:active {
                        background-color: transparent;
                        color: #6f7074;
                    }
                }
            }
            .form-group {
                margin-bottom: 30px;
            }
            label {
                font-size: 15px;
                font-family: Arial;
                color: rgb(79, 79, 79);
                line-height: 1.867;
            }
            .ai_title {
                font-size: 18px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.2;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            p {
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                line-height: 1.75;
            }
        }
    }
}
.checkout_form.style2 {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 30px;
    .heading {
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.2;
            margin-bottom: 15px;
        }
    }
}
.order_sidebar_widget {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 30px 30px 10px;
    position: relative;
    .title {
        color: $dark-grey;
        font-size: 18px;
        font-family: Arial;;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 40px;
    }
    ul {
        li {
            p {
                margin-bottom: 27px;
            }
        }
    }
    .subtitle {
        p {
            color: $dark-grey;
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
        }
        span {
            color: $red;
            font-size: 16px;
            font-family: Arial;;
        }
        .totals {
            color: $red;
            font-size: 16px;
            font-family: Arial;;
        }
    }
}
.order_sidebar_widget.style2 {
    padding: 30px;
}
.payment_widget {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 30px;
    .bt_details {
        border-radius: 5px;
        background-color: #f7f7f7;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
        margin-bottom: 15px;
        margin-top: 5px;
        padding: 25px;
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.714;
            margin-bottom: 0;
        }
    }
}
.payment_widget_btn {
    .btn-block {
        margin-top: 30px;
        width: 100%;
        &:hover {
            border: 1px solid #ff1053;
        }
    }
}
.cart_page_form {
    thead {
        display: block;
    }
    border: 2px solid rgb(237, 239, 247);
    background-color: #ffffff;
    padding: 30px;
    margin-bottom: 30px;
    table.table.table-responsive {
        border-radius: 5px 5px 0 0;
        margin-bottom: 0;
    }
    tr.carttable_row {
        background-color: #f7f7f7;
        border-radius: 8px;
        display: block;
        height: 60px;
        th {
            &:first-child {
                width: 47%;
            }
            &:nth-child(2).cartm_title {
                width: 19%;
            }
            &:nth-child(3).cartm_title {
                width: 15%;
            }
            &:nth-child(4).cartm_title {
                width: 20%;
            }
        }
        th.cartm_title {
            background-color: #f7f7f7;
            border: none;
            color: $dark-grey;
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
            line-height: 2.2;
            padding-left: 20px;
        }
    }
    .table_body {
        td {
            border-bottom: 1px solid #ebebeb;
            border-top: none;
            padding: 30px 0 30px 0;
            vertical-align: middle;
        }
        th {
            border-bottom: 1px solid #ebebeb;
            border-top: none;
            padding: 30px 0 30px 0;
            vertical-align: middle;
        }
    }
    tbody.table_body {
        display: inline-table;
        width: 100%;
        th {
            .cart_list {
                .cart_title {
                    font-size: 18px;
                    font-family: Arial;;
                    color: $dark-grey;
                    font-weight: bold;
                    line-height: 1.444;
                }
                li {
                    margin-right: 0;
                    vertical-align: middle;
                }
            }
        }
        td {
            .cart_count {
                border: none;
                border-radius: 8px;
                background-color: #efefef;
                font-size: 16px;
                font-family: Arial;;
                color: $dark-grey;
                font-weight: bold;
                height: 50px;
                line-height: 1.2;
                width: 70px;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}
.table_body {
    .cart_list {
        margin-bottom: 0;
    }
}
.cart_page_form.style2 {
    .table_body {
        td {
            padding: 30px 31px;
            vertical-align: middle;
            width: 13%;
        }
        th {
            padding: 30px 31px;
            vertical-align: middle;
            width: 55%;
        }
    }
}
.cart_page_form.style3 {
    .table_body {
        td {
            border-bottom: 1px solid #dedede;
            width: 0%;
        }
        th {
            border-bottom: 1px solid #dedede;
            width: 52%;
        }
        tr {
            border-left: 1px solid #dedede;
            border-right: 1px solid #dedede;
        }
    }
    border: none;
    tr.borderless_table_row {
        border-left: none;
        border-right: none;
        th {
            border: none;
        }
        td {
            border: none;
        }
    }
    tr.borderless_table_row.style2 {
        th {
            border: none;
        }
        td {
            border: none;
        }
    }
}
.order_key_status {
    li {
        &:last-child {
            span {
                margin-left: 54px;
            }
        }
        font-size: 18px;
        font-family: Arial;;
        color: rgb(10, 10, 10);
        line-height: 2.222;
        span {
            font-size: 15px;
            font-family: Arial;
            color: rgb(126, 126, 126);
            line-height: 2.667;
            margin-left: 75px;
        }
    }
}
.single_product_grid {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin: 0;
    padding: 10px 0;
    position: relative;
    .sps_content {
        .content {
            text-align: left;
            .shop_single_product_details {
                padding: 15px 10px 0 0;
                p {
                    font-size: 14px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.714;
                }
            }
        }
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
    }
}
.single_product_slider.owl-theme {
    .owl-nav {
        display: none;
    }
    .owl-dots {
        text-align: left;
        .owl-dot {
            span {
                border-radius: 6px;
                height: 80px;
                margin-bottom: 0;
                margin-left: 0;
                margin-right: 10px;
                margin-top: 10px;
                width: 90px;
            }
            &:first-child {
                span {
                    background-image: url(../images/shop/ss1.png);
                    background-position: center center;
                    background-size: cover;
                }
            }
            &:nth-child(2) {
                span {
                    background-image: url(../images/shop/ss2.png);
                    background-position: center center;
                    background-size: cover;
                }
            }
            &:nth-child(3) {
                span {
                    background-image: url(../images/shop/ss3.png);
                    background-position: center center;
                    background-size: cover;
                }
            }
        }
    }
}
.single_product {
    position: relative;
    .product_popup {
        border-radius: 50%;
        background-color: #ffffff;
        height: 50px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 10px;
        width: 50px;
        span {
            color: $dark-grey;
            font-size: 25px;
            line-height: 2;
        }
    }
    .single_item {
        .thumb {
            img {
                height: 312px;
            }
        }
    }
}
.shop_single_product_details {
    h4 {
        color: $dark-grey;
        font-size: 22px;
        font-family: Arial;;
        font-weight: bold;
        line-height: 1.182;
        margin-bottom: 15px;
    }
    .cart_btns {
        input {
            background-color: #efefef;
            border: none;
            border-radius: 8px;
            font-size: 16px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.2;
            height: 50px;
            text-align: center;
            width: 70px;
            &:focus {
                outline: none;
            }
        }
        button.btn {
            background-color: $red;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            border-radius: 8px;
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
            line-height: 1.2;
            color: #ffffff;
            height: 50px;
            width: 170px;
            &:hover {
                background-color: #ffffff;
                border: 1px solid $red;
                color: $red;
            }
        }
    }
    .sspd_price {
        font-size: 18px;
        font-family: Arial;;
        color: $red;
        font-weight: bold;
        line-height: 1.2;
    }
    .sspd_sku {
        li {
            a {
                font-size: 15px;
                font-family: Arial;
                color: rgb(126, 126, 126);
                line-height: 1.6;
            }
        }
    }
    .sspd_social_icon {
        li {
            &:first-child {
                font-size: 18px;
                font-family: Arial;;
                color: rgb(59, 59, 59);
                line-height: 1.333;
                padding-right: 10px;
            }
            padding-right: 20px;
            a {
                font-size: 16px;
                font-family: "FontAwesome";
                color: #b6b9c7;
                line-height: 1.2;
            }
            &:hover {
                a {
                    color: rgb(72, 73, 77);
                }
            }
        }
    }
}
.product_single_content {
    .mbp_pagination_comments {
        .mbp_first.media {
            .media-body {
                p {
                    font-size: 14px;
                    font-family: Arial;;
                    color: $dark-grey;
                    line-height: 1.857;
                    margin-bottom: 0;
                }
            }
            h4.sub_title {
                ul {
                    li {
                        a {
                            font-size: 13px;
                            font-family: "FontAwesome";
                            color: #bcc52a;
                            line-height: 1.2;
                        }
                    }
                }
                .sspd_review {
                    ul {
                        li {
                            margin-right: 2px;
                        }
                    }
                }
            }
        }
    }
}
.product_single_content.style2 {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(237, 239, 247);
    border-radius: 5px;
    background-color: rgba(42, 53, 59, 0);
}
.cs_rwo_tabs.csv2 {
    .nav-tabs {
        border-bottom: none;
        .nav-link {
            border: 1px solid transparent;
            font-size: 18px;
            font-family: Arial;;
            color: $dark-grey;
            height: 64px;
            line-height: 2.5;
            text-align: center;
            width: 165px;
            &:hover {
                border: 1px solid transparent;
            }
        }
        .nav-link.active {
            background-color: #ffffff;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
            position: relative;
            &:before {
                background-color: #ffffff;
                content: "";
                height: 18px;
                left: 0;
                margin-left: 75px;
                margin-top: 46px;
                position: absolute;
                right: 0;
                width: 18px;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}
.shop_single_tab_content {
    .nav-tabs {
        border-bottom: 1px solid #ebebeb;
        li.nav-item {
            padding: 15px 0 0 30px;
        }
        .nav-link {
            border: none;
            font-size: 18px;
            font-family: Arial;;
            color: $dark-grey;
            font-weight: bold;
            line-height: 1.2;
            padding: .5rem 0;
        }
        .nav-link.active {
            border-bottom: 1px solid $red;
            color: $red;
        }
    }
    background-color: #ffffff;
    border-bottom: 1px solid #ebebeb;
    border-radius: 8px;
    .mbp_pagination_comments {
        margin-top: 0;
        position: relative;
        .mbp_first.media {
            padding: 30px 30px 20px;
            .media-body {
                h4.sub_title {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.shop_single_tab_content.style3 {
    .nav-tabs {
        border-bottom: none;
        li.nav-item {
            padding: 0 0 0 30px;
        }
        .nav-link {
            padding-top: 0;
            font-size: 16px;
            font-weight: 400;
        }
    }
}
.ls_barchart {
    max-height: 345px !important;
}
ul.total_reivew_view {
    cursor: pointer;
    margin-bottom: 0;
    padding: 30px 30px 0;
    position: relative;
    width: 100%;
    li.sub_titles {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-right: 10px;
    }
    li.avrg_review {
        font-size: 14px;
        font-family: Arial;;
        color: #767676;
        line-height: 2.143;
    }
    li.write_review {
        font-size: 14px;
        font-family: Arial;;
        color: $red;
        line-height: 2.57;
        position: absolute;
        right: 30px;
    }
    .star_list {
        li {
            margin-right: 4px;
            a {
                font-size: 14px;
                font-family: "FontAwesome";
                color: #bcc52a;
                line-height: 2.143;
            }
            &:last-child {
                a {
                    color: #e1e1e1;
                }
            }
        }
    }
}
.mbp_comment_form.style2 {
    padding: 10px 30px 30px;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 25px;
    }
    ul {
        margin-bottom: 20px;
    }
    .sspd_review {
        .review_rating_para {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.714;
            padding-left: 10px;
        }
        li {
            margin-right: 0;
            ul {
                li {
                    a {
                        font-size: 14px;
                        font-family: "FontAwesome";
                        color: #bcc52a;
                        line-height: 2.143;
                    }
                    &:last-child {
                        a {
                            color: #e1e1e1;
                        }
                    }
                }
            }
        }
    }
    .comments_form {
        background-color: transparent;
        padding: 0;
        .form-group {
            input {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                height: 52px;
                margin-bottom: 20px;
                padding-left: 20px;
            }
            textarea {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                color: $dark-grey;
                font-size: 14px;
                font-family: Arial;;
                line-height: 1.2;
                margin-bottom: 20px;
                padding: 20px;
            }
        }
        button.btn {
            border-radius: 8px;
            background-color: $red;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            color: #ffffff;
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
            height: 50px;
            line-height: 35px;
            width: 200px;
            &:hover {
                background-color: #ffffff;
                color: $red;
                border: 1px solid $red;
            }
            &:focus {
                background-color: #ffffff;
                color: $red;
                border: 1px solid $red;
            }
        }
    }
}
.bsp_reveiw_wrt {
    border: 1px solid rgb(235, 235, 235);
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 30px;
    h4 {
        font-size: 18px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 25px;
    }
    .comments_form {
        background-color: transparent;
        padding: 0;
        .form-group {
            input {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                font-size: 14px;
                font-family: Arial;;
                color: $dark-grey;
                line-height: 1.2;
                height: 52px;
                margin-bottom: 20px;
                padding-left: 20px;
            }
            textarea {
                background-color: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                color: $dark-grey;
                font-size: 14px;
                font-family: Arial;;
                line-height: 1.2;
                margin-bottom: 20px;
                padding: 20px;
            }
        }
        button.btn {
            border-radius: 8px;
            background-color: $red;
            -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
            color: #ffffff;
            font-size: 16px;
            font-family: Arial;;
            font-weight: bold;
            height: 50px;
            line-height: 35px;
            width: 200px;
            &:hover {
                background-color: #ffffff;
                color: $red;
                border: 1px solid $red;
            }
            &:focus {
                background-color: #ffffff;
                color: $red;
                border: 1px solid $red;
            }
        }
    }
}
.review_star {
    li {
        p {
            font-size: 14px;
            font-family: Arial;;
            color: $dark-grey;
            line-height: 1.714;
            padding-left: 10px;
        }
        margin-right: 0;
        .sspd_review {
            ul {
                li {
                    margin-right: 0;
                }
            }
        }
        ul {
            li {
                a {
                    font-size: 14px;
                    font-family: "FontAwesome";
                    color: #bcc52a;
                    line-height: 2.143;
                }
                &:last-child {
                    a {
                        color: #e1e1e1;
                    }
                }
            }
        }
    }
}
.custom_hr {
    position: relative;
    &:before {
        background-color: #ebebeb;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}
.custom_hr.style3 {
    &:before {
        top: 30px;
    }
}
.become_instructor_container {
    position: relative;
    .titles {
        color: $dark-grey;
        font-family: nunito;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 30px;
    }
}
.home_two_map.style2 {
    display: table-footer-group;
    position: relative;
}
.map-canvas.half_style {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
    width: 100%;
}
.gm-style {
    .gm-style-iw-c {
        background-color: #ffffff;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
        border-radius: 8px;
        top: 15px;
        max-height: 321px !important;
        max-width: 290px !important;
    }
    .gm-style-iw-t {
        &::after {
            top: 15px;
        }
    }
}
button.gm-ui-hover-effect {
    background-color: #ffffff !important;
    border-radius: 5px;
    border: 0 !important;
    color: inherit;
    font-size: 30px;
    height: 35px;
    line-height: 30px;
    outline: none;
    opacity: 1;
    position: absolute !important;
    right: 10px !important;
    text-align: center;
    top: 15px !important;
    width: 35px;
    &:hover {
        opacity: 1;
    }
    &:focus {
        opacity: 1;
    }
}
.gm-style-iw-d {
    height: auto;
    width: 290px;
    img {
        margin-bottom: 15px;
        max-height: 175px;
        max-width: 270px;
        width: 100%;
    }
    h5 {
        font-size: 14px;
        font-family: Arial;;
        color: $red;
        line-height: 1.2;
    }
    h4 {
        font-size: 16px;
        font-family: Arial;;
        color: $dark-grey;
        font-weight: bold;
        line-height: 1.2;
    }
    p {
        font-size: 14px;
        font-family: Arial;;
        color: $dark-grey;
        line-height: 1.2;
        span {
            margin-right: 10px;
        }
    }
}
@media screen and (-ms-high-contrast: active) {
    .main-banner-wrapper {
        overflow: hidden;
    }
}
@media (-ms-high-contrast: none) {
    .main-banner-wrapper {
        overflow: hidden;
    }
}

@media screen and (max-width: 768px){
    .our_agent {
        .thumb {
            img {
                width: 204px;
                height:auto;
            }
        }
    }
}

.circles {
    figure {
        background: url('/images/more-icon-sprite.png') 0 0 no-repeat transparent;
        height: 120px;
        width: 120px;

        &.address {
            background-position: 0 0;
        }

        &.phone {
            background-position: -240px 0;
        }

        &.email {
            background-position: -480px 0;
        }
    }
}

#pills-home{
    min-height: 62px;
    ul li{
        height: 43px;
    }
}

.subscribe-box-font-color {
    color:#FFFFFF !important;
}

.subscribe-box-background-picture {
    background-image:url('/images/subscribe-box/bg2-min.jpg') !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .invalid-feedback{
        color: #FFFFFF;
    }
}

.footer-estates-linkbox{
    .nav-tabs{
        border-bottom:0;
    }
}
.modal-open {
    overflow: unset !important;
}
.subscribe-modal {
    p {
        line-height: 24px !important;
        margin-bottom: 30px !important;
        font-size: 20px !important;
        font-weight: bold !important;
        padding-left: 30px !important;
        margin:0px !important;
        padding-bottom: 15px !important;

    }
    .modal-dialog{
        float: right !important;
        top: 720px !important;
    }
    .modal-header{
        display:none !important;
    }

    .modal-body{
        position: relative !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
        padding: 0px 10px 10px 0px !important;
    }
    .subscribe-modal-button{
        background-color: #db1c1f !important;
        border-radius: 8px !important;
        color: #ffffff !important;
        -webkit-transition: all 0.3s ease !important;
        -o-transition: all 0.3s ease !important;
        transition: all 0.3s ease !important;
        padding:10px 20px 10px 20px !important;
        font-size: 18px !important;
        :hover {
            color:#FFFFFF !important;
        }
    }
    .modal-content {
        position: relative !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        width: 100% !important;
        pointer-events: auto !important;
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 1px solid rgba(0,0,0,.2) !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border-top: solid 2px  #db1c1f !important;
    }
    .close {
        margin-bottom: 5px !important;
        float: left !important;
        padding: 0 !important;
        background-color: transparent !important;
        border: 0 !important;
        appearance: none !important;
        font-size: 30px !important;
        color: black !important;
        font-weight: 100 !important;
        width: 30px !important;
        height: 30px !important;
        background-color: lightgray !important;
    }

    @media screen and (max-width: 768px){
        .modal-dialog {
            float: inherit !important;
            top: 450px !important;
        }
        .subscribe-modal-button{
            float:none !important;
            margin-bottom:10px !important;
        }
    }

}

.already-seen-icon{
    color:white;
}

.iti {
    display: block !important;

    #messageform-phone {
        padding-left: 45px !important;
    }
}