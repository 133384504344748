#startpage-layers {
    font-size: 16px;
    overflow: hidden;
    padding: 1px 0;
    text-align: left;
    position: relative;
    border-bottom: 2px solid #a4a4a426;
}

#startpage-layers .startpage-layers-close {
    position: absolute;
    top: 15px;
    right: 0px;
}

#startpage-layers img {
    border: 0 none;
}

#startpage-layers .hidden {
    //display: none;
}

#startpage-layers .layer-tabs {
    clear: both;
    display: block;
    float: left;
    height: 27px;
    margin: 14px 0;
    width: 654px;
}

#startpage-layers .layer-tab {

}

#startpage-layers .layer-tab label {
    margin-left: 5px;
    font-size: 14px;
}

#startpage-layers .layer-tab .item {
    margin-bottom: 5px;
}

#startpage-layers .layer-tabs li {
    float: left;
    height: 24px;
    margin: 0;
    list-style: none;
}

#startpage-layers .layer-tabs li.first a {
    border-left: 0 none;
}

#startpage-layers .layer-tabs li a {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    float: left;
    height: 15px;
    margin-right: 15px;
    width: 170px;
    height: 36px;
    line-height: 36px;
    position: relative;
    text-align: center;
    background: #91969f;
}
#startpage-layers .layer-tabs li a:hover {
    background-color: #333438;
}

#startpage-layers .layer-tabs li a.current {
    background-color: #4E5359;
}
#startpage-layers .layer-tabs li a.current:hover {
    background-color: #333438;
}
/** END regio valaszto lenyilo lista **/





/** BEGIN map search **/
#startpage-layers .map-container {
    float: left;
    width: 344px;
    height: 245px;
}

#startpage-layers .map-container .map .mapimage {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
#startpage-layers .map-container .map .map-transparent-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

#startpage-layers .map-container .map .mapimage.hover, #startpage-layers .map-container .map .mapimage.selected {
    display: block;
}

#startpage-layers .region-checkboxes-container {
    margin-left: 20px;
}

#startpage-layers .region-checkboxes-container .column1 {
    float: left;
}

#startpage-layers .region-checkboxes-container .column2 {
    float: left;
}

#startpage-layers .region-checkboxes-container .region-selector-container {
}

/* budapest */
#startpage-layers #layer-budapest .map-container {
    background: url('/images/map/map_budapest.png') no-repeat center center;
    width: 257px;
    height: 245px;
    position: relative;
}
#startpage-layers #layer-budapest .map-container .mapimage {
    background-image: url('/images/map/map_sprite_budapest.png');
    background-repeat: no-repeat;
    width: 257px;
    height: 245px;
}
#startpage-layers #layer-budapest .map-container .mapimage.hover {
    background-image: url('/images/map/map_sprite_budapest_hover.png');
}

#layer-budapest .column1 {
    width: 120px;
    margin-right: 57px;
}
#layer-budapest .column2 {
    width: 199px;
}
#layer-budapest .column3 {
    float: left;
    width: 82px;
    margin-right: 28px;
}
#layer-budapest .column4 {
    float: left;
    width: 89px;
}

/* orszagos */
#startpage-layers #layer-orszagszerte .map-container {
    background: url('/images/map/map_orszag.png') no-repeat center center;
    width: 319px;
    height: 207px;
    position: relative;
}
#startpage-layers #layer-orszagszerte .map-container .mapimage {
    background-image: url('/images/map/map_sprite_orszag.png');
    background-repeat: no-repeat;
    width: 319px;
    height: 207px;
}
#startpage-layers #layer-orszagszerte .map-container .mapimage.hover {
    background-image: url('/images/map/map_sprite_orszag_hover.png');
}

#layer-orszagszerte .region-checkboxes-container {
    margin-left: 10px;
}

#layer-orszagszerte .column1 {
    width: 148px;
    margin-right: 17px;
}
#layer-orszagszerte .column2 {
    width: 160px;
}

#layer-balaton .column1 {
    margin-right: 20px;
    min-width: 150px;
}

/* balaton */
#startpage-layers #layer-balaton .map-container {
    background: url('/images/map/map_balaton.png') no-repeat center center;
    width: 286px;
    height: 205px;
    position: relative;
}
#startpage-layers #layer-balaton .map-container .mapimage {
    background-image: url('/images/map/map_sprite_balaton.png');
    background-repeat: no-repeat;
    width: 286px;
    height: 205px;
}
#startpage-layers #layer-balaton .map-container .mapimage.hover {
    background-image: url('/images/map/map_sprite_balaton_hover.png');
}

#mapimage_budapest_i_ker {
    background-position: 0 0;
}
#mapimage_budapest_ii_ker {
    background-position: 0 -244px;
}
#mapimage_budapest_iii_ker {
    background-position: 0 -489px;
}
#mapimage_budapest_iv_ker {
    background-position: 0 -735px;
}
#mapimage_budapest_v_ker {
    background-position: 0 -979px;
}
#mapimage_budapest_vi_ker {
    background-position: 0 -1225px;
}
#mapimage_budapest_vii_ker {
    background-position: 0 -1469px;
}
#mapimage_budapest_viii_ker {
    background-position: 0 -1714px;
}
#mapimage_budapest_ix_ker {
    background-position: 0 -1960px;
}
#mapimage_budapest_x_ker {
    background-position: 0 -2204px;
}
#mapimage_budapest_xi_ker {
    background-position: 0 -2449px;
}
#mapimage_budapest_xii_ker {
    background-position: 0 -2695px;
}
#mapimage_budapest_xiii_ker {
    background-position: 0 -2940px;
}
#mapimage_budapest_xiv_ker {
    background-position: 0 -3185px;
}
#mapimage_budapest_xv_ker {
    background-position: 0 -3429px;
}
#mapimage_budapest_xvi_ker {
    background-position: 0 -3674px;
}
#mapimage_budapest_xvii_ker {
    background-position: 0 -3920px;
}
#mapimage_budapest_xviii_ker {
    background-position: 0 -4165px;
}
#mapimage_budapest_xix_ker {
    background-position: 0 -4409px;
}
#mapimage_budapest_xx_ker {
    background-position: 0 -4654px;
}
#mapimage_budapest_xxi_ker {
    background-position: 0 -4900px;
}
#mapimage_budapest_xxii_ker {
    background-position: 0 -5144px;
}
#mapimage_budapest_xxiii_ker {
    background-position: 0 -5390px;
}
#mapimage_baranya_megye {
    background-position: 0 -207px
}
#mapimage_bacs_kiskun_megye {
    background-position: 0 0;
}
#mapimage_bekes_megye {
    background-position: 0 -414px;
}
#mapimage_borsod_abauj_zemplen_megye {
    background-position: 0 -621px;
}
#mapimage_budapest {
    background-position: 0 -828px;
}
#mapimage_csongrad_megye {
    background-position: 0 -1035px;
}
#mapimage_fejer_megye {
    background-position: 0 -1242px;
}
#mapimage_gyor_moson_sopron_megye {
    background-position: 0 -1449px;
}
#mapimage_hajdu_bihar_megye {
    background-position: 0 -1656px;
}
#mapimage_heves_megye {
    background-position: 0 -1863px;
}
#mapimage_jasz_nagykun_szolnok_megye {
    background-position: 0 -2070px;
}
#mapimage_komarom_esztergom_megye {
    background-position: 0 -2277px;
}
#mapimage_nograd_megye {
    background-position: 0 -2484px;
}
#mapimage_pest_megye {
    background-position: 0 -2691px;
}
#mapimage_somogy_megye {
    background-position: 0 -2898px;
}
#mapimage_szabolcs_szatmar_bereg_megye {
    background-position: 0 -3105px;
}
#mapimage_tolna_megye {
    background-position: 0 -3312px;
}
#mapimage_vas_megye {
    background-position: 0 -3519px;
}
#mapimage_veszprem_megye {
    background-position: 0 -3726px;
}
#mapimage_zala_megye {
    background-position: 0 -3933px;
}

.map-selector {
    width: 100%;
    .select2-selection {
        border: 1px solid #ebebeb;
        .select2-search__field {
            &::placeholder {
                color: #6c757d;
            }
        }
    }
}
.map-selector-wrap {
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    padding: 30px 0;
    border-top:0;
    z-index:4; 
    position:relative;
    margin-top: -15px;
    padding-bottom: 10px;
    //display: none;
    width: 100%;
    .map-close {
        position: absolute;
        right: 55px;
    }
    .nav-tabs {
        margin-right: 15px;
        margin-bottom: 30px;
        background: #c6c6c642;
        border-bottom: none;
        border-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .nav-link {
        padding: .5rem 1.5rem;
        border: none;
        border-bottom: 3px solid transparent;
        transition: .2s ease-in-out;
        font-size: 17px;
        color: #000000c2;
        letter-spacing: 1px;
        &.active, &:hover, &:focus, &:active {
            border: none;
            border-bottom: 3px solid $red;
            background-color: transparent;
        }
    }
    .nav-tabs{
        margin-right: 0;
        .nav-item{
            line-height: 33px;
            color: #777;
            font-family: Arial;
            font-size: 16px;
            height: auto !important;
        }
    }
}
.map-selector {
    .form-group {
        margin-bottom: 0;
    }
}
.map-selector, .map-selector-modal {
    .select2-selection__choice {
        background-color: $red !important;
        color: #fff !important;
        padding: 5px !important;
        border-color: $red !important;
        font-weight: 600;
        height: auto !important;
    }
    .select2-selection__choice__remove {
        color: #fff !important;
    }
}
.map-selector {
    .field-locations-main {
        position: relative;
        @media (max-width: 435px) {
            .select2-selection {
                &:after {
                    content: unset !important;
                }
            }
        }
        .select2-selection__rendered {
            @include screen-xl {
                li:not(li:nth-child(-n + 2)) {
                    display: none;
                }
            }
            @include screen-xl-down {
                li:not(li:nth-child(-n + 5)) {
                    display: none;
                }
            }
            @media (max-width: 890px) {
                li:not(li:nth-child(-n + 3)) {
                    display: none;
                }
            }
            @media (max-width: 610px) {
                li:not(li:nth-child(-n + 2)) {
                    display: none;
                }
            }
            @media (max-width: 440px) {
                li:not(li:nth-child(-n + 1)) {
                    display: none;
                }
            }
        }
        .select2-selection__choice {
            line-height: 1.4 !important;
        }
        .select2-selection {
            &:after {
                content: attr(data-after);
                width: 152px;
                height: 75%;
                position: absolute;
                right: 1px;
                top: 0;
                background: #020024;
                background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, white 20%, white 100%);
                margin: 5px 0;
                font-size: 13px;
                padding-top: 10px;
                text-align: right;
                padding-right: 10px;
            }
        }
        ul {
            text-overflow: initial !important;
            white-space: nowrap;
            li {
                //float: unset !important;
                height: auto !important;
            }
        }
    }
}

#map-selector-modal {
    background-color: rgba(0,0,0, .5);
    .ui_kit_checkbox {
        .custom-control-label {
            line-height: 18px;
        }
    }
    .ui_kit_checkbox {
        .custom-control-label {
            &:after {
                top: .1rem;
            }
            &:before {
                top: 0;
            }
        }
    }
}
.map-selector-modal {
    @include screen-xl-down {
        .modal-xl {
            max-width: 90% !important;
        }
    }
    @include screen-lg-down {
        .region-checkbox {
            &.custom-region {
                margin-top: 24px;
                .item {
                    display: inline-block;
                    width: 30%;
                }
            }
        }
    }
    @include screen-sm-down {

    }
    @media (max-width: 650px) {
        margin-right: 0;
        .map-selector-wrap {
            .nav-tabs {
                justify-content: space-evenly;
            }
        }
        .region-checkbox {
            &.custom-region {
                .item {
                    width: 49%;
                }
            }
        }
        .modal-xl {
            max-width: 100% !important;
            margin: 0 !important;
            .modal-content {
                border-radius: 0;
                border: none;
            }
        }
        .map-buttons {
            position: sticky;
            bottom: 0;
            left: 0;
            box-shadow: 0 0 4px rgba(0,0,0,.2);
            width: calc(100% + 63px);
            margin-left: -31px;
            margin-right: -31px;
            padding: 25px 0;
            background: #fff;
            text-align: center;
            margin-top: 10px;
        }
        .modal-body {
            margin-bottom: 65px;
        }
    }
    @media (max-width: 500px) {
        .region-checkbox {
            &.custom-region {
                .item {
                    width: 100%;
                }
            }
        }
    }
    h3 {
        margin-bottom: 24px;
    }
    .map-selector-header {
        border-bottom: none;
    }
    .select2-container--krajee-bs4 .select2-selection--multiple .select2-search--inline .select2-search__field {
        width: 100% !important;
    }
    .map-buttons {
        margin-top: 34px;
    }
}

.normal-line-height {
    line-height: 34px;
}

.more-search-types {
    @media (max-width: 1340px) {
        padding-left: 0 !important;
        &.search-dd-wrap {
            margin-left: 0 !important;
        }
    }
}

.feature_property_home6_slider {
    &.owl-carousel {
        z-index: 0;
    }
}
@media (max-width: 650px) {
    #startpage-layers {
        border-bottom: none;
    }
}
@media (max-width: 420px) {
    .map-container {
        display: none !important;
    }
    #startpage-layers {
        .tab-content {
            margin-top: 5px !important;
        }
    }
}
