.grid_list_search_result {
    .bootstrap-select {
        >.dropdown-toggle {
            background-color: #ffffff;
            border: 1px solid #dddddd;
            border-radius: 4px;
            color: #7f7f7f;
            height: 50px;
            line-height: 35px;
            &:hover {
                background-color: #ffffff;
                color: #7f7f7f;
                box-shadow: none;
                outline: none !important;
            }
            &:focus {
                background-color: #ffffff;
                color: #7f7f7f;
                box-shadow: none;
                outline: none !important;
            }
            &:active {
                background-color: #ffffff;
                color: #7f7f7f;
                box-shadow: none;
                outline: none !important;
            }
        }
    }
    .dropdown-item.active {
        background-color: #f7f7f7;
        color: #484848;
    }
    .dropdown-item {
        &:active {
            background-color: #f7f7f7;
            color: #484848;
        }
    }
    background-color: #ffffff;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    height: 77px;
    margin: 0 auto 30px;
    padding: 0 15px;
    position: relative;
    width: 100%;
    .left_area {
        p {
            margin-bottom: 0;
            margin-top: 28px;
        }
    }
    .right_area {
        ul {
            margin-bottom: 0;
            li {
                &:not(.no-border) {
                    &:first-child {
                        margin-right: 40px;
                        position: relative;
                        &:before {
                            background-color: rgb(232, 238, 242);
                            content: "";
                            height: 35px;
                            position: absolute;
                            right: -22px;
                            top: 8px;
                            width: 1px;
                        }
                        .dropdown.bootstrap-select.show-tick {
                            border: none;
                            box-shadow: none;
                            padding-left: 0;
                            padding-right: 0;
                            width: 120px;
                        }
                        button.btn.dropdown-toggle {
                            border: none;
                            box-shadow: none;
                            padding-left: 0;
                            padding-right: 0;
                            width: 120px;
                        }
                    }
                }

                &:last-child {
                    .dropdown.bootstrap-select.show-tick {
                        border: none;
                        box-shadow: none;
                        padding-left: 0;
                        padding-right: 0;
                        width: 227px;
                    }
                    button.btn.dropdown-toggle {
                        border: none;
                        box-shadow: none;
                        padding-left: 0;
                        padding-right: 0;
                        width: 227px;
                    }
                }
                .filter-option {
                    text-align: center;
                    padding-right: 10px;
                }
                .filter-option-inner-inner {
                    font-size: 14px;
                    font-family: Arial;
                    color: #484848;
                }
                span.stts {
                    font-size: 14px;
                    font-family: Arial;
                    color: #484848;
                    font-weight: bold;
                }
                span.shrtby {
                    font-size: 14px;
                    font-family: Arial;
                    color: #484848;
                    font-weight: bold;
                    display: inline-flex;
                }
                span.short-form {
                    display: inline-flex;
                }
            }
        }
        li {
            margin-top: 14px;
        }
        ul.dropdown-menu.inner {
            li {
                margin-right: 0;
                overflow: hidden;
            }
        }
        .bootstrap-select.show-tick {
            .dropdown-menu {
                li {
                    a {
                        span.text {
                            color: #777777;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .right_area.style2 {
        ul {
            li {
                &:first-child {
                    margin-right: 0;
                    &:before {
                        display: none;
                    }
                    button.btn.dropdown-toggle {
                        border: none;
                        box-shadow: none;
                        padding-left: 0;
                        padding-right: 0;
                        width: 120px;
                    }
                }
            }
        }
    }
    .half_map_advsrch_navg {
        ul {
            li {
                &:first-child {
                    &:before {
                        background-color: rgb(232, 238, 242);
                        content: "";
                        height: 35px;
                        position: absolute;
                        right: -20px;
                        top: 7px;
                        width: 1px;
                    }
                    margin-right: 35px;
                    position: relative;
                }
                margin-right: 15px;
                margin-top: 6px;
                ul.dropdown-menu.inner.show {
                    li {
                        margin-right: 0;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                .dropdown.bootstrap-select.show-tick {
                    border: none;
                    box-shadow: none;
                    padding-left: 0;
                    padding-right: 0;
                    width: 120px;
                }
                button.btn.dropdown-toggle {
                    border: none;
                    box-shadow: none;
                    padding-left: 0;
                    padding-right: 0;
                    width: 120px;
                }
            }
        }
        .dropdown.bootstrap-select.show-tick.show {
            .dropdown-menu.show {
                .inner.show {
                    overflow-y: initial !important;
                }
            }
        }
    }
}

.bold-label {
    margin-bottom: 20px;
    font-size: 15px;
    color: $dark-grey;
    font-weight: 700;
}

.search-dd-wrap {
    height: 52px;
    align-items: center;
}

.h-search {
    height: 52px;
}
.dropdown-menu.show {
    left: -20px !important;
}

.list-save-search{ 
    min-height: 225px; 
    background-color: white;
    border: 1px solid $very-light-gray;
    border-radius: 10px;
    .list-save-search-image{
        background-image: url("/images/svg/mail.svg"); 
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 170px;
    } 
    .save-search-text-container{
        padding: 20px;
        .search-title{
            font-weight: bold;
            font-size: 18px;
        }
    }
    .rounded-box{
        background-color: $very-light-gray;
        border-radius: 100px;
        height: 160px;
        width: 160px;
        margin: auto;
        margin-top: 15%;
    }
    img{ 
        margin-top: 18%;
        height: 140px;
    }
    .btn-transparent{
        justify-content: center;
        align-items: center !important;
        display: flex !important; 
        line-height: normal !important;
    }

}